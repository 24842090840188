@import "../../utils/_variables";

.ArbitrationStatusStreamer_Container {
  @include row-evenly;

  width: 100%;
  height: 250px;
  max-height: 280px;
  padding: 10px;
  @media #{$mobileAndTabletOnly} {
    @include col-evenly
  }

  .ArbitrationStatusStreamer_TitleBox {
    @include col-center;
    width: 30%;
    @media #{$mobileAndTabletOnly} {
      width: 100%
    }


    .quest-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100px;
      width: 130px;
      margin-right: auto;
      margin-left: auto;
      position: relative;
      margin-top: 10px;
      margin-right: 25px;
      @media #{$mobileAndTabletOnly} {
        margin: 0px 0px 0px 0px;
        height: 80px;
        width: 100px;
        
      }
    }

  }

  .ArbitrationStatusStreamer_Decision {
    width: 70%;
    overflow-y: scroll;
    @include col-evenly;
    @media #{$mobileAndTabletOnly} {
      width: 100%;
      height: 100%;
      margin: 0px;
      padding: 0px;
      overflow: show;
    }
    height: 100%;
    // min-height: 250px;
    border-radius: 8px;
    margin: 15px 0px;
    .title {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.24px;
      color: #4be0ca;
      width: 100%;
    }
    h2 {
      font-family: Roboto;
      font-size: 18px;
      width: 100%;
      @media #{$mobileAndTabletOnly} {
        font-size: 14px;
      }
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.3px;
      text-align: center;
      color: #fff;
    }
    .decision {
      width: 100%;
      font-size: 16px;
      @media #{$mobileAndTabletOnly} {
        font-size: 14px;
      }
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.5px;
      color: #a142ff;
    }


    .reason {
      width: 100%;
    }

  .ArbitrationStatusStreamer_Comments {
    width: 100%;
    h2 {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.3px;
      color: #fff;
    }

    .comments {
      text-align: left;

    }
  }

  }

}
