@import "../../utils/_variables";

.Profile {
  padding-bottom: 150px;
  .add-hover-glow {
    @include hoverGlow;
  }
  .inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .Profile__container {
    padding-top: 200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 700px;
    // Mobile
    padding-right: 15px;
    padding-left: 15px;
    @media #{$tabletAndLarger} {
      padding-right: 0;
      padding-left: 0;
    }
    /*********** START PROFILE TOP ***********/
    .Profile__top {
      display: flex;
      .Profile__top__left {
        justify-content: flex-start;
        font-size: 32px;
      }
      .Profile__top__right {
        margin-left: auto;
        justify-content: flex-end;
        .Profile__top__balance {
          font-size: 16px;
          color: #83858c;
          display: flex;
          text-align: right;
          justify-content: flex-end;
        }
        .Profile__top__amount {
          font-size: 20px;
          font-weight: 700;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    /*********** END PROFILE TOP ***********/
    /*********** START PROFILE INFO ***********/
    .Profile__info {
      margin-top: 20px;
      padding-bottom: 30px;
      background-color: #202227;
      .Profile__info__title {
        color: #83858c;
        padding: 30px 0 10px 25px;
        font-size: 12px;
      }
      .Profile__info__content {
        @media #{$tabletAndLarger} {
          display: flex;
        }

        .Profile__info__right {
          padding: 0 20px;
          flex: 10;

          @media #{$tabletAndLarger} {
            border-left: #2e2f32 2px solid;
            padding: 0 10px;
          }
          .Profile__info__form {
            border-top: #2e2f32 2px solid;
            padding-top: 35px;
            @media #{$tabletAndLarger} {
              padding-top: 0;
              border-top: none;
            }
            .Profile__info__form__row {
              span.disabled {
                color: #83858c;
              }
              &.mobile__hide {
                display: none;
              }
              @media #{$tabletAndLarger} {
                display: flex;
                // not redundant
                &.mobile__hide {
                  display: flex;
                }
              }
              justify-content: space-around;
              .Profile__info__form__left {
                flex-basis: 45%;
                position: relative;
                input[disabled] {
                  cursor: default;
                  color: #83858c;
                }
                .Profile__info__change-password {
                  color: #3a74e0;
                  position: absolute;
                  top: 22px;
                  right: 0;
                  font-size: 12px;
                  cursor: pointer;
                  z-index: 2;
                }
              }
              .Profile__info__form__right {
                flex-basis: 45%;
                position: relative;
                input[disabled] {
                  cursor: default;
                  color: #83858c;
                }
                .Profile__info__change-password {
                  color: #3a74e0;
                  position: absolute;
                  top: 22px;
                  right: 0;
                  font-size: 12px;
                  cursor: pointer;
                  z-index: 2;
                }
              }
              .Profile__info__form__single {
                flex-basis: 95%;
                input[disabled] {
                  cursor: default;
                  color: #83858c;
                }
              }
              .Profile__info__form__last {
                flex-basis: 95%;
                display: flex;
                justify-content: flex-end;
                button {
                  margin-left: 6px;
                }
              }
              label {
                color: #83858c;
                font-size: 12px;
                line-height: 12px;
              }
              .input-wrapper {
                position: relative;
                .error-message {
                  color: #dc4f4f;
                  font-size: 12px;
                  position: absolute;
                  right: 0;
                  bottom: -17px;
                }
                .error-icon {
                  color: #dc4f4f;
                  display: none;
                  position: absolute;
                  right: 10px;
                  top: 10px;
                }
                .ok-icon {
                  position: absolute;
                  right: 10px;
                  bottom: 5px;
                  color: #49e1ca;
                }
              }
              input,
              select {
                background-color: #202227;
                border: none;
                border-bottom: 2px solid #83858c;
                border-radius: 0px;
                color: white;
                display: inline-block;
                padding-left: 0;
                text-align: left;
                margin: 0;
                width: 100%;
              }
              input.error,
              select.error {
                border-bottom: 2px solid #dc4f4f;
              }
              select {
                background-image: none;
              }
              a {
                text-decoration: none;
              }
              ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: rgb(131, 133, 140);
                opacity: 1; /* Firefox */
              }

              :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: rgb(131, 133, 140);
              }

              ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: rgb(131, 133, 140);
              }
              .first-option {
                color: rgb(131, 133, 140);
              }
              input:focus,
              select:focus {
                border-bottom: 2px solid #3a74e0;
                box-shadow: none;
              }
              input:placeholder-shown + label {
                cursor: text;
                max-width: 66.66%;
                color: #d1d1d1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                transform-origin: left bottom;
                transform: translate(0, 1.9rem) scale(1.5);
                &:after {
                  content: attr(data-label);
                }
              }
              .input-wrapper {
                display: flex;
                flex-flow: column-reverse;
                margin-bottom: 1em;
                position: relative;
              }
              input,
              select {
                z-index: 1;
                background-color: transparent;
              }
              select option {
                background-color: #202227;
              }
              label {
                z-index: 0;
              }
              label,
              input {
                transition: all 0.2s;
                touch-action: manipulation;
              }
            }
          }
        }
        .Profile__info__left {
          display: flex;
          flex: 3;
          justify-items: center;
          // Mobile
          margin-bottom: 50px;
          @media #{$tabletAndLarger} {
            display: flex;
            margin-bottom: 0px;
          }
          .UserInfo {
            display: flex;
            flex: 1;
            justify-content: space-around;
            .UserInfo__profile {
              color: #fff;
              display: flex;
              flex-basis: 90%;
              align-items: flex-start;
              justify-content: center;
              font-family: "Lato", sans-serif;
              position: relative;
              .UserInfo__profile--container {
                position: relative;
                max-width: 95px;
                max-height: 95px;
                width: 100%;
                height: 100%;
                cursor: pointer;
                border-radius: 100%;
                background: linear-gradient(to bottom, #02bdad, #036ccf);
                .UserInfo__profile--container-avatar {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);

                  width: calc(100% - 6px);
                  height: calc(100% - 6px);
                  padding: 0;
                  margin: 0;
                  border-radius: 100%;
                  background-repeat: no-repeat;
                  background-size: contain;
                  object-fit: cover;
                  cursor: pointer;
                }
                .UserInfo__profile__button {
                  cursor: pointer;
                  padding-top: 100px;
                  color: #3a74e0;
                  font-size: 14px;
                  font-weight: 700;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
    /*********** END PROFILE INFO ***********/
    /*********** START PROFILE CONNECT ***********/
    .Profile__connect {
      margin-top: 50px;
      background-color: #202227;
      .Profile__connect__title {
        color: #83858c;
        padding: 30px 0 10px 25px;
        font-size: 12px;
      }
      .Profile__connect__message {
        color: $colorLight;
        padding: 0 0 10px 25px;
        font-size: 12px;
        a {
          color: $colorLight;
        }
      }
      .Profile__connect__content {
        margin-bottom: 50px;
        padding: 0 25px 25px 25px;
        position: relative;
      }
    }
    /*********** END PROFILE CONNECT ***********/
  }
  .cta__button {
    background-image: linear-gradient(
      to bottom,
      #3a74e0,
      #4a6fe6,
      #5d69ea,
      #7061ec,
      #8358ed
    );
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    font-weight: normal;
    border-radius: 2px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 20px;
  }
  .cta2__button {
    -webkit-box-shadow: inset 0px 0px 0px 2px #83858c;
    -moz-box-shadow: inset 0px 0px 0px 2px #83858c;
    box-shadow: inset 0px 0px 0px 2px #83858c;
    background-color: #202227;
    color: #83858c;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    font-weight: normal;
    border-radius: 2px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 20px;
  }
  .ProfileUpdateImage {
    font-family: Roboto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 100%;
    padding-top: 120px;
    button.StripeCheckout {
      margin-top: 60%;
    }
    .ModalMaster {
      .Modal__updateavatar {
        .Modal__wrapper {
          max-height: 350px;
          max-width: 350px;
          display: flex;
          flex-flow: wrap;
          background: $colorBackground;
          .Modal__updateavatar__content {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin: 4%;
            width: 92%;
            height: 92%;
            cursor: pointer;
            .Modal__updateavatar__image {
              padding-top: 30px;
              background: url("../../images/profile/upload.png");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              height: 50px;
              width: 55px;
              margin-right: auto;
              margin-left: auto;
              position: relative;
            }
            .Modal__updateavatar__title {
              padding-top: 25px;
              font-weight: 700;
              font-size: 20px;
              width: 100%;
              color: white;
              position: relative;
            }
            .Modal__updateavatar__or {
              padding-top: 10px;
              font-size: 14px;
              color: $colorLightGray;
              width: 100%;
              position: relative;
            }
            .Modal__updateavatar__action {
              padding-top: 10px;
              position: relative;
              button,
              label {
                padding: 15px 30px;
                font-weight: 900;
                font-size: 16px;
              }
              label {
                width: 50%;
                line-height: 1;
                margin: 0 auto;
              }
            }
            .Modal__updateavatar__loading {
              margin-top: 60px;
              height: 70px;
              margin-right: 40px;
              margin-left: 40px;
              position: relative;
              background: $colorDarkGray;
              .Modal__updateavatar__loading__progress {
                position: absolute;
                height: 100%;
                width: 42%;
                background-color: $colorEnergyBlue;
              }
              .Modal__updateavatar__loading__percentage {
                position: absolute;
                font-weight: 900;
                right: 10px;
                font-size: 30px;
                line-height: 70px;
                color: $colorBackground2;
              }
              .Modal__updateavatar__loading__fileinfo {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .Modal__updateavatar__loading__top {
                  position: relative;
                  font-size: 18px;
                  text-align: left;
                  padding-left: 15px;
                }
                .Modal__updateavatar__loading__bottom {
                  position: relative;
                  font-size: 12px;
                  text-align: left;
                  font-weight: 300;
                  padding-left: 15px;
                }
              }
            }
            .Modal__updateavatar__title2 {
              padding-top: 10px;
              font-weight: 700;
              font-size: 20px;
              width: 100%;
              color: white;
              position: relative;
            }
            .Modal__updateavatar__button {
              cursor: pointer;
              padding-top: 10px;
              color: $colorEnergyBlue;
              font-size: 14px;
              font-weight: 700;
            }
            .Modal__updateavatar__avatar {
              color: #fff;
              display: flex;
              width: 140px;
              height: 140px;
              align-items: center;
              justify-content: center;
              justify-self: center;
              margin-right: auto;
              margin-left: auto;
              font-family: "Lato", sans-serif;
              position: relative;
              .Modal__updateavatar__avatar--container {
                position: relative;
                max-width: 130px;
                max-height: 130px;
                width: 100%;
                height: 100%;
                border-radius: 100%;
                background: linear-gradient(to bottom, #02bdad, #036ccf);
                .Modal__updateavatar__avatar--container-avatar {
                  width: calc(100% - 6px);
                  height: calc(100% - 6px);
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  padding: 0;
                  margin: 0;
                  border-radius: 100%;
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }
            }
          }
        }
      }
      .Modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        opacity: 0;
        //    transition: 200ms ease all;
        overflow-x: hidden;
        background: rgba(0, 0, 0, 0.5);

        .Modal__layer {
          // background: rgba(0,0,0,0.5);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
        }
        align-items: flex-start;
        @media screen and (min-width: 640px) {
          align-items: center;
        }

        .Modal__wrapper {
          width: 90%;
          height: 90%;
          position: relative;
          text-align: center;
          align-items: center;
          justify-content: center;
          transform: scale(0.8);
          margin: 5vw 0 5vh;
          @media screen and (min-width: 640px) {
            margin: 0;
          }
          .Modal__updateavatar__border {
            position: absolute;
            width: 92%;
            height: 92%;
            border: 2px dashed $colorBackground2;
            &.active {
              border: 2px solid $colorEnergyBlue;
            }
          }
          .icon {
            width: 50px;
            margin: 30px auto;
            img {
              width: 100%;
            }
          }
          .Modal__title {
            font-size: 25px;
            letter-spacing: 2px;
            margin: 20px 0 0;
          }
          .Modal__close-btn {
            width: 35px;
            height: 35px;
            margin-left: auto;
            position: absolute;
            right: 25px;
            top: 25px;
            cursor: pointer;
            z-index: 10;
            $crossColor: $colorBackground2;
            &:before {
              content: "";
              width: 3px;
              height: 90%;
              background: $crossColor;
              display: block;
              position: absolute;
              left: 50%;
              // top: 50%;
              transform-origin: center;
              transform: rotate(45deg);
            }
            &:after {
              content: "";
              width: 3px;
              height: 90%;
              background: $crossColor;
              display: block;
              position: absolute;
              left: 50%;
              // top: 50%;
              transform-origin: center;
              transform: rotate(-45deg);
            }
          }
        }

        &.active {
          visibility: visible;
          opacity: 1;

          .Modal__wrapper {
            transform: scale(1);
            //transition: 200ms ease all;
          }
        }
      }
    }
  }
}
