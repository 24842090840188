@import "../../../../../utils/_variables";

.DeliverableCard {
  display: flex;
  align-items: center;

  position: relative;

  height: 147px;
  width: 100%;
  margin: 10px 0 20px 0;
  padding: 14px;

  @media #{$mobileOnly} {
    @include col-center;

    height: auto;
    padding: 20px 5px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  background-color: $colorBackground;
  border-radius: 10px;

  .DeliverableModal {
    display: flex;
    flex-direction: column;

    height: 200px;
    width: 60%;

    background-color: $colorBackground;
  }

  .DeliverableCard_Profile_Img {
    height: 118px;
    width: 118px;

    margin-right: 23px;

    @media #{$mobileOnly} {
      margin-right: 0px;
      margin-bottom: 10px;
    }

    border-radius: 8px;
    object-fit: cover;
  }

  .DeliverableCard__Right {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$mobileOnly} {
      @include col-center;
    }

    width: 100%;

    .DeliverableCard__Right__Info {
      display: flex;
      flex-direction: column;

      @media #{$mobileOnly} {
        @include col-center;
        margin: 10px 0px;
      }

      .DeliverableCard__Right__Buttons {
        display: flex;
      }

      .DeliverableCard__Right__Info__Name {
        color: $colorSoftPurple;
        font-size: 24px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.24px;
      }

      .DeliverableCard__Right__Info__Time {
        color: $colorCyan;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.32px;
      }
    }

    .DeliverableCard__Right__Review__Buttons {
      display: flex;
      align-items: center;

      .DeliverableCard__Right__Review__Buttons__Button {
        @include gray-border-button;

        height: 48px;
        width: 170px;

        &.Completed {
          @include purple-gradient-button;
        }
      }
    }
  }

  .DeliverableCard__Progress {
    position: absolute;

    width: 100%;

    left: 0;
    bottom: 0;

    border-radius: 0 0 10px 10px;

    .DeliverableCard__Progress__Bar {
      height: 7px;

      border-radius: 0 10px 10px 10px;

      background-image: linear-gradient(to right, #b7bbfd, #e8cdfe);

      &.Completed {
        background-image: none;
        background-color: $colorCyan;
        border-radius: 0 0 10px 10px;
      }
    }
  }
}

.DeliverableButton {
  @include center-content;

  height: 32px;
  width: 32px;

  margin-right: 10px;

  background-color: $colorFGray;
  border-radius: 50%;

  svg {
    height: auto;
    width: 14px;
  }

  .DeliverableButton__Icon {
    height: auto;
    width: 14px;

    filter: brightness(0) invert(1);
  }

  &.Completed {
    background-image: linear-gradient(to bottom, #4be0ca, #62e841);

    .DeliverableButton__Icon {
      filter: brightness(0);
    }
  }
}
