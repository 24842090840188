@import "../../../../../utils/_variables";

.DeliverableModal {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 10px;

  .DeliverableModal__Close {
    position: absolute;
    top: -15px;
    right: -5px;

    cursor: pointer;

    svg {
      height: 14px;
      width: auto;
    }
  }

  .DeliverableModal__Title {
    font-size: 36px;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .DeliverableModal__Subheader {
    margin-bottom: 10px;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .DeliverableModal__Content {
    display: flex;
    flex-direction: column;

    width: 100%;

    .DeliverableModal__Content__Submission {
      @include center-content;

      // width: 100%;

      padding: 15px;
      border-radius: 5px;
      background-color: $colorCDarkGray;
      overflow-y: auto;

      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;

      cursor: pointer;
    }
  }

  .DeliverableModal__Actions {
    @include center-content;

    width: 100%;
    margin-top: 20px;

    & > * {
      margin: 0 10px;
    }

    .DeliverableModal__Actions__Button {
      @include center-content;
    
      height: 40px;
      width: 40px;
    
      border-radius: 50%;
      cursor: pointer;
    
      svg {
        height: auto;
        width: 18px;
      }
    
      &.Approve {
        border: 3px solid $colorCandyGreen;
      }
    
      &.Back {
        border: 3px solid $colorSoftGray;
      }
    
      &.Reject {
        border: 3px solid $colorCandyRed;
      }
    } 
  }
}
