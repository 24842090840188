@import  '../../../utils/_variables';

.closeCampaignSelectorOverlay {
	background: rgba(0,0,0,0.5);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
  z-index: 2;
}

.CampaignSelector {
	display: flex;
	align-items: center;
	height: 50%;
	min-width: 400px;
	border-left: 1px solid #2c3239;
	margin: 0 0 0 24px;
	cursor: pointer;
	z-index: 3;
	
	.selection {
		margin: 0 0 0 12px;
		display: flex;
		flex-direction: column;
	}

	img {
		float: left;
		width: 44px;
		height: 44px;
		margin: 0 0 0 17px;
	}

	h3 {
		font-size: 16px;
		margin: 0;
	}

	span.dateBudget {
		font-size: 14px;
		color: $colorGray;
		margin: 0 7px 0 0;

		&.border {
			border-left: 1px solid $colorGray;
			margin: 0;
			padding: 0 0 0 7px;
		}
	}

	.fa {
		margin: 0 20px 0 auto;
	}

	.campaignMenu {
		position: absolute;
		height: 230px;
		width: 400px;
		overflow-y: scroll;
		top: 90%;

		.campaign {
			background: #3e424d;
			padding: 17px;
			border-bottom: 1px solid #202227;

			a {
				text-decoration: none;
			}
		}
	}
}