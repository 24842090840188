/* Style Guide v1 */
.typo_hero {
  font-family: "Roboto";
  font-weight: 700;
  line-height: 80px;
  font-size: 4.5vw; }
  @media (min-aspect-ratio: 16 / 9) {
    .typo_hero {
      font-size: 8vh; } }
  @media (max-width: 768px) {
    .typo_hero {
      font-size: 8vw; } }

.typo_heading_1 {
  font-family: "Roboto";
  font-weight: 700;
  line-height: 60px;
  font-size: 3.125vw; }
  @media (min-aspect-ratio: 16 / 9) {
    .typo_heading_1 {
      font-size: 5.55556vh; } }
  @media (max-width: 768px) {
    .typo_heading_1 {
      font-size: 5.55556vw; } }

.typo_heading_2 {
  font-family: "Roboto";
  font-weight: 700;
  line-height: 40px;
  font-size: 2vw; }
  @media (min-aspect-ratio: 16 / 9) {
    .typo_heading_2 {
      font-size: 3.55556vh; } }
  @media (max-width: 768px) {
    .typo_heading_2 {
      font-size: 3.55556vw; } }

.typo_heading_3 {
  font-family: "Roboto";
  font-weight: 700;
  line-height: 32px;
  font-size: 1.25vw; }
  @media (min-aspect-ratio: 16 / 9) {
    .typo_heading_3 {
      font-size: 2.22222vh; } }
  @media (max-width: 768px) {
    .typo_heading_3 {
      font-size: 2.22222vw; } }

.typo_paragraph_big {
  font-family: "Roboto";
  font-weight: 400;
  line-height: 32px;
  font-size: 1.25vw; }
  @media (min-aspect-ratio: 16 / 9) {
    .typo_paragraph_big {
      font-size: 2.22222vh; } }
  @media (max-width: 768px) {
    .typo_paragraph_big {
      font-size: 2.22222vw; } }

.typo_paragraph {
  font-family: "Roboto";
  font-weight: 400;
  line-height: 24px;
  font-size: 1vw; }
  @media (min-aspect-ratio: 16 / 9) {
    .typo_paragraph {
      font-size: 1.77778vh; } }
  @media (max-width: 768px) {
    .typo_paragraph {
      font-size: 1.77778vw; } }

.typo_subtitle {
  font-family: "Roboto";
  font-weight: 700;
  line-height: 20px;
  font-size: 0.75vw; }
  @media (min-aspect-ratio: 16 / 9) {
    .typo_subtitle {
      font-size: 1.33333vh; } }
  @media (max-width: 768px) {
    .typo_subtitle {
      font-size: 1.33333vw; } }

.typo_small_paragraph {
  font-family: "Roboto";
  font-weight: 700;
  line-height: 20px;
  font-size: 0.75vw; }
  @media (min-aspect-ratio: 16 / 9) {
    .typo_small_paragraph {
      font-size: 1.33333vh; } }
  @media (max-width: 768px) {
    .typo_small_paragraph {
      font-size: 1.33333vw; } }

/* Style Guide v1 */
.slide-enter {
  transform: translateY(-100%); }

.slide-enter.slide-enter-active {
  transform: translateY(0%);
  transition: transform 300ms ease-in-out; }

.slide-leave {
  transform: translateY(0%); }

.slide-leave.slide-leave-active {
  transform: translateY(100%);
  transition: transform 300ms ease-in-out; }

.fadeinout-enter {
  opacity: 0.01; }

.fadeinout-enter-active {
  opacity: 1;
  transition: all 300ms ease-in; }

.fadeinout-exit {
  opacity: 1; }

.fadeinout-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-in; }

.longfadeinout-enter {
  opacity: 0.01; }

.longfadeinout-enter-active {
  opacity: 1;
  transition: all 3000ms ease-in; }

.longfadeinout-exit {
  opacity: 1; }

.longfadeinout-exit-active {
  opacity: 0.01;
  transition: all 3000ms ease-in; }

.LogIn {
  /* Track */
  /* Handle */
  /* Handle on hover */
  align-items: center;
  background-image: url("../images/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  font-family: Roboto;
  justify-content: center;
  margin-top: -70px;
  min-height: 100vh;
  min-width: 100%;
  padding-top: 70px; }
  .LogIn ::-webkit-scrollbar {
    width: 10px; }
  .LogIn ::-webkit-scrollbar-track {
    background: #202227; }
  .LogIn ::-webkit-scrollbar-thumb {
    background: #888; }
  .LogIn ::-webkit-scrollbar-thumb:hover {
    background: #49E1CA; }
  .LogIn .errors-container {
    color: #dc4f4f;
    font-size: 13px;
    text-align: left;
    padding: 5px 25px 5px 10px;
    border-radius: 1px;
    border: 1px solid #dc4f4f;
    position: relative;
    margin: 0 0 30px 0; }
  .LogIn .success-container {
    color: #4fdc4f;
    font-size: 13px;
    text-align: left;
    padding: 5px 25px 5px 10px;
    border-radius: 1px;
    border: 1px solid #4fdc4f;
    position: relative;
    margin: 0 0 30px 0; }
  .LogIn form.client-portal input {
    border: solid 2px #313337;
    background-color: #202227;
    margin: 10px 0 25px 0;
    color: #fff; }
  .LogIn form.client-portal input.error, .LogIn form.client-portal select.error {
    border: 2px solid #dc4f4f; }
  .LogIn form.client-portal .fa-exclamation, .LogIn form.client-portal .fa-check, .LogIn form.client-portal error-icon {
    display: none; }
  .LogIn form.client-portal .error-message {
    color: #dc4f4f;
    font-size: 12px;
    position: absolute;
    margin: -20px 0px 0 0;
    right: 40px; }
  .LogIn .ModalMaster .Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    overflow-x: hidden;
    background: rgba(0, 0, 0, 0.5);
    align-items: flex-start; }
    .LogIn .ModalMaster .Modal .Modal__layer {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer; }
    @media screen and (min-width: 640px) {
      .LogIn .ModalMaster .Modal {
        align-items: center; } }
    .LogIn .ModalMaster .Modal .Modal__wrapper {
      width: 90%;
      max-width: 1224px;
      height: 90%;
      max-height: 850px;
      background: #202227;
      padding: 0;
      position: relative;
      text-align: center;
      align-items: center;
      justify-content: space-between;
      transform: scale(0.8);
      display: flex; }
      @media (max-width: 1024px) {
        .LogIn .ModalMaster .Modal .Modal__wrapper {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          max-height: 9999px; } }
      @media (max-width: 768px) {
        .LogIn .ModalMaster .Modal .Modal__wrapper {
          flex-direction: column;
          min-height: 100%;
          height: auto;
          justify-content: flex-start; } }
      .LogIn .ModalMaster .Modal .Modal__wrapper .icon {
        width: 50px;
        margin: 30px auto 30px auto; }
        .LogIn .ModalMaster .Modal .Modal__wrapper .icon img {
          width: 100%; }
      .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__content {
        padding: 0 18%; }
        @media (min-width: 769px) {
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__content {
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translate(0, -50%); } }
        @media (max-width: 1024px) {
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__content {
            padding: 0 10%; } }
        @media (max-width: 768px) {
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__content {
            padding: 0; } }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__content .datepicker-label {
          position: absolute;
          top: -5px;
          z-index: 0; }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__content .date {
          margin-top: 8px !important; }
      .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__left {
        background-image: url(../images/login-img-desktop.jpg);
        background-position: center;
        background-size: cover;
        width: 30%;
        height: 100%;
        position: relative; }
        @media (max-width: 768px) {
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__left {
            width: 100%;
            height: 30vh;
            background-image: url(../images/login-img-mobile.jpg); } }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__left .logo {
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          width: 100px;
          height: 38px;
          top: 30px;
          left: 30px; }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__left .blurb {
          display: block;
          position: absolute;
          color: #202227;
          width: 100%;
          font-weight: 900;
          text-align: left;
          padding: 0 40px 0 30px;
          bottom: 20%; }
          @media (max-width: 768px) {
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__left .blurb {
              display: none; } }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__left .shapes-container {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 120px;
          height: 120px; }
          @media (max-width: 768px) {
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__left .shapes-container {
              width: 64px;
              height: 64px; } }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__left .shapes-container .box {
            position: absolute; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__left .shapes-container .box1 {
            width: 33%;
            height: 33%;
            background: #3a74e0; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__left .shapes-container .box2 {
            width: 67%;
            height: 67%;
            background: #202227;
            bottom: 0;
            right: 0; }
      .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right {
        width: 70%;
        overflow-y: scroll;
        position: relative;
        height: 100%; }
        @media (max-width: 768px) {
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right {
            width: 88%;
            height: auto; } }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .input-container label {
          color: #83858c;
          text-align: left;
          font-size: 12px;
          line-height: 12px;
          margin-top: 0px; }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .input-container .input-wrapper {
          position: relative; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .input-container .input-wrapper .error-message {
            color: #dc4f4f;
            font-size: 12px;
            position: absolute;
            right: 0;
            bottom: -17px;
            top: 50px; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .input-container .input-wrapper .success-message {
            color: #49E1CA;
            font-size: 12px;
            position: absolute;
            right: 0;
            bottom: -17px; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .input-container .input-wrapper .error-icon {
            color: #dc4f4f;
            display: none;
            position: absolute;
            right: 10px;
            top: 10px; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .input-container .input-wrapper .ok-icon {
            position: absolute;
            right: 5px;
            bottom: 5px;
            color: #49E1CA; }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right ul.Modal__tabs {
          margin-bottom: 60px;
          padding: 0;
          text-align: left; }
          @media (max-width: 768px) {
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right ul.Modal__tabs {
              margin-bottom: 40px; } }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right ul.Modal__tabs li {
            display: inline-block;
            font-size: 40px;
            color: #36373B;
            padding-right: 30px; }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right ul.Modal__tabs li a {
              display: inline-block;
              background: initial;
              padding: initial;
              text-transform: inherit;
              transition: 300ms ease background;
              position: relative;
              cursor: pointer;
              z-index: 1;
              font-weight: 900;
              text-decoration: none;
              color: #37393d; }
              .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right ul.Modal__tabs li a.Modal__tab:after {
                content: "";
                display: block;
                width: 0%;
                position: absolute;
                bottom: 8px;
                height: 12px;
                z-index: -1;
                transition: 200ms ease all; }
              .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right ul.Modal__tabs li a.Modal__tab:hover:after, .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right ul.Modal__tabs li a.Modal__tab.active:after {
                background: #49E1CA;
                z-index: -1;
                width: 100%;
                transition: 200ms ease all; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right ul.Modal__tabs li a.active {
            color: white; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right ul.Modal__tabs li a:hover {
            color: white; }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .success-container {
          display: flex;
          font-size: 13px;
          text-align: left;
          padding: 0px 25px 0px 10px;
          border-radius: 1px;
          position: relative;
          margin: 0;
          background: rgba(255, 255, 255, 0.01);
          align-items: center;
          visibility: hidden;
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          transition: ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms padding, ease 300ms margin; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .success-container.active {
            visibility: visible;
            opacity: 1;
            max-height: inherit;
            min-height: 35px;
            padding: 5px 25px 5px 10px;
            margin: 0 0 30px 0; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .success-container:hover {
            background: rgba(255, 255, 255, 0.03); }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .success-container .success-dismiss {
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            top: 8px;
            right: 10px;
            background: rgba(79, 220, 79, 0.22);
            cursor: pointer;
            outline: none;
            transition: ease 200ms all; }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .success-container .success-dismiss::before {
              content: '';
              height: 1px;
              width: 10px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
              background: rgba(255, 255, 255, 0.5);
              transition: ease 200ms all; }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .success-container .success-dismiss::after {
              content: '';
              height: 1px;
              width: 10px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
              background: rgba(255, 255, 255, 0.5);
              transition: ease 200ms all; }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .success-container .success-dismiss:hover {
              background: rgba(79, 220, 79, 0.5); }
              .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .success-container .success-dismiss:hover::before {
                background: rgba(255, 255, 255, 0.7); }
              .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .success-container .success-dismiss:hover::after {
                background: rgba(255, 255, 255, 0.7); }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .errors-container {
          display: flex;
          color: #dc4f4f;
          font-size: 13px;
          text-align: left;
          padding: 0px 25px 0px 10px;
          border-radius: 1px;
          border: 1px solid #dc4f4f;
          position: relative;
          margin: 0;
          background: rgba(255, 255, 255, 0.01);
          align-items: center;
          visibility: hidden;
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          transition: ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms padding, ease 300ms margin; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .errors-container.active {
            visibility: visible;
            opacity: 1;
            max-height: inherit;
            min-height: 35px;
            padding: 5px 25px 5px 10px;
            margin: 0 0 30px 0; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .errors-container:hover {
            background: rgba(255, 255, 255, 0.03); }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .errors-container .error-dismiss {
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            top: 8px;
            right: 10px;
            background: rgba(220, 79, 79, 0.22);
            cursor: pointer;
            outline: none;
            transition: ease 200ms all; }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .errors-container .error-dismiss::before {
              content: '';
              height: 1px;
              width: 10px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
              background: rgba(255, 255, 255, 0.5);
              transition: ease 200ms all; }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .errors-container .error-dismiss::after {
              content: '';
              height: 1px;
              width: 10px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
              background: rgba(255, 255, 255, 0.5);
              transition: ease 200ms all; }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .errors-container .error-dismiss:hover {
              background: rgba(220, 79, 79, 0.5); }
              .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .errors-container .error-dismiss:hover::before {
                background: rgba(255, 255, 255, 0.7); }
              .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right .errors-container .error-dismiss:hover::after {
                background: rgba(255, 255, 255, 0.7); }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup {
          width: 100%; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input, .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup select {
            background-color: #202227;
            border: none;
            border-bottom: 2px solid #83858C;
            border-radius: 0px;
            color: white;
            display: inline-block;
            padding-left: 0;
            text-align: left;
            margin: 0;
            width: 100%; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input.error, .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup select.error {
            border-bottom: 2px solid #dc4f4f; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup select {
            background-image: none; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup a {
            text-decoration: none; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #202227;
            opacity: 1;
            /* Firefox */ }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #202227; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #202227; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup .firstOptionSelected {
            color: #d1d1d1; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input:focus, .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup select:focus {
            border-bottom: 2px solid #3A74E0;
            box-shadow: none; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input:placeholder-shown + label {
            cursor: text;
            max-width: 66.66%;
            color: #d1d1d1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transform-origin: left bottom;
            transform: translate(0, 1.9rem) scale(1.5); }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input:placeholder-shown + label:after {
              content: attr(data-label); }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup select.firstOptionSelected + label {
            cursor: text;
            max-width: 66.66%;
            color: #d1d1d1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transform-origin: left bottom;
            transform: translate(0, 1.9rem) scale(1.5); }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup select.firstOptionSelected + label:after {
              content: attr(data-label); }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup .input-wrapper {
            display: flex;
            flex-flow: column-reverse;
            margin-bottom: 2em; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input, .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup select {
            z-index: 1;
            background-color: transparent; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup select option {
            background-color: #202227; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup label {
            z-index: 0; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup label, .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input {
            transition: all 0.2s;
            touch-action: manipulation; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup ::-webkit-input-placeholder {
            opacity: 0;
            transition: inherit; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input:focus::-webkit-input-placeholder {
            opacity: 1; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup _:-ms-lang(x), .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input + label {
            color: #83858c !important;
            transform: translate(0, 0) scale(1); }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup _:-ms-lang(x):after, .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input + label:after {
              content: attr(data-label-small); }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input:not(:placeholder-shown) + label,
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input:focus + label,
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup select:not(.firstOptionSelected) + label,
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup select:focus + label {
            color: #83858c !important;
            transform: translate(0, 0) scale(1);
            cursor: pointer; }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input:not(:placeholder-shown) + label:after,
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup input:focus + label:after,
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup select:not(.firstOptionSelected) + label:after,
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup select:focus + label:after {
              content: attr(data-label-small); }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup .i_agree_wrap .input-container {
            display: inline-block !important;
            width: 20px !important;
            margin-left: -20px !important;
            vertical-align: text-top; }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup .i_agree_wrap .input-container .input-wrapper {
              margin: 0; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup .i_agree_wrap .ok-icon {
            display: none; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup .i_agree_error {
            border: 2px solid #dc4f4f; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__right form.signup .i_agree {
            display: inline-block;
            width: 20px;
            margin-left: -20px; }
      .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__title {
        font-size: 25px;
        letter-spacing: 2px;
        margin: 20px 0px 0px 0px; }
      .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__close-btn {
        width: 35px;
        height: 35px;
        margin-left: auto;
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer; }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__close-btn:before {
          content: "";
          width: 3px;
          height: 90%;
          background: #37393D;
          display: block;
          position: absolute;
          left: 50%;
          transform-origin: center;
          transform: rotate(45deg); }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__close-btn:after {
          content: "";
          width: 3px;
          height: 90%;
          background: #37393D;
          display: block;
          position: absolute;
          left: 50%;
          transform-origin: center;
          transform: rotate(-45deg); }
        @media (max-width: 768px) {
          .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__close-btn {
            position: fixed; }
            .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__close-btn:before, .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__close-btn:after {
              background: #fff; } }
      .LogIn .ModalMaster .Modal .Modal__wrapper .form__message {
        color: #83858c;
        font-size: 12px;
        margin-top: 15px;
        text-align: left; }
      .LogIn .ModalMaster .Modal .Modal__wrapper .form__footer {
        padding-top: 60px; }
        @media (max-width: 768px) {
          .LogIn .ModalMaster .Modal .Modal__wrapper .form__footer {
            padding-top: 40px;
            flex-direction: column;
            align-items: baseline; } }
        .LogIn .ModalMaster .Modal .Modal__wrapper .form__footer .form__footer__left {
          padding-left: 0;
          text-align: left; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .form__footer .form__footer__left .cta-button {
            background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
            color: #fff;
            cursor: pointer;
            text-decoration: none;
            text-align: center;
            font-weight: normal;
            border-radius: 4px;
            font-weight: 900;
            font-size: 16px;
            padding: 20px 40px; }
        .LogIn .ModalMaster .Modal .Modal__wrapper .form__footer .form__footer__right {
          font-size: 12px;
          padding-top: 10px;
          border: 2px solid #202227; }
          @media (min-width: 769px) and (max-width: 1024px) {
            .LogIn .ModalMaster .Modal .Modal__wrapper .form__footer .form__footer__right {
              padding-left: 20px; } }
          @media (max-width: 768px) {
            .LogIn .ModalMaster .Modal .Modal__wrapper .form__footer .form__footer__right {
              text-align: left;
              margin-bottom: 20px; } }
          .LogIn .ModalMaster .Modal .Modal__wrapper .form__footer .form__footer__right a {
            color: #3a74e0;
            font-weight: 600; }
        .LogIn .ModalMaster .Modal .Modal__wrapper .form__footer .form__footer__right__2 {
          font-size: 12px;
          padding-top: 20px; }
          .LogIn .ModalMaster .Modal .Modal__wrapper .form__footer .form__footer__right__2 button {
            color: #3a74e0;
            cursor: pointer;
            font-weight: 600; }
      .LogIn .ModalMaster .Modal .Modal__wrapper .form__footer__2 {
        width: 40%; }
      .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__forgot {
        width: 50%;
        float: right;
        font-size: 12px;
        margin-top: -35px;
        margin-right: 30px; }
        .LogIn .ModalMaster .Modal .Modal__wrapper .Modal__forgot a {
          color: #3a74e0;
          cursor: pointer;
          font-weight: 600; }
    .LogIn .ModalMaster .Modal.active {
      visibility: visible;
      opacity: 1; }
      .LogIn .ModalMaster .Modal.active .Modal__wrapper {
        transform: scale(1); }
