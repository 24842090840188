@import  '../../utils/_variables';

.basic-menu {
  align-items: center;
  background-color: initial;
  display: flex;
  flex-direction: row;
  height: initial;
  justify-content: center;
  left: 0;
  opacity: 1;
  padding-top: 0;
  position: initial;
  top: 120px;
  transition: 200ms ease all;
  visibility: visible;
  width: 100%;

  ul {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    list-style: none;
    margin: 0;
    margin-left: 10px;
    padding: 0;
    width: 100%;

    li {
      position: relative;

      a {
        align-items: center;
        cursor: pointer;
        font-family: Roboto;
        font-weight: 700;
        justify-content: center;
        letter-spacing: 0.4px;
        text-decoration: none;
      }

      &.menu-item {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        margin-left: 4vw;
        margin-right: 0;

        a {
          background: none;
          border: none;
          color: #FFF;
          display: flex;
          font-size: 1.5vw;
          height: 100%;
          margin: 0;
          padding: 0;
          position: relative;
          transition: 300ms ease background;
          width: 100%;

          @media screen and (min-width: 1500px){
            font-size: 22px;
          }

          &:after {
            background-position-x: -45px;
            background-repeat: no-repeat;
            background-size: 35px;
            background: none;
            border-bottom: 0px solid #49e1ca;
            border-image-slice: 1;
            border-image-source: linear-gradient(to left, #3a74e0, #a142ff );
            border-left: 0px solid #49e1ca;
            border-right: 0px solid #49e1ca;
            border-top: 3px solid #49e1ca;
            bottom: 0;
            color: #FFF;
            content: "";
            display: block;
            height: inherit;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            transition: 200ms ease all;
            width: 100%;
            z-index: 10;
          }

          &:hover:after,
          &.active:after {
            background-repeat: no-repeat;
            background-position-x: 0px;
            opacity: 1;
          }
        }
      }

      &.brands-btn {
        margin-left: auto;

        a {
          background: initial;
          border: solid 2px white;
          border-radius: 24px;
          display: inline-flex;
          font-size: 14px;
          height: 48px;
          line-height: 16px;
          margin-left: 20px;
          padding: 10px 30px;
          transition: 300ms ease all;
          width: 140px;

          &:hover {
            background:#FFFFFF;
            color: black;
          }
        }
      }

      &.creators-btn {
        margin-left: 10px;

        a {
          background: linear-gradient(to bottom, #3a74e0 0%, #5d34f5 100%);
          border-radius: 24px;
          display: inline-flex;
          font-size: 14px;
          height: 48px;
          justify-content: center;
          line-height: 16px;
          margin: 0;
          opacity: 0.95;
          outline: none;
          padding: 10px 30px;
          position: relative;
          transition: 300ms ease all;
          width: 140px;

          &:hover {
            background:#3A74E0;
            color: #FFFFFF;
          }
        }
      }

      &:nth-child(1) {
        margin-left: auto;
      }
    }
  }
}