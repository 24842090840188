@import  '../../../utils/_variables';

.CustomCheckbox {
	width: 24px;
	height: 24px;
  background-size: 24px 24px;
  background-image: url("../../../components/images/quest-details-page/requirement-indicators/unchecked.svg");
  float: left;
  margin: 0 7px;
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;


  &.editable {
    &:hover {
      background-image: url("../../../components/images/quest-details-page/requirement-indicators/hover.svg");
    }

    &:active {
      background-image: url("../../../components/images/quest-details-page/requirement-indicators/active.svg");
    }
  }

  &.completed {
    background-image: url("../../../components/images/quest-details-page/requirement-indicators/checked.svg");
  }
  
  &.review {
    background-image: url("../../../components/images/quest-details-page/requirement-indicators/under-review.svg");
  }

  @media #{$mobileOnly}{
    width: 12px;
    height: 12px;
    background-size: 12px 12px;
    margin: 0 2px;
  }
}