@import "../../../utils/variables";

.Search {
  display: flex;
  flex-direction: column;

  .Search_Top {
    display: flex;
    align-items: center;

    margin-bottom: 10px;

    .Search_Input {
      display: flex;
      align-items: center;

      width: 412px;
      height: 50px;

      background-color: $colorBackground;
      border-radius: 10px;

      margin-right: 27px;
      padding: 0 25px;

      svg {
        width: 20px;
        height: 21px;

        margin-right: 10px;
      }

      #search-bar-input {
        border: none;
        background: none;
        margin-bottom: 1px;
        box-shadow: none;
        width: 100%;

        color: $colorLightWhite;

        &:focus {
          outline: none;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }

      &.Disabled {
        @include no-select;

        cursor: not-allowed;
      }
    }

    .Search_Filters_Button {
      @include center-content;

      height: 50px;
      width: 50px;

      border-radius: 10px;
      background-image: linear-gradient(to bottom, #e8cdfe, #b7bbfd);
      cursor: pointer;

      svg {
        width: 20px;
        height: 21px;
      }
    }
  }

  .Search_Filters {
    display: inline-block;
    margin-bottom: 10px;
    max-height: 0;
    width: 60%;
    max-width: 450px;

    -webkit-transition: max-height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
    // transition: width 0.4s ease-in-out;

    background-color: $colorBackground;
    border-radius: 10px;

    overflow: hidden;

    .Search_Filters_Content {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      padding: 20px;

      .Search_Filters_Content_Title {
        font-size: 120%;
        font-weight: bolder;
      }

      .Search_Filters_Content_Item {
        display: flex;
        align-items: center;

        cursor: pointer;

        .Search_Filters_Content_Item_Checkbox {
          color: $colorLightWhite;
        }

        .Search_Filters_Content_Item_Title {
          color: $colorLightWhite;
        }
      }
    }
  }

  .Filters_Open {
    max-height: 420px;
  }
}

.Filters {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-bottom: 20px;

  -webkit-transition: height 1s;
  -moz-transition: height 1s;
  -ms-transition: height 1s;
  -o-transition: height 1s;
  transition: height 1s;

  .Filters_Buttons {
    display: flex;

    .Filters_Button {
      @include center-content;

      height: 45px;

      margin-right: 22px;
      padding: 0 20px;
      position: relative;

      background-color: $colorPurpleRay;
      border-radius: 4px;
      color: $colorWhite;
      cursor: pointer;

      font-size: 90%;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;

      .Filters_Button_Close {
        position: absolute;
        top: 0;
        right: 6px;
      }
    }
  }

  .Filters_Clear {
    @include gray-border-button;

    height: 45px;
    width: 193px;

    font-size: 90%;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
  }
}
