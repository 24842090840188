@import '../../utils/_variables';

.LogIn {
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #202227; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background:#49E1CA;
  }

  align-items: center;
  background-image: url("../images/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  font-family: Roboto;
  justify-content: center;
  margin-top: -70px;
  min-height: 100vh;
  min-width: 100%;
  padding-top: 70px;

  .errors-container {
    color: #dc4f4f;
    font-size: 13px;
    text-align: left;
    padding: 5px 25px 5px 10px;
    border-radius: 1px;
    border: 1px solid #dc4f4f;
    position: relative;
    margin: 0 0 30px 0;
	}

	.success-container {
    color: rgb(79, 220, 79);
    font-size: 13px;
    text-align: left;
    padding: 5px 25px 5px 10px;
    border-radius: 1px;
    border: 1px solid rgb(79, 220, 79);
    position: relative;
    margin: 0 0 30px 0;
  }

  // Client Portal
  form.client-portal {
    input {
      border: solid 2px #313337;
      background-color: #202227;
      margin: 10px 0 25px 0;
      color: #fff;
    }

    input.error, select.error{
      border: 2px solid #dc4f4f;
    }
    .fa-exclamation, .fa-check, error-icon {
      display: none;
    }
    .error-message {
      color: #dc4f4f;
      font-size: 12px;
      position: absolute;
      margin: -20px 0px 0 0;
      right: 40px;
    }
  }

  .ModalMaster{
    .Modal{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
  		background: rgba(0,0,0,.5);
      .Modal__layer{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      align-items: flex-start;
      @media screen and (min-width: 640px){
        align-items: center;
      }
      .Modal__wrapper{
        width: 90%;
  			max-width: 1224px;
  			height: 90%;
  			max-height: 850px;
        background: #202227;
  			padding: 0;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        transform: scale(0.8);
        display: flex;

        @media #{$nonDesktop}{
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          max-height: 9999px;
        }
        @media #{$mobileOnly}{
          flex-direction: column;
          min-height: 100%;
          height: auto;
          justify-content: flex-start;
        }
        .icon{
          width: 50px;
          margin: 30px auto 30px auto;
          img{
            width: 100%;
          }
  			}

        .Modal__content {
          padding: 0 18%;

          @media #{$tabletAndLarger} {
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translate(0,-50%);
          }

          @media #{$nonDesktop}{
            padding: 0 10%;
          }

          @media #{$mobileOnly}{
            padding: 0;
					}

					.datepicker-label {
					 	position: absolute;
						top: -5px;
						z-index: 0;
					}

					.date {
						margin-top: 8px !important;
          }
        }

        .Modal__left {
          background-image: url('../images/login-img-desktop.jpg');
          background-position: center;
          background-size: cover;
          width: 30%;
          height: 100%;
          position: relative;

          @media #{$mobileOnly} {
            width: 100%;
            height: 30vh;
            background-image: url('../images/login-img-mobile.jpg');
          }

          .logo {
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            width: 100px;
            height: 38px;
            top: 30px;
            left: 30px;
          }

          .blurb {
            display: block;
            position: absolute;
            color: #202227;
            width: 100%;
            font-weight: 900;
            text-align: left;
            padding: 0 40px 0 30px;
            bottom: 20%;

            @media #{$mobileOnly}{
              display: none;
            }
          }

          .shapes-container {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 120px;
            height: 120px;

            @media #{$mobileOnly} {
              width: 64px;
              height: 64px;
            }

            .box {
              position: absolute;
            }

            .box1 {
              width: 33%;
              height: 33%;
              background: #3a74e0;
            }

            .box2 {
              width: 67%;
              height: 67%;
              background: #202227;
              bottom: 0;
              right: 0;
            }
          }
        }

  			.Modal__right {
          width: 70%;
          overflow-y: scroll;
          position: relative;
          height: 100%;

          @media #{$mobileOnly} {
            width: 88%;
            height: auto;
          }

  				.input-container {
  					label {
  						color: #83858c;
  						text-align: left;
  						font-size: 12px;
  						line-height: 12px;
  						margin-top: 0px;
  					}

  					.input-wrapper {
  						position: relative;

  						.error-message {
  							color: #dc4f4f;
  							font-size: 12px;
  							position: absolute;
  							right: 0;
  							bottom: -17px;
                top: 50px;
							}

							.success-message {
  							color: #49E1CA;
  							font-size: 12px;
  							position: absolute;
  							right: 0;
  							bottom: -17px;
  						}

  						.error-icon {
  							color: #dc4f4f;
  							display: none;
  							position: absolute;
  							right: 10px;
  							top: 10px;
  						}

  						.ok-icon {
  							position: absolute;
  							right: 5px;
  							bottom: 5px;
  							color: #49E1CA;
  						}
  					}
  				}

  				ul.Modal__tabs {
  					margin-bottom: 60px;
  					padding: 0;
  					text-align: left;

            @media #{$mobileOnly}{
              margin-bottom: 40px;
            }

  					li {
  						display: inline-block;
  						font-size: 40px;
  						color: #36373B;
  						padding-right: 30px;

  						a {
  							display: inline-block;
  							background: initial;
  							padding: initial;
  							text-transform: inherit;
  							transition: 300ms ease background;
  							position: relative;
  							cursor: pointer;
  							z-index: 1;
  							font-weight: 900;
  					    text-decoration: none;
                color: #37393d;

  							&.Modal__tab:after {
  								content: "";
  								display: block;
  								width: 0%;
  								position: absolute;
  								bottom: 8px;
  								height: 12px;
  								z-index: -1;
  								transition: 200ms ease all;
  							}

  							&.Modal__tab:hover:after,
  							&.Modal__tab.active:after {
  								background: #49E1CA;
  								z-index: -1;
  								width: 100%;
  								transition: 200ms ease all;
  							}
  						}
  					}

  					li a.active {
  						color: white;
  					}

  					li a:hover {
  						color: white;
  					}
					}

					.success-container {
						display: flex;
            font-size: 13px;
            text-align: left;
            padding: 0px 25px 0px 10px;
            border-radius: 1px;
            position: relative;
            margin: 0;
            background: rgba(255, 255, 255, 0.01);
            align-items: center;
            visibility: hidden;
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms padding, ease 300ms margin;

            &.active{
              visibility: visible;
              opacity: 1;
              max-height: inherit;
              min-height: 35px;
              padding: 5px 25px 5px 10px;
              margin: 0 0 30px 0;
            }
            &:hover{
              background: rgba(255, 255, 255, 0.03)
            }
						.success-dismiss {
              position: absolute;
              width: 18px;
              height: 18px;
              border-radius: 100%;
              top: 8px;
              right: 10px;
              background: rgba(79, 220, 79, 0.22);
              cursor: pointer;
              outline: none;
              transition: ease 200ms all;
              &::before{
                content: '';
                height: 1px;
                width: 10px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                background: rgba(255, 255, 255, 0.5);
                transition: ease 200ms all;
              }
              &::after{
                content: '';
                height: 1px;
                width: 10px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                background: rgba(255, 255, 255, 0.5);
                transition: ease 200ms all;
              }
              &:hover{
                background: rgba(79, 220, 79, 0.5);
                &::before{
                  background: rgba(255, 255, 255, 0.7);
                }
                &::after{
                  background: rgba(255, 255, 255, 0.7);
                }
              }
            }
					}
          .errors-container {
            display: flex;
            color: #dc4f4f;
            font-size: 13px;
            text-align: left;
            padding: 0px 25px 0px 10px;
            border-radius: 1px;
            border: 1px solid #dc4f4f;
            position: relative;
            margin: 0;
            background: rgba(255, 255, 255, 0.01);
            align-items: center;
            visibility: hidden;
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms padding, ease 300ms margin;
            &.active{
              visibility: visible;
              opacity: 1;
              max-height: inherit;
              min-height: 35px;
              padding: 5px 25px 5px 10px;
              margin: 0 0 30px 0;
            }
            &:hover{
              background: rgba(255, 255, 255, 0.03)
            }
            .error-dismiss {
              position: absolute;
              width: 18px;
              height: 18px;
              border-radius: 100%;
              top: 8px;
              right: 10px;
              background: rgba(220, 79, 79, 0.22);
              cursor: pointer;
              outline: none;
              transition: ease 200ms all;
              &::before{
                content: '';
                height: 1px;
                width: 10px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                background: rgba(255, 255, 255, 0.5);
                transition: ease 200ms all;
              }
              &::after{
                content: '';
                height: 1px;
                width: 10px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                background: rgba(255, 255, 255, 0.5);
                transition: ease 200ms all;
              }
              &:hover{
                background: rgba(220, 79, 79, 0.5);
                &::before{
                  background: rgba(255, 255, 255, 0.7);
                }
                &::after{
                  background: rgba(255, 255, 255, 0.7);
                }
              }
						}
          }

  				form.signup{
  					width: 100%;
  					input, select{
  						background-color: #202227;
  						border: none;
  						border-bottom: 2px solid #83858C;
  						border-radius: 0px;
  						color: white;
  						display: inline-block;
  						padding-left: 0;
  						text-align: left;
  						margin: 0;
  						width: 100%;
  					}
  					input.error, select.error{
  						border-bottom: 2px solid #dc4f4f;
  					}
  					select {
  						background-image: none;
  					}
  					a {
  						text-decoration: none;
  					}
  					::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  						color: #202227;
  						opacity: 1; /* Firefox */
  					}

  					:-ms-input-placeholder { /* Internet Explorer 10-11 */
  						color: #202227;
  					}

  					::-ms-input-placeholder { /* Microsoft Edge */
  						color: #202227;
  					}

  					.firstOptionSelected{
  						color: #d1d1d1;
  					}

  					input:focus, select:focus{
  						border-bottom: 2px solid #3A74E0;
  						box-shadow: none;
  					}

  					input:placeholder-shown + label {
  						cursor: text;
  						max-width: 66.66%;
  						color:#d1d1d1;
  						white-space: nowrap;
  						overflow: hidden;
  						text-overflow: ellipsis;
  						transform-origin: left bottom;
  						transform: translate(0, 1.9rem) scale(1.5);
  						&:after{
  							content: attr(data-label);
  						}
  					}
  					select.firstOptionSelected + label{
  						cursor: text;
  						max-width: 66.66%;
  						color:#d1d1d1;
  						white-space: nowrap;
  						overflow: hidden;
  						text-overflow: ellipsis;
  						transform-origin: left bottom;
  						transform: translate(0, 1.9rem) scale(1.5);
  						&:after {
  							content: attr(data-label);
  						}
  					}
  					.input-wrapper {
  						display: flex;
  						flex-flow: column-reverse;
  						margin-bottom: 2em;
  					}
  					input, select{
  						z-index:1;
  						background-color: transparent;
  					}
  					select option{
  						background-color: #202227;
  					}
  					label{
  						z-index:0;
  					}
  					label, input {
  						transition: all 0.2s;
  						touch-action: manipulation;
  					}
  					::-webkit-input-placeholder {
  						opacity: 0;
  						transition: inherit;
  					}

  					input:focus::-webkit-input-placeholder {
  						opacity: 1;
  					}

						_:-ms-lang(x), input + label{
							color:#83858c !important;
							transform: translate(0, 0) scale(1);
							&:after{
								content: attr(data-label-small);
							}
						}

						input:not(:placeholder-shown) + label,
  					input:focus + label,
  					select:not(.firstOptionSelected) + label,
  					select:focus + label {
  						color:#83858c !important;
  						transform: translate(0, 0) scale(1);
  						&:after{
  							content: attr(data-label-small);
  						}
  						cursor: pointer;
  					}

					.i_agree_wrap{
						.input-container{
							.input-wrapper{
								margin:0;
							}
							display:inline-block !important;
							width:20px !important;
							margin-left:-20px !important;
							vertical-align:text-top;
						}
						.ok-icon{
							display:none;
						}
					}

					.i_agree_error{
						border:2px solid #dc4f4f;
					}

					.i_agree{
						display:inline-block;
						width:20px;
						margin-left:-20px;
					}
  				}
  			}


        .Modal__title{
          font-size: 25px;
          letter-spacing: 2px;
          margin: 20px 0px 0px 0px;
        }
        .Modal__close-btn{
          width: 35px;
          height: 35px;
          margin-left: auto;
          position: absolute;
          right: 30px;
          top: 30px;
          cursor: pointer;
          &:before{
            content: "";
            width: 3px;
            height: 90%;
            background: #37393D;
            display: block;
            position: absolute;
            left: 50%;
            transform-origin: center;
            transform: rotate(45deg);
          }
          &:after{
            content: "";
            width: 3px;
            height: 90%;
            background: #37393D;
            display: block;
            position: absolute;
            left: 50%;
            transform-origin: center;
            transform: rotate(-45deg);
          }
          @media #{$mobileOnly}{
            position: fixed;
            &:before, &:after{
              background: #fff;
            }
          }
  			}
  			.form__message{
  				color: #83858c;
  				font-size: 12px;
  				margin-top: 15px;
  				text-align: left;
  			}
  			.form__footer{
  				padding-top: 60px;
          @media #{$mobileOnly}{
            padding-top: 40px;
            flex-direction: column;
            align-items: baseline;
          }
  				.form__footer__left{
  					padding-left: 0;
  					text-align: left;
  					.cta-button{
  						background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
  						color: #fff;
  						cursor: pointer;
  						text-decoration: none;
  						text-align: center;
  						font-weight: normal;
  						border-radius: 4px;
  						font-weight: 900;
  						font-size: 16px;
  						padding: 20px 40px;
  					}
  				}
  				.form__footer__right{
  					font-size: 12px;
            padding-top: 10px;
            border: 2px solid #202227;
            @media #{$tabletOnly}{
              padding-left: 20px;
            }
            @media #{$mobileOnly}{
              text-align: left;
              margin-bottom: 20px;
            }
  					a{
  						color: #3a74e0;
  						font-weight: 600;
  					}
  				}
  				.form__footer__right__2{
  					font-size: 12px;
  					padding-top: 20px;
  					button{
  						color: #3a74e0;
  						cursor: pointer;
  						font-weight: 600;
  					}
  				}
  			}
  			.form__footer__2{
  				width: 40%;
  			}
  			.Modal__forgot{
  				width: 50%;
  				float: right;
  				font-size: 12px;
  				margin-top: -35px;
  				margin-right: 30px;
  				a{
  					color: #3a74e0;
  					cursor: pointer;
  					font-weight: 600;
  				}
  			}
  		}
      &.active{
        visibility: visible;
  			opacity: 1;
        .Modal__wrapper{
          transform: scale(1);
        }
      }
  	}
  }
}
