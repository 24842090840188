@import "../../../../../utils/_variables";

.ArbitrationStatus_Container {
  @include col-center;

  .ArbitrationStatus_TitleBox {
    h1 {
      font-family: Roboto;
      font-size: 42px;
      @media #{$mobileAndTabletOnly} {
        font-size: 32px;
      }
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.42px;
      text-align: center;
      color: #fff;
    }

    .title {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.24px;
      text-align: center;
      color: #4be0ca;
    }
  }

  .ArbitrationStatus_Decision {
    width: 100%;
    min-height: 250px;
    border-radius: 8px;
    background-color: #242529;
    margin: 25px 0px;
    padding: 10px 0px;

    h2 {
      font-family: Roboto;
      font-size: 30px;
      @media #{$mobileAndTabletOnly} {
        font-size: 20px;
      }
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.3px;
      text-align: center;
      color: #fff;
    }

    .decision {
      font-size: 50px;
      @media #{$mobileAndTabletOnly} {
        font-size: 30px;
      }
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.5px;
      text-align: center;
      color: #a142ff;
    }

    .reason {
      margin: 20px 10%;
    }
  }

  .ArbitrationStatus_Comments {
    @include col-start;
    h2 {
      font-family: Roboto;
      font-size: 30px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.3px;
      text-align: left;
      color: #fff;
    }

    .comments {

    }
  }
}
