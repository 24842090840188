@import "../../../../utils/variables";

$timeTransition: 0.25s;

.LargeToggle {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .LargeToggle_Header {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.16px;
    line-height: normal;
    margin-right: 15px;
  }

  .LargeToggle_Toggle {
    border: 4px solid;
    border-radius: 30px;
    cursor: pointer;
    display: block;
    height: 26px;
    margin-right: 10px;
    position: relative;
    transition: border $timeTransition ease-in-out;
    width: 44px;

    .LargeToggle_Toggle_Ball,
    .LargeToggle_Toggle_Left,
    .LargeToggle_Toggle_Right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .LargeToggle_Toggle_Ball {
      border-radius: 50%;
      display: inline-block;
      height: 38px;
      transition: all $timeTransition ease-in-out;
      width: 38px;
      z-index: 10;
    }

    .LargeToggle_Toggle_Left {
      left: 9px;
    }

    .LargeToggle_Toggle_Right {
      right: 7px;
    }

    .LargeToggle_Toggle_Left,
    .LargeToggle_Toggle_Right {
      @include center-content;
      height: 14px;

      svg {
        min-height: 100%;
        width: auto;
      }
    }
  }
}