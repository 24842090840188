@import "../../../../utils/_variables";

.Assets_Card {
  display: flex;
  flex-direction: column;
  -webkit-transition: height 1s;
  -moz-transition: height 1s;
  -ms-transition: height 1s;
  -o-transition: height 1s;
  transition: height 1s;
  width: 100%;

  .Assets_Card_Header {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.16px;
    margin-bottom: 25px;
    text-align: left;
  }

  .Assets_Card_Content {
    align-items: center;
    background-color: $colorCDarkGray;
    border: 2px solid transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 117px;
    padding: 20px;
    width: 100%;

    .Assets_Card_Img {
      max-height: 77px;
      max-width: 77px;
      min-height: 77px;
      min-width: 77px;
      object-fit: cover;
    }

    .Assets_Card_Text {
      color: $colorCyan;
      font-family: Roboto;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      text-align: left;
    }

    .Assets_Card_Delete {
      @include center-content;

      svg {
        @include transform-scale;
        cursor: pointer;
        height: auto;
        width: 18px;
      }
    }
  }

  .Error {
    border: 2px solid $colorCandyRed;
  }

  .Populated {
    .Assets_Card_Text {
      width: 70%;
    }
  }

  .Empty {
    display: flex;
    flex-direction: column;

    .EmptyCard {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      svg {
        height: 77px;
        width: auto;
      }

      .Assets_Card_Text {
        width: 28%;
      }

      .Assets_Card_Select {
        @include gray-border-button;
        height: 41px;
        width: 28%;
      }

      .Assets_Card_Upload {
        @include purple-gradient-button;
        height: 41px;
        width: 28%;
      }
    }

  }
}

.Assets_Card_Modal {
  background-color: $colorBackground;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  max-height: 0;
  overflow: auto;
  -webkit-transition: height 1s;
  -moz-transition: height 1s;
  -ms-transition: height 1s;
  -o-transition: height 1s;
  transition: height 1s;
  width: 100%;

  .GalleryCards {
    display: flex;
    flex-wrap: wrap;

    .GalleryCards__Img {
      border: 2px solid transparent;
      border-radius: 8px;
      cursor: pointer;
      margin-bottom: 10px;
      margin-right: 10px;
      max-height: 100px;
      max-width: 100px;
      min-height: 100px;
      min-width: 100px;
      object-fit: cover;

      &:hover {
        border: 2px solid $colorCyan;
      }
    }
  }
}

.Active {
  margin-top: 20px;
  max-height: 130px;
  padding: 15px;
}