@import "../../../utils/variables";

.RequiredGames {
  // position: absolute;
  // width: 400;
  // // backgroundColor: theme.palette.background.paper;
  // // border: 2px solid $colorDarkGray;
  // // box-shadow: 10px 5px 5px $colorDarkGray;
  // // boxShadow: theme.shadows[5];
  // // padding: theme.spacing(2, 4, 3),
  // padding: 10px;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // background-color: $colorGray;
  margin-bottom: 14px;

  .RequiredGames--label {
    color: $colorWhite;
  }

}