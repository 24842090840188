.white-hover-border {
 border: 1px solid transparent;
 border-radius: 2px;
 &:hover {
  border-color: white;
 }
 transition: border-color .25s ease;
 -webkit-transition: border-color .25s ease;
 -moz-transition: border-color .25s ease;
 -o-transition: border-color .25s ease;
 -ms-transition: border-color .25s ease;
 transition: border-color .25s ease;
}


.rich-text-center {
  // center col flex 100 width
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;

}