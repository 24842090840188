$colorGray: #83858c;

.Main__Header.Portal {
  background: #18191d;
  height: 76px;
  margin: 0;
  max-width: 1800px;
  padding-left: 6%;
  padding-right: 6%;
  width: 100%;

  .Main__Header__wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;

    button.toggle-mobile-menu {
      top: 6px;
    }
  }
}