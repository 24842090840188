@import '../../../utils/_variables';

.SFGame{
  // margin-top: 120px;
  .SFGame__background{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    figure {
      margin: 0;
      width: 100%;
      height: 100vh;
      background-position: center center;
      background-size: cover;
    }
    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      // background: linear-gradient(to bottom, #18191d 0%, rgba(24, 25, 29, 0.2) 35%, rgba(24, 25, 29, 0) 75%, rgb(24, 25, 29) 100%);
      background: linear-gradient(to bottom, #18191d 0%, rgba(24, 25, 29, .20) 35%, rgba(21, 11, 4, 0.9) 75%, #18191d 100%);
    }
  }
  .SFGame__container{
    transform: translateX(1px);
    padding-top: 200px;
    .SFGame__secondary{
      .SFGame__secondary--wrapper{
        max-width: 1024px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 40px;
        margin-bottom: 30px;
        position: relative;
        text-align: center;
        width: 80%;
        margin-bottom: 30px;
        h1, h2, h3, h4{
          margin:0;
          @media #{$mobileOnly}{
            font-size: 16px;
          }
        }
        .SFGameInfo__ctas--pre-order2{
          margin-top: 25px;
        }
      }
    }
    .SFGame__primary{
      .SFGame__primary--wrapper{
        max-width: 1024px;
        min-height: 386px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        margin-bottom: 30px;
        .PrimaryGameMedia{
          transition: 500ms ease all;
          width: 100%;
          flex-wrap: wrap;
          flex-direction: row;
          // min-height: 420px;
          max-height: inherit;
          justify-content: flex-start;
          align-items: flex-start;
          // overflow: hidden;
          transition: ease 300ms transform, ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms max-height, ease 300ms padding, ease 300ms margin;
          transform: translateY(0px);
          @media #{$tabletAndLarger}{
            display: flex;
          }
          &.active{
            visibility: visible;
            opacity: 1;
            max-height: inherit;
            // min-height: 35px;
            transform: translateY(0px);
          }
          .SFGameGallery2{
            height: 250px;
            position: relative;
            @media #{$tabletAndLarger}{
              display: none;
            }
            .SFGameGalleryT{
              margin-right: auto;
              margin-left: auto;
              width: 80%;
              overflow: hidden;
              height: 200px;
              top: 0;
              right: 0;
              .swiper-pagination-bullets{
                bottom: 15px;
              }
              .swiper-pagination-bullet{
                width: 10px;
                height: 10px;
                display: inline-block;
                border-radius: 100%;
                background: $colorLightGray;
                opacity: 1;
              }
              .swiper-pagination-bullet-active{
                background: #007aff;
              }
              .thumb-slide{
                width: 100%;
                padding: 0;
                margin: 0;
                border: none;
                cursor: pointer;
                position: relative;
                outline: none;
                height: 100%;
                margin-right: 1px;
                .thumb-overlay {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                  top: 0;
                  left: 0;
                }
              }
              .swiper-scrollbar{
                background: rgba(255,255,255,0.1);
                border-radius: 1px !important;
                bottom: 0;
                width: 100%;
                .swiper-scrollbar-drag{
                  position: relative;
                  background: #3a74e0;
                  border-radius: 1px !important;
                }
              }
            }
          }
          .SFGameGallery{
            width: 65%;
            padding: 0px 0;
            display: none;
            @media #{$tabletAndLarger}{
              display: block;
            }
            // background: #202227;
            .SFGameGallery__wrapper{
              margin: 0 auto;
              max-width: 1024px;
              height: 600px;
              position: relative;
              display: flex;
              flex-direction: row;
              overflow: hidden;
              .SFGameGallery__current-image{
                width: 100%;
                height: calc(100% - 105px);
                background: #CCC;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                &::before{
                  content: "";
                  padding-top: 56.25%;
                  display: block;
                }
              }
              .SFGameGalleryThumb{
                position: absolute;
                width: 100%;
                overflow: hidden;
                height: 100px;
                bottom: 0;
                right: 0;
                .thumb-slide{
                  width: 20%;
                  padding: 0;
                  margin: 0;
                  border: none;
                  cursor: pointer;
                  position: relative;
                  outline: none;
                  height: 100%;
                  margin-right: 1px;
                  .thumb-overlay {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    top: 0;
                    left: 0;
                  }
                }
                .swiper-scrollbar{
                  background: rgba(255,255,255,0.1);
                  border-radius: 1px !important;
                  bottom: 0;
                  width: 100%;
                  .swiper-scrollbar-drag{
                    position: relative;
                    background: #3a74e0;
                    border-radius: 1px !important;
                  }
                }
              }
            }
          }
          .SFGameInfo {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 auto;
            padding: 0;
            flex-wrap: wrap;
            position: relative;
            width: 80%;
            @media #{$tabletAndLarger}{
              width: 35%;
            }
            .SFGameInfo__details{
              width: calc(100%);
              display: flex;
              justify-content: center;
              align-items: flex-start;
              flex-direction: column;
              height: 100%;
              @media #{$tabletAndLarger}{
                padding: 0 20px;
              }
              .SFGameInfo__logo{
                padding: 0 40px;
                position: relative;
                width: 100%;
                background: #212227;
                figure{
                  width: 100%;
                  height: 90px;
                  margin: 0;
                  height: 75px;
                  margin: 20px 0;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                }
              }
              .SFGame__right__header{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                .SFGame__right__developerlogo{
                  width: 120px;
                  height: 120px;
                  display: inline-flex;
                  background: #212227;
                  margin-right: 10px;
                  position: relative;
                  figure{
                    position: absolute;
                    margin: 0;
                    padding: 0;
                    width: 80%;
                    height: 80%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                  }
                }
                .SFGame__right__row__center{
                  display: inline-flex;
                  flex-direction: column;
                  width: 40%;
                  .SFGame__right__developer{
                    color: #83858c;
                    font-weight: 500;
                    font-size: 16px;
                  }
                  .SFGame__right__name{
                    color: #3a74e0;
                    font-weight: 700;
                    font-size: 28px;
                  }
                }
                // .SFGame__right__row__right{
                //   flex-direction: row;
                //   margin-left: auto;
                //   order: 2;
                //   display: none;
                //   @media #{$tabletAndLarger} {
                //     display: flex;
                //   }
                //   a{
                //     display:inline-flex;
                //     color: #83858c;
                //     text-decoration: none;
                //     margin-right: 10px;
                //     width: 20%;
                //   }
                // }
              }
              .SFGameInfo__details--title {
                font-size: 50px;
                line-height: 50px;
                font-weight: 800;
                font-family: "Roboto";
                margin: 0;
              }
              .SFGame__socialNetwork{
                display: flex;
                flex-direction: row;
                margin-top: 15px;
                a{
                  display:inline-flex;
                  color: #83858c;
                  text-decoration: none;
                  margin-right: 10px;
                  width: 20%;
                }
                // @media #{$tabletAndLarger} {
                //   display: none;
                // }
              }
              .SFGameInfo__details--bottom{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 20px;
                .SFGameInfo__details--description{
                  color: #FFF;
                  font-size: 14px;
                  line-height: 18px;
                  letter-spacing: .5px;
                  width: 100%;
                  font-weight: 400;
                  margin: 0 20px 0 0;
                  @media #{$tabletAndLarger}{
                    font-size: 12px;
                  }
                }
                .SFGameInfo__tags {
                  padding-top: 25px;
                  font-size: 12px;
                  letter-spacing: 0.8px;
                  color: #FFF;
                  line-height: 22px;
                  font-weight: 600;
                  color: #83858c;
                  @media #{$tabletAndLarger}{
                    margin-left: 20px;
                  }
                  @media screen and (min-width: 1024px){
                    letter-spacing: initial;
                  }
                  .tag{
                    // color: #52ccc4;
                    color: #FFF;
                    text-decoration: none;
                    font-weight: 400;
                    &:first-child:before{
                      content: " ";
                    }
                    &:after{
                      content: ", ";
                      color: #FFF;
                    }
                    &:last-child:after{
                      content: '';
                    }
                  }
                }
                .SFGameInfo__ctas{
                  position: relative;
                  padding-top: 30px;
                  @media #{$tabletAndLarger}{
                    display: flex;
                  }
                  .SFGameInfo__ctas--pre-order{
                    
                    background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
                    color: #fff;
                    cursor: pointer;
                    text-decoration: none;
                    text-align: center;
                    font-weight: normal;
                    border-radius: 2px;
                    font-weight: 700;
                    font-size: 13px;
                    padding: 12px 22px;
                    width: 100%;
                    @media #{$tabletAndLarger}{
                      display: inline-block;
                      width: 50%;
                    }
                    &.disabled{
                      cursor: disabled;
                      background: #2e2f32;
                      color: $colorGray;
                    }
                  }
                  .SFGameInfo__ctas--message{
                    
                    font-size: 12px;
                    color: grey;
                    margin-top: 10px;
                    a {
                      color: inherit;
                    }
                    a:hover 
                    {
                      color: inherit;
                      cursor:pointer;  
                    }
                    width: 100%;
                    @media #{$tabletAndLarger}{
                      width: 45%;
                      margin-top: 0;
                      margin-left: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .SFGameInfo__ctas--pre-order{
      display: inline-block;
      background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
      color: #fff;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
      font-weight: normal;
      border-radius: 2px;
      font-weight: 700;
      font-size: 13px;
      padding: 12px 22px;
      &.disabled{
        cursor: disabled;
        background: #2e2f32;
        color: $colorGray;
      }
    }
  }
  .ModalMaster {
		.Modal__store__game__success{
			.Modal__wrapper{
				max-height: 300px;
				max-width: 300px;
				display: flex;
				flex-flow: wrap;
				.Modal__success__content{
					flex: 0 0 100%;
					.Modal__success__image{
						padding-top: 30px;
						background-size: cover;
						background-repeat: no-repeat;
            background-position: center;
            background-image: url("//images/store/success.png");
						height: 130px;
						width: 130px;
						margin-right: auto;
						margin-left: auto;
						position: relative;
					}
					.Modal__success__title {
						padding-top: 10px;
						font-weight: 700;
						font-size: 25px;
						width: 100%;
					}
					.Modal__success__description {
						padding-top: 10px;
						font-size: 12px;
						color: gray;
						padding: 0 40px;
						width: 100%;
					}
				}
			}
		}
		.Modal__store__game__checkout{
			.Modal__wrapper{
				max-height: 400px;
				max-width: 600px;
				display: flex;
  			flex-flow: wrap;
				.Modal__left{
					flex: 0 0 50%;
					.Modal__left__image{
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center;
						height: 200px;
						width: 130px;
						margin-right: auto;
						margin-left: auto;
						position: relative;
						.Cover__corner__1{
              position: absolute;
              width:100%;
              height:100%;
              transition: 400ms ease all;
              &:after {
                content: "";
                display: block;
                height: 40px;
                width: 2px;
                bottom: -5px;
                left: -5px;
                position: absolute;
								transition: 400ms ease all;
								background: #00E2CA;
              }
              &:before{
                content: "";
                display: block;
                height: 2px;
                width: 40px;
                bottom: -5px;
                left: -5px;
                position: absolute;
								transition: 400ms ease all;
								background: #00E2CA;
              }
						}
						.Cover__corner__2{
              position: absolute;
              width:100%;
              height:100%;
              transition: 400ms ease all;
              &:after{
                content: "";
                display: block;
                height: 40px;
                width: 2px;
                top: -5px;
                right: -5px;
                position: absolute;
								transition: 400ms ease all;
								background: #00E2CA;
              }
              &:before{
                content: "";
                display: block;
                height: 2px;
                width: 40px;
                right: -5px;
                top: -5px;
                position: absolute;
								transition: 400ms ease all;
								background: #00E2CA;
              }
            }
					}
					.Modal__left__title{
						padding-top: 20px;
						font-weight: 700;
						font-size: 25px;
					}
					.Modal__left__description{
						padding-top: 10px;
						font-size: 12px;
						color: gray;
						padding: 10px 40px;
					}
				}
				.Modal__right{
					flex: 0 0 50%;
					height: 100%;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
				}
			}
		}
		.Modal {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
			display: flex;
			align-items: center;
			justify-content: center;
			visibility: hidden;
			opacity: 0;
			//    transition: 200ms ease all;
			overflow-x: hidden;
			background: rgba(0,0,0,.5);

			.Modal__layer {
				// background: rgba(0,0,0,0.5);
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				cursor: pointer;
			}
			align-items: flex-start;
			@media screen and (min-width: 640px) {
				align-items: center;
			}
			.Modal__wrapper {
				width: 90%;
				height: 90%;
				background: #202227;
				//border-radius: 5px;
				//padding: 0 30px 0 0;
				position: relative;
				//box-shadow: 0px 1px 5px 0px rgba(66, 177, 212, 0.88);
				//border: 1px solid #383838;
				text-align: center;
				//display: flex;
				//flex-direction: column;
				align-items: center;
				justify-content: center;
				//transition: 200ms ease all;
				transform: scale(0.8);
				margin: 5vw 0 5vh;
				@media screen and (min-width: 640px) {
					margin: 0;
				}

				.icon {
					width: 50px;
					margin: 30px auto;

					img {
						width: 100%;
					}
				}

				.Modal__title {
					font-size: 25px;
					letter-spacing: 2px;
					margin: 20px 0 0;
				}
				.Modal__close-btn {
					width: 35px;
					height: 35px;
					margin-left: auto;
					position: absolute;
					right: 10px;
					top: 10px;
					cursor: pointer;
					&:before {
						content: "";
						width: 3px;
						height: 90%;
						background: #37393D;
						display: block;
						position: absolute;
						left: 50%;
						// top: 50%;
						transform-origin: center;
						transform: rotate(45deg);
					}
					&:after {
						content: "";
						width: 3px;
						height: 90%;
						background: #37393D;
						display: block;
						position: absolute;
						left: 50%;
						// top: 50%;
						transform-origin: center;
						transform: rotate(-45deg);
					}
				}
			}
			&.active {
				visibility: visible;
				opacity: 1;
				.Modal__wrapper {
					transform: scale(1);
					//transition: 200ms ease all;
				}
			}
		}
	}
}
