@import '../../../utils/_variables';

.Recover{
  .ModalMaster{
    .Modal{
      .Modal__wrapper{
        .Modal__content{
          padding-bottom: 52px;
        }
      } 
    }
  } 
}