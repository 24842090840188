.region-module-container {
  display: flex;
  height: 600px;
  width: calc(100% - 316px);

  h2 {
    font-size: 20px;
    text-align: center;
    margin: 1.38em 0 1em 0;
  }

  .region-module {
    background: #202227;
    border-radius: 0.38em;
    height: 100%;
    width: 100%;
  
    .regionHeader {
      border: none;
      background: #202227;
      font-size: 14px;
      font-weight: 900;
    }
  
    .completionSection {
      align-items: center;
      display: flex;
      font-size: 12px;
      width: 100%;
  
      .completionBarWrapper {
        display: flex;
        width: 100%;
      }
  
      &.accv {
        color: #3a74e0;
  
        span.completionBar {
          background-image: linear-gradient(to left, #49e1ca, #3a74e0 100%);
        }
      }
  
      &.influencers {
        color: #a142ff;
  
        span.completionBar {
          background-image: linear-gradient(to left, #fd8d82, #a142ff 100%);
        }
      }
    }
  
    span.completionBar {
      display: block;
      width: 80%;
      height: 12px;
      position: relative;
      margin: 5px 10px 5px 0px;
    }
  
    span.completionVal {
      width: 200px;
      display: flex;
      align-items: center;
    }
  
    .RegionCell {
      border: none;
  
      &.odd {
        background: #2b2e38;
      }
  
      &.even {
        background: #202227;
      }
    }
  }
}
