.inputText_title{
    font-size: 1.8em;
}
.inputText .CustomInput{
    flex-direction: row;
    flex-wrap: nowrap;
}
.inputText .CustomInput__Header{
    width: 6vw;
    text-align: right;
    margin-right: 5px;

}
.inputText1 .CustomInput__Header{
    width: 10vw;

}

@media screen and (max-width: 560px) {
    .inputText .CustomInput{
        flex-wrap: wrap;

    }
    .inputText .CustomInput__Header,
    .inputText1 .CustomInput__Header{
        width: auto;
        text-align: left;
    }
}