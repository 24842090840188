.hatchway{
    display: flex;
    margin-top: 10vh;
    justify-content: space-evenly;
    text-align: center;
}
h2{
    text-transform: uppercase;
    font-size: 3em;
    margin-bottom: 10px;
}
p{
    font-size: 18px;
}
h2 em,
p em{
    color: #ef3937;
    font-style: normal;
}
.btn{
    text-transform: uppercase;
    display: block;
    background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6 31%, #5d69ea 67%, #8358ed);
    border-radius: 24px;
    color: white;
    font-size: 18px;
    height: 48px;
    width: 180px;
    line-height: 48px;
    text-decoration: none;
    margin: 20px auto 0;
}