@import "../../../../../utils/_variables";

.GallerySocials {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 35px;
  padding: 20px;

  background-color: $colorBackground;
}