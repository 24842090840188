@import  '../../../../utils/_variables';

.AGameList{
  .AGameList__wrapper{
    margin: 0 auto;
		padding: 30px;
    padding-top: 120px;
    .button-style{
      width: 300px;
      height: 50px;
      border-radius: 30px;
      background: linear-gradient(to right, #45cacd, #47b8d4);
      color: #FFF;
      border: none;
      outline: none;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 22px;
      letter-spacing: 1.2px;
      margin: 20px 0 20px 0;
      cursor: pointer;
    }
    .AGameList__title{
			font-weight: 500;
			letter-spacing: 3.5px;
	    text-align: left;
			text-transform: uppercase;
			@include vw-pixels((
				'font-size': 35
			));
		}
    .AGameList__actions{
      button{
        @extend .button-style;
        margin-right: 10px;
      }
    }
    .ToggleTable {
      .ToggleTable__header {
        background: #2b2e38;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 30px;
        border-bottom: 1px solid #454545;
        border-top: 1px solid #454545;
        .ToggleTable__header__left{
          padding: 0;
          margin: 0;
          font-size: 22px;
          letter-spacing: 2.5px;
        }
        .ToggleTable__header__right{
          margin-left: auto;
          button{
            &.active {
              background: linear-gradient(to right, #45cacd, #47b8d4);
            }
            border-radius: 4px;
            padding: 5px 15px;
            background: #2B2E38;
            color: #FFF;
            border: none;
            outline: none;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 22px;
            letter-spacing: 1.2px;
            cursor: pointer;
            margin-left: auto;
          }
          i{
            font-size: 30px;
            line-height: 18px;
            margin-left: auto;
          }
        }
        .dot-status{
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: #ef3937;
          box-shadow: 0 0 9px 0 #ff987f;
          margin-left: 10px;
        }
        
      }
      .ToggleTable__table-wrapper {
        background: #2b2e38;
        padding: 30px;
        .no-results {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 44px;
          margin-top: 30px;
        }
        .row{
          border-bottom: 1px solid #1e1f25;
          padding: 0 10px;
          &:hover {
            background: rgba(255,255,255,0.025);
            &:first-child {
              background: rgba(255,255,255,0);
            }
          }
          &:last-child{
            border-bottom: none;
          }
          .table-cell{
            justify-content: center;
            &.headline{
              color: #9b9b9b;
              font-weight: 400;
              letter-spacing: 1.2px;
            }
            &:first-child{
              justify-content: flex-start;
            }
            .remove-key {
              background: rgba(255,255,255,0);
              border: none;
              padding: 5px 8px;
              border-radius: 3px;
              color: #FFF;
              letter-spacing: 1px;
              font-weight: 600;
              text-transform: uppercase;
              cursor: pointer;
              font-size: 11px;
              &:hover{
                background: rgba(255,255,255,.2);
              }
            }
            .removing-key {
              background: rgba(255,255,255,0);
              border: none;
              padding: 5px 8px;
              border-radius: 3px;
              color: #e91e63;
              letter-spacing: 1px;
              font-weight: 600;
              text-transform: uppercase;
              font-size: 11px;
              cursor: pointer;
            }
          }
        }
      }
      .ToggleTable__footer {
        background: #2b2e38;
        height: 100px;
        button{
          cursor: pointer;
          i{
            font-size: 60px;
            color: white;
          }
        }
        .ToggleTable__footer__left{
          float: left;
          padding: 30px;
          width: 50%;
        }
        .ToggleTable__footer__right{
          float: right;
          text-align: right;
          padding: 30px;
          width: 50%;
        }
      }
    }
  }
}