@import "../../utils/_variables";

.WhatsNoiz {
  color: #fff;
  background: #000;
  @include vw-pixels(
      (
        "padding-top": 180,
      )
    );
    h2 {
    text-align: center;
    margin: 0;
  }

  p {
    text-align: center;
    margin: 0;
  }
  h2 {
    @include vw-pixels(
      (
        "font-size": 40,
        "margin-bottom": 80,
      ),
      1.4
    );
  }
  p {
    @include vw-pixels(
      (
        "font-size": 30,
      ),
      1.4
    );
    img {
      @include vw-pixels(
        (
          "width": 180,
        )
      );
    }
    &.offset {
      @include vw-pixels(
        (
          "margin-left": 180,
        )
      );
    }
  }
  .Hero {
    text-align: center;
  }
  .WhatsNoiz__steps {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
    position: relative;
    @include vw-pixels(
      (
        "margin-top": 60,
        "padding-top": 120,
      )
    );
    .column {
      margin: 0 10%;
      @media #{$mobileOnly} {
        margin: 20px 10%;
      }
    }
    h4 {
      text-align: left;
      margin: 0;
      @include vw-pixels(
        (
          "font-size": 30,
        ),
        1.8
      );
    }
    p {
      text-align: left;
      margin: 0;
      font-weight: 300;
      line-height: 140%;
      @include vw-pixels(
        (
          "font-size": 21,
        ),
        1.8
      );
    }
    &:after {
      content: "";
      position: absolute;
      width: 6%;
      height: 2px;
      background: red;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  .noiz-icon {
    margin-left: 25%;
    @media #{$mobileOnly} {
      margin: 0 auto;
    }
    @include vw-pixels(
        (
          "margin-bottom": 40,
        )
      );
      &.clock {
      background-image: url('./icons/icon1.png');
    }
    &.money {
      background-image: url('./icons/icon2.png');
    }
  }
  .cta-button {
    display: inline-block;
    @include vw-pixels(
      (
        "margin-top": 160,
        "margin-bottom": 160,
      )
    );
  }
}
