@import  '../../utils/_variables';

.CareersPost{
  margin-top: 120px;
  @media screen and (min-width: 1024px){
    margin-top: 110px;
  }
  .bg-button{
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: #FFF;
		position: relative;
		background-color: #3a74e0;
		background: linear-gradient(180deg, #3a74e0 0%, #3a74e0 50%, #6762e8 100%);
		border-radius: 2px;
    font-weight: 900;
		text-decoration: none;
		width: 100%;
  }

  .CareersPostSummary{
    background: #FFF;
    display: flex;
    flex-direction: column;
    background-image: url('./assets/FloatingElements.png');
    background-size: 110vh;
    background-position: 30% 0;
    position: relative;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 4vw 5vw 4vw 5vw;
    min-height: 30vh;
    @media screen and (min-width: 1024px){
      // padding: 0 5vw 5vh;
      // height: 60vh;
      // background-size: 110vh;
      // background-position: 0 0;
      padding: 0 5vw 5vh;
      min-height: 30vh;
      background-size: 100%;
      background-position: 0 20%;
      background-repeat: no-repeat;
    }
    .CareersPostSummary__content{
      z-index: 2;
      @media screen and (min-width: 1024px){
        max-width: 1280px;
        margin: 0 auto;
        width: 100%;
        position: relative;
      }

      .PostingItem{
        display: inline-block;
        position: relative;
        width: 100%;
        margin: 10px 0;
        .PostingItem__apply-btn{
          position: absolute;
          top: 15px;
          right: 0px;
          height: 40px;
          text-align: center;
          background: #3974df;
          border-color: #1a62e4;
          color: white;
          text-decoration: none;
          padding: 5px 15px;
          min-width: 100px;
          border-radius: 3px;
          font-weight: 600;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          display: inline-flex;
          position: initial;
          min-width: initial;
          @media screen and (min-width: 1024px){
            display: inline-flex;
            position: absolute;
            min-width: 100px;
          }
          i {
            margin-left: 8px;
          }
        }
        .PostingItem__wrapper{
          color: #737578;
          text-decoration: none;
          display: block;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          transition: ease all 300ms;
          padding: 10px 0;
          .PostingItem__title{
            margin: 0;
            font: normal 400 36px/1.4 'Roboto', sans-serif;
            color: #515357;
            text-transform: none;
            letter-spacing: 0px;
            font-size: 34px;
            margin-right: 145px;
            overflow: hidden;
            transition: color 150ms;
            line-height: 40px;
            margin: 10px 0 20px 0;
            @media screen and (min-width: 1024px){
              line-height: 45px;
              margin: 0px 0 10px 0;
            }
          }
          .PostingItem__categories{
            font: normal 700 24px/1.4 'Roboto', sans-serif;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin: 0;
            height: 30px;
            vertical-align: top;
            font-size: 18px;
            line-height: 1.4;
            color: #808080;
            .PostingItem__categories--location{

            }
          }
        }
      }

      // .CareersPostSummary__title{
      //   font-weight: 900;
      //   line-height: 114%;
      //   font-size: 13vw;
      //   margin: 0 0 0 0;
      //   @media screen and (min-width: 768px){
      //
      //   }
      //   @media screen and (min-width: 1024px){
      //     font-weight: 900;
      //     line-height: 110%;
      //     font-size: 6vw;
      //     margin: 0 0 2vw 0;
      //   }
      //   @media (min-aspect-ratio: 16/9){
      //     font-size: 10vh;
      //     line-height: 100%;
      //     margin: 0 0 2.5vh 0;
      //   }
      // }
    }
  }

  .CareersPostDetails{
    background: #f9f9f9;
    color: #1b1e22;
    padding: 5vw 5vw 18vw 5vw;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px){
      padding: 2vw 5vw 5vw 5vw;
    }
    @media screen and (min-width: 1280px){
      padding: 2vw 5vw 7vw 5vw;
    }
    .CareersPostDetails__content{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: flex-end;
      align-items: flex-start;
      @media screen and (min-width: 1024px){
				max-width: 1280px;
		    margin: 0 auto;
		    width: 100%;
				position: relative;
			}
      p, li{
        color: #515357;
        font: normal 400 16px/1.8 'Roboto', sans-serif;
        a{
          color: #3974df;
        }
      }
      .CareersPostDetails__content--cta{
        font-weight: 600;
        font-size: 18px;
        text-align: 'center';
        margin: 10vw auto 0;
        @media screen and (min-width: 1024px){
          margin: 5vh auto 0;
        }
        @media screen and (min-width: 1280px){
          margin: 7vh auto 0;
        }
      }
    }
  }
}
