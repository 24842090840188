@import  '../../utils/_variables';
$colorCyan: #43B19F;
$colorGray: #83858c;
.LandingQuest{
  font-family: "Roboto";
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 100%;
  min-height: 100vh;
  margin-top: -70px;
  padding-top: 70px;
  &.active {
    filter: blur(8px);
    -webkit-filter: blur(8px);
  }
  .LandingQuest--loading {
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 50vh;
  }
  .LandingQuest__background{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    &.opactity50 {
      opacity: 0.15;
    }
    figure {
      margin: 0;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: cover;
    }
    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      // background: linear-gradient(to bottom, #18191d 0%, rgba(24, 25, 29, 0.2) 35%, rgba(24, 25, 29, 0) 75%, rgb(24, 25, 29) 100%);
      background: linear-gradient(to bottom, #18191d 0%, rgba(24, 25, 29, .20) 35%, rgba(21, 11, 4, 0.9) 100%);
    }
  }
  .LandingQuest--wrapper {
    .LandingQuest__header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: visible;
      opacity: 1;
      padding: 250px 0 100px 0;
      background: rgba(0,0,0,.5);
      align-items: flex-start;
      overflow: hidden;
      position: relative;
      @media #{$mobileOnly} {
        padding: 150px 0 100px 0;
      }
      .LandingQuest__header--wrapper {
        width: 90%;
        max-width: 1000px;
        padding: 0;
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: row-reverse;
        // justify-content: center;
        transform: scale(1);
        @media #{$mobileOnly} {
          display: block;
          width: 80%;
        }
        @media screen and (min-width: 640px) {
          margin: 0;
        }
        .LandingQuest__header--title {
          font-size: 30px;
          font-weight: 900;
          text-align: left;
          @media #{$mobileOnly} {
            text-align: center;
          }
        }
        .LandingQuest__header--logo {
          margin-left: auto;
          .LandingQuest__header--figure {
            height: 150px;
            width: 150px;
            right: 0;
            top: 0;
            margin: 0;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            @media #{$mobileOnly} {
              margin: 0 auto;
            }
          }
        }
      }
    }
    .LandingQuest__header--previewLabel {
      background: rgb(221, 74, 65);
      padding: 10px;
      text-align: center;
      font-weight: 600;
    }
    .LandingQuest__middle {
      background: #f4f4f9;
      color: black;
      padding: 50px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .LandingQuest__middle--wrapper {
        width: 90%;
        max-width: 1000px;
        justify-content: center;
        display: flex;
        @media #{$mobileOnly} {
          display: block;
          width: 80%;
        }
        .LandingQuest__middle--label {
          display: flex;
          align-items: flex-start;
          text-align: left;
          font-size: 16px;
          font-weight: 700;
          width: 25%;
          @media #{$mobileOnly} {
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
          }
        }
        .LandingQuest__middle--description {
          width: 75%;
          font-size: 20px;
          font-weight: 500;
          font-family: Helvetica;
          @media #{$mobileOnly} {
            width: 100%;
          }
          p {
            margin-top: 0;
            margin-bottom: 20px;
          }
          .LandingQuest__middle--cta {
            background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
            border-radius: 4px;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            text-decoration: none;
            text-align: center;
            font-weight: normal;
            border-radius: 4px;
            font-weight: 700;
            font-size: 16px;
            padding: 15px 40px;
            &.disabled{
              cursor: disabled;
              background: #2e2f32;
              color: $colorGray;
            }
          }
        }
      }
    }
    .LandingQuest__slider {
      background: rgb(52, 129, 230);
      overflow: hidden;
      position: relative;
      .LandingQuest__slider--wrapper {
        width: 90%;
        max-width: 1000px;
        margin: 0 auto;
        padding: 100px 0;
        .LandingQuest__slider--label {
          color: white;
          font-size: 16px;
          font-weight: 700;
          @media #{$mobileOnly} {
            display: block;
            width: 100%;
            text-align: center;
          }
        }
        .GameCarousel {
          width: 100%;
          max-width: 1024px;
          margin-top: 50px;
          margin-bottom: 50px;
          .GameCarousel__wrapper{
            display: flex;
            flex-direction: row;
            opacity: 0.7;
            min-height: 275px;
            transition: 500ms ease all;
            @media #{$tabletAndLarger}{
              min-height: 550px;
            }
          }
          &.swiper-slide-next {
            .GameCarousel__wrapper{
              transform: scale(0.70) translateY(20%) translateX(-15%);
            }
          }
          &.swiper-slide-prev {
            .GameCarousel__wrapper{
              transform: scale(0.70) translateY(20%) translateX(15%);
            }
          }
          &.swiper-slide-prev,
          &.swiper-slide-next{
            .GameCarousel__wrapper{
              opacity: 0.5;
              cursor: pointer;
              transition: 500ms ease all;
            }
            &:hover{
              .GameCarousel__wrapper{
                // transform: scale(0.90);
                opacity: 0.7;
                transition: 500ms ease all;
              }
            }
          }
          &.swiper-slide-active{
            .GameCarousel__wrapper{
              opacity: 1;
              transform: scale(1);
              transition: 500ms ease all;
            }
          }
          .GameCarousel__left{
            position: relative;
            width: 100%;
            margin: 4px 0 0;
            figure{
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              margin: 0;
              padding: 0;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center bottom;
            }
            &:before{
              content: '';
              position: absolute;
              height: 4px;
              width: 36%;
              background-color: #FC8D82;
              top: -4px;
              right: 0;
            }
            &:after{
              content: '';
              position: absolute;
              height: 4px;
              width: 36%;
              background-color: #FC8D82;
              bottom: -4px;
              left: 0;
            }
          }
        }
      }
    }
  }
  .LandingQuest__questions{
    a,
    a:visited{
      color:#fff;
    } 
  }
  .LandingQuest__questions{
    padding-right: 10%;
    padding-left: 10%;
    .LandingQuest__questions__wrapper{
      max-width: 1000px;
      margin: 0 auto;
      display: block;
      padding: 70px 0;
      @media #{$tabletAndLarger}{
        display: flex;
      }
      .LandingQuest__questions_left{
        flex: 3;
        .LandingQuest__questions_title{
          font-weight: 700;
          font-size: 30px;
        }
        .LandingQuest__questions_contact{
          padding-top: 20px;
          .LandingQuest__questions_contact_label{
            font-size: 12px;
          }
          .LandingQuest__questions_contact_email{
            font-size: 14px;
          }
        }
      }
      .LandingQuest__questions_right{
        flex: 4;
        padding-top: 30px;
        @media #{$tabletAndLarger}{
          padding-top: 0;
        }
        .LandingQuest__questions_question{
          
          margin-bottom: 25px;
          padding-bottom:10px;
          border-bottom: 2px solid $colorBackground2;
          .LandingQuest__questions_question_top{
            display: flex;
            font-size: 14px;
            font-weight: 700;
            i {
              margin-left: auto;
              color: $colorEnergyBlue;
              font-size: 18px;
            }
          }
          .LandingQuest__questions_question_bottom{
            padding: 20px 0 10px 0;
            font-size: 12px;
            p{
              margin:0 !important;
            }
          }
        }
      }
    }
  }
}

.LandingQuest_ModalMaster{
  filter: none;
  -webkit-filter: none;
    &.active{
      visibility: visible;
      opacity: 1;
      .Modal__wrapper{
        transform: scale(1);
      }
    }
    .Modal{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      background: rgba(0,0,0,.5);
      align-items: flex-start;
      @media screen and (min-width: 640px){
        align-items: center;
      }
      &.active{
        visibility: visible;
        opacity: 1;
        .Modal__wrapper{
          // transform: scale(1);
          // top: 0%;
          transform: scale(1) translateY(-50px);
          margin: auto 0;
          @media #{$mobileOnly}{
            transform: none !important;
            margin:5vh 0 !important;
            top:0 !important;
          }
        }
      }
      .Modal__layer{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      .Modal__wrapper {
        width: 90%;
        max-width: 825px;
        background: #202227;
        padding: 0;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
        // margin: 5vw 0 5vh 0;
        @media screen and (min-width: 640px){
          margin: 0;
        }
        .Modal__header{
          display: flex;
          align-items: center;
          height: 100px;
          position: relative;
          overflow:hidden;
          padding: 0 20px;
          .Modal__header__background2{
            &::before{
              content: "";
              top: 0;
              left: 0;
              position: absolute;
              width: 100%;
              height: 100%;
              background: rgba(0,0,0,0.5);
            }
          }
          .Modal__header__background, .Modal__header__background2{
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            margin: 0;
            background-color: #202227;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            &::before{
              content: "";
              top: 0;
              left: 0;
              position: absolute;
              width: 100%;
              height: 100%;
            }
          }
          .Modal__header__logo{
            width: 150px;
            margin: 0;
            position: relative;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &:before{
              content: "";
              padding-top: 52.26%;
              display: block;
            }
          }
          .Modal__header-type{
            margin-left: auto;
            text-transform: capitalize;
            color: #FFF;
            z-index: 1;
            margin-right: 20px;
            font-weight: 800;
            font-family: "Roboto";
          }
          .Modal__close-btn{
            width: 35px;
            height: 35px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
            cursor: pointer;
            outline: none;
            &:hover{
              &::after,
              &::before{
                background: #6d7179;
              }
            }
            &::before{
              content: "";
              width: 3px;
              height: 90%;
              background: #37393D;
              display: block;
              position: absolute;
              left: 50%;
              transform-origin: center;
              transform: rotate(45deg);
              transition: ease 300ms background;
            }
            &::after{
              content: "";
              width: 3px;
              height: 90%;
              background: #37393D;
              display: block;
              position: absolute;
              left: 50%;
              transform-origin: center;
              transform: rotate(-45deg);
              transition: ease 300ms background;
            }
          }
        }
        .Modal__body {
          padding: 30px 0;
          a {
            color: inherit;
          }

        }
        .Modal__footer {
          padding-bottom: 30px;
          button {
            background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
            border-radius: 4px;
            color: #fff;
            cursor: pointer;
            display: inline-flex;
            text-decoration: none;
            text-align: center;
            font-weight: normal;
            border-radius: 4px;
            font-weight: 700;
            font-size: 16px;
            padding: 15px 30px;
            outline: none;
          }
        }
      }
    }
  }
