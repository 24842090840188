.AdminImportUsers{

  .title{
    margin: 20px auto;
    display: block;
    text-align: center;
    color: #FFF;
  }

  .Dropzone{
      // min-height: 100vh;
  }

  .Dropzone__DropLayer{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 2.5em 0;
      background: rgba(0,0,0,0.5);
      text-align: center;
      color: #fff;
      z-index: 999999;
  }

  .FilePickButton{
      display: flex;
      border: 2px dashed #CCC;
      border-radius: 4px;
      background: #fbfbfb;
      margin: 10px auto 20px auto;
      width: 90%;
      max-width: 500px;
      min-height: 100px;
      justify-content: center;
      cursor: pointer;
      outline: none;
  }

  .FilePickButton:hover{
      background: #f7f7f7;
  }

  .FilePickButton__icon-container {
      font-size: 40px;
      padding-right: 10px;
      color: #454545;
      margin-left: auto;
  }

  .FilePickButton__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-family: 'Lato', sans-serif;
      margin: auto auto auto 0px;
  }

  .FilePickButton__info > h3{
      margin: 0px 0px 0px 0px;
      color: #454545;
  }

  .FilePickButton__info > div{
      color: #555555;
  }

  .FilePickButton__info > div > span.link-style{
      color: #0077e4;
      text-decoration: underline;
      font-size: 12px;
  }
}
