.NoizLoader {
  position: fixed;
  background: black;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  display: flex;
  justify-content: center;
  opacity: 1;

  video {
    width: 200px;
  }
}