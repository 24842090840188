@import '../../utils/variables';

.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: flex-end;
  margin-bottom: 10px;
  position: relative;

  .page {
    display: flex;
    
    span {
      display: flex;
      height: 100%;
      
      @include vw-pixels((
        'height': 30,
        'margin-left': 10
      ), $vwPixelsScale);
  
      @media #{$mobileOnly} {
        @include vw-pixels((
          'height': 44,
          'margin-left': 10
        ));
      }

      @media #{$macroScreens} {
        height: 30px;
        margin-left: 10px;
      }
    }
  
    button {
      align-items: center;
      background: rgb(32, 33, 38);
      border: 1.5px solid #3a74e0;
      border-radius: 2px;
      color: #FFF;
      cursor: pointer;
      display: flex;
      justify-content: center;
  
      @include vw-pixels((
        'font-size': 16,
        'height': 30,
        'margin-left': 10,
        'width': 30
      ), $vwPixelsScale);
  
      @media #{$mobileOnly} {
        @include vw-pixels((
          'font-size': 18,
          'height': 44,
          'margin-left': 10,
          'width': 44
        ));
      }

      @media #{$macroScreens} {
        font-size: 17px;
        height: 35px;
        margin-left: 10px;
        width: 35px;
      }
  
      &.active {
        background: linear-gradient(180deg, #3a74e0 0%, #3a74e0 50%, #6762e8 100%);
        cursor: default;
      }
    }
  }
}