@import '../../../../utils/_variables';

.Categories {
  background-color: $colorSoftPurple;
  display: flex;
  flex-direction: column;
  padding: 35px 21px;
  width: 100%;

  & > * {
    margin: 10px 0;
  }

  .Categories_Header {
    color: $colorBackground;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.16px;
    text-align: left;
  }

  .Categories_Selected {
    display: flex;
    flex-wrap: wrap;

    .Categories_Selected_Item {
      align-items: center;
      background-color: $colorDarkPurple;
      border-radius: 8px;
      color: $colorBackground;
      cursor: pointer;
      display: flex;
      height: 45px;
      justify-content: space-between;
      margin-bottom: 9px;
      margin-right: 15px;
      padding: 10px;
      padding-right: 15px;
      position: relative;

      svg {
        height: 12px;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 12px;
      }
    }
  }

  .Categories_Options {
    background-color: $colorDarkPurple;
    border: none;
    height: 52px;
    padding: 15px;
    width: 100%;
  }
}