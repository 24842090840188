@import  '../../../utils/_variables';



.MyAccountHome{
	max-width: 1024px;
	margin: 0 auto;
	@media screen and (min-width: 1024px){
		max-width: 1024px;
	}
	.MyAccountHome__main{
		justify-content: space-between;
		padding: 6%;
		.column{
			width: auto;
		}
	}

	.MyAccountHome__main__profile{
		display: flex;
		align-items: center;
		@media #{$mobileOnly}{
			flex-direction: column;
			justify-content: space-around;
			text-align: center;
		}
		.profile-image{
			width: 88px;
			height: 88px;
			background: #fff;
		}
		.profile-name{
			margin-left: 20px;
			h1{
				font-weight: 400;
				letter-spacing: 0.09em;
				-webkit-font-smoothing: antialiased;
				margin-bottom: 4px;
			}
			label{
				font-weight: 300;
				letter-spacing: 0.06em;
			}
		}
	}
	.MyAccountHome__main__account{
		text-align: center;
		@media #{$mobileOnly}{
			margin-top: 20px;
			padding-top: 20px;
			border-top: 1px solid #000;
		}
		h2{
			font-weight: 300;
			font-size: 20px;
			letter-spacing: 0.12em;
		}
		h3{
			margin-top: 8px;
			font-size: 30px;
			letter-spacing: 0.09em;
			-webkit-font-smoothing: antialiased;
		}
	}
	.MyAccountHome__bank{
		align-items: baseline;
		.column{
			@media #{$tabletAndLarger}{
				&:first-child{
					margin-right: 30px;
				}
			}
		}
		.account-information{
			.content-block{
				display: flex;
				flex-direction: column;
				align-items: center;
				.account-information__wrapper{
					display: flex;
					flex-direction: column;
				}
				label{
					margin: 8px 0;
					font-weight: 300;
					letter-spacing: 0.06em;
				}
			}
		}
	}
	.payment__table{
		.row{
			border-bottom: 1px solid #000;
			&:last-child{
				border: none;
			}
		}
		.table-cell{
			&.headline{
				color: #9B9B9B;
			}
			.green-plus{
				color: #B8E986;
				margin-right: 4px;
				text-shadow: 0 0 2px rgba(184,233,134, 0.4);
			}
		}
	}
	h1,h2,h3{
		margin: 0;
	}
}
