@import  '../../utils/_variables';

.StatusBar {
	min-height: 60px;
	width: 100%;
	border-radius: 3px;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 20px;
	margin: 0 0 20px 0;

	&.hidden {
		display: none;
	}

	a {
		font-weight: bold;
		margin: 0 0 0 3px;
	}

	&.pending {
		background-image: linear-gradient(to right, #49e1ca 0%, #3a74e0 100%);
	}

	&.no-twitch,
	&.accv-min {
		background-image: linear-gradient(to right, #fd8d82 0%, #6236ff 100%);
	}

  @media #{$mobileOnly}{
  	border-radius: 0;
  }
}