@import "../../../../utils/_variables";

.NewUsersCounts_MasterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // width: 100%;

  // @extend .button-style;
  // .OpenModal {

  //   margin-right: 4px;
  //   border-radius: 4px;
  //   padding: 5px 15px;
  //   background: #202227;
  //   color: #fff;
  //   border: none;
  //   outline: none;
  //   font-family: Roboto;
  //   font-size: 14px;
  //   font-weight: 500;
  //   font-style: normal;
  //   font-stretch: normal;
  //   line-height: 22px;
  //   letter-spacing: 1.2px;
  //   cursor: pointer;
  //   margin-left: auto;

  //   &:hover {
  //     background: rgba(255, 255, 255, 0.2);
  //   }
  // }
  .NewUsersCounts_WorkingContainer {
    // width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .NewUsersCounts_Count {
    
      // width: 100%;
    }
  }
}
