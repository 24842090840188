@import '../../../utils/_variables';

.CommandCenter {
	.brand-toggle {
		font-size: 50%;
		color: rgb(183, 183, 183);
		@include row-start;
	}

	.CommandCenter__wrapper {
		// max-width: 1024px;
		margin: 0 auto;
		padding: 30px;
		padding-top: 120px;

		.CommandCenter__title {
			font-weight: bold;
			letter-spacing: 1px;
			text-align: center;
			text-transform: uppercase;
			font-size: 40px;
		}

		.CommandCenter__container {
			display: flex;
			flex-direction: row;

			.GameList {
				display: flex;
				flex-direction: column;
				width: 20%;
				margin-right: 30px;
				max-width: 260px;
				background: #202227;
				min-height: 100vh;

				// padding: 30px;
				.GameList__title {
					font-weight: bold;
					letter-spacing: 0.6px;
					text-align: left;
					color: #ffffff;
					font-size: 24px;
					margin: 22px 18px;
				}

				.GameList__wrapper {
					.loading {
						text-align: center;
						padding: 20px 0 0 0;
					}

					ul {
						list-style: none;
						margin: 0;
						padding: 0;

						.separator {
							border-top: 2px solid #83858c;
							margin: 0 0 15px 0;
						}

						li {
							margin: 0 0 15px 0;
							font-weight: 400;
						}

						.GameList__label {
							font-weight: bold;
							cursor: pointer;
							display: flex;
							align-items: center;
							color: #ffffff;
							background: #2c3239;
							height: 44px;
							margin: 0 0 4px 0;
							padding: 0 0 0 18px;

							i {
								margin: 0 16px 0 auto;
								align-self: center;
							}
						}

						.GameList__GameItem {
							list-style: none;
							height: 44px;
							margin: 0;
							display: flex;
							align-items: center;
							padding: 0 10px 0 30px;
							font-size: 14px;

							&:last-child {
								margin: 0 0 20px 0;
							}

							a,
							button {
								position: relative;
								font-size: 14px;
								font-style: normal;
								font-stretch: normal;
								line-height: normal;
								color: #ffffff;
								text-decoration: none;
								list-style: none;

								&.active,
								&.hover {
									font-weight: bold;
									color: $colorLifeGreen;
								}
							}

							&.active,
							&.hover {
								background: #3e424d;

								a,
								button {
									font-weight: bold;
									color: $colorLifeGreen;
								}
							}
						}

						.link-out {
							margin: 0 5px;

							&.active::after,
							&:hover::after {
								width: 0 !important;
								height: 0 !important;
							}
						}
					}
				}
			}

			.CommandCenter__child {
				display: flex;
				flex-direction: column;
				min-width: calc(75% - 30px);
				// background: #282930;
				// padding: 30px;
				width: 100%;

				.CommandCenter__navigation {
					margin: 0 0 30px 0;

					>a {
						position: relative;
						font-size: 14px;
						font-weight: 900;
						font-style: normal;
						font-stretch: normal;
						line-height: normal;
						color: #83858c;
						text-decoration: none;
						list-style: none;
						margin-right: 20px;
						padding: 14px 18px;
						border-radius: 3px;

						&.active,
						&:hover {
							color: $colorLifeGreen;
							background: #3e424d;
						}
					}

					// Allow for active state on child routes
					span {
						a {
							position: relative;
							font-size: 14px;
							font-weight: 900;
							font-style: normal;
							font-stretch: normal;
							line-height: normal;
							color: #83858c;
							text-decoration: none;
							list-style: none;
							margin-right: 20px;
							padding: 14px 18px;
							border-radius: 3px;


							&:hover {
								color: $colorLifeGreen;
								background: #3e424d;
							}
						}
					}

					.active {
						a {
							position: relative;
							font-size: 14px;
							font-weight: 900;
							font-style: normal;
							font-stretch: normal;
							line-height: normal;
							text-decoration: none;
							list-style: none;
							margin-right: 20px;
							padding: 14px 18px;
							border-radius: 3px;
							text-decoration: none;
							color: $colorLifeGreen !important;
							background: #3e424d !important;
						}
					}
				}
			}
		}
	}
}