@import "../../utils/_variables";

.Platform {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  .Platform__Title {
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: bolder;
  }

  .Platform__Input {
    margin-bottom: 15px;
  }
}