@import "../../../../utils/variables";

$border-radius-speed: 0.2s;
$max-width: 300px;
$max-height-speed: 1s;

.GlossyDropdown {
  @include no-select;

  display: flex;
  flex-direction: column;
  max-width: $max-width;

  width: max-content;
  // position: relative;

  .GlossyDropdown__Title {
    align-items: center;

    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background-color: rgba(255, 255, 255, 0.4);

    border-radius: 12px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;

    height: 45px;
    justify-content: space-between;
    letter-spacing: normal;
    line-height: 1.36;
    max-width: $max-width;
    padding: 0 14px;
    text-align: left;

    -webkit-transition: border-radius $border-radius-speed;
    -moz-transition: border-radius $border-radius-speed;
    -ms-transition: border-radius $border-radius-speed;
    -o-transition: border-radius $border-radius-speed;
    transition: border-radius $border-radius-speed;
    width: 100%;

    svg {

      height: auto;
      margin-left: 10px;
      width: 14px;
    }

    &.isOpen {
      border-radius: 12px 12px 0 0;
    }
  }

  .GlossyDropdown__Options {
    @include no-select;

    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background-color: rgba(255, 255, 255, 0.25);

    border-radius: 12px;

    // display: flex;
    // flex-direction: column;
    display: inline-block;
    max-height: 0;
    max-width: $max-width;
    overflow: hidden;

    // position: absolute;
    top: 100%;

    -webkit-transition: max-height $max-height-speed;
    -moz-transition: max-height $max-height-speed;
    -ms-transition: max-height $max-height-speed;
    -o-transition: max-height $max-height-speed;
    transition: max-height $max-height-speed;
    -webkit-transition: height $max-height-speed;
    -moz-transition: height $max-height-speed;
    -ms-transition: height $max-height-speed;
    -o-transition: height $max-height-speed;
    transition: height $max-height-speed;

    -webkit-transition: border-radius $border-radius-speed;
    -moz-transition: border-radius $border-radius-speed;
    -ms-transition: border-radius $border-radius-speed;
    -o-transition: border-radius $border-radius-speed;
    transition: border-radius $border-radius-speed;

    width: max-content;

    &.isOpen {
      border-radius: 0 0 12px 12px;
      max-height: 400px;

      overflow: auto;
    }

    .GlossyDropdown__Option {
      align-items: center;
      display: flex;

      height: 55px;
      justify-content: space-between;
      padding: 0 14px;

      &.Disabled {
        color: $colorDarkGray;
        cursor: not-allowed;
        opacity: 0.5;
      }

      .GlossyDropdown__Option__Left {
        align-items: center;

        cursor: pointer;
        display: flex;

        .GlossyDropdown__Option__Img {

          border-radius: 8px;
          height: 35px;

          margin-right: 10px;
          object-fit: cover;
          width: 35px;
        }

        .GlossyDropdown__Option__Label {
          display: block;
        }
      }

      .GlossyDropdown__Option__Button {

        // -webkit-appearance: none;
        // appearance: none;
        // background-color: #fff;

        border: 0.15em solid currentColor;

        border-radius: 50%;
        color: $colorWhite;
        cursor: pointer;
        display: grid;
        font: inherit;
        margin-left: 10px;
        max-height: 22px;
        max-width: 22px;

        min-height: 22px;
        min-width: 22px;
        place-content: center;

        &::before {
          border-radius: 50%;
          box-shadow: inset 1em 1em var(--form-control-color);
          content: "";
          height: 0.65em;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          width: 0.65em;
        }
      }

      &.Disabled>.GlossyDropdown__Option__Left,
      &.Disabled > .GlossyDropdown__Option__Button {
        cursor: not-allowed;
      }

      .Selected {
        background-image: linear-gradient(to bottom, #62e841, #4be0ca);
        border: none;
      }
    }

    .GlossyDropdown__Submit {
      @include center-content;

      .GlossyDropdown__Submit__Button {
        @include purple-gradient-button;

        height: 41px;
        width: 45%;
      }
    }
  }
}
