.ReputationModal {

	span.label {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: bold;
	}

	.optionsList {
		margin: 12px 0 18px 0px;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		max-height: 260px;

		img.emoji {
			width: 64px;
		}

		span.option {
			cursor: pointer;
			display: flex;
			align-items: center;
			flex-direction: column;
			width: 105px;
			height: 140px;
		  border-radius: 12px;
		  padding: 20px 15px;
		  transition: background 150ms ease-out;
		  background: none;
		  border: none;
		  margin: 0;
		  text-transform: uppercase;
		  font-size: 12px;
		  font-weight: bold;

		  img {
		  	margin: 0 0 18px 0;
		  }

		  &.selected {
  			border: solid 2px #49e1ca;
  			background: #2c3239;
		  	transition: background 150ms ease-out;
		  }
		}

		input[type=checkbox] {
			cursor: pointer;
			margin: 0 8px 0 0;

			&:checked {
				background: red;
			}
		}
	}

	span.note {
		color: #83858c;
	}

	button {
		width: 160px;
		height: 44px;
	}

	.buttons.quality {
		margin: 35px 0 0 0;
		float: right;
	}
}