.grid-x > .sgrid-0,
.grid-x > .sgrid-05,
.grid-x > .sgrid-1,
.grid-x > .sgrid-2,
.grid-x > .sgrid-3,
.grid-x > .sgrid-4,
.grid-x > .sgrid-5,
.grid-x > .sgrid-6,
.grid-x > .sgrid-7,
.grid-x > .sgrid-8,
.grid-x > .sgrid-9,
.grid-x > .sgrid-10,
.grid-x > .sgrid-11,
.grid-x > .sgrid-12,
.grid-x > .sgrid-13,
.grid-x > .sgrid-14,
.grid-x > .sgrid-15,
.grid-x > .sgrid-16,
.grid-x > .sgrid-17,
.grid-x > .sgrid-18,
.grid-x > .sgrid-19,
.grid-x > .sgrid-20,
.grid-x > .sgrid-21,
.grid-x > .sgrid-22,
.grid-x > .sgrid-23,
.grid-x > .sgrid-24,
.grid-x > .sgrid-25,
.grid-x > .sgrid-26,
.grid-x > .sgrid-27,
.grid-x > .sgrid-28,
.grid-x > .sgrid-29,
.grid-x > .sgrid-30,
.grid-x > .sgrid-31,
.grid-x > .sgrid-32,
.grid-x > .sgrid-33,
.grid-x > .sgrid-34,
.grid-x > .sgrid-35,
.grid-x > .sgrid-36,
.grid-x > .sgrid-37,
.grid-x > .sgrid-38,
.grid-x > .sgrid-39,
.grid-x > .sgrid-40,
.grid-x > .sgrid-41,
.grid-x > .sgrid-42,
.grid-x > .sgrid-43,
.grid-x > .sgrid-44,
.grid-x > .sgrid-45,
.grid-x > .sgrid-46,
.grid-x > .sgrid-47,
.grid-x > .sgrid-48,
.grid-x > .sgrid-49,
.grid-x > .sgrid-50,
.grid-x > .sgrid-51,
.grid-x > .sgrid-52,
.grid-x > .sgrid-53,
.grid-x > .sgrid-54,
.grid-x > .sgrid-55,
.grid-x > .sgrid-56,
.grid-x > .sgrid-57,
.grid-x > .sgrid-58,
.grid-x > .sgrid-59,
.grid-x > .sgrid-60,
.grid-x > .sgrid-61,
.grid-x > .sgrid-62,
.grid-x > .sgrid-63,
.grid-x > .sgrid-64,
.grid-x > .sgrid-65,
.grid-x > .sgrid-66,
.grid-x > .sgrid-67,
.grid-x > .sgrid-68,
.grid-x > .sgrid-69,
.grid-x > .sgrid-70,
.grid-x > .sgrid-71,
.grid-x > .sgrid-72,
.grid-x > .sgrid-73,
.grid-x > .sgrid-74,
.grid-x > .sgrid-75,
.grid-x > .sgrid-76,
.grid-x > .sgrid-77,
.grid-x > .sgrid-78,
.grid-x > .sgrid-79,
.grid-x > .sgrid-80,
.grid-x > .sgrid-81,
.grid-x > .sgrid-82,
.grid-x > .sgrid-83,
.grid-x > .sgrid-84,
.grid-x > .sgrid-85,
.grid-x > .sgrid-86,
.grid-x > .sgrid-87,
.grid-x > .sgrid-88,
.grid-x > .sgrid-89,
.grid-x > .sgrid-90,
.grid-x > .sgrid-91,
.grid-x > .sgrid-92,
.grid-x > .sgrid-93,
.grid-x > .sgrid-94,
.grid-x > .sgrid-95,
.grid-x > .sgrid-96,
.grid-x > .sgrid-97,
.grid-x > .sgrid-98,
.grid-x > .sgrid-99,
.grid-x > .sgrid-full {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-x > .mgrid-0,
  .grid-x > .mgrid-05,
  .grid-x > .mgrid-1,
  .grid-x > .mgrid-2,
  .grid-x > .mgrid-3,
  .grid-x > .mgrid-4,
  .grid-x > .mgrid-5,
  .grid-x > .mgrid-6,
  .grid-x > .mgrid-7,
  .grid-x > .mgrid-8,
  .grid-x > .mgrid-9,
  .grid-x > .mgrid-10,
  .grid-x > .mgrid-11,
  .grid-x > .mgrid-12,
  .grid-x > .mgrid-13,
  .grid-x > .mgrid-14,
  .grid-x > .mgrid-15,
  .grid-x > .mgrid-16,
  .grid-x > .mgrid-17,
  .grid-x > .mgrid-18,
  .grid-x > .mgrid-19,
  .grid-x > .mgrid-20,
  .grid-x > .mgrid-21,
  .grid-x > .mgrid-22,
  .grid-x > .mgrid-23,
  .grid-x > .mgrid-24,
  .grid-x > .mgrid-25,
  .grid-x > .mgrid-26,
  .grid-x > .mgrid-27,
  .grid-x > .mgrid-28,
  .grid-x > .mgrid-29,
  .grid-x > .mgrid-30,
  .grid-x > .mgrid-31,
  .grid-x > .mgrid-32,
  .grid-x > .mgrid-33,
  .grid-x > .mgrid-34,
  .grid-x > .mgrid-35,
  .grid-x > .mgrid-36,
  .grid-x > .mgrid-37,
  .grid-x > .mgrid-38,
  .grid-x > .mgrid-39,
  .grid-x > .mgrid-40,
  .grid-x > .mgrid-41,
  .grid-x > .mgrid-42,
  .grid-x > .mgrid-43,
  .grid-x > .mgrid-44,
  .grid-x > .mgrid-45,
  .grid-x > .mgrid-46,
  .grid-x > .mgrid-47,
  .grid-x > .mgrid-48,
  .grid-x > .mgrid-49,
  .grid-x > .mgrid-50,
  .grid-x > .mgrid-51,
  .grid-x > .mgrid-52,
  .grid-x > .mgrid-53,
  .grid-x > .mgrid-54,
  .grid-x > .mgrid-55,
  .grid-x > .mgrid-56,
  .grid-x > .mgrid-57,
  .grid-x > .mgrid-58,
  .grid-x > .mgrid-59,
  .grid-x > .mgrid-60,
  .grid-x > .mgrid-61,
  .grid-x > .mgrid-62,
  .grid-x > .mgrid-63,
  .grid-x > .mgrid-64,
  .grid-x > .mgrid-65,
  .grid-x > .mgrid-66,
  .grid-x > .mgrid-67,
  .grid-x > .mgrid-68,
  .grid-x > .mgrid-69,
  .grid-x > .mgrid-70,
  .grid-x > .mgrid-71,
  .grid-x > .mgrid-72,
  .grid-x > .mgrid-73,
  .grid-x > .mgrid-74,
  .grid-x > .mgrid-75,
  .grid-x > .mgrid-76,
  .grid-x > .mgrid-77,
  .grid-x > .mgrid-78,
  .grid-x > .mgrid-79,
  .grid-x > .mgrid-80,
  .grid-x > .mgrid-81,
  .grid-x > .mgrid-82,
  .grid-x > .mgrid-83,
  .grid-x > .mgrid-84,
  .grid-x > .mgrid-85,
  .grid-x > .mgrid-86,
  .grid-x > .mgrid-87,
  .grid-x > .mgrid-88,
  .grid-x > .mgrid-89,
  .grid-x > .mgrid-90,
  .grid-x > .mgrid-91,
  .grid-x > .mgrid-92,
  .grid-x > .mgrid-93,
  .grid-x > .mgrid-94,
  .grid-x > .mgrid-95,
  .grid-x > .mgrid-96,
  .grid-x > .mgrid-97,
  .grid-x > .mgrid-98,
  .grid-x > .mgrid-99,
  .grid-x > .mgrid-full {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-x > .lgrid-0,
  .grid-x > .lgrid-05,
  .grid-x > .lgrid-1,
  .grid-x > .lgrid-2,
  .grid-x > .lgrid-3,
  .grid-x > .lgrid-4,
  .grid-x > .lgrid-5,
  .grid-x > .lgrid-6,
  .grid-x > .lgrid-7,
  .grid-x > .lgrid-8,
  .grid-x > .lgrid-9,
  .grid-x > .lgrid-10,
  .grid-x > .lgrid-11,
  .grid-x > .lgrid-12,
  .grid-x > .lgrid-13,
  .grid-x > .lgrid-14,
  .grid-x > .lgrid-15,
  .grid-x > .lgrid-16,
  .grid-x > .lgrid-17,
  .grid-x > .lgrid-18,
  .grid-x > .lgrid-19,
  .grid-x > .lgrid-20,
  .grid-x > .lgrid-21,
  .grid-x > .lgrid-22,
  .grid-x > .lgrid-23,
  .grid-x > .lgrid-24,
  .grid-x > .lgrid-25,
  .grid-x > .lgrid-26,
  .grid-x > .lgrid-27,
  .grid-x > .lgrid-28,
  .grid-x > .lgrid-29,
  .grid-x > .lgrid-30,
  .grid-x > .lgrid-31,
  .grid-x > .lgrid-32,
  .grid-x > .lgrid-33,
  .grid-x > .lgrid-34,
  .grid-x > .lgrid-35,
  .grid-x > .lgrid-36,
  .grid-x > .lgrid-37,
  .grid-x > .lgrid-38,
  .grid-x > .lgrid-39,
  .grid-x > .lgrid-40,
  .grid-x > .lgrid-41,
  .grid-x > .lgrid-42,
  .grid-x > .lgrid-43,
  .grid-x > .lgrid-44,
  .grid-x > .lgrid-45,
  .grid-x > .lgrid-46,
  .grid-x > .lgrid-47,
  .grid-x > .lgrid-48,
  .grid-x > .lgrid-49,
  .grid-x > .lgrid-50,
  .grid-x > .lgrid-51,
  .grid-x > .lgrid-52,
  .grid-x > .lgrid-53,
  .grid-x > .lgrid-54,
  .grid-x > .lgrid-55,
  .grid-x > .lgrid-56,
  .grid-x > .lgrid-57,
  .grid-x > .lgrid-58,
  .grid-x > .lgrid-59,
  .grid-x > .lgrid-60,
  .grid-x > .lgrid-61,
  .grid-x > .lgrid-62,
  .grid-x > .lgrid-63,
  .grid-x > .lgrid-64,
  .grid-x > .lgrid-65,
  .grid-x > .lgrid-66,
  .grid-x > .lgrid-67,
  .grid-x > .lgrid-68,
  .grid-x > .lgrid-69,
  .grid-x > .lgrid-70,
  .grid-x > .lgrid-71,
  .grid-x > .lgrid-72,
  .grid-x > .lgrid-73,
  .grid-x > .lgrid-74,
  .grid-x > .lgrid-75,
  .grid-x > .lgrid-76,
  .grid-x > .lgrid-77,
  .grid-x > .lgrid-78,
  .grid-x > .lgrid-79,
  .grid-x > .lgrid-80,
  .grid-x > .lgrid-81,
  .grid-x > .lgrid-82,
  .grid-x > .lgrid-83,
  .grid-x > .lgrid-84,
  .grid-x > .lgrid-85,
  .grid-x > .lgrid-86,
  .grid-x > .lgrid-87,
  .grid-x > .lgrid-88,
  .grid-x > .lgrid-89,
  .grid-x > .lgrid-90,
  .grid-x > .lgrid-91,
  .grid-x > .lgrid-92,
  .grid-x > .lgrid-93,
  .grid-x > .lgrid-94,
  .grid-x > .lgrid-95,
  .grid-x > .lgrid-96,
  .grid-x > .lgrid-97,
  .grid-x > .lgrid-98,
  .grid-x > .lgrid-99,
  .grid-x > .lgrid-full {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

.grid-x > .sgrid-05 {
  width: 0%;
}
.grid-x > .sgrid-05 {
  width: 0.5%;
}
.grid-x > .sgrid-1 {
  width: 1%;
}
.grid-x > .sgrid-2 {
  width: 2%;
}
.grid-x > .sgrid-3 {
  width: 3%;
}
.grid-x > .sgrid-4 {
  width: 4%;
}
.grid-x > .sgrid-5 {
  width: 5%;
}
.grid-x > .sgrid-6 {
  width: 6%;
}
.grid-x > .sgrid-7 {
  width: 7%;
}
.grid-x > .sgrid-8 {
  width: 8%;
}
.grid-x > .sgrid-9 {
  width: 9%;
}
.grid-x > .sgrid-10 {
  width: 10%;
}
.grid-x > .sgrid-11 {
  width: 11%;
}
.grid-x > .sgrid-12 {
  width: 12%;
}
.grid-x > .sgrid-13 {
  width: 13%;
}
.grid-x > .sgrid-14 {
  width: 14%;
}
.grid-x > .sgrid-15 {
  width: 15%;
}
.grid-x > .sgrid-16 {
  width: 16%;
}
.grid-x > .sgrid-17 {
  width: 17%;
}
.grid-x > .sgrid-18 {
  width: 18%;
}
.grid-x > .sgrid-19 {
  width: 19%;
}
.grid-x > .sgrid-20 {
  width: 20%;
}
.grid-x > .sgrid-21 {
  width: 21%;
}
.grid-x > .sgrid-22 {
  width: 22%;
}
.grid-x > .sgrid-23 {
  width: 23%;
}
.grid-x > .sgrid-24 {
  width: 24%;
}
.grid-x > .sgrid-25 {
  width: 25%;
}
.grid-x > .sgrid-26 {
  width: 26%;
}
.grid-x > .sgrid-27 {
  width: 27%;
}
.grid-x > .sgrid-28 {
  width: 28%;
}
.grid-x > .sgrid-29 {
  width: 29%;
}
.grid-x > .sgrid-30 {
  width: 30%;
}
.grid-x > .sgrid-31 {
  width: 31%;
}
.grid-x > .sgrid-32 {
  width: 32%;
}
.grid-x > .sgrid-33 {
  width: 33%;
}
.grid-x > .sgrid-34 {
  width: 34%;
}
.grid-x > .sgrid-35 {
  width: 35%;
}
.grid-x > .sgrid-36 {
  width: 36%;
}
.grid-x > .sgrid-37 {
  width: 37%;
}
.grid-x > .sgrid-38 {
  width: 38%;
}
.grid-x > .sgrid-39 {
  width: 39%;
}
.grid-x > .sgrid-40 {
  width: 40%;
}
.grid-x > .sgrid-41 {
  width: 41%;
}
.grid-x > .sgrid-42 {
  width: 42%;
}
.grid-x > .sgrid-43 {
  width: 43%;
}
.grid-x > .sgrid-44 {
  width: 44%;
}
.grid-x > .sgrid-45 {
  width: 45%;
}
.grid-x > .sgrid-46 {
  width: 46%;
}
.grid-x > .sgrid-47 {
  width: 47%;
}
.grid-x > .sgrid-48 {
  width: 48%;
}
.grid-x > .sgrid-49 {
  width: 49%;
}
.grid-x > .sgrid-50 {
  width: 50%;
}
.grid-x > .sgrid-51 {
  width: 51%;
}
.grid-x > .sgrid-52 {
  width: 52%;
}
.grid-x > .sgrid-53 {
  width: 53%;
}
.grid-x > .sgrid-54 {
  width: 54%;
}
.grid-x > .sgrid-55 {
  width: 55%;
}
.grid-x > .sgrid-56 {
  width: 56%;
}
.grid-x > .sgrid-57 {
  width: 57%;
}
.grid-x > .sgrid-58 {
  width: 58%;
}
.grid-x > .sgrid-59 {
  width: 59%;
}
.grid-x > .sgrid-60 {
  width: 60%;
}
.grid-x > .sgrid-61 {
  width: 61%;
}
.grid-x > .sgrid-62 {
  width: 62%;
}
.grid-x > .sgrid-63 {
  width: 63%;
}
.grid-x > .sgrid-64 {
  width: 64%;
}
.grid-x > .sgrid-65 {
  width: 65%;
}
.grid-x > .sgrid-66 {
  width: 66%;
}
.grid-x > .sgrid-67 {
  width: 67%;
}
.grid-x > .sgrid-68 {
  width: 68%;
}
.grid-x > .sgrid-69 {
  width: 69%;
}
.grid-x > .sgrid-70 {
  width: 70%;
}
.grid-x > .sgrid-71 {
  width: 71%;
}
.grid-x > .sgrid-72 {
  width: 72%;
}
.grid-x > .sgrid-73 {
  width: 73%;
}
.grid-x > .sgrid-74 {
  width: 74%;
}
.grid-x > .sgrid-75 {
  width: 75%;
}
.grid-x > .sgrid-76 {
  width: 76%;
}
.grid-x > .sgrid-77 {
  width: 77%;
}
.grid-x > .sgrid-78 {
  width: 78%;
}
.grid-x > .sgrid-79 {
  width: 79%;
}
.grid-x > .sgrid-80 {
  width: 80%;
}
.grid-x > .sgrid-81 {
  width: 81%;
}
.grid-x > .sgrid-82 {
  width: 82%;
}
.grid-x > .sgrid-83 {
  width: 83%;
}
.grid-x > .sgrid-84 {
  width: 84%;
}
.grid-x > .sgrid-85 {
  width: 85%;
}
.grid-x > .sgrid-86 {
  width: 86%;
}
.grid-x > .sgrid-87 {
  width: 87%;
}
.grid-x > .sgrid-88 {
  width: 88%;
}
.grid-x > .sgrid-89 {
  width: 89%;
}
.grid-x > .sgrid-90 {
  width: 90%;
}
.grid-x > .sgrid-91 {
  width: 91%;
}
.grid-x > .sgrid-92 {
  width: 92%;
}
.grid-x > .sgrid-93 {
  width: 93%;
}
.grid-x > .sgrid-94 {
  width: 94%;
}
.grid-x > .sgrid-95 {
  width: 95%;
}
.grid-x > .sgrid-96 {
  width: 96%;
}
.grid-x > .sgrid-97 {
  width: 97%;
}
.grid-x > .sgrid-98 {
  width: 98%;
}
.grid-x > .sgrid-99 {
  width: 99%;
}
.grid-x > .sgrid-full {
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .grid-x > .mgrid-05 {
    width: 0%;
  }
  .grid-x > .mgrid-05 {
    width: 0.5%;
  }
  .grid-x > .mgrid-1 {
    width: 1%;
  }
  .grid-x > .mgrid-2 {
    width: 2%;
  }
  .grid-x > .mgrid-3 {
    width: 3%;
  }
  .grid-x > .mgrid-4 {
    width: 4%;
  }
  .grid-x > .mgrid-5 {
    width: 5%;
  }
  .grid-x > .mgrid-6 {
    width: 6%;
  }
  .grid-x > .mgrid-7 {
    width: 7%;
  }
  .grid-x > .mgrid-8 {
    width: 8%;
  }
  .grid-x > .mgrid-9 {
    width: 9%;
  }
  .grid-x > .mgrid-10 {
    width: 10%;
  }
  .grid-x > .mgrid-11 {
    width: 11%;
  }
  .grid-x > .mgrid-12 {
    width: 12%;
  }
  .grid-x > .mgrid-13 {
    width: 13%;
  }
  .grid-x > .mgrid-14 {
    width: 14%;
  }
  .grid-x > .mgrid-15 {
    width: 15%;
  }
  .grid-x > .mgrid-16 {
    width: 16%;
  }
  .grid-x > .mgrid-17 {
    width: 17%;
  }
  .grid-x > .mgrid-18 {
    width: 18%;
  }
  .grid-x > .mgrid-19 {
    width: 19%;
  }
  .grid-x > .mgrid-20 {
    width: 20%;
  }
  .grid-x > .mgrid-21 {
    width: 21%;
  }
  .grid-x > .mgrid-22 {
    width: 22%;
  }
  .grid-x > .mgrid-23 {
    width: 23%;
  }
  .grid-x > .mgrid-24 {
    width: 24%;
  }
  .grid-x > .mgrid-25 {
    width: 25%;
  }
  .grid-x > .mgrid-26 {
    width: 26%;
  }
  .grid-x > .mgrid-27 {
    width: 27%;
  }
  .grid-x > .mgrid-28 {
    width: 28%;
  }
  .grid-x > .mgrid-29 {
    width: 29%;
  }
  .grid-x > .mgrid-30 {
    width: 30%;
  }
  .grid-x > .mgrid-31 {
    width: 31%;
  }
  .grid-x > .mgrid-32 {
    width: 32%;
  }
  .grid-x > .mgrid-33 {
    width: 33%;
  }
  .grid-x > .mgrid-34 {
    width: 34%;
  }
  .grid-x > .mgrid-35 {
    width: 35%;
  }
  .grid-x > .mgrid-36 {
    width: 36%;
  }
  .grid-x > .mgrid-37 {
    width: 37%;
  }
  .grid-x > .mgrid-38 {
    width: 38%;
  }
  .grid-x > .mgrid-39 {
    width: 39%;
  }
  .grid-x > .mgrid-40 {
    width: 40%;
  }
  .grid-x > .mgrid-41 {
    width: 41%;
  }
  .grid-x > .mgrid-42 {
    width: 42%;
  }
  .grid-x > .mgrid-43 {
    width: 43%;
  }
  .grid-x > .mgrid-44 {
    width: 44%;
  }
  .grid-x > .mgrid-45 {
    width: 45%;
  }
  .grid-x > .mgrid-46 {
    width: 46%;
  }
  .grid-x > .mgrid-47 {
    width: 47%;
  }
  .grid-x > .mgrid-48 {
    width: 48%;
  }
  .grid-x > .mgrid-49 {
    width: 49%;
  }
  .grid-x > .mgrid-50 {
    width: 50%;
  }
  .grid-x > .mgrid-51 {
    width: 51%;
  }
  .grid-x > .mgrid-52 {
    width: 52%;
  }
  .grid-x > .mgrid-53 {
    width: 53%;
  }
  .grid-x > .mgrid-54 {
    width: 54%;
  }
  .grid-x > .mgrid-55 {
    width: 55%;
  }
  .grid-x > .mgrid-56 {
    width: 56%;
  }
  .grid-x > .mgrid-57 {
    width: 57%;
  }
  .grid-x > .mgrid-58 {
    width: 58%;
  }
  .grid-x > .mgrid-59 {
    width: 59%;
  }
  .grid-x > .mgrid-60 {
    width: 60%;
  }
  .grid-x > .mgrid-61 {
    width: 61%;
  }
  .grid-x > .mgrid-62 {
    width: 62%;
  }
  .grid-x > .mgrid-63 {
    width: 63%;
  }
  .grid-x > .mgrid-64 {
    width: 64%;
  }
  .grid-x > .mgrid-65 {
    width: 65%;
  }
  .grid-x > .mgrid-66 {
    width: 66%;
  }
  .grid-x > .mgrid-67 {
    width: 67%;
  }
  .grid-x > .mgrid-68 {
    width: 68%;
  }
  .grid-x > .mgrid-69 {
    width: 69%;
  }
  .grid-x > .mgrid-70 {
    width: 70%;
  }
  .grid-x > .mgrid-71 {
    width: 71%;
  }
  .grid-x > .mgrid-72 {
    width: 72%;
  }
  .grid-x > .mgrid-73 {
    width: 73%;
  }
  .grid-x > .mgrid-74 {
    width: 74%;
  }
  .grid-x > .mgrid-75 {
    width: 75%;
  }
  .grid-x > .mgrid-76 {
    width: 76%;
  }
  .grid-x > .mgrid-77 {
    width: 77%;
  }
  .grid-x > .mgrid-78 {
    width: 78%;
  }
  .grid-x > .mgrid-79 {
    width: 79%;
  }
  .grid-x > .mgrid-80 {
    width: 80%;
  }
  .grid-x > .mgrid-81 {
    width: 81%;
  }
  .grid-x > .mgrid-82 {
    width: 82%;
  }
  .grid-x > .mgrid-83 {
    width: 83%;
  }
  .grid-x > .mgrid-84 {
    width: 84%;
  }
  .grid-x > .mgrid-85 {
    width: 85%;
  }
  .grid-x > .mgrid-86 {
    width: 86%;
  }
  .grid-x > .mgrid-87 {
    width: 87%;
  }
  .grid-x > .mgrid-88 {
    width: 88%;
  }
  .grid-x > .mgrid-89 {
    width: 89%;
  }
  .grid-x > .mgrid-90 {
    width: 90%;
  }
  .grid-x > .mgrid-91 {
    width: 91%;
  }
  .grid-x > .mgrid-92 {
    width: 92%;
  }
  .grid-x > .mgrid-93 {
    width: 93%;
  }
  .grid-x > .mgrid-94 {
    width: 94%;
  }
  .grid-x > .mgrid-95 {
    width: 95%;
  }
  .grid-x > .mgrid-96 {
    width: 96%;
  }
  .grid-x > .mgrid-97 {
    width: 97%;
  }
  .grid-x > .mgrid-98 {
    width: 98%;
  }
  .grid-x > .mgrid-99 {
    width: 99%;
  }
  .grid-x > .mgrid-full {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-x > .lgrid-0 {
    width: 0%;
  }
  .grid-x > .lgrid-05 {
    width: 0.5%;
  }
  .grid-x > .lgrid-1 {
    width: 1%;
  }
  .grid-x > .lgrid-2 {
    width: 2%;
  }
  .grid-x > .lgrid-3 {
    width: 3%;
  }
  .grid-x > .lgrid-4 {
    width: 4%;
  }
  .grid-x > .lgrid-5 {
    width: 5%;
  }
  .grid-x > .lgrid-6 {
    width: 6%;
  }
  .grid-x > .lgrid-7 {
    width: 7%;
  }
  .grid-x > .lgrid-8 {
    width: 8%;
  }
  .grid-x > .lgrid-9 {
    width: 9%;
  }
  .grid-x > .lgrid-10 {
    width: 10%;
  }
  .grid-x > .lgrid-11 {
    width: 11%;
  }
  .grid-x > .lgrid-12 {
    width: 12%;
  }
  .grid-x > .lgrid-13 {
    width: 13%;
  }
  .grid-x > .lgrid-14 {
    width: 14%;
  }
  .grid-x > .lgrid-15 {
    width: 15%;
  }
  .grid-x > .lgrid-16 {
    width: 16%;
  }
  .grid-x > .lgrid-17 {
    width: 17%;
  }
  .grid-x > .lgrid-18 {
    width: 18%;
  }
  .grid-x > .lgrid-19 {
    width: 19%;
  }
  .grid-x > .lgrid-20 {
    width: 20%;
  }
  .grid-x > .lgrid-21 {
    width: 21%;
  }
  .grid-x > .lgrid-22 {
    width: 22%;
  }
  .grid-x > .lgrid-23 {
    width: 23%;
  }
  .grid-x > .lgrid-24 {
    width: 24%;
  }
  .grid-x > .lgrid-25 {
    width: 25%;
  }
  .grid-x > .lgrid-26 {
    width: 26%;
  }
  .grid-x > .lgrid-27 {
    width: 27%;
  }
  .grid-x > .lgrid-28 {
    width: 28%;
  }
  .grid-x > .lgrid-29 {
    width: 29%;
  }
  .grid-x > .lgrid-30 {
    width: 30%;
  }
  .grid-x > .lgrid-31 {
    width: 31%;
  }
  .grid-x > .lgrid-32 {
    width: 32%;
  }
  .grid-x > .lgrid-33 {
    width: 33%;
  }
  .grid-x > .lgrid-34 {
    width: 34%;
  }
  .grid-x > .lgrid-35 {
    width: 35%;
  }
  .grid-x > .lgrid-36 {
    width: 36%;
  }
  .grid-x > .lgrid-37 {
    width: 37%;
  }
  .grid-x > .lgrid-38 {
    width: 38%;
  }
  .grid-x > .lgrid-39 {
    width: 39%;
  }
  .grid-x > .lgrid-40 {
    width: 40%;
  }
  .grid-x > .lgrid-41 {
    width: 41%;
  }
  .grid-x > .lgrid-42 {
    width: 42%;
  }
  .grid-x > .lgrid-43 {
    width: 43%;
  }
  .grid-x > .lgrid-44 {
    width: 44%;
  }
  .grid-x > .lgrid-45 {
    width: 45%;
  }
  .grid-x > .lgrid-46 {
    width: 46%;
  }
  .grid-x > .lgrid-47 {
    width: 47%;
  }
  .grid-x > .lgrid-48 {
    width: 48%;
  }
  .grid-x > .lgrid-49 {
    width: 49%;
  }
  .grid-x > .lgrid-50 {
    width: 50%;
  }
  .grid-x > .lgrid-51 {
    width: 51%;
  }
  .grid-x > .lgrid-52 {
    width: 52%;
  }
  .grid-x > .lgrid-53 {
    width: 53%;
  }
  .grid-x > .lgrid-54 {
    width: 54%;
  }
  .grid-x > .lgrid-55 {
    width: 55%;
  }
  .grid-x > .lgrid-56 {
    width: 56%;
  }
  .grid-x > .lgrid-57 {
    width: 57%;
  }
  .grid-x > .lgrid-58 {
    width: 58%;
  }
  .grid-x > .lgrid-59 {
    width: 59%;
  }
  .grid-x > .lgrid-60 {
    width: 60%;
  }
  .grid-x > .lgrid-61 {
    width: 61%;
  }
  .grid-x > .lgrid-62 {
    width: 62%;
  }
  .grid-x > .lgrid-63 {
    width: 63%;
  }
  .grid-x > .lgrid-64 {
    width: 64%;
  }
  .grid-x > .lgrid-65 {
    width: 65%;
  }
  .grid-x > .lgrid-66 {
    width: 66%;
  }
  .grid-x > .lgrid-67 {
    width: 67%;
  }
  .grid-x > .lgrid-68 {
    width: 68%;
  }
  .grid-x > .lgrid-69 {
    width: 69%;
  }
  .grid-x > .lgrid-70 {
    width: 70%;
  }
  .grid-x > .lgrid-71 {
    width: 71%;
  }
  .grid-x > .lgrid-72 {
    width: 72%;
  }
  .grid-x > .lgrid-73 {
    width: 73%;
  }
  .grid-x > .lgrid-74 {
    width: 74%;
  }
  .grid-x > .lgrid-75 {
    width: 75%;
  }
  .grid-x > .lgrid-76 {
    width: 76%;
  }
  .grid-x > .lgrid-77 {
    width: 77%;
  }
  .grid-x > .lgrid-78 {
    width: 78%;
  }
  .grid-x > .lgrid-79 {
    width: 79%;
  }
  .grid-x > .lgrid-80 {
    width: 80%;
  }
  .grid-x > .lgrid-81 {
    width: 81%;
  }
  .grid-x > .lgrid-82 {
    width: 82%;
  }
  .grid-x > .lgrid-83 {
    width: 83%;
  }
  .grid-x > .lgrid-84 {
    width: 84%;
  }
  .grid-x > .lgrid-85 {
    width: 85%;
  }
  .grid-x > .lgrid-86 {
    width: 86%;
  }
  .grid-x > .lgrid-87 {
    width: 87%;
  }
  .grid-x > .lgrid-88 {
    width: 88%;
  }
  .grid-x > .lgrid-89 {
    width: 89%;
  }
  .grid-x > .lgrid-90 {
    width: 90%;
  }
  .grid-x > .lgrid-91 {
    width: 91%;
  }
  .grid-x > .lgrid-92 {
    width: 92%;
  }
  .grid-x > .lgrid-93 {
    width: 93%;
  }
  .grid-x > .lgrid-94 {
    width: 94%;
  }
  .grid-x > .lgrid-95 {
    width: 95%;
  }
  .grid-x > .lgrid-96 {
    width: 96%;
  }
  .grid-x > .lgrid-97 {
    width: 97%;
  }
  .grid-x > .lgrid-98 {
    width: 98%;
  }
  .grid-x > .lgrid-99 {
    width: 99%;
  }
  .grid-x > .lgrid-full {
    width: 100%;
  }
}

.s-mb-1 {
  margin-bottom: 1px;
}
.s-mb-2 {
  margin-bottom: 2px;
}
.s-mb-3 {
  margin-bottom: 3px;
}
.s-mb-4 {
  margin-bottom: 4px;
}
.s-mb-5 {
  margin-bottom: 5px;
}
.s-mb-6 {
  margin-bottom: 6px;
}
.s-mb-7 {
  margin-bottom: 7px;
}
.s-mb-8 {
  margin-bottom: 8px;
}
.s-mb-9 {
  margin-bottom: 9px;
}
.s-mb-10 {
  margin-bottom: 10px;
}
.s-mb-11 {
  margin-bottom: 11px;
}
.s-mb-12 {
  margin-bottom: 12px;
}
.s-mb-13 {
  margin-bottom: 13px;
}
.s-mb-14 {
  margin-bottom: 14px;
}
.s-mb-15 {
  margin-bottom: 15px;
}
.s-mb-16 {
  margin-bottom: 16px;
}
.s-mb-17 {
  margin-bottom: 17px;
}
.s-mb-18 {
  margin-bottom: 18px;
}
.s-mb-19 {
  margin-bottom: 19px;
}
.s-mb-20 {
  margin-bottom: 20px;
}
.s-mb-25 {
  margin-bottom: 20px;
}
.s-mb-30 {
  margin-bottom: 30px;
}
.s-mb-35 {
  margin-bottom: 30px;
}
.s-mb-40 {
  margin-bottom: 40px;
}
.s-mb-45 {
  margin-bottom: 40px;
}
.s-mb-50 {
  margin-bottom: 50px;
}
.s-mb-55 {
  margin-bottom: 50px;
}
.s-mb-60 {
  margin-bottom: 60px;
}
.s-mb-65 {
  margin-bottom: 60px;
}
.s-mb-70 {
  margin-bottom: 70px;
}
.s-mb-75 {
  margin-bottom: 70px;
}
.s-mb-80 {
  margin-bottom: 80px;
}
.s-mb-85 {
  margin-bottom: 80px;
}
.s-mb-90 {
  margin-bottom: 90px;
}
.s-mb-95 {
  margin-bottom: 90px;
}
.s-mb-100 {
  margin-bottom: 100px;
}
.s-mb-105 {
  margin-bottom: 105px;
}
.s-mb-110 {
  margin-bottom: 110px;
}
.s-mb-115 {
  margin-bottom: 115px;
}
.s-mb-120 {
  margin-bottom: 120px;
}
.s-mb-125 {
  margin-bottom: 125px;
}
.s-mb-130 {
  margin-bottom: 130px;
}
.s-mb-135 {
  margin-bottom: 135px;
}
.s-mb-140 {
  margin-bottom: 140px;
}
.s-mb-145 {
  margin-bottom: 145px;
}
.s-mb-150 {
  margin-bottom: 150px;
}
.s-mb-155 {
  margin-bottom: 155px;
}
.s-mb-160 {
  margin-bottom: 160px;
}
.s-mb-165 {
  margin-bottom: 165px;
}
.s-mb-170 {
  margin-bottom: 170px;
}
.s-mb-175 {
  margin-bottom: 175px;
}
.s-mb-180 {
  margin-bottom: 180px;
}
.s-mb-185 {
  margin-bottom: 185px;
}
.s-mb-190 {
  margin-bottom: 190px;
}
.s-mb-195 {
  margin-bottom: 195px;
}
.s-mb-200 {
  margin-bottom: 200px;
}
.s-mb-300 {
  margin-bottom: 300px;
}
.s-mb-400 {
  margin-bottom: 400px;
}
.s-mb-500 {
  margin-bottom: 500px;
}
.s-mb-600 {
  margin-bottom: 600px;
}
.s-mb-700 {
  margin-bottom: 700px;
}
.s-mb-800 {
  margin-bottom: 800px;
}
.s-mb-900 {
  margin-bottom: 900px;
}
.s-mb-1000 {
  margin-bottom: 1000px;
}

@media print, screen and (min-width: 40em) {
  .m-mb-1 {
    margin-bottom: 1px;
  }
  .m-mb-2 {
    margin-bottom: 2px;
  }
  .m-mb-3 {
    margin-bottom: 3px;
  }
  .m-mb-4 {
    margin-bottom: 4px;
  }
  .m-mb-5 {
    margin-bottom: 5px;
  }
  .m-mb-6 {
    margin-bottom: 6px;
  }
  .m-mb-7 {
    margin-bottom: 7px;
  }
  .m-mb-8 {
    margin-bottom: 8px;
  }
  .m-mb-9 {
    margin-bottom: 9px;
  }
  .m-mb-10 {
    margin-bottom: 10px;
  }
  .m-mb-11 {
    margin-bottom: 11px;
  }
  .m-mb-12 {
    margin-bottom: 12px;
  }
  .m-mb-13 {
    margin-bottom: 13px;
  }
  .m-mb-14 {
    margin-bottom: 14px;
  }
  .m-mb-15 {
    margin-bottom: 15px;
  }
  .m-mb-16 {
    margin-bottom: 16px;
  }
  .m-mb-17 {
    margin-bottom: 17px;
  }
  .m-mb-18 {
    margin-bottom: 18px;
  }
  .m-mb-19 {
    margin-bottom: 19px;
  }
  .m-mb-20 {
    margin-bottom: 20px;
  }
  .m-mb-25 {
    margin-bottom: 20px;
  }
  .m-mb-30 {
    margin-bottom: 30px;
  }
  .m-mb-35 {
    margin-bottom: 30px;
  }
  .m-mb-40 {
    margin-bottom: 40px;
  }
  .m-mb-45 {
    margin-bottom: 40px;
  }
  .m-mb-50 {
    margin-bottom: 50px;
  }
  .m-mb-55 {
    margin-bottom: 50px;
  }
  .m-mb-60 {
    margin-bottom: 60px;
  }
  .m-mb-65 {
    margin-bottom: 60px;
  }
  .m-mb-70 {
    margin-bottom: 70px;
  }
  .m-mb-75 {
    margin-bottom: 70px;
  }
  .m-mb-80 {
    margin-bottom: 80px;
  }
  .m-mb-85 {
    margin-bottom: 80px;
  }
  .m-mb-90 {
    margin-bottom: 90px;
  }
  .m-mb-95 {
    margin-bottom: 90px;
  }
  .m-mb-100 {
    margin-bottom: 100px;
  }
  .m-mb-105 {
    margin-bottom: 105px;
  }
  .m-mb-110 {
    margin-bottom: 110px;
  }
  .m-mb-115 {
    margin-bottom: 115px;
  }
  .m-mb-120 {
    margin-bottom: 120px;
  }
  .m-mb-125 {
    margin-bottom: 125px;
  }
  .m-mb-130 {
    margin-bottom: 130px;
  }
  .m-mb-135 {
    margin-bottom: 135px;
  }
  .m-mb-140 {
    margin-bottom: 140px;
  }
  .m-mb-145 {
    margin-bottom: 145px;
  }
  .m-mb-150 {
    margin-bottom: 150px;
  }
  .m-mb-155 {
    margin-bottom: 155px;
  }
  .m-mb-160 {
    margin-bottom: 160px;
  }
  .m-mb-165 {
    margin-bottom: 165px;
  }
  .m-mb-170 {
    margin-bottom: 170px;
  }
  .m-mb-175 {
    margin-bottom: 175px;
  }
  .m-mb-180 {
    margin-bottom: 180px;
  }
  .m-mb-185 {
    margin-bottom: 185px;
  }
  .m-mb-190 {
    margin-bottom: 190px;
  }
  .m-mb-195 {
    margin-bottom: 195px;
  }
  .m-mb-200 {
    margin-bottom: 200px;
  }
  .m-mb-300 {
    margin-bottom: 300px;
  }
  .m-mb-400 {
    margin-bottom: 400px;
  }
  .m-mb-500 {
    margin-bottom: 500px;
  }
  .m-mb-600 {
    margin-bottom: 600px;
  }
  .m-mb-700 {
    margin-bottom: 700px;
  }
  .m-mb-800 {
    margin-bottom: 800px;
  }
  .m-mb-900 {
    margin-bottom: 900px;
  }
  .m-mb-1000 {
    margin-bottom: 1000px;
  }
}

@media print, screen and (min-width: 64em) {
  .l-mb-1 {
    margin-bottom: 1px;
  }
  .l-mb-2 {
    margin-bottom: 2px;
  }
  .l-mb-3 {
    margin-bottom: 3px;
  }
  .l-mb-4 {
    margin-bottom: 4px;
  }
  .l-mb-5 {
    margin-bottom: 5px;
  }
  .l-mb-6 {
    margin-bottom: 6px;
  }
  .l-mb-7 {
    margin-bottom: 7px;
  }
  .l-mb-8 {
    margin-bottom: 8px;
  }
  .l-mb-9 {
    margin-bottom: 9px;
  }
  .l-mb-10 {
    margin-bottom: 10px;
  }
  .l-mb-11 {
    margin-bottom: 11px;
  }
  .l-mb-12 {
    margin-bottom: 12px;
  }
  .l-mb-13 {
    margin-bottom: 13px;
  }
  .l-mb-14 {
    margin-bottom: 14px;
  }
  .l-mb-15 {
    margin-bottom: 15px;
  }
  .l-mb-16 {
    margin-bottom: 16px;
  }
  .l-mb-17 {
    margin-bottom: 17px;
  }
  .l-mb-18 {
    margin-bottom: 18px;
  }
  .l-mb-19 {
    margin-bottom: 19px;
  }
  .l-mb-20 {
    margin-bottom: 20px;
  }
  .l-mb-25 {
    margin-bottom: 20px;
  }
  .l-mb-30 {
    margin-bottom: 30px;
  }
  .l-mb-35 {
    margin-bottom: 30px;
  }
  .l-mb-40 {
    margin-bottom: 40px;
  }
  .l-mb-45 {
    margin-bottom: 40px;
  }
  .l-mb-50 {
    margin-bottom: 50px;
  }
  .l-mb-55 {
    margin-bottom: 50px;
  }
  .l-mb-60 {
    margin-bottom: 60px;
  }
  .l-mb-65 {
    margin-bottom: 60px;
  }
  .l-mb-70 {
    margin-bottom: 70px;
  }
  .l-mb-75 {
    margin-bottom: 70px;
  }
  .l-mb-80 {
    margin-bottom: 80px;
  }
  .l-mb-85 {
    margin-bottom: 80px;
  }
  .l-mb-90 {
    margin-bottom: 90px;
  }
  .l-mb-95 {
    margin-bottom: 90px;
  }
  .l-mb-100 {
    margin-bottom: 100px;
  }
  .l-mb-105 {
    margin-bottom: 105px;
  }
  .l-mb-110 {
    margin-bottom: 110px;
  }
  .l-mb-115 {
    margin-bottom: 115px;
  }
  .l-mb-120 {
    margin-bottom: 120px;
  }
  .l-mb-125 {
    margin-bottom: 125px;
  }
  .l-mb-130 {
    margin-bottom: 130px;
  }
  .l-mb-135 {
    margin-bottom: 135px;
  }
  .l-mb-140 {
    margin-bottom: 140px;
  }
  .l-mb-145 {
    margin-bottom: 145px;
  }
  .l-mb-150 {
    margin-bottom: 150px;
  }
  .l-mb-155 {
    margin-bottom: 155px;
  }
  .l-mb-160 {
    margin-bottom: 160px;
  }
  .l-mb-165 {
    margin-bottom: 165px;
  }
  .l-mb-170 {
    margin-bottom: 170px;
  }
  .l-mb-175 {
    margin-bottom: 175px;
  }
  .l-mb-180 {
    margin-bottom: 180px;
  }
  .l-mb-185 {
    margin-bottom: 185px;
  }
  .l-mb-190 {
    margin-bottom: 190px;
  }
  .l-mb-195 {
    margin-bottom: 195px;
  }
  .l-mb-200 {
    margin-bottom: 200px;
  }
  .l-mb-300 {
    margin-bottom: 300px;
  }
  .l-mb-400 {
    margin-bottom: 400px;
  }
  .l-mb-500 {
    margin-bottom: 500px;
  }
  .l-mb-600 {
    margin-bottom: 600px;
  }
  .l-mb-700 {
    margin-bottom: 700px;
  }
  .l-mb-800 {
    margin-bottom: 800px;
  }
  .l-mb-900 {
    margin-bottom: 900px;
  }
  .l-mb-1000 {
    margin-bottom: 1000px;
  }
}

.s-mt-1 {
  margin-top: 1px;
}
.s-mt-2 {
  margin-top: 2px;
}
.s-mt-3 {
  margin-top: 3px;
}
.s-mt-4 {
  margin-top: 4px;
}
.s-mt-5 {
  margin-top: 5px;
}
.s-mt-6 {
  margin-top: 6px;
}
.s-mt-7 {
  margin-top: 7px;
}
.s-mt-8 {
  margin-top: 8px;
}
.s-mt-9 {
  margin-top: 9px;
}
.s-mt-10 {
  margin-top: 10px;
}
.s-mt-11 {
  margin-top: 11px;
}
.s-mt-12 {
  margin-top: 12px;
}
.s-mt-13 {
  margin-top: 13px;
}
.s-mt-14 {
  margin-top: 14px;
}
.s-mt-15 {
  margin-top: 15px;
}
.s-mt-16 {
  margin-top: 16px;
}
.s-mt-17 {
  margin-top: 17px;
}
.s-mt-18 {
  margin-top: 18px;
}
.s-mt-19 {
  margin-top: 19px;
}
.s-mt-20 {
  margin-top: 20px;
}
.s-mt-25 {
  margin-top: 20px;
}
.s-mt-30 {
  margin-top: 30px;
}
.s-mt-35 {
  margin-top: 30px;
}
.s-mt-40 {
  margin-top: 40px;
}
.s-mt-45 {
  margin-top: 40px;
}
.s-mt-50 {
  margin-top: 50px;
}
.s-mt-55 {
  margin-top: 50px;
}
.s-mt-60 {
  margin-top: 60px;
}
.s-mt-65 {
  margin-top: 60px;
}
.s-mt-70 {
  margin-top: 70px;
}
.s-mt-75 {
  margin-top: 70px;
}
.s-mt-80 {
  margin-top: 80px;
}
.s-mt-85 {
  margin-top: 80px;
}
.s-mt-90 {
  margin-top: 90px;
}
.s-mt-95 {
  margin-top: 90px;
}
.s-mt-100 {
  margin-top: 100px;
}
.s-mt-105 {
  margin-top: 105px;
}
.s-mt-110 {
  margin-top: 110px;
}
.s-mt-115 {
  margin-top: 115px;
}
.s-mt-120 {
  margin-top: 120px;
}
.s-mt-125 {
  margin-top: 125px;
}
.s-mt-130 {
  margin-top: 130px;
}
.s-mt-135 {
  margin-top: 135px;
}
.s-mt-140 {
  margin-top: 140px;
}
.s-mt-145 {
  margin-top: 145px;
}
.s-mt-150 {
  margin-top: 150px;
}
.s-mt-155 {
  margin-top: 155px;
}
.s-mt-160 {
  margin-top: 160px;
}
.s-mt-165 {
  margin-top: 165px;
}
.s-mt-170 {
  margin-top: 170px;
}
.s-mt-175 {
  margin-top: 175px;
}
.s-mt-180 {
  margin-top: 180px;
}
.s-mt-185 {
  margin-top: 185px;
}
.s-mt-190 {
  margin-top: 190px;
}
.s-mt-195 {
  margin-top: 195px;
}
.s-mt-200 {
  margin-top: 200px;
}
.s-mt-300 {
  margin-top: 300px;
}
.s-mt-400 {
  margin-top: 400px;
}
.s-mt-500 {
  margin-top: 500px;
}
.s-mt-600 {
  margin-top: 600px;
}
.s-mt-700 {
  margin-top: 700px;
}
.s-mt-800 {
  margin-top: 800px;
}
.s-mt-900 {
  margin-top: 900px;
}
.s-mt-1000 {
  margin-top: 1000px;
}

@media print, screen and (min-width: 40em) {
  .m-mt-1 {
    margin-top: 1px;
  }
  .m-mt-2 {
    margin-top: 2px;
  }
  .m-mt-3 {
    margin-top: 3px;
  }
  .m-mt-4 {
    margin-top: 4px;
  }
  .m-mt-5 {
    margin-top: 5px;
  }
  .m-mt-6 {
    margin-top: 6px;
  }
  .m-mt-7 {
    margin-top: 7px;
  }
  .m-mt-8 {
    margin-top: 8px;
  }
  .m-mt-9 {
    margin-top: 9px;
  }
  .m-mt-10 {
    margin-top: 10px;
  }
  .m-mt-11 {
    margin-top: 11px;
  }
  .m-mt-12 {
    margin-top: 12px;
  }
  .m-mt-13 {
    margin-top: 13px;
  }
  .m-mt-14 {
    margin-top: 14px;
  }
  .m-mt-15 {
    margin-top: 15px;
  }
  .m-mt-16 {
    margin-top: 16px;
  }
  .m-mt-17 {
    margin-top: 17px;
  }
  .m-mt-18 {
    margin-top: 18px;
  }
  .m-mt-19 {
    margin-top: 19px;
  }
  .m-mt-20 {
    margin-top: 20px;
  }
  .m-mt-25 {
    margin-top: 20px;
  }
  .m-mt-30 {
    margin-top: 30px;
  }
  .m-mt-35 {
    margin-top: 30px;
  }
  .m-mt-40 {
    margin-top: 40px;
  }
  .m-mt-45 {
    margin-top: 40px;
  }
  .m-mt-50 {
    margin-top: 50px;
  }
  .m-mt-55 {
    margin-top: 50px;
  }
  .m-mt-60 {
    margin-top: 60px;
  }
  .m-mt-65 {
    margin-top: 60px;
  }
  .m-mt-70 {
    margin-top: 70px;
  }
  .m-mt-75 {
    margin-top: 70px;
  }
  .m-mt-80 {
    margin-top: 80px;
  }
  .m-mt-85 {
    margin-top: 80px;
  }
  .m-mt-90 {
    margin-top: 90px;
  }
  .m-mt-95 {
    margin-top: 90px;
  }
  .m-mt-100 {
    margin-top: 100px;
  }
  .m-mt-105 {
    margin-top: 105px;
  }
  .m-mt-110 {
    margin-top: 110px;
  }
  .m-mt-115 {
    margin-top: 115px;
  }
  .m-mt-120 {
    margin-top: 120px;
  }
  .m-mt-125 {
    margin-top: 125px;
  }
  .m-mt-130 {
    margin-top: 130px;
  }
  .m-mt-135 {
    margin-top: 135px;
  }
  .m-mt-140 {
    margin-top: 140px;
  }
  .m-mt-145 {
    margin-top: 145px;
  }
  .m-mt-150 {
    margin-top: 150px;
  }
  .m-mt-155 {
    margin-top: 155px;
  }
  .m-mt-160 {
    margin-top: 160px;
  }
  .m-mt-165 {
    margin-top: 165px;
  }
  .m-mt-170 {
    margin-top: 170px;
  }
  .m-mt-175 {
    margin-top: 175px;
  }
  .m-mt-180 {
    margin-top: 180px;
  }
  .m-mt-185 {
    margin-top: 185px;
  }
  .m-mt-190 {
    margin-top: 190px;
  }
  .m-mt-195 {
    margin-top: 195px;
  }
  .m-mt-200 {
    margin-top: 200px;
  }
  .m-mt-300 {
    margin-top: 300px;
  }
  .m-mt-400 {
    margin-top: 400px;
  }
  .m-mt-500 {
    margin-top: 500px;
  }
  .m-mt-600 {
    margin-top: 600px;
  }
  .m-mt-700 {
    margin-top: 700px;
  }
  .m-mt-800 {
    margin-top: 800px;
  }
  .m-mt-900 {
    margin-top: 900px;
  }
  .m-mt-1000 {
    margin-top: 1000px;
  }
}

@media print, screen and (min-width: 64em) {
  .l-mt-1 {
    margin-top: 1px;
  }
  .l-mt-2 {
    margin-top: 2px;
  }
  .l-mt-3 {
    margin-top: 3px;
  }
  .l-mt-4 {
    margin-top: 4px;
  }
  .l-mt-5 {
    margin-top: 5px;
  }
  .l-mt-6 {
    margin-top: 6px;
  }
  .l-mt-7 {
    margin-top: 7px;
  }
  .l-mt-8 {
    margin-top: 8px;
  }
  .l-mt-9 {
    margin-top: 9px;
  }
  .l-mt-10 {
    margin-top: 10px;
  }
  .l-mt-11 {
    margin-top: 11px;
  }
  .l-mt-12 {
    margin-top: 12px;
  }
  .l-mt-13 {
    margin-top: 13px;
  }
  .l-mt-14 {
    margin-top: 14px;
  }
  .l-mt-15 {
    margin-top: 15px;
  }
  .l-mt-16 {
    margin-top: 16px;
  }
  .l-mt-17 {
    margin-top: 17px;
  }
  .l-mt-18 {
    margin-top: 18px;
  }
  .l-mt-19 {
    margin-top: 19px;
  }
  .l-mt-20 {
    margin-top: 20px;
  }
  .l-mt-25 {
    margin-top: 20px;
  }
  .l-mt-30 {
    margin-top: 30px;
  }
  .l-mt-35 {
    margin-top: 30px;
  }
  .l-mt-40 {
    margin-top: 40px;
  }
  .l-mt-45 {
    margin-top: 40px;
  }
  .l-mt-50 {
    margin-top: 50px;
  }
  .l-mt-55 {
    margin-top: 50px;
  }
  .l-mt-60 {
    margin-top: 60px;
  }
  .l-mt-65 {
    margin-top: 60px;
  }
  .l-mt-70 {
    margin-top: 70px;
  }
  .l-mt-75 {
    margin-top: 70px;
  }
  .l-mt-80 {
    margin-top: 80px;
  }
  .l-mt-85 {
    margin-top: 80px;
  }
  .l-mt-90 {
    margin-top: 90px;
  }
  .l-mt-95 {
    margin-top: 90px;
  }
  .l-mt-100 {
    margin-top: 100px;
  }
  .l-mt-105 {
    margin-top: 105px;
  }
  .l-mt-110 {
    margin-top: 110px;
  }
  .l-mt-115 {
    margin-top: 115px;
  }
  .l-mt-120 {
    margin-top: 120px;
  }
  .l-mt-125 {
    margin-top: 125px;
  }
  .l-mt-130 {
    margin-top: 130px;
  }
  .l-mt-135 {
    margin-top: 135px;
  }
  .l-mt-140 {
    margin-top: 140px;
  }
  .l-mt-145 {
    margin-top: 145px;
  }
  .l-mt-150 {
    margin-top: 150px;
  }
  .l-mt-155 {
    margin-top: 155px;
  }
  .l-mt-160 {
    margin-top: 160px;
  }
  .l-mt-165 {
    margin-top: 165px;
  }
  .l-mt-170 {
    margin-top: 170px;
  }
  .l-mt-175 {
    margin-top: 175px;
  }
  .l-mt-180 {
    margin-top: 180px;
  }
  .l-mt-185 {
    margin-top: 185px;
  }
  .l-mt-190 {
    margin-top: 190px;
  }
  .l-mt-195 {
    margin-top: 195px;
  }
  .l-mt-200 {
    margin-top: 200px;
  }
  .l-mt-300 {
    margin-top: 300px;
  }
  .l-mt-400 {
    margin-top: 400px;
  }
  .l-mt-500 {
    margin-top: 500px;
  }
  .l-mt-600 {
    margin-top: 600px;
  }
  .l-mt-700 {
    margin-top: 700px;
  }
  .l-mt-800 {
    margin-top: 800px;
  }
  .l-mt-900 {
    margin-top: 900px;
  }
  .l-mt-1000 {
    margin-top: 1000px;
  }
}

.pt-1 {
  padding-top: 1px;
}
.pt-2 {
  padding-top: 2px;
}
.pt-3 {
  padding-top: 3px;
}
.pt-4 {
  padding-top: 4px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-6 {
  padding-top: 6px;
}
.pt-7 {
  padding-top: 7px;
}
.pt-8 {
  padding-top: 8px;
}
.pt-9 {
  padding-top: 9px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-11 {
  padding-top: 11px;
}
.pt-12 {
  padding-top: 12px;
}
.pt-13 {
  padding-top: 13px;
}
.pt-14 {
  padding-top: 14px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-17 {
  padding-top: 17px;
}
.pt-18 {
  padding-top: 18px;
}
.pt-19 {
  padding-top: 19px;
}
.pt-20 {
  padding-top: 20px;
}

.pb-1 {
  padding-bottom: 1px;
}
.pb-2 {
  padding-bottom: 2px;
}
.pb-3 {
  padding-bottom: 3px;
}
.pb-4 {
  padding-bottom: 4px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-6 {
  padding-bottom: 6px;
}
.pb-7 {
  padding-bottom: 7px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pb-9 {
  padding-bottom: 9px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-11 {
  padding-bottom: 11px;
}
.pb-12 {
  padding-bottom: 12px;
}
.pb-13 {
  padding-bottom: 13px;
}
.pb-14 {
  padding-bottom: 14px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-17 {
  padding-bottom: 17px;
}
.pb-18 {
  padding-bottom: 18px;
}
.pb-19 {
  padding-bottom: 19px;
}
.pb-20 {
  padding-bottom: 20px;
}

.pr-1 {
  padding-right: 1px;
}
.pr-2 {
  padding-right: 2px;
}
.pr-3 {
  padding-right: 3px;
}
.pr-4 {
  padding-right: 4px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-6 {
  padding-right: 6px;
}
.pr-7 {
  padding-right: 7px;
}
.pr-8 {
  padding-right: 8px;
}
.pr-9 {
  padding-right: 9px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-11 {
  padding-right: 11px;
}
.pr-12 {
  padding-right: 12px;
}
.pr-13 {
  padding-right: 13px;
}
.pr-14 {
  padding-right: 14px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-16 {
  padding-right: 16px;
}
.pr-17 {
  padding-right: 17px;
}
.pr-18 {
  padding-right: 18px;
}
.pr-19 {
  padding-right: 19px;
}
.pr-20 {
  padding-right: 20px;
}

.pl-1 {
  padding-left: 1px;
}
.pl-2 {
  padding-left: 2px;
}
.pl-3 {
  padding-left: 3px;
}
.pl-4 {
  padding-left: 4px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-6 {
  padding-left: 6px;
}
.pl-7 {
  padding-left: 7px;
}
.pl-8 {
  padding-left: 8px;
}
.pl-9 {
  padding-left: 9px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-11 {
  padding-left: 11px;
}
.pl-12 {
  padding-left: 12px;
}
.pl-13 {
  padding-left: 13px;
}
.pl-14 {
  padding-left: 14px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-17 {
  padding-left: 17px;
}
.pl-18 {
  padding-left: 18px;
}
.pl-19 {
  padding-left: 19px;
}
.pl-20 {
  padding-left: 20px;
}

@media print, screen and (min-width: 64em) {
  .l-pr-50p {
    padding-right: 50%;
  }
}

.text-12 {
  font-size: 12px;
}

.text-center {
  text-align: center;
}

.fontBlack {
  font-weight: 900;
}

.responsive {
  width: 100%;
  height: auto;
}

.clearBoth {
  clear: both;
}

// create margin classes for any screen width

.mt-1 {
  margin-top: 1px;
}
.mt-2 {
  margin-top: 2px;
}
.mt-3 {
  margin-top: 3px;
}
.mt-4 {
  margin-top: 4px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-6 {
  margin-top: 6px;
}
.mt-7 {
  margin-top: 7px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-9 {
  margin-top: 9px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-11 {
  margin-top: 11px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-13 {
  margin-top: 13px;
}
.mt-14 {
  margin-top: 14px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-17 {
  margin-top: 17px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-19 {
  margin-top: 19px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-1 {
  margin-bottom: 1px;
}
.mb-2 {
  margin-bottom: 2px;
}
.mb-3 {
  margin-bottom: 3px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-6 {
  margin-bottom: 6px;
}
.mb-7 {
  margin-bottom: 7px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-9 {
  margin-bottom: 9px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-11 {
  margin-bottom: 11px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-13 {
  margin-bottom: 13px;
}
.mb-14 {
  margin-bottom: 14px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-17 {
  margin-bottom: 17px;
}
.mb-18 {
  margin-bottom: 18px;
}
.mb-19 {
  margin-bottom: 19px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mr-1 {
  margin-right: 1px;
}
.mr-2 {
  margin-right: 2px;
}
.mr-3 {
  margin-right: 3px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-7 {
  margin-right: 7px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-9 {
  margin-right: 9px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-11 {
  margin-right: 11px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-13 {
  margin-right: 13px;
}
.mr-14 {
  margin-right: 14px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-17 {
  margin-right: 17px;
}
.mr-18 {
  margin-right: 18px;
}
.mr-19 {
  margin-right: 19px;
}
.mr-20 {
  margin-right: 20px;
}
.ml-1 {
  margin-left: 1px;
}
.ml-2 {
  margin-left: 2px;
}
.ml-3 {
  margin-left: 3px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-6 {
  margin-left: 6px;
}
.ml-7 {
  margin-left: 7px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-9 {
  margin-left: 9px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-11 {
  margin-left: 11px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-13 {
  margin-left: 13px;
}
.ml-14 {
  margin-left: 14px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-17 {
  margin-left: 17px;
}
.ml-18 {
  margin-left: 18px;
}
.ml-19 {
  margin-left: 19px;
}
.ml-20 {
  margin-left: 20px;
}
