@import '../../utils/_variables';
@import '../../utils/_util';

.HomeV4 {
	.bg-button {
		align-items: center;
		background: linear-gradient(180deg, #3a74e0 0%, #3a74e0 50%, #6762e8 100%);
		background-color: #3a74e0;
		border-radius: 2px;
		color: #FFF;
		display: flex;
		font-weight: 900;
		justify-content: center;
		position: relative;
		text-align: center;
		text-decoration: none;
		width: 100%;

		&.outlined {
			background: url('./assets/v4/outlined-btn.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}
	}

	.Hero {
		align-items: flex-start;
		background-image: url('./assets/v4/hero-bg.jpg');
		background-position: center;
		background-size: cover;
		display: flex;
		flex-direction: column;
		height: 100vh;
		height: 100vh;
		justify-content: space-around;
		padding: 110px 5vw 10vh;
		position: relative;
		width: 100vw;

		@media screen and (min-width: 1024px) {
			padding: 0 5vw 15vh;
		}

		.Hero__content {
			z-index: 2;

			@media screen and (min-width: 1024px) {
				margin: 0 auto;
				max-width: 1280px;
				position: relative;
				width: 100%;
			}
		}

		.Hero__title {
			font-size: 13vw;
			font-weight: 900;
			line-height: 114%;
			margin: 0 0 5.5vw 0;

			@media screen and (min-width: 500px) and (max-width: 767px) {
				font-size: 9vw;
				line-height: 112%;
				margin: 0 0 4vw 0;
			}

			@media screen and (min-width: 768px) and (max-width: 1023px) {
				font-size: 7vw;
				line-height: 111%;
				margin: 0 0 3vw 0;
			}

			@media screen and (min-width: 1024px) {
				font-size: 6vw;
				line-height: 110%;
				margin: 0 0 2vw 0;
			}

			@media (min-aspect-ratio: 16/9) {
				font-size: 10vh;
				line-height: 100%;
				margin: 0 0 2.5vh 0;
			}
		}

		.Hero__description {
			font-size: 6vw;
			letter-spacing: 0.1px;
			line-height: 9vw;
			margin: 0 0 14vw 0;
			max-width: 80vw;

			@media screen and (min-width: 500px) and (max-width: 767px) {
				font-size: 4.5vw;
				line-height: 7vw;
				margin: 0 0 10vw 0;
			}

			@media screen and (min-width: 768px) and (max-width: 1023px) {
				font-size: 3vw;
				line-height: 5vw;
				margin: 0 0 7vw 0;
			}

			@media screen and (min-width: 1024px) {
				font-size: 1.5vw;
				letter-spacing: 0.1px;
				line-height: 1.5vw;
				margin: 0 0 4vw 0;
				max-width: 80vw;
			}

			@media (min-aspect-ratio: 16/9) {
				font-size: 2.8vh;
				line-height: 4.5vh;
				margin: 0 0 7vh 0px;
			}
		}

		.Hero__cta {
			font-size: 5vw;
			height: 14vw;
			width: 50vw;

			@media screen and (min-width: 500px) and (max-width: 767px) {
				font-size: 3.6vw;
				height: 10vw;
				width: 36vw;
			}

			@media screen and (min-width: 768px) and (max-width: 1023px) {
				font-size: 2.4vw;
				height: 6.5vw;
				width: 24vw;
			}

			@media screen and (min-width: 1024px) {
				font-size: 1.2vw;
				height: 3.8vw;
				width: 12vw;
			}

			@media (min-aspect-ratio: 16/9) {
				font-size: 2.2vh;
				height: 6.8vh;
				width: 22vh;
			}
		}

		.Hero__scroll-cta {
			background-color: transparent;
			border: none;
			display: none;

			@media screen and (min-width: 1024px) {
				align-items: center;
				bottom: 0;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				height: 22vh;
				justify-content: space-between;
				outline: none;
				position: absolute;
				right: 0;
				width: 3vh;
			}

			span {
				color: #FFF;
				display: block;
				font-family: 'Roboto', sans-serif;
				font-size: 1.4vh;
				font-weight: bold;
				line-height: 1.4vh;
				opacity: 0.3;
				overflow: hidden;
				transform: rotate(-90deg) translateX(-50%);
				white-space: nowrap;
				width: 13vh;
			}

			img {
				align-self: center;
				bottom: 1vw;
				display: block;
				position: absolute;
				width: 90%;
			}
		}
	}

	.OurBenefits {
		background: #FFF;
		color: #000;

		.OurBenefits__content {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding: 18vw 5vw 18vw 5vw;
			position: relative;

			@media screen and (min-width: 1024px) {
				margin: 0 auto;
				max-width: 1280px;
				padding: 8vw 0;
				width: 100%;
			}

			.OurBenefits__title {
				color: #000;
				font-family: 'Roboto', sans-serif;
				font-size: 8vw;
				font-weight: 900;
				margin: 0 0 16vw 0;

				@media screen and (min-width: 1024px) {
					font-size: 3vw;
					margin: 0 0 5vw 0;
				}
			}

			.OurBenefits__items {
				align-items: center;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				position: relative;

				@media screen and (min-width: 1024px) {
					align-items: flex-start;
					flex-direction: row;
					justify-content: space-between;
				}

				.BenefitsItem {
					align-items: center;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					position: relative;

					@media screen and (min-width: 1024px) {
						width: 33%;
					}

					&:nth-child(1) {
						.BenefitsItem__decoration {
							.d1 {
								height: 22px;
								left: 0;
								top: -6px;
								width: 15px;
								z-index: 1;
							}

							.d2 {
								height: 30px;
								right: -6px;
								top: -6px;
								width: 30px;
							}

							.d3 {
								bottom: 0;
								height: 30px;
								right: -6px;
								width: 30px;
							}

							.d4 {
								bottom: -6px;
								height: 90px;
								left: -6px;
								width: 90px;
							}
						}
					}

					&:nth-child(2) {
						.BenefitsItem__decoration {
							.d1 {
								height: 30px;
								left: 0;
								top: -5px;
								width: 30px;
							}

							.d2 {
								height: 15px;
								right: 0;
								top: -5px;
								width: 15px;
								z-index: 1;
							}

							.d3 {
								bottom: 0;
								height: 90px;
								left: -5px;
								width: 90px;
							}

							.d4 {
								bottom: -5px;
								height: 30px;
								right: -5px;
								width: 30px;
							}
						}
					}

					&:nth-child(3) {
						.BenefitsItem__decoration {
							.d1 {
								bottom: 0;
								height: 15px;
								right: 0;
								width: 15px;
								z-index: 1;
							}

							.d2 {
								height: 90px;
								left: -10px;
								top: -5px;
								width: 90px;
							}

							.d3 {
								height: 30px;
								right: -20px;
								top: -5px;
								width: 30px;
								z-index: 1;
							}

							.d4 {
								bottom: -5px;
								height: 30px;
								right: 15px;
								width: 30px;
							}
						}
					}

					.BenefitsItem__icon-wrapper {
						align-items: center;
						background: #e5e5f3;
						display: flex;
						justify-content: center;
						margin: 0vw 0 10vw 0;
						position: relative;
						position: relative;
						width: 100px;

						&:before {
							content: '';
							display: block;
							padding-top: 100%;
						}

						@media screen and (min-width: 1024px) {
							margin: 0vw 0 2.5vw 0;
						}

						.BenefitsItem__decoration {
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;

							.d1 {
								background: #3a74e0;
								position: absolute;
							}

							.d2 {
								background: #a142ff;
								position: absolute;
							}

							.d3 {
								background: #fd8d82;
								position: absolute;
							}

							.d4 {
								background: #47dbc4;
								position: absolute;
							}
						}

						.BenefitsItem__background-layer {
							background: #e5e5f3;
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;
						}

						.BenefitsItem__icon {
							background-size: contain;
							height: 50%;
							width: 50%;
							z-index: 1;
						}
					}

					.BenefitsItem__title {
						color: #000;
						font-family: 'Roboto', sans-serif;
						font-size: 6.3vw;
						font-weight: 900;
						margin: 0 0 4.5vw 0;
						text-align: center;

						@media screen and (min-width: 1024px) {
							font-size: 1.8vw;
							margin: 0 0 1.5vw 0;
							text-align: center;
							width: 48%;
						}

						@media (min-aspect-ratio: 16/9) {
							width: 60%;
						}
					}

					.BenefitsItem__description {
						color: #83858c;
						font-family: 'Roboto', sans-serif;
						font-size: 4vw;
						margin: 0 0 15vw 0;
						padding: 0 6%;
						text-align: center;

						@media screen and (min-width: 1024px) {
							font-size: 1.2vw;
							margin: 0 0 4.5vw 0;
						}
					}
				}
			}

			.OurBenefits__cta {
				font-size: 4.4vw;
				height: 14vw;
				width: 50vw;

				@media screen and (min-width: 1024px) {
					font-size: 1.1vw;
					height: 3.5vw;
					width: 11vw;
				}
			}
		}
	}

	.SeeHowItWorks {
		background-image: url('./assets/v4/how-it-works-bg.jpg');
		background-position: center;
		background-size: cover;
		display: flex;
		flex-direction: column;
		position: relative;

		.SeeHowItWorks__content {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding: 12vw 5vw 12vw 5vw;
			position: relative;

			@media screen and (min-width: 1024px) {
				padding: 6vw 0vw 8vw 0vw;
			}

			.SeeHowItWorks__title {
				color: #FFF;
				font-family: 'Roboto', sans-serif;
				font-size: 8.5vw;
				font-weight: 900;
				margin: 0 0 10vw 0;

				@media screen and (min-width: 1024px) {
					font-size: 3.5vw;
					margin: 0 0 3.5vw 0;
				}
			}

			.SeeHowItWorks__player {
				position: relative;
				width: 95%;

				@media screen and (min-width: 1024px) {
					width: 65%;
				}

				&::before {
					content: "";
					display: block;
					padding-top: 56%;
					// padding-top: 70%;
					// border: 1px solid #FFF;
				}

				.SeeHowItWorks__player--decoration {
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;

					.d1 {
						border: 1px #47dbc4 solid;
						border-bottom: none;
						border-right: none;
						height: 11%;
						left: -2px;
						position: absolute;
						top: -2px;
						// background: #47dbc4;
						width: 10%;

						@media screen and (min-width: 1024px) {
							border: 4px #47dbc4 solid;
							border-bottom: none;
							border-right: none;
							height: 11%;
							left: -5px;
							top: -5px;
						}
					}

					.d2 {
						border: 2px dotted #fd8d82;
						border-bottom: none;
						border-left: none;
						height: 15%;
						position: absolute;
						right: -3px;
						top: -3px;
						width: 11%;

						@media screen and (min-width: 1024px) {
							border: 4px #fd8d82 dotted;
							border-bottom: none;
							border-left: none;
							right: -5px;
							top: -5px;
						}
					}

					.d3 {
						border-bottom: 3px dotted #3a74e0;
						bottom: -3px;
						height: 15%;
						left: 10px;
						position: absolute;
						width: 16%;

						@media screen and (min-width: 1024px) {
							border: 4px #3a74e0 dotted;
							border-left: none;
							border-top: none;
							bottom: -5px;
							left: 10px;
						}
					}

					.d4 {
						background: #a142ff;
					}
				}

				.SeeHowItWorks__player--video {
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}

				.SeeHowItWorks__player--preview-image {
					background-image: url('./assets/v4/how-it-works-bg.jpg');
					background-position: top center;
					background-size: cover;
					display: block;
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;

					&.hidden {
						display: none;
					}
				}

				.SeeHowItWorks__player--preview-overlay {
					background: rgba(0, 0, 0, 0.6);
					content: "";
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					transition: ease-in-out 300ms all;
					width: 100%;

					&.hidden {
						display: none;
					}
				}

				.SeeHowItWorks__player--play-btn {
					background: rgba(255, 255, 255, 0);
					cursor: pointer;
					left: 50%;
					max-width: 70px;
					opacity: 0.8;
					outline: none;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					transform-origin: center;
					transition: ease-in-out 200ms all;
					width: 16%;

					&.hidden {
						display: none;
					}

					&:hover {
						background: rgba(255, 255, 255, 0.2);
					}
				}

				&:hover {
					.SeeHowItWorks__player--preview-overlay {
						background: rgba(0, 0, 0, 0.4);
					}

					.SeeHowItWorks__player--play-btn {
						opacity: 1;
					}
				}
			}
		}
	}

	.TrackingAndPayments {
		background: #FFF;
		color: #000;

		.TrackingAndPayments__content {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding: 12vw 5vw 12vw 5vw;
			position: relative;

			@media screen and (min-width: 1024px) {
				flex-direction: row;
				padding: 2vw 0vw 5vw 0vw;
			}

			.TrackingAndPayments__media {
				position: relative;
				width: 95%;

				@media screen and (min-width: 1024px) {
					width: 65%;
				}

				&::before {
					content: "";
					display: block;
					padding-top: 84%;
				}

				.TrackingAndPayments__media--decoration {
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;

					.d1 {
						background: #3a74e0;
						left: 8.8vw;
						position: absolute;
						top: 12.3vw;
						width: 14%;

						@media screen and (min-width: 1024px) {
							left: 5.5vw;
							top: 8vw;
						}

						&::before {
							content: "";
							display: block;
							padding-top: 100%;
						}
					}

					.d2 {
						background: #fd8d82;
						position: absolute;
						right: 10vw;
						top: 20vw;
						width: 11%;

						@media screen and (min-width: 1024px) {
							right: 6.5vw;
							top: 18vw;
						}

						&::before {
							content: "";
							display: block;
							padding-top: 100%;
						}
					}

					.d3 {
						background-image: url('./assets/v4/square-dots-mesh.svg');
						background-repeat: no-repeat;
						background-size: 100% 100%;
						bottom: 7.5vw;
						position: absolute;
						right: 9vw;
						width: 11%;

						@media screen and (min-width: 1024px) {
							bottom: 4.2vw;
							right: 6.2vw;
						}

						&::before {
							content: "";
							display: block;
							padding-top: 100%;
						}

					}
				}

				.TrackingAndPayments__media--image {
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}
			}

			.TrackingAndPayments__details {
				align-items: center;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				position: relative;

				@media screen and (min-width: 1024px) {
					align-items: flex-start;
					width: 50%;
				}

				.TrackingAndPayments__title {
					align-self: flex-start;
					color: #000;
					font-family: 'Roboto', sans-serif;
					font-size: 8.5vw;
					font-weight: 900;
					line-height: 10vw;
					margin: 0 0 5vw 0;

					&:nth-child(2) {
						display: none;
					}

					@media screen and (min-width: 1024px) {
						align-self: flex-start;
						font-size: 3vw;
						line-height: 3vw;
						line-height: 3.6vw;
						margin: 0 0 2.5vw 0;

						&:nth-child(1) {
							display: none;
						}

						&:nth-child(2) {
							display: block;
						}
					}
				}

				.TrackingAndPayments__description {
					color: #83858c;
					font-family: 'Roboto', sans-serif;
					font-size: 4vw;
					margin: 0 0 10vw 0;
					padding: 0 6%;
					text-align: left;

					@media screen and (min-width: 1024px) {
						align-self: flex-start;
						font-size: 1.2vw;
						line-height: 2vw;
						margin: 0 0 3.5vw 0;
						max-width: 65%;
					}
				}

				.TrackingAndPayments__cta {
					font-size: 4.4vw;
					height: 14vw;
					width: 50vw;

					@media screen and (min-width: 1024px) {
						font-size: 1.1vw;
						height: 3.5vw;
						margin: 0 6%;
						width: 10vw;
					}
				}
			}
		}
	}

	.PlatformSupport {
		background: #49e1ca;

		.PlatformSupport__content {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			position: relative;

			@media screen and (min-width: 1024px) {
				flex-direction: row;
				justify-content: center;
			}

			.PlatformSupport__content-left {
				background: #3972dc;
				display: flex;
				flex-direction: column;
				padding: 25vw 5vw 25vw 5vw;

				@media screen and (min-width: 1024px) {
					align-items: flex-start;
					height: 100%;
					// padding: 14vw 5vw;
					padding: 20vw 5vw 10vw;
					width: 60%;
				}

				@media screen and (min-width: 1420px) {
					padding-left: calc((100vw - 1280px) / 2);
				}

				.PlatformSupport__title {
					color: #FFF;
					font-family: 'Roboto', sans-serif;
					font-size: 8.5vw;
					font-weight: 900;
					line-height: 10vw;
					margin: 0 0 10vw 0;

					@media screen and (min-width: 1024px) {
						font-size: 3.5vw;
						line-height: 4vw;
						margin: 0 0 2vw 0;
						// max-width: 60%;
						max-width: 75%;
					}
				}

				.PlatformSupport__description {
					color: #FFF;
					font-family: 'Roboto', sans-serif;
					font-size: 4vw;
					margin: 0 0 10vw 0;
					padding: 0 6%;
					text-align: left;

					@media screen and (min-width: 1024px) {
						font-size: 1.2vw;
						margin: 0 0 3vw 0;
						max-width: 60%;
					}
				}

				.PlatformSupport__platforms {
					// justify-content: space-between;
					align-items: center;
					display: flex;
					flex-direction: row;
					width: 100%;

					@media screen and (min-width: 1024px) {
						max-width: 70%;
					}

					.PlatformItem {
						align-items: center;
						border-radius: 3px;
						display: flex;
						flex-direction: row;
						justify-content: center;
						margin-left: 10px;
						overflow: hidden;
						position: relative;
						// height: 15vw;
						width: 32%;

						&:before {
							content: '';
							display: block;
							padding-top: 65%;
						}

						&.coming-soon {
							&:after {
								align-items: center;
								background: rgba(0, 0, 0, 0.7);
								content: 'Coming Soon';
								display: flex;
								font-weight: bold;
								height: 100%;
								justify-content: center;
								left: 0;
								position: absolute;
								text-transform: uppercase;
								top: 0;
								transition: 300ms ease opacity;
								width: 100%;
							}

							&:hover {
								&:after {
									opacity: 0;
								}
							}
						}

						.PlatformItem__icon {
							width: 20%;

							&.PlatformItem__icon--youtube {
								width: 50%;
							}
						}

						&:nth-child(1) {
							background: #a144f6;
						}

						&:nth-child(2) {
							background: #fc8d82;
						}

						&:nth-child(3) {
							background: #49e1ca;

							.PlatformItem__icon {
								width: 75%;
							}
						}
					}
				}
			}

			.PlatformSupport__content-right {
				align-items: flex-end;
				background: #49e1ca;
				display: -ms-flexbox;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 12vw 0vw 12vw 5vw;
				width: 100%;

				@media screen and (min-width: 1024px) {
					height: 100%;
					padding: 0;
					position: relative;
					width: 40%;
				}

				.PlatformSupport__media {
					margin-top: -25vw;
					width: 90%;

					@media screen and (min-width: 1024px) {
						left: -6vw;
						margin: inherit;
						position: absolute;
						top: -15vw;
						transform: scale(1.2);
						width: 100%;
					}
				}
			}
		}
	}

	.Brands {
		background: #3a74e0;
		display: flex;
		flex-direction: column;
		padding: 18vw 5vw 18vw 5vw;

		@media screen and (min-width: 1024px) {
			padding: 5vw 5vw;
		}

		@media screen and (min-width: 1280px) {
			padding: 7vw 5vw;
		}

		.Brands__content {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			position: relative;

			@media screen and (min-width: 1024px) {
				flex-direction: column;
				margin: 0 auto;
				max-width: 1280px;
				position: relative;
				width: 100%;
			}

			.Brands__content--title {
				align-self: center;
				color: #FFF;
				font-family: 'Roboto', sans-serif;
				font-size: 48px;
				font-weight: 900;
				line-height: 48px;
				margin-top: 0;

				@media screen and (min-width: 1024px) {
					font-size: 42px;
					line-height: 42px;
					margin-bottom: 20px;
				}
			}

			.Brands__logos-wrapper {
				align-items: center;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				margin: 0 auto;

				@media screen and (min-width: 1024px) {
					width: 84%;
				}

				.Brands__logo {
					align-items: center;
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin: 2%;
					width: 130px;

					@media screen and (min-width: 1024px) {
						align-items: flex-start;
					}

					img {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
}