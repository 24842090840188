.ADMIN_tabs{
  $colorCyan: #49e1ca;
  $colorGray: #83858c;
  $colorDarkGray: #313337;
  $colorBGray: #212227;
  $colorBDarkGray: #18191D;
  $colorBlue: #0075E4;
  $colorSalmon: #FF8E83;
  $colorLifeGreen: #49e1ca;
  $colorLGray: #3e424d;
  ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 768px){
      width: initial;
      flex-direction: row;
    }
    li{
      position: relative;
      margin-left: 20px;
      a,
      button{
        position: relative;
        display: block;
        width: 100%;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.4px;
        text-decoration: none;
        color: $colorGray;
        border: none;
        cursor: pointer;
        background: none;
        margin: 0;
        padding: 0;
        transition: 300ms ease background;
        @media screen and (min-width: 768px){
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background: initial;
          font-size: 14px;
          line-height: 16px;
          padding: 15px 20px;
          i{
            font-size: 16px;
            margin-right: 8px;
          }
          &:after{
            content: "";
            z-index: 10;
            display: block;
            width: 0%;
            position: absolute;
            bottom: -9px;
            height: 7px;
            transition: 200ms ease all;
          }
          &:hover:after,
          &.active:after{
            width: 100%;
            z-index: 10;
            transition: 200ms ease all;
          }
          &.custom-btn,
          &:hover,
          &.active {
            border-radius: 3px;
            background: $colorLGray;
            outline: none;
            color: $colorLifeGreen;
            &::after, &::before{
              content: initial;
            }
            .custom-btn-badge{
              position: absolute;
              top: -8px;
              right: -8px;
              border-radius: 50%;
              background-color: #49e1ca;
              font-size: 8px;
              font-weight: 600;
              text-align: center;
              width: 16px;
              height: 16px;
              color: black;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: "Helvetica Neue";
            }
          }
        }
        &:after{
          content: "";
          display: block;
          z-index: 10;
          bottom: -9px;
          height: 7px;
          transition: 200ms ease all;
        }
        &:hover:after,
        &.active:after{
          width: 100%;
          transition: 200ms ease all;
        }
      }
    }
  }
}