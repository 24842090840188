@import '../../../../utils/_variables';

.ReputationModal {
	position: fixed;
	top: calc(50% - 350px);
	left: calc(50% - 300px);
	height: auto;
	width: 640px;
	background: #212227;
	z-index: 101;
	padding: 50px 40px 40px 40px;
	border-radius: 12px;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
	border: solid 1px #3e424d;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	img.close {
		cursor: pointer;
		position: absolute;
		top: -16px;
		right: -16px;
	}

	h2 {
		text-align: center;
		margin: 0 0 30px 0;
	}

	textarea {
		margin: 12px 0;
		background: #18191d;
		height: 160px;
		width: 80%;
		border-radius: 3px;
		border: solid 2px #2c3239;
		color: white;

		&:focus {
			background: #18191d;
			border-radius: 3px;
			border: solid 2px #2c3239;
			box-shadow: none;
		}
	}

	span.username {
		color: $colorLifeGreen;
	}

	span.error {
		color: red;
		display: block;
		margin: 5px 0px;
	}
}

.NoizModalOverlay {
	position: fixed;
	z-index: 100;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
}