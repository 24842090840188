@import  '../../../../utils/_variables';
.edit-modal-input-override {
    
    input {
        // min-height: 45px;
        outline: none;
    background-color: transparent;
    box-shadow: none;
    outline: 0
    }

    input[type="text"]:focus { 
      
        outline: 0;
        background-color: transparent;
        box-shadow: none;
        border: none;
       
    }

    
}

.transition-modal-title {
    background-color: $purple;
}

.transition-modal-time {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}