.Notification__actions {
    display: flex;
    flex-direction: row;
}

.Notification__actions button{
    border-radius: 2px;
    padding: 6px 20px;
    font-weight: bold;
    margin: 10px 10px 0px 0px;
    border: 0px;
    color: rgb(255, 255, 255);
}
