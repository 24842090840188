@import '../../../utils/_variables';

.SignIn{
  .ModalMaster{
    .Modal{
      .Modal__wrapper{
        .Modal__content{
          padding-bottom: 27px;
        }
      } 
    }
  } 
}