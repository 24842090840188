@import '../../../../../utils/_variables';

.TrackingTags {
  display: flex;
  flex-direction: column;

  // height: 100%;
  width: 100%;

  padding: 23px;

  background-color: $colorSoftPurple;

  & > * {
    margin: 10px 0;
  }

  .TrackingTags_Header {
    color: $colorBlack;
    font-size: 26px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.26px;
    text-align: left;
  }

  .TrackingTags_Tags {
    display: flex;
    flex-wrap: wrap;

    .TrackingTags_Tag {
      display: flex;
      align-items: center;
      justify-content: space-between;

      position: relative;

      height: 45px;

      margin: 10px;
      padding: 10px;
      padding-right: 15px;

      background-color: $colorDarkPurple;
      border-radius: 8px;
      color: $colorBackground;
      cursor: pointer;

      svg {
        position: absolute;
        top: 5px;
        right: 5px;

        width: 12px;
        height: 12px;
      }
    }
  }

  .TrackingTags_Add {
    display: flex;

    width: 100%;

    // padding: 5px;

    .TrackingTags_Input {
      @include no-select;

      height: 52px;
      width: 100%;

      padding: 15px;

      background-color: $colorDarkPurple;
      border: 2px solid transparent;
      border-radius: 8px;

      &:focus {
        border: 2px solid $colorBlack;
      }
    }

    .TrackingTags_Button {
      @include center-content;

      height: 52px;
      width: 52px;

      border-radius: 8px;
      cursor: pointer;

      svg {
        height: 33px;
        width: auto;
      }
    }
  }
}