@import "../../../utils/_variables";

.Portal__MainView {
  background: $colorSemiGray;
  border-radius: 10px;
  display: flex;
  height: auto;
  padding: 5px;
  position: relative;
  width: 100%;
  
  @media #{$mobileAndTabletOnly} {
    @include col-start;  
  }
}
