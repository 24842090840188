.SFGame__checkout {
	font-family: Roboto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 100%;
	padding-top: 120px;
	button.StripeCheckout{
		margin-top: 60%;
	}
	.ModalMaster {
		.Modal__store__game__success{
			.Modal__wrapper{
				max-height: 300px;
				max-width: 300px;
				display: flex;
				flex-flow: wrap;
				.Modal__success__content{
					flex: 0 0 100%;
					.Modal__success__image{
						padding-top: 30px;
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center;
						height: 130px;
						width: 130px;
						margin-right: auto;
						margin-left: auto;
						position: relative;
					}
					.Modal__success__title {
						padding-top: 10px;
						font-weight: 700;
						font-size: 25px;
						width: 100%;
					}
					.Modal__success__description {
						padding-top: 10px;
						font-size: 12px;
						color: gray;
						padding: 0 40px;
						width: 100%;
					}
				}
			}
		}
		.Modal__store__game__checkout{
			.Modal__wrapper{
				max-height: 400px;
				max-width: 600px;
				display: flex;
  			flex-flow: wrap;
				.Modal__left{
					flex: 0 0 50%;
					.Modal__left__image{
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center;
						height: 200px;
						width: 130px;
						margin-right: auto;
						margin-left: auto;
						position: relative;
						.Cover__corner__1{
              position: absolute;
              width:100%;
              height:100%;
              transition: 400ms ease all;
              &:after {
                content: "";
                display: block;
                height: 40px;
                width: 2px;
                bottom: -5px;
                left: -5px;
                position: absolute;
								transition: 400ms ease all;
								background: #00E2CA;
              }
              &:before{
                content: "";
                display: block;
                height: 2px;
                width: 40px;
                bottom: -5px;
                left: -5px;
                position: absolute;
								transition: 400ms ease all;
								background: #00E2CA;
              }
						}
						.Cover__corner__2{
              position: absolute;
              width:100%;
              height:100%;
              transition: 400ms ease all;
              &:after{
                content: "";
                display: block;
                height: 40px;
                width: 2px;
                top: -5px;
                right: -5px;
                position: absolute;
								transition: 400ms ease all;
								background: #00E2CA;
              }
              &:before{
                content: "";
                display: block;
                height: 2px;
                width: 40px;
                right: -5px;
                top: -5px;
                position: absolute;
								transition: 400ms ease all;
								background: #00E2CA;
              }
            }
					}
					.Modal__left__title{
						padding-top: 20px;
						font-weight: 700;
						font-size: 25px;
					}
					.Modal__left__description{
						padding-top: 10px;
						font-size: 12px;
						color: gray;
						padding: 10px 40px;
					}
				}
				.Modal__right{
					flex: 0 0 50%;
					height: 100%;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
				}
			}
		}
		.Modal {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
			display: flex;
			align-items: center;
			justify-content: center;
			visibility: hidden;
			opacity: 0;
			//    transition: 200ms ease all;
			overflow-x: hidden;
			background: rgba(0,0,0,.5);

			.Modal__layer {
				// background: rgba(0,0,0,0.5);
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				cursor: pointer;
			}
			align-items: flex-start;
			@media screen and (min-width: 640px) {
				align-items: center;
			}

			.Modal__wrapper {
				width: 90%;
				height: 90%;
				background: #202227;
				//border-radius: 5px;
				//padding: 0 30px 0 0;
				position: relative;
				//box-shadow: 0px 1px 5px 0px rgba(66, 177, 212, 0.88);
				//border: 1px solid #383838;
				text-align: center;
				//display: flex;
				//flex-direction: column;
				align-items: center;
				justify-content: center;
				//transition: 200ms ease all;
				transform: scale(0.8);
				margin: 5vw 0 5vh;
				@media screen and (min-width: 640px) {
					margin: 0;
				}

				.icon {
					width: 50px;
					margin: 30px auto;

					img {
						width: 100%;
					}
				}

				.Modal__title {
					font-size: 25px;
					letter-spacing: 2px;
					margin: 20px 0 0;
				}
				.Modal__close-btn {
					width: 35px;
					height: 35px;
					margin-left: auto;
					position: absolute;
					right: 10px;
					top: 10px;
					cursor: pointer;
					&:before {
						content: "";
						width: 3px;
						height: 90%;
						background: #37393D;
						display: block;
						position: absolute;
						left: 50%;
						// top: 50%;
						transform-origin: center;
						transform: rotate(45deg);
					}

					&:after {
						content: "";
						width: 3px;
						height: 90%;
						background: #37393D;
						display: block;
						position: absolute;
						left: 50%;
						// top: 50%;
						transform-origin: center;
						transform: rotate(-45deg);
					}
				}
			}

			&.active {
				visibility: visible;
				opacity: 1;

				.Modal__wrapper {
					transform: scale(1);
					//transition: 200ms ease all;
				}
			}
		}
	}
}
