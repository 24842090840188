@import "../../../utils/variables";

.LiveTag {
  position: relative;

  .LiveTag__Box1 {
    position: absolute;
    top: -5px;
    left: -5px;

    height: 14px;
    width: 14px;

    background-color: $colorCandyRed2;
  }

  .LiveTag__Tag {
    @include center-content;

    height: 37px;
    width: 86px;

    background-color: $colorCandyRed;

    font-size: 26px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.26px;
    text-align: left;
  }

  .LiveTag__Box2 {
    position: absolute;
    top: 80%;
    right: 0;

    height: 12px;
    width: 75px;

    background-color: $colorCandyRed2;
    z-index: -1;
  }
}
