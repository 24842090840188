@import "../../../../utils/_variables";

.Arbitration_MasterContainer {
  margin: 0 auto;
  padding-top: 30px;
  padding-top: 120px;
  width: 100%;
  height: 100;
  @include col-center;
  

  .Arbitration_WorkingContainer {
    @include col-start;
    width: 80%;
    padding: 42px 30px 29px 35px;
    margin: 20px 0px; 
    background-color: #1f2227;
    border-radius: 10px;

  }

  .Arbitration_Search {
    width: 100%;
    align-self: flex-start;

    input {
      width: 100%;
      max-width: 500px;
      height: 50px;
      padding: 15px 146px 14px 25px;
      border-radius: 10px;
      background-color: #16181c;
      color: white;
    }

    button {
      cursor: pointer;
      font-size: 20px;
      margin-left: 10px; 
      letter-spacing: 1.2px;
      text-align: center;
      color: #16181c;
      width: 50px;
      height: 50px;
      padding: 15px 15px 14px;
      border-radius: 10px;
      background-image: linear-gradient(to bottom, #e8cdfe, #b7bbfd);
      &:hover {
        background-image: linear-gradient(to bottom, #b7bbfd, #e8cdfe);
        color: slategray;
      }
    }
  }

  .Arbitration_Filters {
    @include row-between;
    width: 100%;

    .Arbitration_Filters__Tiles {
    }

    .Arbitration_Filters__Search_Fields {
    }
  }

  .Arbitration_MainList {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    background-color: inherit;
    border-radius: 10px;
    padding: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 500px;
  }
}
