@import "../../utils/_variables";

.OffplatformQuest{
  display: flex;
  margin: 0 auto;
  padding: 10px;
  padding-top: 100px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .OffplatformQuest_Back {
    width: 80%;
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: inherit;
  }
}
