@import '../../utils/variables';

.game-card {
  background-color: $colorBGray;
  display: flex;
  flex-direction: row;

  @media #{$mobileOnly} {
    border-radius: 4px;
    flex-direction: column;

    @include vw-pixels((
      'height': 480
    ), $vwPixelsScale);
  }

  @media #{$macroScreens} {
    min-height: 480px;
  }

  .image-container {
    flex: 1.618003;

    @media #{$mobileOnly} {
      width: 100%;
    }

    .image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 0;
      justify-content: center;
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
  
      @media #{$mobileOnly} {
        flex: 1;
      }
  
      img {
        height: 100%;
        object-fit: cover;
  
        @media #{$mobileOnly} {
          width: 100%;
        }
      }
  
      .image-overlay {
        background: radial-gradient(circle, rgba(2, 0, 36, 0) 0%, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.618) 100%);
        padding-bottom: 56.25%;
        width: 100%;
      }
    }  
  }


  .info {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    width: 100%;

    @include vw-pixels((
      'padding': 30
    ), $vwPixelsScale);

    @media #{$mobileOnly} {
      height: 400px;
      padding: 0;
    }

    @media #{$macroScreens} {
      padding: 30px;
    }

    .dev {
      display: flex;
      order: 0;

      @media #{$mobileOnly} {
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));
        max-height: 70px;

        @include vw-pixels((
          'padding': 12
        ));
      }

      @media #{$macroScreens} {
        padding: 12px;
      }

      .logo {
        background-color: #000;
        border-radius: 50%;

        @include vw-pixels((
          'height': 70,
          'margin-right': 20,
          'padding-bottom': 9,
          'padding-left': 9,
          'padding-right': 8,
          'padding-top': 7,
          'width': 70
        ), $vwPixelsScale);

        @media #{$mobileOnly} {
          height: 40px;
          margin: 0;
          width: 40px;

          @include vw-pixels((
            'margin': 8,
          ));
        }

        @media #{$macroScreens} {
          height: 60px;
          margin-right: 20px;
          padding-bottom: 9px;
          padding-left: 9px;
          padding-right: 9px;
          padding-top: 8px;
          width: 60px;
        }
      }

      .names {
        display: flex;
        flex-direction: column;
        text-align: left;

        @media #{$mobileOnly} {
          justify-content: center;

          @include vw-pixels((
            'font-size': 25,
            'padding-left': 12,
          ));
        }

        @media #{$tabletAndLarger} {
          @include vw-pixels((
            'font-size': 18,
          ));
        }

        @media #{$macroScreens} {
          font-size: 18px;
          padding-left: 8px;
        }

        .dev-name {
          color: $colorGray;
        }

        .game-name {
          font-weight: 900;
        }
      }
    }

    .game-description {
      flex: 1;
      font-stretch: extra-condensed;
      font-weight: bold;
      line-height: 1;
      order: 1;
      overflow: hidden;
      text-align: left;
      text-transform: uppercase;

      @include vw-pixels((
        'font-size': 40,
        'margin-top': 40,
      ));
      
      @media #{$mobileOnly} {
        margin-top: 0;
        order: 0;

        @include vw-pixels((
          'font-size': 32,
          'padding': 32
        ));
      }

      @media #{$macroScreens} {
        font-size: 40px;
        margin-top: 40px;
      }

      p {
        margin: 0;
      }
    }

    .view-quests-button {
      background-image: $purpleGradient;
      border-radius: 26px;
      bottom: 0;
      color: #fff;
      cursor: pointer;
      font-weight: 900;
      left: 0;
      order: 2;
      position: relative;
      text-align: center;
      text-decoration: none;

      @include vw-pixels((
        'font-size': 18,
        'height': 52,
        'margin-bottom': 20,
        'margin-top': 54,
        'width': 228,
      ));

      @media #{$mobileOnly} {
        align-self: flex-end;
        bottom: 10;
        margin-left: auto;
        position: relative;
        width: 38.2%;

        @include vw-pixels((
          'font-size': 27,
          'height': 72,
          'margin-bottom': 32,
          'margin-right': 32,
          'margin-top': 10
        ));
      }

      @media #{$macroScreens} {
        font-size: 20px;
        height: 60px;
        margin-bottom: 20px;
        margin-top: 54px;
        width: 38.2%;
      }

      .quest-button-text {
        left: 50%;
        position: absolute;
        text-align: center;
        top: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
}
