#maintenance-mode-myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.maintenance-mode-content {
  position: fixed;
  top: 30%;
  right: 40%;
  /* background: rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  color: #f1f1f1;
  width: 50%;
  padding: 25px;
  font-family: 'Press Start 2P', cursive;
  /* line-height: 25px; */
}

.maintenance-mode-noiz-logo {
  max-width: 130px;
}

.maintenance-mode-spaceman {
  max-width: 80px;
}

// .maintenance-mode-link {
//   text-decoration: none;
// }
