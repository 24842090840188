

.ConnectPlatforms {
  text-align: center;
  .ConnectPlatforms--squares {
    .ConnectPlatforms--square {
      border: 1px solid grey;
      display: inline-block;
      height: 0;
      width: 32%;
      background: initial;
      padding-bottom: 32%;
      position: relative;
      cursor: pointer;
      .ConnectPlatforms--disconnected {
        &.authorize {
          margin-top: 10px !important;
          padding: 10px;
          background: grey;
          color: white;
        }
      }
      &:hover {
        .ConnectPlatforms--disconnected {
          &.authorize {
            background: #3a74e0;
            color: white;
          }
        }
      }
      @media screen and (max-width: 640px) {
        display:flex;
        align-items: center;
        flex-direction:row;
        width:100%;
        padding-bottom: 40%;
        padding-top: 6%;
      }

      .ConnectPlatforms--check {
        display: none;
        position: absolute;

        @media screen and (max-width: 1023px) {
          display:none !important;
        }

        background-color: rgba(29, 31, 35,0.5);
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        font-size: 100px;
        line-height: 200px;

        i {
          line-height: 25px;
        }
      }

      &.not_connected {
        .ConnectPlatforms--disconnected {
          display: inline-block;
        }
        .coming_soon{
          color: #83858c;
        }
        .ConnectPlatforms--connecting {
          display: none;
        }

        .ConnectPlatforms--connected {
          display: none;
        }

        .ConnectPlatforms--check {
          display: none;
        }
      }
      @-webkit-keyframes Rotate {
        from {
          -webkit-transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(360deg);
        }
      }
      @-moz-keyframes Rotate {
        from {
          -moz-transform: rotate(0deg);
        }

        to {
          -moz-transform: rotate(360deg);
        }
      }
      @-ms-keyframes Rotate {
        from {
          -ms-transform: rotate(0deg);
        }

        to {
          -ms-transform: rotate(360deg);
        }
      }

      &.connecting {
        .ConnectPlatforms--border {
          /*position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: transparent;
            border-radius: 50%;
            border: 2px dashed #000;
            -webkit-animation-name: Rotate;
            -webkit-animation-duration: 2s;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-timing-function: linear;
            -moz-animation-name: Rotate;
            -moz-animation-duration: 2s;
            -moz-animation-iteration-count: infinite;
            -moz-animation-timing-function: linear;
            -ms-animation-name: Rotate;
            -ms-animation-duration: 2s;
            -ms-animation-iteration-count: infinite;
            -ms-animation-timing-function: linear;*/
        }

        .ConnectPlatforms--disconnected {
          display: none;
        }

        .ConnectPlatforms--connecting {
          margin-top: 12px;
          display: inline-block;
        }

        .ConnectPlatforms--connected {
          display: none;
        }

        .ConnectPlatforms--check {
          display: none;
        }
      }

      &.connected {
        .ConnectPlatforms--disconnected {
          display: none;
        }

        .ConnectPlatforms--connecting {
          display: none;
        }

        .ConnectPlatforms--connected {
          display: inline-block;
        }

        .ConnectPlatforms--check {
          display: block;
        }
      }

      .ConnectPlatforms--inner {
        display: block;
        min-height: 100%;
        padding-top: 30%;

        @media screen and (max-width: 640px) {
          display:block;
          width:100%;
          align-items:center;
        }

        .ConnectPlatforms--inner1 {
          height: 60%;
          @media screen and (max-width: 640px) {
                text-align: center;
              width: 100%;
              margin-top: 15px;
            }

          img {
            display: block;
            margin-right: auto;
            margin-left: auto;

            @media screen and (max-width: 640px) {
              margin-left: 0;
              height:60px !important;
              object-fit: cover;
              display: inline-block;
              width: 80%;
              min-width:144px;
            }
          }
        }

        .ConnectPlatforms--inner2 {
          height: 40%;

          @media screen and (max-width: 640px) {
            margin-right:10% !important;
            width: 100%;
            text-align: center;
          }

          button {
            margin-top: 20px;
          }

          .ConnectPlatforms--connecting {
            color: #83858c;
            font-size: 12px;
          }
        }
      }

      &.ConnectPlatforms--square1 {
        .ConnectPlatforms--inner1 {
          img {
              @media screen and (max-width: 640px) {
                object-position: -50px 0px !important;
                    margin: auto;
                  display: inline-block;
                  object-position: center center !important;
                  object-fit: contain;
              }
            }
        }
      }

      &.ConnectPlatforms--square2 {
        .ConnectPlatforms--inner1 {
          img {
              @media screen and (max-width: 640px) {
                width:auto !important;
              }
            }
        }
      }

      &.ConnectPlatforms--square3 {
        .ConnectPlatforms--inner1 {
          img {
              @media screen and (max-width: 640px) {
                width:auto !important;
              }
            }
        }
      }

      &.ConnectPlatforms--square1:after {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        bottom: -1px;
        left: -5%;
        background: #3A74E0;
        width: 10px;
        height: 15%;
        transition: 200ms ease all;
        @media screen and (max-width: 640px) {
          left:-6px;
          width:6px;
          height:10vw;
        }
      }

      &.ConnectPlatforms--square1:before {
        @media screen and (max-width: 640px) {
          content: "";
          display: block;
          position: absolute;
          background: #3A74E0;
          width: 10px;
          height: 15%;
          transition: 200ms ease all;
          left:-6px;
          bottom:-6px;
          height:6px;
          width:10vw;
        }
      }

      &.ConnectPlatforms--square1 .ConnectPlatforms--inner2:after {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        top: -1px;
        left: -3%;
        background: #43C8B5;
        width: 6px;
        height: 40%;
        transition: 200ms ease all;
        @media screen and (max-width: 640px) {
          left:-6px;
          height:10vw;
        }
      }

      &.ConnectPlatforms--square2:after {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        bottom: -2%;
        left: -1%;
        background: #a558ff;
        width: 20%;
        height: 4px;
        transition: 200ms ease all;
        @media screen and (max-width: 640px) {
          left:-6px;
          bottom:-6px;
          width:15vw;
          height:6px;
        }
      }

      &.ConnectPlatforms--square2:before {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        bottom: -2%;
        left: -2%;
        background: #a558ff;
        width: 4px;
        height: 20%;
        transition: 200ms ease all;
        @media screen and (max-width: 640px) {
          left:-6px;
          height:15vw;
          width:6px;
        }
      }

      &.ConnectPlatforms--square2 .ConnectPlatforms--inner2:after {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        top: -3%;
        right: -2%;
        background: #3A74E0;
        width: 10%;
        height: 6px;
        transition: 200ms ease all;
        @media screen and (max-width: 640px) {
          top:-6px;
          right:-6px;
          width:5vw;
          height:6px;
        }
      }

      &.ConnectPlatforms--square2 .ConnectPlatforms--inner2:before {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        top: -3%;
        right: -2%;
        background: #3A74E0;
        width: 6px;
        height: 10%;
        transition: 200ms ease all;

        @media screen and (max-width: 640px) {
          top:-6px;
          right:-6px;
          width:6px;
          height:5vw;
        }
      }

      &.ConnectPlatforms--square3:after {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        bottom: -5%;
        right: -1px;
        background: #43C8B5;
        height: 10px;
        width: 30%;
        transition: 200ms ease all;
        @media screen and (max-width: 640px) {
          right:-1px;
          bottom:-6px;
          width:15vw;
          height:6px;
        }
      }

      &.ConnectPlatforms--square3 .ConnectPlatforms--inner2:after {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        top: -1px;
        right: -3%;
        background: #3A74E0;
        width: 6px;
        height: 60%;
        transition: 200ms ease all;
        @media screen and (max-width: 640px) {
          right:-6px;
          top:-1px;
          height:15vw;
          width:6px;
        }
      }
    }
    .ConnectPlatforms--between {
      display: inline-block;
      width: 2%;
    }
  }
  @media screen and (max-width: 1023px) {
    .ConnectPlatforms--inner2{
      button.ConnectPlatforms--connected.mobile{
        &.connected{
          display: -webkit-box !important;
        }
        display:none;
        overflow:hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical !important;
        text-overflow: ellipsis !important;
        width: 100%;
        padding: 0 10px;
      }
    }
  }
  a,
  button {
    color: #3a74e0;
    cursor: pointer;
    font-weight: 700;
    text-decoration: none;
    @media screen and (max-width: 1023px) {
      &.desktop{
        display:none !important;
      }
    }
    @media screen and (min-width: 1024px) {
      &.mobile{
        display:none !important;
      }
    }
  }
}