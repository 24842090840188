@import "../variables";
.Chart {

  .StackedChart_Chart {
    cursor: crosshair;
  }
  .StackedChart_ResetButton {
    width: auto;
    height: auto;
    margin: 15px;
    padding: 8px;
    border-radius: 4px;
    background-color: #3e71e1;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 100%;
    color: white;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      &:focus {
        outline: none;
        background-color: transparent;
      }
    }
  }

  .StackedChart_DataManipulation {
    @include row-between;
    padding-top: 10px;
    padding-right: 15px;
    width: 100%;
    height: auto;

    @media #{$mobileOnly} {
      @include col-center;
    }

    .StackedChart_FilterBar {
      select {
        width: 284px;
        height: 64px;
        padding: 16px;
        border-radius: 4px;
        border: solid 1px #707070;
        color: white;
        background-color: transparent;
      }
      cursor: pointer;
    }
  }
}
