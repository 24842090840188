@import "../../../utils/_variables";

.AddPaymentOptionForm {
  @include col-center;
  align-self: center;
  background-color: #e8cdfe;
  border-radius: 10px;
  color: black;
  padding: 25px;
  width: 60%;

  input {
    width: 100%;
  }

  .AddPaymentOptionForm__Title {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.16px;
    margin-bottom: 25px;
    text-align: left;
  }


  .AddPaymentOptionForm__Fields {
    width: 100%;
    @include col-center;

  }

  .AddPaymentOptionForm_Title {
    font-size: 26px;
    margin: 20px 0px;
    width: 100%;
  }

  .AddPaymentOptionForm_Input {
    font-size: 16px;
    margin: 20px 0px;
    width: 100%;
  }

  .AddPaymentOptionForm_Button {
    font-size: 16px;
    margin: 20px 0px;
    width: 100%;
  }

  .AddPaymentOptionForm__Fields_Row {
    @include row-around;
    width: 100%;
  }

  .AddPaymentOptionForm__Fields__Field {
    @include col-center;
    margin: 20px 0px;
    // width: 100%;
  }

  .AddPaymentOptionForm__Fields__Field__Title {
    align-self: flex-start;
    margin-bottom: 5px;
  }

}

.AddPayButton {
  .PaymentText {
    margin-left: 10px;
  }

  @include no-select;
  @include row-center;
  background-color: #e8cdfe;
  border: 0px solid white;
  border-radius: 12px;
  color: #16181c;
  cursor: pointer;
  font-family: Roboto;
  font-size: 120%;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  // @include hoverGlow;
  letter-spacing: 0.22px;
  line-height: 2.73;
  margin: 10px 0px;
  min-height: 116px;
  text-align: center;
  transition: all linear .5s;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  -moz-transition: all .5s;
  width: 100%;

  // margin: 32px 12px 65px 17px;
  // padding: 44px 232px 43px 234px;
  &.disabled {
    background-color: #16181c;
    border: 1px solid #16181c;
    color: rgb(50, 50, 50);
    cursor: not-allowed;

    &:hover {
      background-color: #16181c;
      border: 1px solid #16181c;
      color: rgb(50, 50, 50);
    }
  }

}

.SavedPaymentOption {
  @include row-between;
  border-radius: 8px;
  color: #bfbfbf;
  font-family: Roboto;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 25px 0px;
  padding: 10px;
  text-align: left;
  transition: all ease-in-out .3s;
  width: 100%;

  &.IsBrand {
    &:hover {
      background-color: #7a9bdc63;
      transition: all ease-in-out .3s;
    }
  }

  .IconType {
    align-self: flex-start;
    margin-right: 5px;
  }

  .PaymentCluster {
    @include row-start;
  }

  .TrashThis {
    color: rgb(194, 81, 81);
    cursor: pointer;
  }
}

// STREAMER 

.StripeCluster {
  @include row-between;
  background-color: #18191d;
  border-radius: 10px;
  flex-wrap: wrap;
  margin: 10px 0px;
  min-height: 200px;
  padding: 20px;
  width: 100%;

  @media #{$mobileOnly} {
    @include col-center;
    margin: auto;
    width: auto;
  }

  // create a box with center text with conditional styles in either go green or stop red
  .StripeCluster__Status {
    @include col-center;
    background-color: #18191d;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.16px;
    // width: 40%;
    // height: 150px;

    padding: 20px;
    text-align: center;
    // box-shadow: 0 0 15px .1px #e8cdfe;
    // add large fuzzy glowing stripe purple box shadow
  }


  .stripe_button {
    // add css to the button to make it look like a Stripe checkout button
    background-color: #6772e5;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    color: #fff;
    cursor: pointer;
    font-family: Camphor,
    Open Sans,
    Segoe UI,
    sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 600;
    line-height: 40px;
    margin: 10px 0;
    padding: 0 14px;
    text-align: center;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
    white-space: nowrap;

    &:hover {
      background-color: #7795f8;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      transform: translateY(-1px);
    }
  }

  .go {
    color: #7ac943;
  }

  .stop {
    color: #c25151;
  }

  .StripeInfo {
    color: white;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.16px;
    margin: 10px 0px;
    width: 40%;

    @media #{$mobileOnly} {
      font-size: 14px;
      padding: 0px;
      width: 100%;
    }


    .ItemMarker {
      @include row-between;
      margin-bottom: 5px;
      width: 100%;
    }
    // box-shadow: 0 0 15px .1px #e8cdfe;
    // add large fuzzy glowing stripe purple box shadow
  }
}