.InsightsFilterBar {
  background: #202227;
  cursor: pointer;
  display: flex;
  height: 60px;
	width: 100%;

  &.sticky {
    position: fixed;
    top: 0;
  }
}

.InsightsFilterBar-placeholder {
	height: 60px;
}

.InsightsFilterBarOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  z-index: 1;
}