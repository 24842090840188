@import  '../../../../utils/_variables';

.ChartStatBox {
  background-image: linear-gradient(to bottom, rgba(87, 97, 107, 0.5), rgba(49, 56, 63, 0.5));
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin: 16px 0;

  &.full {
  	width: 100%;
  }

  &.half {
  	width: calc(50% - 16px);
  }

  h2 {
  	margin: 0;
  }

  .chartTitles {
	  display: flex;
	  flex-direction: column;
	  border-right: 1px solid $colorGray;
	  font-size: 16px;
	  font-weight: bold;
	  text-align: right;

	  .chartTitle {
	  	margin: 0 20px 30px 0;

		  &:last-of-type {
		    margin: 0 20px 10px 0;
		  }

		  img {
		  	width: 20px;
		  	height: 20px;
		  	margin: 0 0 0 10px;
		  }
	  }
  }

  .charts {
  	display: flex;
  	flex-direction: row;
  	width: 100%;
  	height: 100%;
  	align-items: center;
  	margin: 25px 0 0 0;
  }

  .chartBars {
	  display: flex;
	  flex-direction: column;
	  max-width: 100%;
	  min-width: 60%;
	  margin: 7px 0 0 0;

	  span.completionBar {
	    display: flex;
	    align-items: center;
	    margin: auto;
	    width: 100%;
	    height: 12px;
	    position: relative;
	    margin: 0 0 40px 0;


		  &:last-of-type {
		    margin: 0 0 10px 0;
		  }

		  span.label {
		  	margin: 0 0 0 11px;
	      font-weight: bold;
		  }

	    span.fill {
	      display: block;
	      height: 100%;
	      position: relative;
	      overflow: hidden;
	      border-radius: 0px 8px 8px 0px;
  			background-image: linear-gradient(to left, rgba(58, 116, 224, 1), rgba(58, 116, 224, 0.15) 100%);
	    }
	  }
  }
}