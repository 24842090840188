@import "../../../../utils/_util";
@import "../../../../utils/_variables";

.PortalReport {
  align-items: flex-start;
  color: white;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 20px;
  width: 100%;

  .PortalReport_NeedHelp {
    @include hoverGlow;
    @include row-start;
    align-items: flex-start;
    align-self: flex-start;
    background-image: linear-gradient(rgb(253, 141, 130), rgb(161, 66, 255));
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    padding: 20px;
    width: 100%;

    i {
      font-size: 50px;
      margin-right: 5px;
      margin-right: 10px;
    }
  }

  h1 {
    @include vw-pixels(('font-size': 40));
    @media #{$mobileAndTabletOnly} {
      font-size: 45px;
    }
  }

  h2 {
    @include vw-pixels(('font-size': 25));
    @media #{$mobileAndTabletOnly} {
      font-size: 35px;
    }
  }

  .PortalReport_OverviewStats {
    @include row-between;
    // flex-wrap: wrap;
    width: 100%;

    .Overview_One {
      margin: 8px 8px 8px 0px !important;
    }

    .Overview_Two {
      margin: 8px 0px 8px 8px !important;
    }

    @media #{$mobileOnly} {
      @include col-start;
      .Overview_One {
        margin: 8px !important;
      }
      .Overview_Two {
        margin: 8px !important;
      }
    }
  }

  .PortalReport_MetricClusterGroup {
    @include row-evenly;
    align-items: flex-start;
    width: 100%;

    @media #{$mobileOnly} {
      @include col-start;
    }
  }

  .PortalReport_HeaderCal {
    @include row-between;
    margin: 10px 0px;
   
    @media #{$mobileAndTabletOnly} {
      @include col-start;
      align-items: flex-start;
    }

    .PortalReport_Calendar {
      background-image: linear-gradient(to bottom, #0075e4, #a142ff);
      border-radius: 5px;
      height: 62px;
      margin: 0 0 22px 293px;
      max-width: 332px;
      padding: 3px;
      width: 100%;
      @media #{$mobileAndTabletOnly} {
        margin: 0;
      }

      .PRC_Cal_Dark {
        @include row-between;
        background-color: #1f2227;
        height: 100%;
        padding: 0px 10%;
        width: 100%;
        .PRC_Cal_Item {
          background-color: transparent;
        }
      }
    }
  }
  .PortalReport_GraphContainer {
    background-color: black;
    border-radius: 10px;
    margin: 10px 0px;
    max-height: 800px;
    width: 100%;
  }
}
