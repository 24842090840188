@import '../../../../utils/_variables';

.AUserEdit {
  .AUserEdit__wrapper {
    margin: 0 auto;
    padding: 30px;
    padding-top: 120px;

    .button-style {
      width: 300px;
      height: 50px;
      border-radius: 30px;
      background: linear-gradient(to right, #45cacd, #47b8d4);
      color: #FFF;
      border: none;
      outline: none;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 22px;
      letter-spacing: 1.2px;
      margin: 20px 0 20px 0;
      cursor: pointer;
    }

    .AUserEdit__content {
      margin: 0 30%;

      .AUserEdit__title {
        font-weight: 500;
        letter-spacing: 3.5px;
        text-align: left;
        text-transform: uppercase;
        @include vw-pixels(('font-size': 35));
      }

      .AUserEdit__actions {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          @extend .button-style;
        }
      }

      form.admin-user-edit,
      form.admin-user>* {
        padding-right: 20px;
      }

      form.admin-user-edit,
      form.admin-user {
        background-color: #282930;
        padding: 20px;
        width: 100%;

        label {
          color: #83858c;
        }

        .AUserForm__disconnectPlatform--container {
          .AUserForm__disconnectPlatform {
            border-radius: 10px;
            padding: 5px;
            border: 2px solid $colorLightGray;
            cursor: pointer;
            margin: 0 0 5px 0;
            text-align: center;
          }
        }

        .input-wrapper {
          position: relative;

          .error-message {
            color: #dc4f4f;
            font-size: 12px;
            position: absolute;
            right: 0;
            bottom: -17px;
          }

          .error-icon {
            color: #dc4f4f;
            display: none;
            position: absolute;
            right: 10px;
            top: 10px;
          }

          .ok-icon {
            position: absolute;
            right: 10px;
            bottom: 5px;
            color: #49E1CA;
          }
        }

        input,
        select {
          background-color: #202227;
          border: none;
          border-bottom: 2px solid #83858C;
          border-radius: 0px;
          color: white;
          display: inline-block;
          padding-left: 0;
          text-align: left;
          margin: 0;
          width: 100%;
        }

        input.error,
        select.error {
          border-bottom: 2px solid #dc4f4f;
        }

        select {
          background-image: none;
        }

        a {
          text-decoration: none;
        }

        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: rgb(131, 133, 140);
          opacity: 1;
          /* Firefox */
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgb(131, 133, 140);
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgb(131, 133, 140);
        }

        .first-option {
          color: rgb(131, 133, 140);
        }

        input:focus,
        select:focus {
          border-bottom: 2px solid #3A74E0;
          box-shadow: none;
        }

        input:placeholder-shown+label {
          cursor: text;
          max-width: 66.66%;
          color: #d1d1d1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transform-origin: left bottom;
          transform: translate(0, 1.9rem) scale(1.5);

          &:after {
            content: attr(data-label);
          }
        }

        .input-wrapper {
          display: flex;
          flex-flow: column-reverse;
          margin-bottom: 1em;
          position: relative;
        }

        input,
        select {
          z-index: 1;
          background-color: transparent;
        }

        select option {
          background-color: #202227;
        }

        label {
          z-index: 0;
        }

        label,
        input {
          transition: all 0.2s;
          touch-action: manipulation;
        }

        .form__footer {
          display: flex;
          justify-content: center;
          align-items: center;

          .AUserForm__save--btn {
            @extend .button-style;
          }
        }
      }
    }
  }
}