@import "../../../utils/variables";

.carousel-item {
  margin: 0px 1vw 0px 1vw;
  position: relative;

  //  width: 80%;
  //  min-height: 500px;
  z-index: 10;
  img {
    // float: left;

    @include vw-pixels(("width": 640,
        "height": 360,
      ));

    object-fit: cover;
  }
}

ul.thumbs.animated {
  display: none;
}

.carousel-title {
  position: absolute;
  text-align: left;
  top: 10%;
  left: 6%;
  font-family: Roboto;
  text-shadow:1px 1px 8px rgb(65, 65, 65);
  // font-size: calc(2vw + 25px);
  @include vw-pixels(("font-size": 40,
    ));
  text-transform: uppercase;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
}

.carousel-click-through {
  @include greyHoverRipple;
  position: absolute;
  top: 10vw;
  left: 6%;
  // width: 30%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Roboto;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;

  @include vw-pixels(("font-size": 20,
      "width": 200,
      "border-radius": 40,
      "top": 260,
    ));

  @include hoverGlow;

  @media #{$tabletOnly} {
    @include vw-pixels(("top": 190,
      ));
  }

  @media #{$mobileOnly} {
    @include vw-pixels(("top": 235,
      ));
  }

  // margin: 92px 305px 0 0;
  // padding: 15px 53px 16px 54px;
  // border-radius: 25px;
  background-image: linear-gradient(to bottom,
    #3a74e0,
    #4a6fe6 31%,
    #5d69ea 67%,
    #8358ed);
}

.carousel-border {
  // transition: 0.8s ease;

  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  width: 100%;
  height: 100%;
  // background-color: #8358ed;
  // z-index:20000;
  -webkit-box-shadow: inset 0px 0px 16px 30px rgba(92, 237, 255, 0.08);
  box-shadow: inset 0px 0px 16px 30px rgba(92, 237, 255, 0.08);

  &:hover {
    box-shadow: inset 0 0 24px 30px rgba(255, 255, 255, .08);
    transition: 0.8s ease;
  }
}


