@import "../../../utils/_variables";

.DeleteModal__Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // min-height: 40%;
  // min-width: 40%;

  background-color: $colorDarkGray;
  border-radius: 8px;
  box-shadow: 24;
}

.DeleteModal {
  @include col-center;

  position: relative;
  
  padding: 30px 20px;

  .DeleteModal__Close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;

    svg {
      height: auto;
      width: 20px;
    }
  }

  .DeleteModal__Title {
    @include center-content;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .DeleteModal__Content {
    font-size: 16px;
    @include col-center;
  }

  .DeleteModal__Button {
    @include button-base;
    margin-top: 20px;

    width: 50%;
    height: 40px;
    background-color: $colorBDarkGray;
    color: $colorGray;
    font-size: 16px;
    align-self: center;
    font-weight: bold;

    cursor: not-allowed;

    &.Active {
      background-color: $colorCandyRed;
      color: $colorWhite;
      cursor: pointer;

   

      &:hover {
        background-color: $colorCandyRed2;

      }

      &.green {
        background-color: $colorCandyGreen;
        color: $colorBlack;
        &:hover {
          background-color: $colorCandyGreen2;
          color: $colorGrayOffset;
        }

     
      }
      &.disabled {
        color: $colorGray;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}