@import  '../../../utils/_variables';

.InsightsFilter {
  align-items: center;
	border-left: 1px solid rgba(255,255,255,0.05);
  display: flex;
  flex-grow: 9;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  position: relative;

  &:hover {
    background: #2b2e38;
  }

  .filterButton {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    img {
      width: 32px;
      fill: #83858c;
    }

    @media #{$nonDesktop}{
      span.label {
        display: none;
      }
    }
  }

  span {
    justify-content: space-between;

    &.filtering {
      color: #49e1ca;
    }
    .icon {
      margin-right: 24px;
    }
  }
}