@import "../../../../../utils/_variables";

.FundQuestApplicant {
  border-radius: 12px;
  display: flex;
  min-height: 462px;
  // width: 610px;

  .FundQuestApplicant__QuestInfo {
    background-color: $colorNavyBlue;
    border-radius: 12px 0 0 12px;
    display: flex;
    flex-basis: 60%;
    flex-direction: column;
    padding: 30px;

    .FundQuestApplicant__QuestInfo__Title {
      font-size: 40px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.6px;
      line-height: 1;
      margin-bottom: 10px;
      text-align: left;
    }

    .FundQuestApplicant__QuestInfo__Divider {
      background-color: $colorLightBlue;
      content: '';
      display: block;
      margin: 10px 0;
      min-height: 1px;
      width: 100%;
    }

    .FundQuestApplicant__QuestInfo__CostBreakdown,
    .FundQuestApplicant__QuestInfo__Total {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      width: 100%;

      .FundQuestApplicant__QuestInfo__CostBreakdown__Item {}

      .FundQuestApplicant__QuestInfo__CostBreakdown__Cost {
        font-weight: bold;
      }

      .FundQuestApplicant__QuestInfo__Total__Item,
      .FundQuestApplicant__QuestInfo__Total__Cost {
        font-size: 30px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 900;
        letter-spacing: 0.3px;
        line-height: 2;
        text-align: left;
      }
    }

    .FundQuestApplicant__QuestInfo__Button {
      @include purple-gradient-button;
      font-weight: bolder;
      max-height: 48px;
      min-height: 48px;
      width: 100%;

      &.disabled {
        background-color: slategray;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .FundQuestApplicant__ApplicantInfo {
    align-items: center;
    background-color: $colorDarkNavyBlue;
    border-radius: 0 12px 12px 0;
    display: flex;
    flex-basis: 40%;
    flex-direction: column;
    justify-content: center;
    padding: 30px;

    .FundQuestApplicant__ApplicantInfo__Img {
      background-color: $colorDDarkGray;
      border-radius: 8px;
      height: 116px;
      object-fit: cover;
      width: 116px;
    }

    .FundQuestApplicant__ApplicantInfo__Name {
      color: $colorCyan;
      font-size: 30px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.3px;
      text-align: center;
    }

    .FundQuestApplicant__ApplicantInfo__FeeText {
      color: $colorSoftPurple;
      font-size: 13px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.26px;
      text-align: left;
    }

    .FundQuestApplicant__ApplicantInfo__FeeAmount {
      font-size: 25px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.25px;
    }

    .FundQuestApplicant__ApplicantInfo__Stats {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 30px;
      width: 100%;

      .FundQuestApplicant__ApplicantInfo__Stats__Stat {
        align-items: center;
        border-right: 1px solid $colorLightGrayOffset;
        display: flex;
        flex-direction: column;
        padding: 0 15px;

        &:last-child {
          border-right: 0;
        }

        .FundQuestApplicant__ApplicantInfo__Stats__Stat__Stat {
          font-size: 18px;
          font-stretch: normal;
          font-style: normal;
          font-weight: bold;
          letter-spacing: normal;
          text-align: center;
        }

        .FundQuestApplicant__ApplicantInfo__Stats__Stat__Title {
          color: $colorLightGrayOffset;
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          font-weight: normal;
          letter-spacing: normal;
          text-align: center;
        }
      }
    }

    .FundQuestApplicant__ApplicantInfo__ViewProfile {
      @include white-border-button;
      font-weight: bolder;
      height: 48px;
      width: 100%;
    }
  }
}