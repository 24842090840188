.optionsList.congeniality {
	flex-direction: row;
	justify-content: center;

	span.option {
		width: 140px;
		height: 160px;
	}
}

.buttons.congeniality {
	margin: 35px 0 0 0;
	float: right;
}