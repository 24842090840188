@import  '../../utils/_variables';

.Careers{
  .bg-button{
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: #FFF;
		position: relative;
		background-color: #3a74e0;
		background: linear-gradient(180deg, #3a74e0 0%, #3a74e0 50%, #6762e8 100%);
		border-radius: 2px;
    font-weight: 900;
		text-decoration: none;
		width: 100%;
  }
  .Hero{
    display: flex;
    flex-direction: column;
    background-image: url('./assets/FloatingElements.png');
    background-size: 110vh;
    background-position: 30% 0;
    position: relative;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0 5vw 12vw;
    height: 50vh;
    background-color: #0d1116;
    @media screen and (min-width: 1024px){
      // padding: 0 5vw 5vh;
      // height: 60vh;
      // background-size: 110vh;
      // background-position: 0 0;
      padding: 0 5vw 5vh;
      height: 60vh;
      background-size: 100%;
      background-position: 0 20%;
      background-repeat: no-repeat;
    }

    .Hero__icons-container{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      overflow: hidden;
      .icon{
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 10%; height: 10%;
        position: absolute;
        transition: opacity 1200ms ease-out;
        z-index: 1;
        &.dimmed{
          opacity: 0.16;
        }
        &.ghost{
          background-image: url('../../images/particles/icon-ghost.png');
          width: 7.55%;
          height: 8.8vw;
        }
        &.potion{
          background-image: url('../../images/particles/icon-potion.png');
          width: 5.42%;
          height: 5.42vw;
        }
        &.controller{
          background-image: url('../../images/particles/icon-controller.png');
          width: 7.03%;
          height: 4.63vw;
        }
        &.dpad{
          background-image: url('../../images/particles/icon-dpad.png');
          width: 6.8765%;
          height: 6.8765vw;
        }
        &.crown{
          background-image: url('../../images/particles/icon-crown.png');
          width: 4.9%;
          height: 4.11vw;
        }
        &.scroll{
          background-image: url('../../images/particles/icon-scroll.png');
          width: 5%;
          height: 5.21vw;
        }
      }

      .sword-container{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      .boxes-background{
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        .layer1{ left: 32.1785%!important; top: -3vw!important; }
        .layer2{ left: 35%!important; top: 18%!important; }
        .layer3{ left: 70%!important; top: 23%!important; }
        .layer4{ left: 82%!important; top: 44%!important; }
        .layer5{ left: 12%!important; top: 54%!important; }
        .layer6{ left: 97%!important; top: 74%!important; }
        .layer7{ left: 24%!important; top: 80%!important; }
        .box{
          background: #202227;
          width: 5.58vw;
          height: 5.58vw;
          position: absolute;
          &.box1{ animation: boxBaubleTwo 4000ms infinite linear; }
          &.box2{ animation: boxBaubleTwo 2600ms infinite linear; }
          &.box3{ animation: boxBaubleTwo 3200ms infinite linear; }
          &.box4{ animation: boxBaubleTwo 4000ms infinite linear; }
          &.box5{ animation: boxBaubleTwo 5600ms infinite linear; }
          &.box6{ animation: boxBaubleTwo 6400ms infinite linear; }
          &.box7{ animation: boxBaubleTwo 2400ms infinite linear; }
        }
      }
      .boxes-foreground{
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        .layer1{ left: 8%!important; top: 48%!important; width: 6.2vw; height: 6.2vw; }
      .layer2{ left: 84%!important; top: 35%!important; width: 8.33vw; height: 8.33vw; }
      .layer3{ left: 28%!important; top: 58%!important; width: 1.04vw; height: 1.04vw; }
      .layer4{ left: 75%!important; top: 59%!important; width: 0.785vw; height: 0.785vw; }
      .layer5{ left: 17%!important; top: 63%!important; width: 1.04vw; height: 1.04vw; }
      .layer6{ left: 69%!important; top: 81%!important; width: 2.08vw; height: 2.08vw; }
      .layer7{ left: 23%!important; top: 79%!important; width: 1.98vw; height: 1.98vw; }
      .layer8{ left: 47%!important; top: 88%!important; width: 2.08vw; height: 2.08vw; }
        .box{
          position: absolute;
          width: 100%;
          height: 100%;
          &.box1{ animation: boxBauble 4400ms infinite linear; background: $colorLifeGreen; }
          &.box2{ animation: boxBauble 5600ms infinite linear; background: $colorEnergyBlue; }
          &.box3{ animation: boxBauble 3200ms infinite linear; background: $colorEnergyBlue; }
          &.box4{ animation: boxBauble 4200ms infinite linear; background: $colorEnergyBlue; }
          &.box5{ animation: boxBauble 2800ms infinite linear; background: $colorFluidPink; }
          &.box6{ animation: boxBauble 3000ms infinite linear; background: $colorFluidPink; }
          &.box7{ animation: boxBauble 4800ms infinite linear; background: $colorPurpleRay; }
          &.box8{ animation: boxBauble 3600ms infinite linear; background: transparent; border: 0.5vw solid $colorLifeGreen; }
        }
      }
    }

    .Hero__content{
    	z-index: 2;
			@media screen and (min-width: 1024px){
				max-width: 1280px;
		    margin: 0 auto;
		    width: 100%;
				position: relative;
			}

      .Hero__title{
  			font-weight: 900;
  			line-height: 114%;
  			font-size: 13vw;
  			margin: 0 0 0 0;
  			@media screen and (min-width: 768px){

  			}
  			@media screen and (min-width: 1024px){
  				font-weight: 900;
  				line-height: 110%;
  				font-size: 6vw;
  				margin: 0 0 2vw 0;
  			}
  			@media (min-aspect-ratio: 16/9){
  				font-size: 10vh;
  				line-height: 100%;
  				margin: 0 0 2.5vh 0;
  			}
      }
    }
  }

  .MainCareers{
    background: #FFF;
    color: #1b1e22;
    padding: 18vw 5vw 18vw 5vw;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px){
      padding: 5vw 5vw;
    }
    @media screen and (min-width: 1280px){
      padding: 7vw 5vw;
    }
    .MainCareers__content{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: flex-end;
      align-items: flex-start;
      @media screen and (min-width: 1024px){
				max-width: 1280px;
		    margin: 0 auto;
		    width: 100%;
				position: relative;
        flex-direction: row;
			}
      .CareersListing{
        width: 100%;
        .PostingGroup{
          width: 100%;
          .PostingCategory{
            position: relative;
            .PostingCategory__title{
              margin: 0;
              color: #515357;
              font: normal 700 30px/1.4 'Roboto', sans-serif;
              letter-spacing: 1px;
              // margin-bottom: 25px;
              text-transform: uppercase;
            }
            .PostingCategory__label{
              margin: 0;
              color: #808080;
              font: normal 700 24px/1.4 'Roboto', sans-serif;
              letter-spacing: 1px;
              text-transform: uppercase;
            }
            .PostingCategory__spacer{
              margin: 0;
              margin: 20px auto 20px auto;
              width: 80px;
              height: 1px;
              border-bottom-width: 1px;
              border-bottom-style: solid;
              border-color: #e2e2e2;
              margin-left: 0px;
              display: block;
            }
          }
          .PostingItem{
            display: inline-block;
            position: relative;
            width: 100%;
            margin: 10px 0;
            .PostingItem__apply-btn{
              position: absolute;
              top: 15px;
              right: 30px;
              height: 40px;
              text-align: center;
              background: #3974df;
              border-color: #1a62e4;
              color: white;
              text-decoration: none;
              padding: 5px 15px;
              min-width: 100px;
              border-radius: 3px;
              font-weight: 600;
              text-transform: uppercase;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 15px;
            }
            .PostingItem__wrapper{
              color: #737578;
              text-decoration: none;
              display: block;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              transition: ease all 300ms;
              padding: 10px 0;
              &:hover{
                background: rgba(0,0,0,0.025);
                padding: 10px 30px 10px 25px;
                border-left: 5px solid #3974df;
              }
              .PostingItem__title{
                margin: 0;
                font: normal 400 36px/1.4 'Roboto', sans-serif;
                color: #515357;
                text-transform: none;
                letter-spacing: 0px;
                font-size: 24px;
                margin-right: 145px;
                overflow: hidden;
                transition: color 150ms;
              }
              .PostingItem__categories{
                font: normal 700 24px/1.4 'Roboto', sans-serif;
                letter-spacing: 1px;
                text-transform: uppercase;
                margin: 0;
                height: 30px;
                vertical-align: top;
                font-size: 12px;
                line-height: 1.4;
                color: #808080;
                .PostingItem__categories--location{

                }
              }
            }
          }
        }
      }
    }
  }


}
