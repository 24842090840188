@import '../../../utils/_variables';

.PortalSignForm__Wrapper {
  @include center-content;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
  url('https://cdn.zeplin.io/61b39e894d20733a8bc58e68/assets/25ACB0C8-D38D-4BF8-A0CC-CCAD10DE44AF.png');
  // height: 100%;
  min-height: 100vh;
  width: 100%;

  .PortalSignForm {
    @include center-content;
    -webkit-backdrop-filter: blur(10.4px);
    backdrop-filter: blur(10.4px);
    background-color: rgba(0, 0, 0, 0.3);
    flex-direction: column;
    min-height: 704px;
    padding: 80px 90px;
    position: relative;
    width: 552px;

    .PortalSignForm__Bar {
      background-color: $colorPurpleRay;
      min-height: 5px;
      min-width: 491px;
      position: absolute;

      &.TopPosition {
        left: -70px;
        top:0;
      }

      &.BottomPosition {
        bottom:0;
        left: 100px;
      }
    }

    .PortalSignForm__Back {
      color: $colorCyan;
      cursor: pointer;
      display: flex;
      left: 25px;
      position: absolute;
      top: 25px;

      svg {
        height: 20px;
        width: auto;
      }
    }

    .PortalSignForm__Header {
      font-size: 46px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.46px;
      text-align: center;
      width: 100%;
    }

    .PortalSignForm__Buttons {
      cursor: pointer;
      display: flex;
      height: 46px;
      margin-bottom: 30px;
      margin-top: 28px;

      .SignFormButtons {
        @include center-content;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.24px;
        text-align: left;
        width: 116px;
      }

      .SignInButton {
        border-radius: 14px 0 0 14px;
      }
      
      .SignUpButton {
        border-radius: 0 14px 14px 0;
      }

      .Active-Login {
        background-image: linear-gradient(to bottom, #e8cdfe, #b7bbfd);
        color: $colorBlack;
      }

      .Inactive-Login {
        background-color: $colorEDarkGray;
        color: $colorEGray;
      }
    }

    .PortalSignForm__Form {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: 70%;

      & > * {
        margin: 10px 0;
      }

      .PortalSignForm__Form_Headers {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.16px;
        text-align: left;
      }

      .PortalSignForm__Form_Sub_Headers {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        text-align: left;
      }

      .PortalSignForm__Form_Input {
        background: none;
        background-color: $colorCDarkGray;
        border: none;
        box-shadow: none;
        color: $colorWhite;
        min-height: 38px;
        width: 100%;

        &:focus {
          outline: none;
        }

        &.Error {
          border: solid 2px $colorCandyRed;
        }
      }

      .PortalSignForm__Form_Error {
        @include center-content;
        border: 2px solid $colorCandyRed;
        border-radius: 4px;
        color: $colorCandyRed;
        // min-height: 45px;
        padding: 20px 10px;
        width: 100%;
      }

      .PortalSignForm__Form_Button {
        @include purple-gradient-button;
        height: 48px;
        margin-top: 47px;
        width: 100%;
      }

      .PortalSignForm__Form_Button_Spinner {
        @include center-content;
        margin-top: 47px;
        width: 100%;
      }
    }

    .PortalSignForm__Form_Success {
      @include center-content;
      height: 45px;
      margin-top: 47px;
      width: 100%;

      svg {
        height: 100%;
        width: auto;
      }
    }

    .PortalSignForm__Forgot {
      color: $colorCyan;
      cursor: pointer;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.16px;
      text-align: center;
    }
  }
}
