@import "../../utils/_variables";

.back {
  color: $colorLifeGreen;
  margin-left: 1%;
  max-width: 120px;
  text-decoration: none;
  @include textShadow();
  transition: 0.25s ease;
  @include whiteTextHover;
}