@import '../../utils/_variables';

.AccessDenied{
	width: 100%;
	height: calc(100vh - #{$headerHeight});
	background: #000;
	&__wrapper{
		max-width: 1000px;
		padding: 0 20px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
	}
	&__content{
		width: 65%;
		padding-left:5%;
		padding-right:5%;
		@media #{$mobileOnly}{
			padding-left:5%;
			padding-right:5%;
			width: 100%;
		}
	}
	h1{
		font-size: 38px;
	}
	p {
		font-size: 16px;
		&.sub{
			font-size: 16px;
			@media #{$mobileOnly}{
				padding-bottom:7%;
			}
		}
	}
	a{
		text-decoration: none;
		color: #47ccd0;
	}
	.noiz-icon{
		background-image: url('./icons/icon1.png');
		background-position: center;
		margin-top: 32px;
		width: 10%;
		height: 100%;
		margin-right:17%;
		@media #{$mobileOnly}{
			display: none;
		}
	}
	.site-gradient-btn{
		text-align: center;
		margin-top: 32px;
		color: #fff;
		@media #{$tabletAndLarger}{
			display: inline-block;
		}
	}
}
