@import "../../utils/_variables";

.video-widget-container {

  //   width: 100%;
  position: relative;
  @include vw-pixels(("height": 540,
    ));

  @media #{$tabletOnly} {
    @include vw-pixels(("height": 540,
        "min-width": 1426,
      ));
  }


  @media #{$tabletAndLarger} {
    @include vw-pixels(("height": 540,
        "max-width": 1100,
      ));
  }

  @media (min-width: 1550px) {
    @include vw-pixels(("height": 540,

      ));

    max-width: 67vw;
  }

  // background: linear-gradient(
  //   to bottom,
  //   rgba(25, 116, 250, 0) 50%,
  //   rgba(25, 116, 250, 0.2)
  // );
  background-image: url('../../images/eclipse.gif');
  border-radius: 2px;
  margin: 20px 0px;
  background-repeat: no-repeat;
  background-size: 15%;
  background-position: center;
}

.video-widget-image {
  width: 100%;

  @media #{$tabletAndLarger} {
    @include vw-pixels(("height": 540,
        // "max-width": 1100,
      ));
  }
}

.preview-image {
  overflow: hidden !important;
  display: flex;
  justify-content: center;
  align-items: center;

  @include vw-pixels(("height": 540,
    ));

  .img-man {
    object-fit: cover !important;
    height: 100%;
    // width: 100%;
  }
}

.video-widget-player {
  margin-top: 0% !important;
  width: 100%;
  @include vw-pixels(("height": 540,
    ));
}

.video-widget-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  border: 0;
}

.after-video-image {
  position: absolute;
  top: 50%;
  left: 50%;
  // width: 0%;
  opacity: 0.6;
  transform: translateX(-50%) translateY(-50%);

  img {
    @include vw-pixels(("height": 100,
      ));
  }
}