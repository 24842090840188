@import '../../../utils/_variables';

.contacts-container {
  margin: 0 auto;
  padding-top: 30px;
  padding-top: 120px;

  .body {
    display: flex;
  }

  h1 {
    font-size: 40px;
    text-align: center;
    margin: 45px 0px 25px 0px;
  }

  .progressWrapper {
    position: absolute;
    top: 50%;
    left: calc(50% - 40px);
  }

  span.apply {
    align-items: center;
    border-radius: 3px;
    color: white;
    background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6 31%, #5d69ea 67%, #8358ed);
    display: flex;
    font-size: 16px;
    font-weight: 900;
    height: 44px;
    justify-content: center;
    margin: 30px auto 20px auto;
    text-align: center;
    width: 268px;
  }

  button {
    cursor: pointer;

    &.apply {
      border-radius: 3px;
      color: white;
      display: flex;
      align-items: center;
      background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6 31%, #5d69ea 67%, #8358ed);
      display: flex;
      font-size: 16px;
      font-weight: 900;
      height: 44px;
      justify-content: center;
      margin: 30px auto 20px auto;
      text-align: center;
      width: 268px;

      &.cancel {
        background: rgba(white, 0.05);
      }
    }
  }

  .main {
    min-height: 100vh;
    overflow-y: scroll;
    padding: 30px;
    width: 100vw;
  }

  .sidebar {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23);
    margin-top: 0.5px;
    position: absolute;
    right: 0;
    width: 316px;
    z-index: 10000;

    .btn-container {
      background-color: #202227;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 0.38em 5em 0.618em 0;
    }
  }
}