@import "../../../../../utils/_variables";
.General_Profile {
 

.Add_Payment_Button {
  width: 50%;
  height: 48px;
  border-radius: 22px;
  background-image: linear-gradient(to bottom, #4be0ca, #3e71e1 142%);
  padding: 10px;
  margin: auto;
  color: #16181c;
  cursor: pointer;
  @include row-center;
  @include hoverGlow;

  .Add_Payment_Button_Text {
    font-size: 100%;
    font-weight: 600;
    color: #16181c;
    margin-left: 2%;
  }
}



}

.Profile_Pic_Container {
  @include row-center;
  height: 100%;


  

  .Profile_Pic {
    @include col-evenly;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
    transition: background-color 0.2s ease-in-out;
    margin-bottom: 25px;
    .TheImage {
      margin-bottom: 15px;
      .Profile_Pic_Text {
        color: #4be0ca;
      }
      img {
        width: 50%;
        max-height: 150px;
        border-radius: 50%;
      }

      img {
        width: 100%;
      }
    }
  }
}
