@import  '../../../../utils/_variables';
.CCQuestTiered {
  &.loading {
    text-align: center;
    padding-top: 20px;
  }
  h2{
    margin: 0 0 10px 0;
  }
  .buttonStyle{
    height: 50px;
    border-radius: 30px;
    background: linear-gradient(to right, #45cacd, #47b8d4);
    color: #FFF;
    border: none;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: 1.2px;
    margin: 20px 0 20px 0;
    cursor: pointer;
  }
  .CCQuests__back--btn{
    @extend .buttonStyle;
    width: 300px;
  }
  .CCQuests__save--btn{
    @extend .buttonStyle;
    width: 100%;
  }

  .ToggleTable {
    background: #202227;
    .ToggleTable__header {
      background: #202227;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 30px;
      .ToggleTable__header-title {
        padding: 0;
        margin: 0;
        font-size: 22px;
        letter-spacing: 2.5px;
      }
      .ToggleTable__header--buttons {
        display: flex;
        margin-left: auto;
      }
      .ToggleTable__header--button {
        color: white;
        padding: 5px 10px;
        border: 1px solid white;
        margin-right: 5px;
        border-radius: 3px;
        cursor: pointer;
        &.no-padding {
          padding: 0;
        }
      }
      .dot-status{
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #ef3937;
        box-shadow: 0 0 9px 0 #ff987f;
        margin-left: 10px;
      }
      a{
        line-height: 18px;
        margin-left: auto;
        color: $colorLight;
      }
    }
    .ToggleTable__body-wrapper {
      background: #202227;
      padding: 20px;
      .ToggleTable__body {
        .ToggleTable__body-row {
          display: flex;
          justify-content: space-evenly;
          margin: 30px 0;
          .divider {
            min-height:2px;
            background-color:$colorGray;
            border-radius: 2px;
          }
          .ToggleTable__body-rowItem {
            flex: 1;
            text-align: center;
            .ToggleTable__body-itemBot{
              font-size: 35px;
              &.green {
                color: #49e1ca;
              }
              &.red {
                color: red;
              }
            }
          }
        }
      }
    }
    .ToggleTable__table-wrapper {
      background: #202227;
      padding: 30px;
      .ToggleTable__table--title {
        font-size: 20px;
        font-weight: 600;
        padding: 0 10px 10px 10px;
      }
      .ToggleTable__table--section {
        margin-bottom: 50px;
      }
      .no-results {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        margin-top: 30px;
      }
      .row{
        border-bottom: 1px solid #1e1f25;
        padding: 0 10px;
        &.toggable{
          flex-wrap: wrap;
          .row-basic{
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
          }
          .row-details{
            width: 100%;
            display: none;
          }
          &.open{
            .row-details{
              display: flex;
              .flex-table {
                width: 100%;
                border: 1px solid rgba(0,0,0,0.2);
                margin: 20px 10px;
                .table-cell{
                  position: relative;
                  a {
                    color: white;
                  }
                  &.headline{
                    font-size: 14px;
                    color: #9b9b9b;
                    font-weight: bold;
                    letter-spacing: 1.2px;
                  }
                  &:first-child{
                    justify-content: flex-start;
                    max-width: inherit;
                  }
                  &.last {
                    justify-content: flex-end;
                  }
                  &.success {
                    color: #49e1ca;
                    a {
                      color: #49e1ca;
                    }
                  }
                  &.warning {
                    color: #ffd300;
                    a {
                      color: #ffd300;
                    }
                  }
                  &.error {
                    color: #e91e63;
                    a {
                      color: #e91e63;
                    }
                  }
                }
              }
            }
          }
        }
        &:hover {
          background: rgba(255,255,255,0.025);
          &:first-child {
            background: rgba(255,255,255,0);
          }
        }
        &:last-child{
          border-bottom: none;
        }
        .table-cell{
          position: relative;
          justify-content: center;
          align-items: center;
          text-align: center;
          a {
            color: white;
          }
          &.headline{
            color: #9b9b9b;
            font-weight: 400;
            letter-spacing: 1.2px;
          }
          &.cell-left {
            justify-content: center;
          }
          &.sortable {
            cursor: pointer;
          }
          .reason {
            color: red;
            font-weight: 800;
            cursor: pointer;
          }
          .table-cell-sort {
            display: flex;
            flex-direction: column;
            i {
              color: rgba(155, 155, 155, 0.2);
              padding: 0 0 0 5px;
              height: 9px;
              &.selected {
                color: #9b9b9b;
              }
            }
          }
          &:first-child {
            max-width: 50px;
            &.first {
              max-width: inherit;
            }
          }
          &.last {
            justify-content: flex-end;
          }
          i {
            padding: 4px 0 0 6px;
          }
          span {
            &.error {
              color: $colorCrimson;
            }
            &.success {
              color: $colorLifeGreen;
            }
            &.info {
              color: $colorEnergyBlue;
            }
          }
          .small-btn {
            background: rgba(255,255,255,0.05);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #FFF;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 11px;
            text-decoration: none;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.open{
              background: rgba(255,255,255,.15);
            }
            &:last-child{
              margin-right: 0;
            }
            &:hover{
              background: rgba(255,255,255,.2);
            }
            &.red {
              color: red;
            }
            &.green {
              color: #49e1ca;
            }
            i {
              padding: 0;
            }
          }
          .small-btn.waiting {
            background: rgba(255,255,255,0);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #e91e63;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 11px;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  .NoQuestSale{
      padding-top: 10px;
  }
  .tooltip {
    position: relative;
    display: inline-block;
    color: white;
  }
  .tooltiptext {
    font-size: 10px;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    cursor: default;
    background-color: $colorBackground;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    bottom: 30px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 100;
    &.tooltiptext--100 {
      bottom: 100%;
    }
    &.nodecoration {
      &:before {
        display: none;
      }
    }
    &:before {
      content: "";
      z-index:100;
      position: absolute;
      width: 11px;
      height: 11px;
      bottom: -4.5px;
      left: 0;
      right: 70px;
      margin-left: auto;
      margin-right: auto;
      background-color: $colorBackground;
      border-top: solid 1px $colorBackground;
      border-left: solid 1px $colorBackground;
      transform: rotate(45deg);
    }
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}
  
.edit-icon {
  cursor: pointer;
  margin-left: 6px;
}

.RejectReason--input {
  input {
    box-shadow: none !important;
    border: none;
    outline: none;
    width: 400px;
  }
  input:focus{
    outline: none !important;
    border: none !important;
  }
}