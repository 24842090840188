.toggle-mobile-menu {
  border: none;
  cursor: pointer;
  height: 62px;
  margin-left: auto;
  outline: none;
  position: relative;
  right: 22px;
  top: 30px;
  width: 75px;
  z-index: 10;

  span {
    background: #FFF;
    border-radius: 5px;
    display: block;
    width: 25px;
    height: 3px;
    margin: 0 auto 5px auto;

    &:nth-child(1) {
      transition: 200ms ease all;
    }

    &:nth-child(2) {
      transition: 200ms ease all;
    }

    &:nth-child(3) {
      transition: 200ms ease all;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 31.11px;
      }

      &:nth-child(2) {
        opacity: 0;
        visibility: hidden;
      }

      &:nth-child(3) {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        width: 31.11px;
      }
    }
  }
}