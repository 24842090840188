.UserDetailModule {
	background: #3e424d;
	// border: 1px solid pink;
	bottom: 0;
	left: 0;
  padding: 10px 25px;
  position: relative;

  button.apply {
    width: 100% !important;
    margin-top: 10px !important;
  }

	h2 {
		text-align: center;
	}

  h3 { 
    margin: 0px; 
    padding: 0px;
  }

  img {
    width: 20px;
    margin: 0px 2px;
  }

	.detailsColumn {
		// border: 1px solid yellow;
		width: 50%;
		display: inline-block;

    span {
      font-size: 20px;
    }

		h2 {
      font-size: 30px;
			text-align: left;
			margin: 0px;
		}

		.fa {
			color: grey;
			height: 20px;
			width: 20px;
		}
	}  

  .icons {
    display: flex;
    justify-content: flex-end;
    height: 20px;
  }
    
  span.completionBar {
    display: block;
    margin: auto;
    width: 100%;
    height: 12px;
    background: rgba(255,255,255, 0.1);
    position: relative;
    margin: 20px auto;

    
    span.fill {
      display: block;
      height: 100%;
      position: relative;
      overflow: hidden;
      background-image: linear-gradient(to left, #fd8d82, #a142ff 0%);
    }
  }
}

.ModalOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  z-index: 999;
}

.ModalWrapper {
    align-items: center;
    display: flex;
    margin: 10vh 0;
    padding: 45px;
    z-index: 1000;
    position: fixed;
    top: 25vh;
    transform: translateX(-50vw);

  .Modal {
    position: fixed;
    width: 632px;
    background: #202227;
    z-index: 1000;
    padding: 30px 0px;
    z-index: 1000;

    span.close {
      cursor: pointer;
      position: absolute;
      right: 0;
      margin: 5px 7px;
      top: 0;
    }

    .columnContainer {
      display: flex;
      justify-content: center;
      margin-left: 25px;

      .column {
        display: flex;
        flex-direction: column;
        width: 100%;

        span {
          margin-right: 12px;
          align-items: center;
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}