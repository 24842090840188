.MostWatchedModule {
	background: #3e424d;

	.UserCell {

		border: none;

		&.even {
			background: #202227;
		}

		&.odd {
			background: #2b2e38;
		}
	}

	.MostWatchedList {
		background: #202227;
		cursor: pointer;
		height: calc(100vh - 690px);
		overflow-y: scroll;

		span.selected {
			font-weight: 900;
			color: #49e1ca;
		}
	}

	h3 {
		font-size: 14px;
		margin: 0px;
		padding: 15px 0px;
		text-align: center;
	}
}