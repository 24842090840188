@import "../../../../../utils/_variables";

.StreamerProfile {
  display: flex;
  flex-direction: column;

  width: 100%;

  .StreamerMasthead {
    position: relative;

    width: 100%;

    background-color: $colorBackground;

    .StreamerMasthead__Quests {
      position: absolute;

      top: 40px;
      left: 25px;

      z-index: 100;
    }

    .StreamerMasthead__LiveTag {
      position: absolute;

      top: 40px;
      right: 0;

      z-index: 100;
    }

    .StreamerMasthead__Background {
      position: relative;
      width: 100%;

      .StreamerMasthead__Background__Avatar {
        min-height: 200px;
        max-height: 450px;
        width: 100%;

        background-color: $colorBlack;
        object-fit: cover;
        opacity: 0.5;
      }

      .StreamerMasthead__Avatar {
        position: absolute;
        height: 241px;
        width: 200px;

        top: 65%;
        left: 50%;
        transform: translateX(-50%);

        object-fit: cover;
        border-radius: 14px;
      }
    }

    .StreamerMasthead__Name {
      @include center-content;
      width: 100%;

      margin-top: 125px;

      font-size: 58px;

      @media #{$mobileOnly} {
        font-size: 10vw;
        min-height: 100px;
      }

      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: 0.62px;
    }

    .StreamerMasthead__Button__Wrapper {
      @include center-content;

      width: 100%;
      margin-top: 16px;

      .StreamerMasthead__Button {
        @include center-content;
        @include transform-scale;

        height: 58px;
        width: 90%;

        background-color: $colorTwitch;
        border-radius: 14px;
        color: $colorCDarkGray;
        cursor: pointer;

        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.36px;

        svg {
          margin-left: 16px;
          height: 14px;
          width: auto;
        }
      }

      .IsLive {
        background-color: $colorCyan;
      }
    }

    .StreamerMasthead__Stats {
      @include center-content;
      flex-wrap: wrap;

      width: 100%;

      margin: 16px 0;

      .StreamerMastheadStatItem {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;

        min-height: 100px;

        padding: 0 15px;
        border: 1px solid $colorSemiGray;
        border-width: 0 1px;

        &:first-child {
          border-width: 0 1px 0 0;
        }

        &:last-child {
          border-width: 0 0 0 1px;
        }

        .StreamerMastheadStatItem__Flag {
          height: 50px;
          width: auto;
        }

        .StreamerMastheadStatItem__Title {
          font-size: 50px;

          @media #{$mobileOnly} {
            font-size: 8vw;
          }

          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.55px;
          text-align: center;
        }

        .StreamerMastheadStatItem__Subtitle {
          margin-top: 10px;

          font-size: 14px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: 0.84px;
          text-align: center;
        }
      }
    }

    .StreamerMasthead__About {
      display: flex;
      flex-direction: column;

      margin: 22px;

      .StreamerMasthead__About__Title {
        font-size: 24px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: 0.24px;
      }
    }
  }

  .StreamerTopStreamed {
    display: flex;
    flex-direction: column;

    width: 100%;

    margin: 21px 0;

    .StreamerTopStreamed__Title {
      font-size: 24px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.24px;
    }

    .StreamerTopStreamed__Cards {
      display: flex;
      flex-wrap: wrap;

      width: 100%;

      @media #{$mobileAndTabletOnly} {
        justify-content: center;
      }

      .TopStreamedCard {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        height: 290px;
        width: 195px;

        margin: 22px 22px 0 0;
        padding: 19px 13px;

        background-color: $colorBDarkGray;
        border-radius: 8px;

        @media #{$mobileAndTabletOnly} {
          width: 100%;
          margin-right: 0;
        }

        .TopStreamedCard__Img {
          height: 200px;
          width: 169px;

          border-radius: 8px;
          object-fit: cover;

          @media #{$mobileAndTabletOnly} {
            width: 100%;
            object-fit: contain;
          }
        }

        .TopStreamedCard__Title {
          font-size: 12px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.2px;
          text-align: center;
        }
      }

      .StreamerTopStreamed__Cards__ShowMore {
        @include center-content;

        width: 100%;
        margin-top: 22px;

        cursor: pointer;

        svg {
          height: 14px;
          width: auto;
        }
      }
    }
  }

  .StreamerSocialMedia {
    display: flex;
    flex-direction: column;

    padding: 22px;

    background-color: $colorBackground;

    .StreamerSocialMedia__Title {
      margin-bottom: 25px;

      font-size: 24px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.24px;
      text-align: left;
    }

    .StreamerSocialMedia__Icons {
      display: flex;
      flex-wrap: wrap;

      .StreamerSocialMedia__Icon {
        margin-right: 16px;
        margin-bottom: 16px;
      }
    }
  }
}