.static {
  color: #fff;
  padding-top: 150px;
  padding: 10px;
  margin: 0 auto;
  max-width: 800px;
  letter-spacing: 1.5px;
  line-height: 132%;
  -webkit-font-smoothing: antialiased; }
  .static h1 {
    text-align: center;
    line-height: 132%; }
  .static h2 {
    margin-top: 40px;
    font-size: 20px; }
  .static a {
    color: #5abfd8; }
  .static b {
    text-decoration: underline; }
  .static ol ol {
    list-style-type: lower-alpha; }

.TermsOfUse,
.PrivacyPolicy,
.QuestParticipationAgreement {
  padding-top: 150px;
  padding-bottom: 50px; }

.QuestParticipationAgreement ol li {
  margin: 14px 0; }
