@import '../../utils/variables';

$shiftAmount: 10px;

.loading-spinner {
  left: 50%;
  position: absolute;
  top: 38%;
  transform: translate(-50%, -50%);
}

.no-quests {
  margin: 30px auto 0 auto;
  max-width: 1024px;
  text-align: center;

  a {
    color: white;
    text-decoration: underline !important;
  }
}

.quest-index {
  background-color: #18191d;
  margin: 0 auto;
  padding-top: 110px;
  width: 90%;
  max-width: $macroScreen;

  .featured-games {
    margin-bottom: 3em;
    position: relative;

    .carousel.carousel-slider {
      overflow: visible;
    }

    .carousel {
      .control-dots {
        bottom: -29px;
        height: 10px;
        margin: 0;

        @media #{$mobileOnly} {
          bottom: -28px;
        }

        .dot {
          background: $colorDarkGrayOffset;
          box-shadow: none;
          opacity: unset;
          vertical-align: top;
        }

        .dot.selected {
          background: $colorPurpleRay;
        }
      }
    }
    
    .slide {
      @media #{$mobileOnly} {
        margin-top: 0;
        padding-bottom: 0;
        padding-top: $shiftAmount;
        transition: all 700ms ease-in-out;
      }

      .QuestCard {
        margin-top: $shiftAmount;
        transition: all 700ms ease-in-out;

        @media #{$mobileOnly} {
          margin-top: 0;
        }
      }
    }

    .slide.selected {
      display: list-item;
      
      @media #{$mobileOnly} {
        padding-bottom: $shiftAmount;
        padding-top: 0;
      }

      .QuestCard {
        margin-top: 0;
      }
    }
  }

  .tab-bar {
    border-bottom: solid 2px #2b2e38;
    font-family: Roboto;
    font-weight: bold;
    letter-spacing: 1px;
    width: 100%;

    @include vw-pixels((
      'font-size': 18,
      'padding-bottom': 30
    ), $vwPixelsScale);
  
    @media #{$mobileOnly} {
      @include vw-pixels((
        'font-size': 18,
        'padding-bottom': 30
      ));
    }

    @media #{$macroScreens} {
      font-size: 18px;
      padding-bottom: 30px
    }

    .tab-span {
      cursor: pointer;

      @include vw-pixels((
        'margin-right': 50
      ), $vwPixelsScale);
    
      @media #{$mobileOnly} {
        @include vw-pixels((
          'margin-right': 50
        ));
      }

      @media #{$macroScreens} {
        margin-right: 50px
      }

      &.active {
        border-bottom: solid 2px #a142ff;
        color: #a142ff
      }
    }
  }
}