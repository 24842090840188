@import "../../../utils/_variables";

.BrandPayment {
  @include col-center;
  width: 100%;

  .PaymentTitle {
    font-size: 26px;
    margin: 20px 0px;
  }
}