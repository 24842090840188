.SearchFilter {
	background: #2b2e38;
	box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.38);
	display: none;
	position: absolute;
	top: 100%;
	min-width: 400px;
	z-index: 10001;

	&.display {
		display: block !important;
	}

	&.left {
		left: 0;
	}

	&.right {
		right: 0 !important;
	}

	span.input-wrapper {
		display: flex;
		background: #202227;
		align-items: center;
		justify-content: space-between;

		input {
			width: 100%;
			height: 40px;
			border: none;
			color: white;
			padding: 12px;
			background: #202227;
			outline: none;
		}

		input:focus {
			outline: none;
		}

		.fa {
			padding: 20px;
		}
	}

	.selectedBar {
		background: #3e424d;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 18px;
		font-weight: 400;
		padding: 15px;

		button {
			color: white;
			font-weight: 900;
			font-size: 14px;
		  width: 64px;
		  height: 28px;
		  border-radius: 3px;
		  background: #2b2e38;
		}

		.selections {
			display: flex;
			flex-direction: column;

			span.selected {
				font-weight: 900;
				font-size: 14px;
			}
		}
	}

	.InsightSearch {
		display: flex;
		flex-direction: column;
		max-height: 400px;
		overflow-y: scroll;
		padding: 12px;

		span {
			font-size: 14px;
			font-weight: 400;
			padding: 2px 0px;

			&.selected { 
				color: #49e1ca;
			}
		}
	}
}

.selectedString {
	font-weight: 400;
	font-size: 12px;
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}