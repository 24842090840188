@import "../../../../../utils/_variables";

$grid-height: 512px;
$grid-img-height: 212px;
$grid-img-width: 238px;

.ViewAll {
  display: flex;
  flex-direction: column;

  width: 100%;

  .ViewAll__Header {
    font-size: 50px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;

  }

  .Portal_Product_LoadingGif {
    margin: 0 auto;
    width: 200px;
    height: 200px;
  }

  .ViewAll__Filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top: 20px;

    .ViewAll__Filter__Buttons {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .ViewAll__Filter__Buttons__Button {
        @include center-content;

        height: 40px;
        width: 40px;
        margin-left: 10px;

        background-color: $colorGray;
        border-radius: 12px;
        cursor: pointer;

        svg {
          height: auto;
          width: 18px;
        }
      }

      .ListStyleSelected {
        background-color: $colorPurpleRay;
      }
    }
  }

  .ViewAll__ShowMore {
    @include center-content;

    width: 100%;
    margin-top: 22px;

    cursor: pointer;

    svg {
      height: 14px;
      width: auto;
    }
  }

  .ViewAllApplicants {
    display: flex;
    flex-direction: column;

    width: 100%;
  }

  .ViewAllQuests,
  .ViewAllProducts {
    display: flex;
    flex-direction: column;

    width: 100%;

    &.grid {
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

.ViewAllTitle {
  margin-top: 20px;
  width: 90%;
  max-width: 600px;
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.24px;
  padding: 12px;
  border-radius: 12px;

  &.dropdown {
    @include hoverGlow;
    background-color: rgba(0, 0, 0, 0.2);

  }

}

.ViewAllFilter {
  @include row-between;
  @include no-select;
  width: 100%;
  position: relative;

  // margin-top: 10px;
  cursor: pointer;

  svg {
    height: auto;
    width: 18px;
  }

  .ViewAllFilter__Options {
    position: absolute;
    // width: 100%;
    top: 100%;

    background-color: $colorDarkGray;
    border-radius: 12px;
    box-shadow: 10px 5px 5px $colorBackground;

    z-index: 99;

    .ViewAllFilter__Option {
      display: flex;
      align-items: center;

      height: 48px;
      padding: 0 10px;
      // margin-top: 10px;

      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background-color: $colorGray;
      }

      &:first-child,
      :hover {
        border-radius: 12px 12px 0 0;
      }

      &:last-child,
      :hover {
        border-radius: 0 0 12px 12px;
      }
    }
  }
}

.ApproveButton {
  @include center-content;
  @include no-select;

  height: 50px;
  min-width: 50px;

  background-color: $colorCandyGreen;
  border-radius: 50%;
  cursor: pointer;

  &.grid {
    width: 100%;
    border-radius: 26px;
    color: $colorBlack;

    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
  }

  svg {
    height: auto;
    width: 22px;
  }
}

.RejectButton {
  @include center-content;
  @include no-select;

  height: 50px;
  min-width: 50px;

  background-color: $colorCandyRed;
  border-radius: 50%;
  cursor: pointer;

  &.grid {
    width: 100%;
    border-radius: 26px;

    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
  }

  svg {
    height: auto;
    width: 22px;
  }
}

.NoApplicantsCard,
.NoQuestsCard {
  @include col-center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 900;

  .no-quest-image {
    width: 10%;

    img {
      width: 100%;
    }
  }

  width: 100%;
}

.ApplicantsList {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px 20px;
  margin-top: 10px;
  border-radius: 12px;
  @include scroll-bar-style;
  width: 100%;

  &.grid {
    flex-direction: row;
    flex-wrap: wrap;

    @media #{$mobileOnly} {
      @include col-start;
    }
  }

  max-height: 500px;
  overflow-y: auto;
}

.ApplicantCard {
  @include row-between;

  position: relative;

  @media #{$mobileOnly} {
    @include col-center;
  }

  padding: 14px;
  min-width: 100%;
  margin-top: 20px;

  background-color: $colorBackground;
  border-radius: 10px;

  &.grid {
    flex-direction: column;
    flex-wrap: wrap;

    height: $grid-height;
    min-width: 274px;
    max-width: 274px;

    padding: 18px;
    margin: 18px 0 0 11px;
  }

  .ApplicantCard__Left {
    display: flex;
    align-items: center;

    @media #{$mobileOnly} {
      @include col-evenly;
    }

    // position: relative;

    &.grid {
      flex-direction: column;
    }

    .ApplicantCard__Img {
      height: 118px;
      width: 118px;

      border-radius: 8px;
      object-fit: cover;

      &.grid {
        height: $grid-img-height;
        width: $grid-img-width;
        margin-bottom: 10px;
      }
    }

    .ApplicantCard__Icon {
      @include center-content;
      @include background-blur-and-border;

      height: 48px;
      width: 48px;

      position: absolute;
      top: 10px;
      right: 10px;

      svg {
        height: 18px;
        width: auto;
      }
    }

    .ApplicantCard__Info {
      width: 50%;
      margin-left: 23px;

      &.grid {
        width: 100%;
      }

      .ApplicantCard__Info__Name {
        font-size: 24px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.24px;
        text-align: left;

        @media #{$mobileOnly} {
          font-size: 18px;
        }

        color: $colorSoftPurple;
      }

      .ApplicantCard__Info__CCV {
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.84px;
        text-align: left;

        @media #{$mobileOnly} {
          font-size: 12px;
        }
      }

      .ApplicantCard__Info__Known {
        display: flex;
        align-items: center;

        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.32px;
        text-align: left;

        @media #{$mobileOnly} {
          font-size: 13px;
        }

        .ApplicantCard__Info__Known__Text {
          color: $colorCGray;
        }

        .ApplicantCard__Info__Known__Game {
          color: $colorCyan;
        }
      }

      .ApplicantCard__Info__Date {
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.26px;
        text-align: left;

        @media #{$mobileOnly} {
          font-size: 11px;
        }
      }
    }
  }

  .ApplicantCard__Buttons {
    @include row-center;

    &.grid {
      @include col-between;
      width: 100%;
    }

    .ApplicantCard__Buttons__View {
      @include purple-gradient-button;

      height: 52px;
      width: 52px;
      padding: 0 20px;

      font-size: 100%;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
    }


    .ApplicantCard__Buttons__Deliverable {
      @include purple-gradient-button;
      background-image: linear-gradient(to right, rgb(1, 108, 129), rgb(1, 108, 129));
      height: 52px;
      width: 52px;
      padding: 0 20px;
      margin-right: 12px;
      font-size: 100%;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
    }

    .ApplicantCard__Buttons__Button {
      margin: 0 0 0 12px;

      &.grid {
        margin: 14px 0 0 0;
      }

      .ApplicantCard__Buttons__Button__Grid {
        width: 100%;
      }
    }
  }
}

.ItemCard {
  @include row-start;

  // justify-content: space-between;
  @media #{$mobileOnly} {
    @include col-center;
  }

  padding: 14px;
  min-width: 100%;
  margin-top: 20px;

  background-color: $colorBackground;
  border-radius: 10px;

  &.grid {
    @include col-start;
    flex-wrap: wrap;

    // height: $grid-height;
    min-width: 274px;
    max-width: 274px;

    padding: 18px;
    margin: 18px 0 0 11px;
  }

  .ItemCard__Img {
    position: relative;
    margin-right: 23px;

    &.grid {
      margin-right: 0;
    }

    .ItemCard__Img__Img {
      height: 118px;
      width: 118px;
      border-radius: 8px;
      object-fit: cover;

      &.grid {
        height: $grid-img-height;
        width: $grid-img-width;
      }
    }

    .ItemCard__Img__Icon {
      @include center-content;
      @include background-blur-and-border;

      height: 48px;
      width: 48px;

      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;

      cursor: pointer;

      svg {
        height: 18px;
        width: auto;
      }
    }
  }

  .ItemCard__Info {

    // height: 100%;
    // width: 100%;
    // margin: 20px;
    // background-color: aqua;
    @media #{$mobileOnly} {
      @include col-center;
    }

    &.grid {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
    }

    .ItemCard__Info__Title {
      font-size: 120%;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.24px;
    }

    .ItemCard__Info__Buttons {
      // width: 100%;
      @include row-start;
      flex-wrap: wrap;

      @media #{$mobileOnly} {
        @include row-evenly;
        //first child margin bottom 15px
      }

      margin-top: 22px;

      .ItemCard__Info__Buttons__Button {
        @include purple-gradient-button;

        @media #{$mobileOnly} {
          margin-bottom: 10px;
        }

        height: 48px;
        width: 150px;

        margin: 0 10px;
      }
    }
  }
}