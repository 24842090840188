@import '../../utils/_variables';

.QuestSelector {
	background: rgba(0, 0, 0, 0.5);
	border: 1px blue;
	border-radius: 8px;
	box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.1);
	color: white;
	font-weight: 900;
	max-width: 24.5vw;
	min-width: 344px;
	height: max-content;
	padding: 0 0 34px 0;
	position: fixed;
	right: 50px;
	top: 168px;

	.quest-rejection-message {
		color: red;
		font-size: 11.5px;
		padding: 0px 25px 25px 25px;
	}

	.quest-goal-message {
		color: rgb(159, 232, 155);
		font-size: 21px;
		padding: 10px 25px 25px 25px;
	}


	@media #{$tabletAndLarger} {
		&.sticky {
			margin: 0 !important;
			position: fixed;
			top: 168px !important;
		}

		&.stop {
			position: absolute;
		}
	}

	&.status {
		margin: 80px 0 0 0;
	}

	@media #{$mobileAndTabletOnly} {
		position: relative;
		min-width: 100%;
		right: 0;
		top: 0;

		&.sticky {
			position: relative;
			right: 0;
			top: 0;
		}

		&.status {
			margin: 0 0 0 0;
		}
	}

	.MuiInput-underline:before {
		border: none !important;
	}


	.MuiInput-root {
		background-color: #202227;
		border: solid 2px #2c3239;
		border-radius: 3px;
		height: 52px;
		width: 100%;
	}

	.title-quest-text {
		@include vw-pixels(('font-size': 30,
			));

		@media #{$mobileOnly} {
			@include vw-pixels(('font-size': 55,
				));
		}

		margin: 0;
		padding: 30px 25px;
	}

	.paymentBar {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		background-image: linear-gradient(to top, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));

		span {
			padding: 0 25px;
			color: white;
			font-weight: 900;
			@include vw-pixels(('font-size': 40,
				));
		}

		.disclaimer {
			text-decoration: underline;

			&:hover {
				cursor: help;
			}
		}
	}

	.selections {
		padding: 0 25px 25px 25px;

		h3 {
			color: $colorGray;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: bold;
			@include vw-pixels(('font-size': 12,
				));
			margin: 25px 0 12px 0;
		}

		.fa {
			margin: 0 15px 0 0;
			@include vw-pixels(("font-size": 10,
				));
		}
	}

	button {
		width: 228px;
		margin: 0 24px;

		&.ineligible {
			cursor: not-allowed;
		}
	}

	.buttonsBarQS {
		display: flex;
		justify-content: space-between;
		width: 90%;
	}

	.shareBtn {
		width: 52px;
		height: 52px;
		border-radius: 4px;
		border: solid 2px #2c3239;
		background-color: #202227;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&:hover {
			box-shadow: inset 0 0 24px 30px rgba(255, 255, 255, .08);
			transition: 0.8s ease;
		}
	}

	a.help-button {
		align-items: center;
		color: $colorLifeGreen;
		display: flex;
		font-weight: normal;
		justify-content: center;
		margin: 60px 0 0 0px;
		position: absolute;
		text-decoration: none;
		@include whiteTextHover;
		width: 100%;

		.fa {
			margin: 0 6px 0 0;
		}

		@media #{$mobileAndTabletOnly} {
			margin: 36px 0 0 0;
			position: initial;
		}
	}
}

.MuiSelect-selectMenu {
	padding-left: 10px !important;
	padding-right: 0px;
}