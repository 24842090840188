@import "../../../utils/variables";

.BrowserBack {
  align-items: center;
  display: flex;
  padding: 3px;

  .text {
    font-size: 17px;
    letter-spacing: 0.38px;
  }

  svg {
    height: 100%;
    width: auto;
  }

  &.Hover {
    &:hover {
      color: $colorCyan;
  
      svg {
        fill: $colorCyan;
      }
    }
  }
}