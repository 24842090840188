@import  '../../../../utils/_variables';

.WhiteList{
	.WhiteList__uploader{
		.WhiteList__uploader--title{
			font-family: Roboto;
			font-size: 22px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: 2.4px;
			color: #ffffff;
			margin: 0 0 10px 0;
			// @include vw-pixels((
			// 	'font-size': 25px
			// ));
		}

		.WhiteList__uploader--instructions{
			font-family: Roboto;
			font-size: 14px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: 22px;
			letter-spacing: 1.2px;
			color: #ffffff;
		}
		.WhiteList__uploader--sample-file{
			font-family: Roboto;
			font-size: 14px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: 22px;
			letter-spacing: 1.2px;
			color: #47d0ca;
		}

		.Dropzone {
			display: inline-flex;
			margin: 20px auto 5px 0;
			.fake-input{
				width: 250px;
		    height: 50px;
		    border: solid 1.5px #ffffff;
		    padding: 10px;
		    display: inline-flex;
		    justify-content: flex-start;
		    align-items: center;
				cursor: pointer;
			}

			button.FilePickButton {
		    height: 50px;
		    border: solid 1.5px #ffffff;
		    padding: 0px 25px;
		    display: inline-flex;
		    justify-content: flex-start;
		    align-items: center;
		    text-transform: uppercase;
		    background: rgba(0,0,0,0);
		    color: #FFF;
		    border-left: none;
		    font-size: 14px;
		    font-weight: 600;
				cursor: pointer;
			}
		}

		.WhiteList__uploader--help{
			font-family: Roboto;
			font-size: 14px;
			font-weight: 300;
			font-style: italic;
			font-stretch: normal;
			line-height: 22px;
			letter-spacing: 1.2px;
			color: #FFF;
			margin: 0 0 10px 0;
		}

		.WhiteList__uploader--btn{
			width: 300px;
			height: 50px;
			border-radius: 30px;
			background: linear-gradient(to right, #45cacd, #47b8d4);
			color: #FFF;
			border: none;
			outline: none;
			font-family: Roboto;
			font-size: 14px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: 22px;
			letter-spacing: 1.2px;
			margin: 20px 0 20px 0;
			cursor: pointer;
			&.disabled{
				cursor: not-allowed;
				opacity: 0.5;
			}
		}
	}
	.WhiteList__crud{
		.WhiteList__crud-header-actions {
	    // font-family: Roboto;
	    // font-size: 22px;
	    // font-weight: bold;
	    // font-style: normal;
	    // font-stretch: normal;
	    // line-height: normal;
	    // letter-spacing: 2px;
	    // color: #ffffff;
	    // margin: 0;
			// background-color: #28292e;
			// padding: 30px;
			display: flex;
			justify-content: center;
			.WhiteList__whitelist--btn{
				width: 300px;
				height: 50px;
				border-radius: 30px;
				background: linear-gradient(to right, #45cacd, #47b8d4);
				color: #FFF;
				border: none;
				outline: none;
				font-family: Roboto;
				font-size: 14px;
				font-weight: 500;
				font-style: normal;
				font-stretch: normal;
				line-height: 22px;
				letter-spacing: 1.2px;
		    margin: 20px 0px 20px 20px;
				cursor: pointer;
				&:first-child{
					margin-left: auto;
				}
				&.disabled{
					cursor: not-allowed;
					opacity: 0.5;
				}
			}
		}
		.ToggleTable {
			.ToggleTable__header {
				background: #2b2e38;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 30px;
				border-bottom: 1px solid #454545;
				// border-top: 1px solid #1d1e23;
				border-top: 1px solid #454545;
				.ToggleTable__header-title {
					padding: 0;
					margin: 0;
	    		font-size: 22px;
					letter-spacing: 2.5px;
				}
				.dot-status{
					width: 10px;
					height: 10px;
					border-radius: 100%;
					background-color: #ef3937;
					box-shadow: 0 0 9px 0 #ff987f;
					margin-left: 10px;
				}
				i{
					font-size: 30px;
					line-height: 18px;
					margin-left: auto;
				}
			}
			.ToggleTable__table-wrapper {
				background: #2b2e38;
				padding: 30px;
				.no-results {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 44px;
					margin-top: 30px;
				}
				.row{
					border-bottom: 1px solid #1e1f25;
					padding: 0 10px;
					&:hover {
						background: rgba(255,255,255,0.025);
						&:first-child {
							background: rgba(255,255,255,0);
						}
					}
					&:last-child{
						border-bottom: none;
					}
					.table-cell{
						justify-content: center;
						&.headline{
							color: #9b9b9b;
							font-weight: 400;
							letter-spacing: 1.2px;
						}
						&:first-child{
							justify-content: flex-start;
						}
						.remove-key {
					    background: rgba(255,255,255,0);
					    border: none;
					    padding: 5px 8px;
					    border-radius: 3px;
					    color: #FFF;
					    letter-spacing: 1px;
					    font-weight: 600;
							text-transform: uppercase;
							cursor: pointer;
					    font-size: 11px;
							&:hover{
								background: rgba(255,255,255,.2);
							}
						}
						.removing-key {
					    background: rgba(255,255,255,0);
					    border: none;
					    padding: 5px 8px;
					    border-radius: 3px;
					    color: #e91e63;
					    letter-spacing: 1px;
					    font-weight: 600;
							text-transform: uppercase;
					    font-size: 11px;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
	.Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: 200ms ease all;
    overflow-x: hidden;
    background: rgba(0,0,0,.5);
    .Modal__layer{
      // background: rgba(0,0,0,0.5);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
    align-items: flex-start;
    @media screen and (min-width: 640px){
      align-items: center;
    }
    .Modal__wrapper{
      width: 90%;
      max-width: 600px;
      background: #272727;
      border-radius: 5px;
      padding: 30px 30px;
      position: relative;
      box-shadow: 0px 1px 5px 0px rgba(66, 177, 212, 0.88);
      border: 1px solid #383838;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: 200ms ease all;
      transform: scale(0.8);
      margin: 5vw 0 5vh 0;
      @media screen and (min-width: 640px){
          margin: 0;
      }
      .icon{
        width: 50px;
        margin: 30px auto 30px auto;
        img{
          width: 100%;
        }
      }
      .Modal__title{
        font-size: 25px;
        letter-spacing: 2px;
        margin: 20px 0px 0px 0px;
      }
      .Modal__close-btn{
        width: 25px;
        height: 25px;
        margin-left: auto;
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer;
        &:before{
          content: "";
          width: 3px;
          height: 90%;
          background: #42b1d4;
          display: block;
          position: absolute;
          left: 50%;
          // top: 50%;
          transform-origin: center;
          transform: rotate(45deg);
        }
        &:after{
          content: "";
          width: 3px;
          height: 90%;
          background: #42b1d4;
          display: block;
          position: absolute;
          left: 50%;
          // top: 50%;
          transform-origin: center;
          transform: rotate(-45deg);
        }
      }
    }
    &.active{
      visibility: visible;
      opacity: 1;
      .Modal__wrapper{
        transform: scale(1);
        transition: 200ms ease all;
      }
    }
  }
}
