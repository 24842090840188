@import "../../../../../utils/_variables";

.DeleteModal__Box {
  @include no-select;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // min-height: 40%;
  // min-width: 40%;

  background-color: $colorDarkGray;
  border-radius: 8px;
  box-shadow: 24;
}

.DeleteModal {
  @include col-center;

  position: relative;
  
  padding: 35px;

  font-size: 16px;

  .DeleteModal__Close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;

    svg {
      height: auto;
      width: 20px;
    }
  }

  .DeleteModal__Title {
    @include center-content;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }


  .DeleteModal__Button {
    @include button-base;
    margin-top: 20px;

    width: 50%;
    height: 40px;
    background-color: $colorBDarkGray;
    color: $colorGray;
    font-size: 16px;
    font-weight: bold;

    cursor: not-allowed;

    &.Active {
      background-color: $colorCandyRed;
      color: $colorWhite;
      cursor: pointer;

      &:hover {
        background-color: $colorCandyRed2;
      }
    }
  }
}
