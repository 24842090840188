@import "../../../../../utils/_variables";

.ProductDetail__Wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;

  .ProductDetailMasthead {
    // display: block;
    position: relative;
    @media #{$mobileAndTabletOnly} {
      // min-height: 800px;
      min-height: 660px;

      padding-bottom: 30px;
      -ms-overflow-style: none; /* IE and Edge */
      overflow-y: auto;
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    min-height: 600px;
    width: 100%;

    background-color: $colorBackground;

    .ProductDetailMasthead__Edit {
      @include hazy-button;

      // background-color: $colorBackground;

      top: 30px;

      position: absolute;
      right: 20px;

      // height: 67px;
      // width: 130px;
      padding: 15px;

      z-index: 99;

      svg {
        height: 21px;
        width: auto;
      }

      .ProductDetailMasthead__Edit__Text {
        margin-left: 7px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }

    .ProductDetailMasthead__Cover {
      width: 100%;

      object-fit: cover;

      -webkit-mask-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
      );
      mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0)
      );
    }

    .ProductDetailMasthead__Fade {
      min-height: fit-content;
      min-width: 100%;

      position: absolute;
      top: 0;

      background-image: linear-gradient(
        to bottom,
        rgba(23, 25, 28, 0),
        rgb(23, 25, 28)
      );
    }

    .ProductDetailMasthead__Details {
      @include row-evenly;

      height: 100%;
      width: 100%;
      position: absolute;

      @media #{$mobileAndTabletOnly} {
        @include col-start;
        padding: 0px 25px;
        top: 0;
        margin-top: 100px;
      }

      @media #{$desktopAndLarger} {
        bottom: 0;
        padding: 70px 25px;
      }

      .ProductDetailMasthead__Details__Left {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        height: 100%;

        @media #{$mobileAndTabletOnly} {
          min-width: none;
          max-width: 300px;
          width: 100%;
          @include col-center;
          margin-bottom: 25px;
          margin-right: 0;
        }

        min-width: 213px;
        max-width: 213px;

        margin-right: 30px;

        .ProductDetailMasthead__Details__Left__Portrait {
          height: auto;
          width: 100%;
          @media #{$mobileAndTabletOnly} {
            // background-size: cover;
            // max-width: 213px;
            max-height: 250px;
          }
          border-radius: 8px;

          object-fit: cover;
        }

        .ProductDetailMasthead__Details__Left__Website {
          @include purple-gradient-button;

          height: 48px;
          width: 100%;

          margin-top: 24px;

          font-size: 16px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
        }
      }

      .ProductDetailMasthead__Details__Right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        height: 100%;
        width: 100%;

        .ProductDetailMasthead__Details__Right__Name {
          font-size: 40px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.4px;
          text-align: left;
        }

        .ProductDetailMasthead__Details__Right__ReleaseDate {
          margin-top: 20px;

          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.24px;
          text-align: left;
        }

        .ProductDetailMasthead__Details__Right__Description {
          margin-top: 20px;

          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.14px;
          text-align: left;
        }

        .ProductDetailMasthead__Details__Right__Developer {
          display: flex;
          align-items: center;

          margin-top: 20px;

          .ProductDetailMasthead__Details__Right__Developer__Logo {
            height: auto;
            width: 55px;

            object-fit: cover;
          }

          .ProductDetailMasthead__Details__Right__Developer__Name {
            margin-left: 15px;

            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.24px;
            text-align: left;
          }
        }
      }
    }
  }
}
