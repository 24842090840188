@import '../../../../utils/_variables';

.SocialNetworks {
  display: flex;
  flex-direction: column;

  width: 100%;

  & > * {
    margin-bottom: 18px;
  }

  .SocialNetworks_Header {
    // color: linear-gradient(to bottom, #7933c6, #cf1798 23%, #fe296f 54%, #fe7e4d);
    font-size: 26px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.26px;
    text-align: left;
  }

  .SocialNetworks_List {
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: 18px;
    }

    .SocialNetworks_Card {
      display: flex;
      align-items: center;

      height: 117px;
      width: 100%;

      padding: 18px;

      background-color: $colorCDarkGray;

      .SocialNetworks_Card_Input {
        width: 100%;

        padding: 10px;

        background: none;
        background-color: $colorCDarkGray;
        border: 2px solid transparent;
        border-radius: 4px;
        box-shadow: none;
        color: $colorWhite;

        &:focus {
          outline: none;
          border: 2px solid $colorCDarkGray;
        }
      }
    }

  }
}