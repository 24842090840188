.ReportTable_Container {
  width: 100%;
  background-color: #16181c;
  border-radius: 5px;
  padding: 15px;
  margin: 1% 0px 0px 0px;
  overflow: scroll;
  scrollbar-width: thin !important;

        scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      
    
    
    &::-webkit-scrollbar {
      display: none;
    }

}
