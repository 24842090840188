@import '../../../utils/_variables';

.CompleteRegistration {
  .Modal__content {
    @media screen and (min-width: 1024px){
    	height: 100%;
			margin-top: 1.61em;
    }
  }
}

label.consoles {
	color: #83858c !important;
	text-align: left;
	font-size: 12px;
	line-height: 12px;
	margin-top: 10px;
	margin-bottom: 10px;
}