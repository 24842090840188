@import  '../../utils/_variables';

@keyframes backgroundOut {
  from {
    background-color: #18191dff;
  }

  to {
    background-color: #18191d00;
  }
}

@keyframes bottomIn {
  from {
    display: none;
  }

  to {
    display: block;
    opacity: 1;
  }
}

@keyframes bottomOut {
  from {
    display: block;
    opacity: 1;
  }

  to {
    display: none;
    opacity: 0;
  }
}

@keyframes mainDownAndOut {
  from {
    display: block;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    display: none;
    opacity: 0;
    transform: translate3d(0, 38%, 0);
  }
}

@keyframes mainUpAndIn {
  from {
    display: none;
  }

  to {
    display: block;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.mobile-menu {
  align-items: center;
  background-color: #18191dff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: left;
  left: 0;
  margin: 0;
  opacity: 1;
  padding-bottom: 25px;
  padding-left: 32px;
  padding-top: 100px;
  position: fixed;
  right: 0;
  top: 0;
  transition: 200ms ease all;
  width: 100%;
  z-index: 5;

  &.fading-out {
    animation-duration: 0.618s;
    animation-fill-mode: forwards;
    animation-name: backgroundOut;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    &.main {
      li {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-name: mainUpAndIn;
        margin-bottom: 10px;
        margin-left: 0;
        opacity: 0;
        position: relative;
        transform: translate3d(0, 38%, 0);
  
        a {
          cursor: pointer;
          font-family: Roboto;
          font-size: 30px;
          font-weight: 300;
          line-height: 1.97;
          margin: 0;
          padding: 0;
          position: relative;
          text-decoration: none;
          transition: 300ms ease background;
          width: 100%;
        }
      }

      &.fading-out {
        li {
          animation-duration: 0.618s;
          animation-name: mainDownAndOut;
        }
      }
    }

    &.bottom {
      bottom: 40px;
      flex-direction: row;
      justify-content: flex-start;
      left: 36px;
      position: absolute;

      li {
        align-items: center;
        animation-duration: 1.61s;
        animation-fill-mode: forwards;
        animation-name: bottomIn;
        height: 48px;
        left: 36px;
        opacity: 0;
        width: 100px;

        a {
          cursor: pointer;
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
          height: 48px;
          text-decoration: none;
          transition: 300ms ease background;
          width: 100%;
        }
      }

      &.auth {
        flex-direction: column;
        left: 0;
        padding: 0 36px;
        width: 100%;
        
        li {
          width: auto;

          a {
            height: 48px;
            line-height: 48px;
          }
        }
      }

      &.fading-out {
        li {
          animation-duration: 0.618s;
          animation-name: bottomOut;
        }
      }
    }
  }
}