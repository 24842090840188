.ReputationModal {

	span.label {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: bold;
	}

	.optionsListBrand {
		margin: 12px 0 18px 0px;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		max-height: 260px;

		span.option {
			cursor: pointer;
			display: flex;
			align-items: center;
			height: 48px;
		  border-radius: 6px;
		  border: solid 1px #2c3239;
		  background-color: #18191d;
		  margin: 0 12px 12px 0;
		  padding: 0 0 0 16px;
		  transition: background 150ms ease-out;
		  text-transform: capitalize;

		  &.selected {
		  	background: #2c3239;
		  	transition: background 150ms ease-out;
		  }
		}

		input[type=checkbox] {
			cursor: pointer;
			margin: 0 8px 0 0;
		}
	}

	span.note {
		color: #83858c;
	}

	textarea {
		margin: 12px 0;
		background: #18191d;
		height: 160px;
	  border-radius: 3px;
	  border: solid 2px #2c3239;

	  &:focus {
			background: #18191d;
		  border-radius: 3px;
		  border: solid 2px #2c3239;
		 	box-shadow: none;
	  }
	}

	button {
		width: 160px;
		height: 44px;
	}

	.buttons.brand-safety {
		margin: 35px 0 0 0;
		display: flex;
		justify-content: space-between;
		float: none !important;
	}
}