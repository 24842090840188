@import '../../../../utils/_variables';

.TopTweets_Container {
  background-image: linear-gradient(to bottom, rgba(87, 97, 107, 0.5), rgba(49, 56, 63, 0.5));
  border-radius: 20px;
  padding: 28px 24px;
  margin: 32px 0 0 0;
  overflow: hidden;

  h2 {
    margin: 0px;
  }

  .tweetsContainer {
    margin: 30px 0 0 0;
    display: flex;
  }

  .TopTweetBox {
    border: 1px solid $colorGray;
    height: 20px;
    width: 50%;
    padding: 23px 17px 16px 17px;
    height: auto;
    display: flex;
    flex-direction: column;

    img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }

    &:nth-of-type(2) {
      margin: 0 0 0 32px;
    }

    span {
      &.topTweetName {
        font-size: 16px;
        font-weight: bold;
        color: white;
      }

      &.topTweetUsername,
      &.topTweetDate {
        font-size: 14px;
        color: $colorGray;
      }

      &.topTweetText {
        font-size: 20px;
        font-weight: 300;
        margin: 10px 0;
      }

    }
  }

  img.tweetProfileImage {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 0 7px 0 0;
  }
}