@import "../../utils/_variables";


.play-games-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    max-height: 30%;
    font-weight: 550;
    text-transform: uppercase;
    @include vw-pixels(
        (
          "font-size": 17,
        )
      );
    margin-bottom: 20px
}


.fading-game-label {
    @include vw-pixels(
        (
          "font-size": 16,
        )
      );
    margin-bottom: 20px;
    min-height: 80px;
    // background-color: rgba($color: #7c7c8352, $alpha: .3);
    padding: 2px 5px;
    border-radius: 10px;
    min-width: 100%;
    > div {
        width: 100%;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}