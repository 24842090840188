@font-face {
  font-family: 'noiz';
  src:  url('fonts/noiz.eot?9tnkiy');
  src:  url('fonts/noiz.eot?9tnkiy#iefix') format('embedded-opentype'),
    url('fonts/noiz.ttf?9tnkiy') format('truetype'),
    url('fonts/noiz.woff?9tnkiy') format('woff'),
    url('fonts/noiz.svg?9tnkiy#noiz') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="noiz-"], [class*=" noiz-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'noiz' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.noiz-thumbs-up:before {
  content: "\e907";
}
.noiz-scroll:before {
  content: "\e906";
}
.noiz-scroll{
  transform: scaleX(-1);
}
.noiz-check-box-checked:before {
  content: "\e908";
}
.noiz-partner:before {
  content: "\e909";
}
.noiz-search:before {
  content: "\e90a";
}
.noiz-video-camera:before {
  content: "\e90b";
}
.noiz-game-controller:before {
  content: "\e905";
}
.noiz-discount:before {
  content: "\e900";
}
.noiz-facebook:before {
  content: "\e901";
}
.noiz-gaming:before {
  content: "\e902";
}
.noiz-key:before {
  content: "\e903";
}
.noiz-user-key:before {
  content: "\e904";
}
