@import  '../../../../utils/_variables';

a {
	color: white;
}

span {
  &.error {
    color: $colorCrimson;
    a {
      color: $colorCrimson;
    }
  }
  &.success {
    color: $colorLifeGreen;
    a {
      color: $colorLifeGreen;
    }
  }
  &.info {
    color: $colorEnergyBlue;
  }
  &.warning {
    color: #ffd300;
    a {
      color: #ffd300;
    }
  }
}

.small-btn {
  background: rgba(255, 255, 255, 0.05);
  border: none;
  padding: 5px 8px;
  border-radius: 3px;
  color: #FFF;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 11px;
  text-decoration: none;
  margin-right: 5px;
  display: flex;
  justify-content: center; 
  align-items: center;
  display: inline-block;
}

.toggleable { 
  display: none; 
}

.NoQuestTiered {
  padding: 30px 40px;
}

.ToggleTable__header-buttons * {
  margin-left: 10px;
}