.APaymentList{
  .Filters__section {
    margin-left: 30px;
    input {
      width: 300px;
    }
  }
  .APaymentList__wrapper{
    margin: 0 auto;
    padding: 30px;
    padding-top: 120px;
    .button-style{
      width: 300px;
      height: 50px;
      border-radius: 30px;
      background: linear-gradient(to right, #45cacd, #47b8d4);
      color: #FFF;
      border: none;
      outline: none;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 22px;
      letter-spacing: 1.2px;
      margin: 20px 0 20px 0;
      cursor: pointer;
    }
    .PaymentList__loading{
      padding-top: 50px;
      text-align: center;
    }

    .APaymentListt__title{
      font-weight: 500;
      letter-spacing: 3.5px;
      text-align: left;
      text-transform: uppercase;
    }
    .APaymentList__actions{
      button{
        @extend .button-style;
      }
    }
    .ToggleTable {
      text-align: center;
      .ToggleTable__header {
        background: #18191d;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
        .ToggleTable__header__left{
          padding: 0;
          margin: 0;
          font-size: 22px;
          letter-spacing: 2.5px;
          button{
            margin-right: 4px;
            border-radius: 4px;
            padding: 5px 15px;
            background: #202227;
            color: #FFF;
            border: none;
            outline: none;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 22px;
            letter-spacing: 1.2px;
            cursor: pointer;
            margin-left: auto;
            &:hover{
              background: rgba(255, 255, 255, 0.2);
            }
          }
          i{
            margin-left: auto;
          }
        }
        .ToggleTable__header__right{
          margin-left: auto;
          button{
            margin-right: 4px;
            border-radius: 4px;
            padding: 5px 15px;
            background: #202227;
            color: #FFF;
            border: none;
            outline: none;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 22px;
            letter-spacing: 1.2px;
            cursor: pointer;
            margin-left: auto;
            &:hover{
              background: rgba(255, 255, 255, 0.2);
            }
          }
          i{
            margin-left: auto;
          }
        }
      }
      .ToggleTable__table-wrapper {
        background: #202227;
        padding: 30px;
        min-height: 686px;
        .no-results {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 44px;
          margin-top: 30px;
        }
        .row{
          border-bottom: 1px solid rgba(254, 254, 254, 0.1);
          padding: 0 10px;
          &:hover {
            background: rgba(255,255,255,0.025);
            &:first-child {
              background: rgba(255,255,255,0);
            }
          }
          &:last-child{
            border-bottom: none;
          }
          .table-cell{
            justify-content: center;
            &.headline{
              color: #9b9b9b;
              font-weight: 400;
              letter-spacing: 1.2px;
            }
            &.headline-clickable{
              cursor: pointer;
            }
            &:first-child{
              justify-content: flex-start;
              text-align: left;
            }
          }
        }
      }
      .ToggleTable__footer {
        background: #202227;
        height: 100px;
        button{
          cursor: pointer;
          i{
            font-size: 60px;
            color: white;
          }
        }
        .ToggleTable__footer__left{
          float: left;
          padding: 30px;
          width: 50%;
        }
        .ToggleTable__footer__right{
          float: right;
          text-align: right;
          padding: 30px;
          width: 50%;
        }
      }
    }
  }
}

.NoPayments {
  margin-top: 20px; 
}

i {
  &.green {
    color: #49e1ca;
  }
  &.red {
    color: #E23645;
  }
}

.date__section {
  text-align: left;
  font-size: 10px;

  .date__picker {
    display: flex;
    width: 175px !important;
    color: white;
    background: #202228;
  }
}
