@import  '../../../utils/_variables';

.AdminDashboard{
	// background: #17181c;
	padding: 2% 6%;
	.content-block-header{
		font-weight: 500;
		letter-spacing: normal;
		text-align: left;
		@include vw-pixels((
			'font-size': 30
		), 1.5);
	}
	.content-block{
		background: #28292e;
		margin-bottom: 40px;
    padding: 30px;
    margin-top: 20px;
	}
	.AdminDashboard__current{
		text-align: center;
		h1{
			margin-bottom: 0;
			@include vw-pixels((
				'font-size': 48
			));
		}
		label{
			font-weight: 300;
			-webkit-font-smoothing: antialiased;
			@include vw-pixels((
				'font-size': 18
			), 1.6);
		}
	}
	.AdminDashboard__estimated{
		.content-block-header{
			label{
				display: block;
				font-size: 50%;
				-webkit-font-smoothing: antialiased;
			}
			letter-spacing: normal;
			@include vw-pixels((
				'font-size': 45
			));
		}
		h4{
			font-size: 14px;
			margin-top: 0;
			font-weight: 300;
			strong{
				font-weight: 700;
			}
		}
	}
	.AdminDashboard__estimated__table{
		.table-cell{
			background: #424242;
			font-size: 14px;
			justify-content: center;
		}
		&.secure{
			margin-bottom: 40px;
		}
		.row{
			.table-cell{
				flex-grow: 2;
				&:first-child{
					flex-grow: 1;
				}
				&:last-child{
					flex-grow: 1;
				}
			}
		}
		.headline{
			background: #834cc4;
		}
	}
}
