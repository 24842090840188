@import '../../../utils/_variables';

.PortalWrapper {
  align-items: center;
  display: flex; 
  height: auto;
  justify-content: center;
  width: 100vw;

  a {
    text-decoration: none;
  }
  
  .PortalClass {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    margin-top: 157px;
    // max width makes it less hellacious styling for macro screens
    max-width: 1800px;
    width: 88%;

    @media #{$mobileOnly} {
      @include col-start;
      padding: 0 5vw;
      width: 100%;
    }
  }
}
