@import "../../../utils/variables.scss";
@import "../../../utils/util.scss";

.off-platform-talent-container {
  padding: 20px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: violet;
}

.off-platform-actions-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.instructions-button {
  color: white;
  padding: 10px 10px;
  margin: 10px 10px 10px 10px;
  font-weight: 500;
  align-self: flex-end;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: rgba(122, 160, 218, 0.319);
  }
  border: 1.5px white solid;
  transition: background-color 0.2s;
}

.offplatform-button-group {
  width: 100%;
  max-width: 30%;
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
  min-width: 400px;
  @media #{$mobileOnly} {
    // margin-left: 20px;
    min-width: 100%;
  }
}

.latest-added-user {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  justify-content: space-evenly;
  height: 30%;
  max-height: 700px;
  //    border: 1px white solid;
  border-radius: 4px;
  min-width: 500px;
  @media #{$mobileOnly} {
    // margin-left: 20px;
    min-width: 100% !important;
  }
  div {
    display: flex;
    flex-direction: row;
    //    flex-basis: max-content;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(60, 60, 60, 0.319);
    margin: 1.5px;
    padding: 1.5px;
  }
}

.off-platform-form-container {
  .contact-form-label {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
  }

  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1200px;
  //  padding: 0 10 0 10;
  @media #{$mobileOnly} {
    // margin-left: 20px;
    min-width: 100% !important;
  }

  .MuiSelect-root {
    border: none !important;
    border-radius: 0 !important;
  }

  .MuiSelect-icon {
    margin-right: 5px;
  }
}
