@import '../../utils/_variables';

.ConnectAccounts {
	font-family: Roboto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 100%;
	min-height: 100vh;
	background-image: url("../images/bg.png");
	padding-top: 120px;
	.ModalMaster {
		.Modal {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
			display: flex;
			align-items: center;
			justify-content: center;
			visibility: hidden;
			opacity: 0;
			//    transition: 200ms ease all;
			overflow-x: hidden;
			background: rgba(0,0,0,.5);

			.Modal__layer {
				// background: rgba(0,0,0,0.5);
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				cursor: pointer;
			}
			align-items: flex-start;
			@media screen and (min-width: 640px) {
				align-items: center;
			}

			.Modal__wrapper {
				width: 90%;
				max-width: 1000px;
				height: 600px;
				max-height: 700px;
				background: #202227;
				//border-radius: 5px;
				padding: 0 30px 0 0;
				position: relative;
				//box-shadow: 0px 1px 5px 0px rgba(66, 177, 212, 0.88);
				//border: 1px solid #383838;
				text-align: center;
				//display: flex;
				//flex-direction: column;
				align-items: center;
				justify-content: center;
				//transition: 200ms ease all;
				transform: scale(0.8);
				margin: 5vw 0 5vh;
				@media screen and (min-width: 640px) {
					margin: 0;
				}

				@media screen and (max-width: 640px) {
					max-height:2000px !important;
					padding-bottom:50px !important;
					height:auto !important;
				}

				.icon {
					width: 50px;
					margin: 30px auto;

					img {
						width: 100%;
					}
				}

				.Modal__title {
					font-size: 25px;
					letter-spacing: 2px;
					margin: 20px 0 0;
				}

				.Modal__close-btn {
					width: 35px;
					height: 35px;
					margin-left: auto;
					position: absolute;
					right: 30px;
					top: 30px;
					cursor: pointer;

					&:before {
						content: "";
						width: 3px;
						height: 90%;
						background: #37393D;
						display: block;
						position: absolute;
						left: 50%;
						// top: 50%;
						transform-origin: center;
						transform: rotate(45deg);
					}

					&:after {
						content: "";
						width: 3px;
						height: 90%;
						background: #37393D;
						display: block;
						position: absolute;
						left: 50%;
						// top: 50%;
						transform-origin: center;
						transform: rotate(-45deg);
					}
				}

				.form__message {
					color: #83858c;
					font-size: 12px;
					margin-top: 15px;
					text-align: left;
				}

				.form__footer {
					padding-top: 60px;

					.form__footer__left {
						padding-left: 0;
						text-align: left;

						.cta-button {
							background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
							color: #fff;
							cursor: pointer;
							text-decoration: none;
							text-align: center;
							font-weight: normal;
							border-radius: 4px;
							font-weight: 900;
							font-size: 16px;
							padding: 20px 40px;
						}
					}

					.form__footer__right {
						font-size: 12px;
						padding-top: 10px;

						a {
							color: #3a74e0;
							font-weight: 600;
						}
					}

					.form__footer__right__2 {
						font-size: 12px;
						padding-top: 20px;

						button {
							color: #3a74e0;
							cursor: pointer;
							font-weight: 600;
						}
					}
				}

				.form__footer__2 {
					width: 40%;
				}

				.Modal__forgot {
					width: 50%;
					float: right;
					font-size: 12px;
					margin-top: -35px;
					margin-right: 30px;

					button {
						color: #3a74e0;
						cursor: pointer;
						font-weight: 600;
					}
				}

				.img-left {
					float: left;
					width: auto; // to maintain aspect ratio. You can use 100% if you don't care about that
					height: 100%;
				}


				.errors-container {
					display: flex;
					color: #dc4f4f;
					font-size: 13px;
					text-align: left;
					padding: 0px 25px 0px 10px;
					border-radius: 1px;
					border: 1px solid #dc4f4f;
					position: relative;
					margin: 0;
					background: rgba(255, 255, 255, 0.01);
					align-items: center;
					visibility: hidden;
					opacity: 0;
					max-height: 0;
					overflow: hidden;
					transition: ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms padding, ease 300ms margin;
					&.active{
						visibility: visible;
						opacity: 1;
						max-height: inherit;
						min-height: 35px;
						padding: 5px 25px 5px 10px;
						margin: 0 0 30px 0;
					}
					&:hover{
						background: rgba(255, 255, 255, 0.03)
					}
					.error-dismiss {
						position: absolute;
						width: 18px;
						height: 18px;
						border-radius: 100%;
						top: 8px;
						right: 10px;
						background: rgba(220, 79, 79, 0.22);
						cursor: pointer;
						outline: none;
						transition: ease 200ms all;
						&::before{
							content: '';
							height: 1px;
							width: 10px;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%) rotate(-45deg);
							background: rgba(255, 255, 255, 0.5);
							transition: ease 200ms all;
						}
						&::after{
							content: '';
							height: 1px;
							width: 10px;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%) rotate(45deg);
							background: rgba(255, 255, 255, 0.5);
							transition: ease 200ms all;
						}
						&:hover{
							background: rgba(220, 79, 79, 0.5);
							&::before{
								background: rgba(255, 255, 255, 0.7);
							}
							&::after{
								background: rgba(255, 255, 255, 0.7);
							}
						}
					}
				}
			}

			&.active {
				visibility: visible;
				opacity: 1;

				.Modal__wrapper {
					transform: scale(1);
					//transition: 200ms ease all;
				}
			}
		}

		.Modal__welcome {
			a,
			button {
				color: #3a74e0;
				cursor: pointer;
				font-weight: 700;
				text-decoration: none;
				@media screen and (max-width: 1023px) {
					&.desktop{
						display:none !important;
					}
				}
				@media screen and (min-width: 1024px) {
					&.mobile{
						display:none !important;
					}
				}

			}

			.Modal__wrapper {
				padding-right: 12%;
				padding-left: 12%;
				max-height: 650px;

				.Welcome__header {
					margin: 70px 0 50px 0;
					h1 {
						font-size: 40px;
						margin-bottom: 5px;
					}
				}

				.Welcome__footer {
					margin-top: 60px;
					display: flex;
					align-items: center;
					justify-content: center;

					.Welcome__footer__left {
						display: inline-flex;
						text-align: left;
						font-size: 14px;
						width: 50%;
						vertical-align: middle;
						align-items: center;

						@media screen and (max-width: 640px) {
							width:0 !important;
						}
					}

					.Welcome__footer__right {
						@media screen and (max-width: 640px) {
							width:100% !important;
						}
						display: inline-flex;
						justify-content: flex-end;
						align-items: center;
						width: 50%;

						.Welcome__footer__right__text {
							color: #83858c;
							font-size: 12px;
							width: 50%;
					    text-align: right;
						}

						.Welcome__footer__right__button {
							.not_connected{
								&:hover{
									cursor:initial !important;
								}
								background-color:rgb(46, 47, 50) !important;
								color:#83858c;
								border-radius:4px;
							}
							button,
							a {
								display: block;
								background-color: #3A74E0;
								color: white;
								cursor: pointer;
								text-decoration: none;
								text-align: center;
								font-weight: normal;
								border-radius: 4px;
								font-weight: 900;
								font-size: 16px;
								padding: 10px 30px;
								margin-left: 20px;
								line-height: 24px;
								&.disabled {
									cursor: disabled;
									background: #2e2f32;
									color: $colorGray;
								}
							}

							a.logout{
								padding: 10px 0 10px 30px;
								display: none;
								background-color: transparent !important;
								text-decoration: underline;
								text-align: right;
								font-weight: normal;
								font-size: 14px;
								margin-left: 20px;
								display:block;
								color: rgba(255, 255, 255, 0.3);
								&:hover {
									cursor: pointer !important;
								}
							}
						}
					}
				}
			}
		}
	}
}
