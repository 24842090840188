@import "../../../../../utils/_variables";


.BrandArbitration_ModalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding:10px;
  // min-height: 40%;
  // min-width: 40%;

  background-color: $colorDarkGray;
  border-radius: 8px;
  box-shadow: 24;
}

.BrandArbitration_Deliverables {
  // max-width: 50%;
  // max-height: 50%;
}

.BrandArbitration_AgreementText {
 @include row-center;
 margin: 15px 0px 5px 0px;
}


.BrandArbitration_Comments {
  width: 100%;
  @include col-center;
  margin-bottom: 10px;

  textarea {
    min-width: 80%;
    min-height: 100px;
    background-color: #141415;
    padding: 5px !important;
    border: 2px solid #83858C;
    border-radius: 0px;
    color: white;
    display: inline-block;
    padding-left: 0;
    text-align: left;
    margin: 0;
    overflow: hidden;
    box-shadow: none;
  }

  .BrandArbitration_RejectButton {
    @include button-base;
    margin-top: 20px;

    width: 50%;
    height: 40px;
    background-color: $colorBDarkGray;
    color: $colorGray;
    font-size: 16px;
    align-self: center;
    font-weight: bold;

    cursor: not-allowed;

    &.Active {
      background-color: $colorCandyRed;
      color: $colorWhite;
      cursor: pointer;

   

      &:hover {
        background-color: $colorCandyRed2;

      }

      &.green {
        background-color: $colorCandyGreen;
        color: $colorBlack;
        &:hover {
          background-color: $colorCandyGreen2;
          color: $colorGrayOffset;
        }

     
      }
      &.disabled {
        color: $colorGray;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
  .BrandArbitration_ErrorList{
    color: $colorCandyRed;
    font-size: 12px;
    margin-top: 5px;
  }
}