@import "../../utils/_variables";

.quest-agreement-title {
  font-size: clamp(15px, 1.4vw, 24px);
}

.payment-confirmation-warning {
  // make the font red and italic and make it not displace an elements
  font-style: italic;
  color: red;
  font-size: 12px;
  margin-bottom: 15px;
  min-height: 30px;

}

.payment-confirmation-input {
  // remove input box shadow
  background-color: $colorGray;
  padding: 10px;
  // width: auto;
  align-self: center;
  &:focus {
    background-color: $colorDarkGray;
    box-shadow: none !important;
  }
  box-shadow: none !important;
  outline: none;
  color: white;

 
  -webkit-appearance: none;
  font-size: 19px;
  -moz-appearance: none;
  appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 25px !important;
  // width: fit-content;
  border-right: none;
  transition: background-color 0.1s ease-in-out;
}



.QuestModal {
  background: #202227;
  width: 80%;
  max-width: 760px;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 48px 48px 36px 48px;
  z-index: 100;
  top: 10%;

  &.open {
    display: flex;
    transition: all 0.4s ease;
  }

  .question {
    display: none;

    &.active {
      display: flex;
      flex-direction: column;
    }
  }

  .questAgreement {
    display: none;

    &.active {
      display: flex;
      flex-direction: column;
    }
  }

  h1 {
    text-align: center;
    margin: 0 0 40px 0;
  }

  textarea {
    margin: 10px 0 48px 0;
    border-radius: 3px;
    border: solid 2px #2c3239;
    background-color: #202227;
    padding: 17px;
    min-height: 120px;
  }

  .agreementCopy {
    max-height: 284px;
    border: solid 2px #2c3239;
    background-color: #202227;
    overflow-y: scroll;

    section.QuestParticipationAgreement {
      padding-top: 10px;
    }
  }

  .buttonsBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 0 32px 0;

    @media #{$mobileOnly} {
      flex-direction: column;
    }

    button {
      min-width: 200px;
      @media #{$mobileOnly} {
        margin-bottom: 20px;
        min-width: 100px;
      }
    }
  }

  .indicators {
    // margin: auto;

    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span.indicator {
      border-radius: 50%;
      min-width: 6px;
      background-color: beige;
      height: 6px;
      margin: 0 6px;
      background-color: rgba(255, 255, 255, 0.25);
      -moz-transition: all 0.25s ease-in;
      -o-transition: all 0.25s ease-in;
      width: auto;
      -webkit-transition: all 0.25s ease-in;
      transition: all 0.15s ease-in;

      &.active {
        min-width: 8px;
        height: 8px;
        background-color: $colorPurpleRay;
      }
    }
  }

  @media #{$mobileOnly} {
    width: 100%;
  }
}

.checkbox-container-questmodal {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 10px 0px;
}

.quest-modal-closer {
  font-size: 30px;
  font-weight: 800;
  display: flex;

  align-items: flex-end;
  justify-content: flex-end;
  height: 0px;

  div {
    // background-color: rgb(179, 0, 255);
    // width: 10%;
    text-align: center;
    cursor: pointer;
    transform: translateX(140%);
    @media #{$mobileOnly} {
      transform: translateX(35px);
    }
  }
}
