// import variables
@import "../../../../../utils/_variables";

.QuestCampaignToggle_Wrapper {
  @include row-evenly;
  width: 100%;
  max-width: 250px;
  margin: 10px 0px 10px 0px;

  input[type="radio"] {
    color: purple;

  }

  // label {
  
  // }

  // label:hover {
  // }

  // input[type="radio"]:focus + label {
  // }

  // input[type="radio"]:checked + label {

  // }

  :nth-child(3) {
    margin-left: 20px;
  }
}
