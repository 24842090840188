@import '../../utils/_variables';

.noiz-footer {
  align-items: flex-start;
  background-image: linear-gradient(to bottom, rgb(24, 25, 29), rgb(0, 0, 0));
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 70px 5vw 0 5vw;
  position: relative;

  &.on-quest-page {
    background-image: linear-gradient(to bottom, rgba(24, 25, 29, 0), rgba(0, 0, 0, 1));
  }

  @media #{$tabletAndLarger} {
    padding: 70px 5vw 40px 5vw;
  }

  .inner-container {
    width: 100%;

    &.constrain {
      margin: 0 auto;
      max-width: $macroScreen;
    }

    .top-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 auto;
      padding-bottom: 37px;
      width: 100%;

      @media (min-width: 500px) and (max-width: 768px) {
        margin-left: 15%;
      }

      @media #{$tabletAndLarger} {
        flex-direction: row;
        margin: 0;
        padding-bottom: 132px;
      }

      .logo {
        align-self: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 40px;
        margin: 0 30px 32px 0;
        width: 107px;
      }

      .links-container {
        color: #ffffff;
        display: flex;
        font-family: Roboto;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        // height: 100px;
        justify-content: space-between;
        letter-spacing: normal;
        line-height: 2.29;
        margin: 1.5% 10% 0 auto;
        padding-bottom: 40px;
        width: 244px;

        @media #{$mobileOnly} {
          margin: 23px 0 0 0;
        }

        a {
          display: block;
          text-decoration: none;
        }

        .links-left {
          // display: inline-block;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          height: 100px;
          width: 150px;
          margin-right: 20px;
        }

        .links-right {
          display: inline-block;
          height: 100px;
          width: 100px;
        }
      }

      .social {
        display: flex;
        justify-content: space-between;
        margin: 25px 0 0 0;
        width: 164px;

        .icon {
          height: 28px;
          width: 28px;
        }
      }
    }

    .bottom-container {
      align-items: center;
      border-top: solid 1px rgba(255, 255, 255, 0.1);
      color: #83858c;
      display: flex;
      flex-direction: column;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      height: 110px;
      letter-spacing: normal;
      justify-content: space-between;
      padding: 15px 0 29px;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      width: 100%;
      z-index: 5;

      .copyright {
        line-height: 1.33;
      }

      nav {
        display: flex;
        justify-content: space-between;
        width: 222px;

        a {
          color: #83858c;
          font-size: 12px;
        }
      }

      @media #{$tabletAndLarger} {
        align-items: flex-start;
        flex-direction: row;
        padding: 28px 0 29px;
      }
    }
  }
}