.Main {
  background-color: #18191d;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;

  .Main_Container {
    margin-bottom: auto;
    min-height: 100vh;
  }
}