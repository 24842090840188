@import '../../utils/_variables';

.Quest__step--details-description {
  a {
    color: #83858c !important;
  }
}

.Game {
  padding: 0 5vw;

  .Game__background {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;

    figure {
      margin: 0;
      width: 100%;
      height: 100vh;
      background-position: center center;
      background-size: cover;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;

      background: linear-gradient(to bottom, #18191d 0%, rgba(24, 25, 29, .20) 35%, rgba(21, 11, 4, 0.9) 75%, #18191d 100%);
    }
  }

  .Game__container {
    transform: translateX(1px);
    padding-top: 110px;

    .GameInfo {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      max-width: 1024px;
      margin: 0 auto;
      padding: 60px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      flex-wrap: wrap;
      position: relative;

      @media screen and (max-width: 767px) {
        padding: 30px 0 0 0 !important;
      }

      .GameInfo__image {
        width: 170px;
        margin: 0 20px 0 0;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media screen and (max-width: 767px) {
          margin: auto !important;
        }

        &:before {
          content: "";
          margin-top: 145%;
          display: block;
        }

        .corner-top-right {
          position: absolute;
          width: 100%;
          height: 100%;
          transition: 400ms ease all;

          &:after {
            content: "";
            display: block;
            height: 50px;
            width: 4px;
            top: -4px;
            right: -4px;
            position: absolute;
            transition: 400ms ease all;
            background: $colorCyan;
          }

          &:before {
            content: "";
            display: block;
            height: 4px;
            width: 50px;
            top: -4px;
            right: -4px;
            position: absolute;
            transition: 400ms ease all;
            background: $colorCyan;
          }
        }

        .corner-bottom-left {
          position: absolute;
          width: 100%;
          height: 100%;
          transition: 400ms ease all;

          &:after {
            content: "";
            display: block;
            height: 50px;
            width: 4px;
            bottom: -4px;
            left: -4px;
            position: absolute;
            transition: 400ms ease all;
            background: $colorCyan;
          }

          &:before {
            content: "";
            display: block;
            height: 4px;
            width: 50px;
            bottom: -4px;
            left: -4px;
            position: absolute;
            transition: 400ms ease all;
            background: $colorCyan;
          }
        }
      }

      .GameInfo__details {
        width: calc(100% - 190px);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: calc(100% - 120px);
        position: absolute;
        top: 60px;
        left: 190px;

        @media screen and (max-width: 767px) {
          position: initial !important;
          width: 100% !important;
          padding: 50px;

          .GameInfo__details--title {
            font-size: 20px !important;
          }

          .GameInfo__details--bottom {
            margin-top: 0 !important;
            flex-direction: column !important;

            .GameInfo__tags {
              margin-left: 0 !important;
              margin-top: 10px;
              line-height: 15px !important;
            }

            .GameInfo__ctas {
              margin-left: 0 !important;
              flex-direction: column-reverse !important;
              margin-top: 30px;

              .GameInfo__ctas--social {
                left: 120px;
                height: 43px;
                top: 44px !important;
                display: flex;
                flex-direction: row;
                align-items: center;

                a {
                  color: #fff;
                  text-decoration: none;
                  display: inline-block;
                  margin-right: 10px;
                }
              }
            }
          }
        }

        .GameInfo__details--title {
          font-size: 50px;
          line-height: 50px;
          font-weight: 800;
          font-family: "Roboto";
          margin: 0;
        }

        .GameInfo__details--bottom {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 20px;

          .GameInfo__details--description {
            color: #FFF;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: .5px;
            max-width: 50%;
            font-weight: 400;
            margin: 0 20px 0 0;

            @media #{$mobileOnly} {
              max-width: 100%;
            }
          }

          .GameInfo__tags {
            margin-left: 20px;
            font-size: 12px;
            letter-spacing: 0.8px;
            color: #FFF;
            line-height: 22px;
            font-weight: 600;
            color: #83858c;

            @media screen and (min-width: 1024px) {
              letter-spacing: initial;
            }

            .tag {
              color: #FFF;
              text-decoration: none;
              font-weight: 400;

              &:first-child:before {
                content: " ";
              }

              &:after {
                content: ", ";
                color: #FFF;
              }

              &:last-child:after {
                content: '';
              }
            }
          }

          .GameInfo__ctas {
            margin-left: auto;
            display: flex;
            position: relative;
            min-width: 150px;

            .GameInfo__ctas--social {
              a {
                color: #FFF;
                text-decoration: none;
                display: inline-block;
                margin-right: 10px;
              }
            }

            .GameInfo__ctas--pre-order {
              display: inline-block;
              background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
              color: #fff;
              cursor: pointer;
              text-decoration: none;
              text-align: center;
              font-weight: normal;
              border-radius: 2px;
              font-weight: 700;
              font-size: 13px;
              padding: 12px 22px;
            }
          }
        }
      }
    }

    .Games__primary {
      .Games__primary--wrapper {
        max-width: 1024px;
        min-height: 386px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        margin-bottom: 30px;

        .Games__primary--tabs {
          margin-top: 60px;

          @media screen and (max-width: 767px) {
            padding: 0 50px;
            margin-top: 30px !important;

            ul {
              li {
                button {
                  font-size: 16px !important;
                }
              }
            }
          }

          ul {
            margin-top: 0;
            margin-bottom: 30px;
            padding: 0;
            text-align: left;

            li {
              display: inline-block;
              font-size: 30px;
              color: #36373B;
              padding-right: 30px;

              button {
                color: white;
                display: inline-block;
                background: initial;
                outline: none;
                padding: initial;
                text-transform: inherit;
                transition: 300ms ease background;
                position: relative;
                cursor: pointer;
                z-index: 1;
                font-weight: 900;
                text-decoration: none;

                &:after {
                  content: "";
                  display: block;
                  width: 0%;
                  position: absolute;
                  bottom: 0px;
                  height: 8px;
                  z-index: -1;
                  transition: 200ms ease all;
                }

                &:hover:after,
                &.active:after {
                  background: #49E1CA;
                  z-index: -1;
                  width: 100%;

                  transition: 200ms ease all;
                }
              }
            }

            li a.active {
              color: white;
            }

            li a:hover {
              color: white;
            }
          }
        }

        .PrimaryQuestsList {
          opacity: 0;
          visibility: hidden;
          transition: 500ms ease all;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          max-height: 0;
          justify-content: flex-start;
          align-items: flex-start;
          transition: ease 300ms transform, ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms max-height, ease 300ms padding, ease 300ms margin;
          transform: translateY(50px);

          @media screen and (max-width: 767px) {
            flex-direction: column !important;

            .QuestCard {
              width: 100% !important;
              padding: 0 50px;
            }
          }

          &.active {
            visibility: visible;
            opacity: 1;
            max-height: inherit;
            min-height: 35px;
            transform: translateY(0px);
          }

          .QuestCard {
            text-decoration: none;
            display: inline-flex;
            position: relative;
            width: calc(33.333% - 20px);
            margin-right: calc(20px + (20px / 2));
            margin-bottom: 20px;
            cursor: pointer;
            flex-direction: column;

            &:nth-child(3n) {
              margin-right: 0px;
            }

            .QuestCard__wrapper {
              position: relative;
              overflow: hidden;

              .QuestCard__image {
                display: flex;
                width: 100%;
                position: relative;

                &:before {
                  content: '';
                  padding-top: 56.25%;
                  display: block;
                }

                figure {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  top: 0;
                  left: 0;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                }
              }

              .QuestCard__details {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: -30px;

                .QuestCard__background-top {
                  position: absolute;
                  width: 100%;
                  height: 30px;
                  top: 0;
                  background-color: #212227;
                }

                .QuestCard__background-bottom {
                  position: absolute;
                  width: 100%;
                  height: calc(100% - 30px);
                  bottom: 0;
                  background-color: #212227;
                }

                .QuestCard__details-wrapper {
                  display: flex;
                  flex-direction: column;
                  z-index: 1;

                  .QuestCard__details--row {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    margin: 0 20px;

                    &:nth-child(1) {
                      margin-top: 15px;
                    }

                    .QuestCard__details--icon {
                      color: #FFF;
                      margin-right: 10px;
                      font-size: 20px;
                      color: $colorGray;
                    }

                    .QuestCard__details--info {
                      width: 100%;

                      .primary-info {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 100%;
                        margin-right: 17%;

                        .QuestCard__details--name {
                          font-weight: 700;
                          color: #FFF;
                          font-family: "Roboto";
                          line-height: 20px;
                          text-transform: capitalize;
                          font-size: 15px;
                          margin-bottom: 5px;
                        }
                      }

                      // Second Row
                      .sub-info {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 100%;
                        margin-top: 2px;

                        .QuestCard__details--game-name {
                          font-size: 10px;
                          color: $colorGray;
                          font-family: "Roboto";
                        }

                        .QuestCard__details--date-interval {
                          margin-left: auto;
                          font-size: 12px;
                          color: $colorGray;
                          font-family: "Roboto";
                        }
                      }
                    }

                    .SeeQuest {
                      background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
                      color: #fff;
                      cursor: pointer;
                      text-decoration: none;
                      text-align: center;
                      font-weight: normal;
                      border-radius: 2px;
                      font-weight: 700;
                      font-size: 13px;
                      width: 100%;
                      padding: 10px 0;
                      margin: 10px 0;

                      &.disabled {
                        cursor: disabled;
                        background: #2e2f32;
                        color: $colorGray;
                      }
                    }

                    .QuestCard__details--open-slots {
                      margin-bottom: 15px;
                      width: 100%;
                      color: $colorGray;
                      text-align: center;
                      font-size: 12px;
                    }
                  }
                }
              }

              .QuestCard__level {
                position: absolute;
                top: 10px;
                right: 10px;
                background-size: contain;
                background-repeat: no-repeat;
                width: 32px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;

                .QuestCard__level--wrapper {
                  position: relative;
                  width: 100%;
                  height: 100%;
                }

                .QuestCard__level--count {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  font-weight: bold;
                  font-size: 10px;
                  line-height: 11px;
                  min-width: 10px;
                  text-align: center;
                }
              }

              .QuestCard__info-panel {
                position: absolute;
                bottom: -90%;
                right: -90%;
                height: 90%;
                width: 90%;
                padding: 20px;
                background-color: #18191D;
                transition: 300ms ease all;
                z-index: 2;
                display: flex;
                flex-direction: column;

                .QuestCard__info-panel--title {
                  color: #4BE0CA;
                  font-size: 15px;
                  font-weight: 700;
                  padding-bottom: 10px;
                  margin: 0;
                }

                .QuestCard__info-panel--details {
                  color: #FFF;
                  font-size: 13px;
                  margin: 0;
                }

                .QuestCard__info-panel--link {
                  color: #4BE0CA;
                  font-size: 14px;
                  margin: 0;
                }
              }
            }

            &:hover .QuestCard__wrapper .QuestCard__info-panel {
              height: 90%;
              width: 90%;
              bottom: 0;
              right: 0;
              -webkit-transition: 300ms ease all;
              -o-transition: 300ms ease all;
              transition: 300ms ease all;
            }

            .QuestCard__corner__1 {
              position: absolute;
              width: 100%;
              height: 100%;
              transition: 400ms ease all;

              &:after {
                content: "";
                display: block;
                height: 15%;
                width: 4px;
                bottom: -4px;
                left: -4px;
                position: absolute;
                transition: 400ms ease all;
              }

              &:before {
                content: "";
                display: block;
                height: 4px;
                width: 15%;
                bottom: -4px;
                left: -4px;
                position: absolute;
                transition: 400ms ease all;
              }
            }

            .QuestCard__corner__2 {
              position: absolute;
              width: 100%;
              height: 100%;

              &:after {
                content: "";
                display: block;
                height: 15%;
                width: 4px;
                top: -4px;
                left: -4px;
                position: absolute;
              }

              &:before {
                content: "";
                display: block;
                height: 4px;
                width: 15%;
                top: -4px;
                left: -4px;
                position: absolute;
              }
            }

            .QuestCard__corner__3 {
              position: absolute;
              width: 100%;
              height: 100%;

              &:after {
                content: "";
                display: block;
                height: 15%;
                width: 4px;
                bottom: -4px;
                right: -4px;
                position: absolute;
              }

              &:before {
                content: "";
                display: block;
                height: 4px;
                width: 15%;
                bottom: -4px;
                right: -4px;
                position: absolute;
              }
            }

            .QuestCard__corner__4 {
              position: absolute;
              width: 100%;
              height: 100%;

              &:after {
                content: "";
                display: block;
                height: 15%;
                width: 4px;
                top: -4px;
                right: -4px;
                position: absolute;
              }

              &:before {
                content: "";
                display: block;
                height: 4px;
                width: 15%;
                top: -4px;
                right: -4px;
                position: absolute;
              }
            }

            &:hover {
              .QuestCard__corner__1 {
                &:after {
                  background: #fd8d82;
                }

                &:before {
                  background: #fd8d82;
                }
              }

              .QuestCard__corner__2 {
                &:after {
                  background: #fd8d82;
                }

                &:before {
                  background: #fd8d82;
                }
              }

              .QuestCard__corner__3 {
                &:after {
                  background: #fd8d82;
                }

                &:before {
                  background: #fd8d82;
                }
              }

              .QuestCard__corner__4 {
                &:after {
                  background: #fd8d82;
                }

                &:before {
                  background: #fd8d82;
                }
              }
            }

            &.active {
              .QuestCard__corner__1 {
                &:after {
                  background: #FC8D82;
                }

                &:before {
                  background: #FC8D82;
                }
              }

              .QuestCard__corner__2 {
                &:after {
                  background: #FC8D82;
                }

                &:before {
                  background: #FC8D82;
                }
              }

              .QuestCard__corner__3 {
                &:after {
                  background: #FC8D82;
                }

                &:before {
                  background: #FC8D82;
                }
              }

              .QuestCard__corner__4 {
                &:after {
                  background: #FC8D82;
                }

                &:before {
                  background: #FC8D82;
                }
              }
            }
          }
        }

        .PrimaryGameMedia {
          opacity: 0;
          visibility: hidden;
          transition: 500ms ease all;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          max-height: 0;
          justify-content: flex-start;
          align-items: flex-start;
          transition: ease 300ms transform, ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms max-height, ease 300ms padding, ease 300ms margin;
          transform: translateY(50px);

          &.active {
            visibility: visible;
            opacity: 1;
            max-height: inherit;
            transform: translateY(0px);
          }

          .GameGallery {
            width: 100%;
            padding: 0px 0;

            .GameGallery__wrapper {
              margin: 0 auto;
              max-width: 1024px;
              position: relative;
              display: flex;
              flex-direction: row;
              overflow: hidden;
              background-color: rgba(0, 0, 0, 0.5);
              
              @media #{$mobileOnly} {
                width: 100%;
                flex-direction: column;
              }
              
              .GameGallery__current-item {
                width: calc(100% - 250px);
                margin-right: 10px;
                position: relative;
                content: "";
                background-color: rgba(0, 0, 0, 0.5);

                @media #{$mobileOnly} {
                  padding: 0px;
                  width: 90%;
                  margin: 10px auto;
                }

                &::before {
                  content: "";
                  padding-top: 56.25%;
                  display: block;
                }

                .youtube-video {
                  position: absolute;
                  left: 0;
                  top: 0;
                }

                .GameGallery__current-image {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                }

                .GameGallery__current-video--cover {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                  outline: none;
                  cursor: pointer;

                  &>.play-icon {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 60px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    transform: translate(-50%, -50%);

                    &>i {
                      position: absolute;
                      left: 50%;
                      top: 50%;
                      font-size: 20px;
                      color: rgba(255, 255, 255, 0.7);
                      transform: translate(-50%, -50%);
                    }
                  }
                }
              }

              .GameGalleryThumb {
                position: absolute;
                width: 240px;
                overflow: hidden;
                height: 100%;
                top: 0;
                right: 0;

                @media #{$mobileOnly} {
                  position: relative;
                  width: 100%;
                  height: 100px;
                }

                .swiper-slide-active .thumb-overlay {
                  outline: 6px $colorCyan solid;
                  opacity: 1 !important;

                  @media #{$mobileOnly} {
                    outline: 4px $colorCyan solid; 
                  }

                  &::before {
                    content: '';
                    width: 0;
                    height: 0;

                    border-top: 18px solid transparent;
                    border-bottom: 18px solid transparent;
                    border-right: 18px solid $colorCyan;
                    
                    position: absolute;
                    margin-left: -56%;

                    @media #{$mobileOnly}{
                      border-left: 12px solid transparent;
                      border-right: 12px solid transparent;
                      border-bottom: 12px solid $colorCyan;

                      margin: auto;
                      margin-top: -44%;
                    }
                  }
                }

                .thumb-slide {
                  width: 85%;
                  padding: 0;
                  margin: 0px 20px;
                  border: none;
                  cursor: pointer;
                  position: relative;
                  outline: none;
                  height: 25%;

                  @media #{$mobileOnly} {
                    width: 120px;
                    height: 70px;
                    margin: auto;
                  }

                  .thumb-overlay {
                    position: absolute;
                    width: calc(100% - 15px);
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: .6;

                    @media #{$mobileOnly} {
                      position: relative;
                      width: 100%;
                    }

                    &>.play-icon {
                      width: 40px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 100%;
                      background: rgba(0, 0, 0, 0.5);
                      font-size: 14px;
                      color: rgba(255, 255, 255, 0.7);
                    }
                  }
                }

                .swiper-scrollbar {
                  width: 4px;
                  border-radius: 2px;
                  top: 0;
                  height: 100%;

                  @media #{$mobileOnly} {
                    width: 100%;
                    height: 4px;
                    margin-bottom: 0px;
                    margin-top: auto;
                  }

                  .swiper-scrollbar-drag {
                    width: 4px;
                    position: relative;
                    background: #2c3239;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .tooltip {
    position: relative;
    z-index: 101;
  }

  .tooltiptext {
    font-size: 10px;
    font-weight: 400;
    padding: 20px 0;
    z-index: 100;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: $colorBackground;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    bottom: 25px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}