@import "../../../utils/variables";

.SocialIcons {
  .SocialIcons_Icon {
    @include center-content;

    min-height: 81px;
    max-height: 81px;
    min-width: 81px;
    max-width: 81px;

    svg {
      height: 35px;
      width: 35px;
    }
  }

  .Grayscale {
    color: $colorCGray;
    background-color: $colorBDarkGray;
  }

  .URL {
    color: $colorWhite;
    cursor: pointer;
  }

  .facebook {
    background-color: $colorFacebook;
  }

  .instagram {
    background-image: $colorInstagram;
  }

  .twitch {
    background-color: $colorTwitch;
  }

  .twitter {
    background-color: $colorTwitter;
  }

  .youtube {
    background-color: $colorYoutube;
  }
}
