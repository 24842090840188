@import "../../../../../utils/_variables";

.SocialNetworks {
  display: flex;
  flex-direction: column;

  .SocialNetworks__Header {
    font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.24px;
  text-align: left;
  }

  .SocialNetworks__Cards {
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin-right: 15px;
    }
  }
}