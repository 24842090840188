@import "../../../../../utils/_variables";

.MetricCluster_Container {
  @include col-start;
  width: 100%;
  height: auto;
  max-width: 1000px;
  background-color: #16181c;
  border-radius: 5px;
  padding: 15px;
  transition: all 1s linear;

  .MetricCluster_Extra {
    max-height: 0;
    overflow: hidden;
    width: 100%;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    padding: 7px;
  }

  .MetricCluster_Extra.Show_Extra {
    max-height: 100%;
    transition: all 0.5s ease-in-out;
    opacity: 1;
  }
}

.Metric_ShowMore_Container {
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: all 2s ease-in-out;
  .MetricCluster_ShowMore {
    @include row-center;
    width: 44px;
    height: 44px;
    padding: 13px;
    border-radius: 12px;
    border: none;
    outline: .5px solid $colorGray;
    background-color: #303030;
    &:hover {
      background-color: $colorGray;
      transition: all 0.2s ease-in-out;
    }

    &:active {
      outline: .5px solid white;
      transition: all 0.1s ease-in-out;
    }

    cursor: pointer;

    color: white;
    transform: translateY(100%) translateX(-10%);
  }

  .MetricCluster_ExtraContent_Container {
    max-height: 0px;
    height: 0px;
  }
  .MetricCluster_ExtraContent {
    // min-height: 2000
  }
}

.MetricCluster_Row {
  @include row-evenly;
  width: 100%;

  @media #{$mobileOnly} {
    @include col-evenly;
  }
}

.MetricCluster_Title {
  font-family: Roboto;
  @include vw-pixels(
    (
      "font-size": 35,
    )
  );
  font-weight: bold;
  text-align: center;
  color: white;
  margin: 10px 0px 20px 0px;
}
