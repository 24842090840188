@import  '../../../../utils/_variables';

.TimeframeModule {
	background: #3e424d;
  cursor: pointer;
	display: flex;
	flex-direction: column;
	padding: 15px 20px;

	span {
		display: flex;
    align-items: center;
    justify-content: space-between;
		h2 {
      font-size: 30px;
			display: block;
      margin: 0px;
		}
	}
}

.DataModule {
  background: #2b2e38;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  
  h2 {
    font-size: 30px;
    display: block;
    margin: 0px;
  }

  span {
    display: flex;
    align-items: baseline;
    font-weight: 900;
  }
}

.DataModule:last-of-type {
  border-top: 1px solid #565b69;
}


.timeframeMenu {
  background: #3e424d;
  position: absolute;
  width: 316px;

  .selectionTabs {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;

    span {
      color: $colorGray;
      display: block;
      font-weight: 900;
      padding: 11px;
      background: #202227;
      width: 100%;

      i {
        float: left;
        margin-top: 5px;
      }

      &.active {
        color: #3e424d;
        background: #49e1ca;
      }

      &:hover {
        color: white !important;
      }
    }
  }
}

  .dateContainer {
    background: #3e424d;
    display: flex;
    width: 750px;
    margin-left: -750px;
    position: absolute;
    z-index: 100;

    .datePicker {
      // border: 1px solid red;
      width: 100%;
      padding: 10px 25px;
    }

    .dateRange {
      // border: 1px solid blue;
      font-size: 16px;
      padding: 20px;
      width: 300px;
      background: #474b56;

      .selector {
        align-items: baseline;
        display: flex;
        flex-direction: row;
        width: 120px;
        margin: 10px 0px;

        .dateInput {
          background: #474b56;
          color: white;
          font-size: 14px;
          min-width: 100px;
        }
      }

      button {
        width: 100%;
        margin: 8px 0px 0px 0px;
      }
    }

    span {
      font-size: 14px;
    }
  }


/* Custom Calendar Styling */

// Single cell day
.react-calendar__tile.react-calendar__month-view__days__day {
  background: #202227;
  border: 2px solid #3e424d;
  border-radius: 0;
  line-height: 0.53em;
  padding: 8px 3px;
}

// Days in next/prev month
.react-calendar__month-view__days__day--neighboringMonth {
  color: #3e424d !important;
  background: none !important;
}

// Months label
.react-calendar__navigation__label {
  text-transform: uppercase;
}


/* Calendar Styling */
.react-calendar {
  width: 400px;
  max-width: 100%;
  background: #3e424d;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;

  &.react-calendar--doubleView {
    width: 400px;
    margin: 0px 20px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;
    }

    .react-calendar__viewContainer > * {
      width: 50%;
      margin: 0.5em;
    }
  }

  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;

    button {
      min-width: 44px;
      background: none;

      &:enabled:hover,
      &:enabled:focus {
        background-color: #00e4ca;
        color: black;
      }
    }

    button[disabled] {
      background-color: #f0f0f0;
    }
  }

  button {
    margin: 0;
    border: 0;
    outline: none;
    color: white;
    font-weight: 900;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    color: #83858c;

    abbr {
      text-decoration: none !important;
    }
  }

  // Tile cursor is currently on
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #00e4ca;
    color: black;
  }

  // Selected range
  .react-calendar__tile.react-calendar__tile--active.react-calendar__month-view__days__day {
    background: #49e1ca;
    color: black;
  }

  // Disabled dates
  .react-calendar__tile:disabled {
    background-color: rgba(255,255,255,0.05);
    color: white;
    cursor: auto;
  }
}

  // Range that could get selected
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #00e4ca;
    color: white;
  }

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: yellow;
  color: black;
}
.react-calendar__tile--hasActive {
  background-color: orange;
  color: black;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background-color: green;
  color: black;
}
.react-calendar__tile--active {
  background-color: red;
  color: black;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: purple;
  color: black;
}

.react-calendar--selectRange .react-calendar__tile--active {
  background-color: blue;
  color: black;
}
