@import '../../utils/_variables';
@import '../../utils/_util';
.SearchQuests{
	.SearchQuests__container{
		width: 100vw;
    background-image: url('../../images/particles/landing-background.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    .SearchQuests__content__extra{
      position:absolute;
      width: 50%;
      background: $colorBackground;
      z-index: 2;
      height: 100%;
      top: 260px;
    }
    .SearchQuests__content{
      z-index: 2;
      max-width: 1024px;
      margin-right: auto;
      margin-left: auto;
      padding-top: 170px;
      display: flex;
      flex-wrap: wrap;
      
      .SearchQuests__header{
        @extend .typo_heading_2;
        z-index: 2;
        display: flex;
        flex-basis: 100%;
        padding: 25px;
      }
      .SearchQuests__fields{
        display: flex;
        flex-basis: 100%;
        padding: 25px;
        z-index: 2;
        background-color: $colorBackground;
        .input-container {
          flex: 2;
          padding-left: 30px;
          border-bottom: 2px solid $colorBackground2;
          &.active{
            border-bottom: 2px solid $colorLightGray;
          }
        }
        .input-wrapper_search {
          flex-flow: row !important;
          margin-top: 14px;
          i {
            display: flex;
            flex: 1;
            padding-top: 8px;
            padding-bottom: 8px;
            color: $colorLight;
            line-height: 24px;
            font-size: 16px;
          }
        }
        .input_search {
          display:flex;
          flex: 5;
        }
        input, select{
          background-color: #202227;
          border: none;
          // border-bottom: 2px solid #83858C;
          border-radius: 0px;
          color: white;
          display: inline-block;
          padding-left: 0;
          padding-right: 0;
          text-align: left;
          margin: 0;
          width: 100%;
        }
        input.error, select.error{
          border-bottom: 2px solid #dc4f4f;
        }
        select {
          background-image: none;
        }
        a {
          text-decoration: none;
        }
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: rgb(131, 133, 140);
          opacity: 1; /* Firefox */
        }
    
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: rgb(131, 133, 140);
        }
    
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: rgb(131, 133, 140);
        }
        .first-option{
          color: rgb(131, 133, 140);
        }
        input:focus, select:focus{
          // border-bottom: 2px solid #3A74E0;
          box-shadow: none;
        }
        input:placeholder-shown + label {
          cursor: text;
          max-width: 66.66%;
          color:#d1d1d1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transform-origin: left bottom;
          transform: translate(0, 1.9rem) scale(1.5);
          &:after{
            content: attr(data-label);
          }
        }
        .input-wrapper {
          display: flex;
          flex-flow: row;
          margin-bottom: 30px;
          position: relative;
          .fa {
            color: $colorBackground2;
            display: flex;
            align-self: center;
          }
        }
        .first-input-container{
          flex: 3;
          padding-left: 0;
        }
        input, select{
          font-size: 14px;
          z-index:1;
          background-color: transparent;
        }
        select option{
          background-color: #202227;
        }
        label{
          color: $colorLightGray;
          z-index:0;
          font-size: 12px;
          line-height: 14px;
        }
        label, input {
          transition: all 0.2s;
          touch-action: manipulation;
        }
      }
      .SearchQuests__quests{
        display: flex;
        flex-basis: 100%;
        padding: 25px;
        flex-direction: row;
        flex-wrap: wrap;
        z-index: 2;
        background-color: $colorBackground;
        .QuestCard{
          text-decoration: none;
          display: inline-flex;
          position: relative;
          width: calc(33.333% - 20px);
          // 20px + the reduction of the margin of the last(removed) divided over the rest.
          margin-right: calc(20px + (20px / 2));
          margin-bottom: 20px;
          cursor: pointer;
          // overflow: hidden;
          flex-direction: column;
          &:nth-child(3n){
            // Removing the 20px from the last element.
            margin-right: 0px;
          }
          .QuestCard__image{
            display: flex;
            width: 100%;
            position: relative;
            &:before{
              content: '';
              padding-top: 56.25%;
              display: block;
            }
            figure{
              position: absolute;
              width: 100%;
              height: 100%;
              margin: 0;
              top: 0;
              left: 0;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
          .QuestCard__details{
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: -30px;
            .QuestCard__background-top{
              position: absolute;
              width: 83%;
              height: 30px;
              top: 0;
              background-color: #212227;
            }
            .QuestCard__background-bottom{
              position: absolute;
              width: 100%;
              height: calc(100% - 30px);
              bottom: 0;
              background-color: #212227;
            }
            .QuestCard__details-wrapper{
              display: flex;
              flex-direction: column;
              z-index: 1;
              .QuestCard__details--row{
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin: 0 20px;
                &:nth-child(1){
                  margin-top: 15px;
                  // margin-right: 17%;
                }
                .QuestCard__details--icon{
                  color: #FFF;
                  margin-right: 10px;
                  font-size: 20px;
                  color: $colorGray;
                }
                .QuestCard__details--info{
                  width: 100%;
                  .primary-info{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    margin-right: 17%;
                    .QuestCard__details--name{
                      font-weight: 700;
                      color: #FFF;
                      font-family: "Roboto";
                      line-height: 20px;
                      text-transform: capitalize;
                     }
                  }
                  // Second Row
                  .sub-info{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    margin-top: 2px;
                    .QuestCard__details--game-name{
                      font-size: 10px;
                      color: $colorGray;
                      font-family: "Roboto";
                    }
                    .QuestCard__details--date-interval{
                      margin-left: auto;
                      font-size: 12px;
                      color: $colorGray;
                      font-family: "Roboto";
                    }
                  }
                }
                .SeeQuest{
                  background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
                  color: #fff;
                  cursor: pointer;
                  text-decoration: none;
                  text-align: center;
                  font-weight: normal;
                  border-radius: 2px;
                  font-weight: 700;
                  font-size: 13px;
                  width: 100%;
                  padding: 10px 0;
                  margin: 10px 0;
                }
                .QuestCard__details--open-slots{
                  margin-bottom: 15px;
                  width: 100%;
                  color: $colorGray;
                  text-align: center;
                  font-size: 12px;
                }
              }
            }
          }
          .QuestCard__level{
            position: absolute;
            top: 10px;
            right: 10px;
            // transform: translate(50%, 5%);
            background-size: contain;
            background-repeat: no-repeat;
            width: 32px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            .QuestCard__level--wrapper{
              position: relative;
              width: 100%;
              height: 100%;
            }
            .QuestCard__level--count{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-weight: bold;
              font-size: 10px;
              line-height: 11px;
              min-width: 10px;
              text-align: center;
            }
          }
          .QuestCard__corner__1{
            position: absolute;
            width:100%;
            height:100%;
            transition: 400ms ease all;
            &:after{
              content: "";
              display: block;
              height: 15%;
              width: 4px;
              bottom: -4px;
              left: -4px;
              position: absolute;
              transition: 400ms ease all;
            }
            &:before{
              content: "";
              display: block;
              height: 4px;
              width: 15%;
              bottom: -4px;
              left: -4px;
              position: absolute;
              transition: 400ms ease all;
            }
          }
          .QuestCard__corner__2{
            position: absolute;
            width:100%;
            height:100%;
            &:after{
              content: "";
              display: block;
              height: 15%;
              width: 4px;
              top: -4px;
              left: -4px;
              position: absolute;
            }
            &:before{
              content: "";
              display: block;
              height: 4px;
              width: 15%;
              top: -4px;
              left: -4px;
              position: absolute;
            }
          }
          .QuestCard__corner__3{
            position: absolute;
            width:100%;
            height:100%;
            &:after{
              content: "";
              display: block;
              height: 15%;
              width: 4px;
              bottom: -4px;
              right: -4px;
              position: absolute;
            }
            &:before{
              content: "";
              display: block;
              height: 4px;
              width: 15%;
              bottom: -4px;
              right: -4px;
              position: absolute;
            }
          }
          .QuestCard__corner__4{
            position: absolute;
            width:100%;
            height:100%;
            &:after{
              content: "";
              display: block;
              height: 15%;
              width: 4px;
              top: -4px;
              right: -4px;
              position: absolute;
            }
            &:before{
              content: "";
              display: block;
              height: 4px;
              width: 15%;
              top: -4px;
              right: -4px;
              position: absolute;
            }
          }
          &:hover{
            .QuestCard__corner__1{
              &:after{
                background: $colorSalmon;
              }
              &:before{
                background: $colorSalmon;
              }
            }
            .QuestCard__corner__2{
              &:after{
                background: $colorSalmon;
              }
              &:before{
                background: $colorSalmon;
              }
            }
            .QuestCard__corner__3{
              &:after{
                background: $colorSalmon;
              }
              &:before{
                background: $colorSalmon;
              }
            }
            .QuestCard__corner__4{
              &:after{
                background: $colorSalmon;
              }
              &:before{
                background: $colorSalmon;
              }
            }
          }
          &.active{
            .QuestCard__corner__1{
              &:after{
                background: #FC8D82;
              }
              &:before{
                background: #FC8D82;
              }
            }
            .QuestCard__corner__2{
              &:after{
                background: #FC8D82;
              }
              &:before{
                background: #FC8D82;
              }
            }
            .QuestCard__corner__3{
              &:after{
                background: #FC8D82;
              }
              &:before{
                background: #FC8D82;
              }
            }
            .QuestCard__corner__4{
              &:after{
                background: #FC8D82;
              }
              &:before{
                background: #FC8D82;
              }
            }
          }
        }
      }
      .SearchQuests__more{
        flex-basis: 100%;
        display: flex;
        padding: 25px;
        z-index: 2;
        justify-content: center;
        background-color: $colorBackground;
        button{
          @extend .typo_small_paragraph;
          color: $colorEnergyBlue;
          cursor: pointer;
        }
      }
    }
    .Hero__icons-container{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      .icon{
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 10%; height: 10%;
        position: absolute;
        transition: opacity 1200ms ease-out;
        z-index: 1;
        &.dimmed{
          opacity: 0.16;
        }
        &.ghost{
          background-image: url('../../images/particles/icon-ghost.png');
          width: 7.55%;
          height: 8.8vw;
        }
        &.potion{
          background-image: url('../../images/particles/icon-potion.png');
          width: 5.42%;
          height: 5.42vw;
        }
        &.controller{
          background-image: url('../../images/particles/icon-controller.png');
          width: 7.03%;
          height: 4.63vw;
        }
        &.dpad{
          background-image: url('../../images/particles/icon-dpad.png');
          width: 6.8765%;
          height: 6.8765vw;
        }
        &.crown{
          background-image: url('../../images/particles/icon-crown.png');
          width: 4.9%;
          height: 4.11vw;
        }
        &.scroll{
          background-image: url('../../images/particles/icon-scroll.png');
          width: 5%;
          height: 5.21vw;
        }
      }

      .sword-container{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      .boxes-background{
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        .layer1{ left: 32.1785%!important; top: -3vw!important; }
        .layer2{ left: 35%!important; top: 18%!important; }
        .layer3{ left: 70%!important; top: 23%!important; }
        .layer4{ left: 82%!important; top: 44%!important; }
        .layer5{ left: 12%!important; top: 54%!important; }
        .layer6{ left: 97%!important; top: 74%!important; }
        .layer7{ left: 24%!important; top: 80%!important; }
        .box{
          background: #202227;
          width: 5.58vw;
          height: 5.58vw;
          position: absolute;
          &.box1{ animation: boxBaubleTwo 4000ms infinite linear; }
          &.box2{ animation: boxBaubleTwo 2600ms infinite linear; }
          &.box3{ animation: boxBaubleTwo 3200ms infinite linear; }
          &.box4{ animation: boxBaubleTwo 4000ms infinite linear; }
          &.box5{ animation: boxBaubleTwo 5600ms infinite linear; }
          &.box6{ animation: boxBaubleTwo 6400ms infinite linear; }
          &.box7{ animation: boxBaubleTwo 2400ms infinite linear; }
        }
      }
      .boxes-foreground{
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        .layer1{ left: 8%!important; top: 48%!important; width: 6.2vw; height: 6.2vw; }
      .layer2{ left: 84%!important; top: 35%!important; width: 8.33vw; height: 8.33vw; }
      .layer3{ left: 28%!important; top: 58%!important; width: 1.04vw; height: 1.04vw; }
      .layer4{ left: 75%!important; top: 59%!important; width: 0.785vw; height: 0.785vw; }
      .layer5{ left: 17%!important; top: 63%!important; width: 1.04vw; height: 1.04vw; }
      .layer6{ left: 69%!important; top: 81%!important; width: 2.08vw; height: 2.08vw; }
      .layer7{ left: 23%!important; top: 79%!important; width: 1.98vw; height: 1.98vw; }
      .layer8{ left: 47%!important; top: 88%!important; width: 2.08vw; height: 2.08vw; }
        .box{
          position: absolute;
          width: 100%;
          height: 100%;
          &.box1{ animation: boxBauble 4400ms infinite linear; background: $colorLifeGreen; }
          &.box2{ animation: boxBauble 5600ms infinite linear; background: $colorEnergyBlue; }
          &.box3{ animation: boxBauble 3200ms infinite linear; background: $colorEnergyBlue; }
          &.box4{ animation: boxBauble 4200ms infinite linear; background: $colorEnergyBlue; }
          &.box5{ animation: boxBauble 2800ms infinite linear; background: $colorFluidPink; }
          &.box6{ animation: boxBauble 3000ms infinite linear; background: $colorFluidPink; }
          &.box7{ animation: boxBauble 4800ms infinite linear; background: $colorPurpleRay; }
          &.box8{ animation: boxBauble 3600ms infinite linear; background: transparent; border: 0.5vw solid $colorLifeGreen; }
        }
      }
    }
    .Hero__title{
      font-weight: 900;
      line-height: 114%;
      @include vw-pixels((
        'font-size': 60
      ));
      @media #{$tabletAndLarger}{
        margin: 11vh 26% 0;
      }
    }
    .Hero__description{
      line-height: 160%;
      
      @include vw-pixels((
          'font-size': 18
          ), 2);
      @media #{$tabletAndLarger}{
        margin: 4.8vh 39% 6.8vh;
      }
    }

    .Hero__inputs{
    border: 2px solid #83858C;
    padding: 3px;
    border-radius: 3px;
    margin: 0 auto;
    width: 90%;
      @include vw-pixels((
        'font-size': 13
      ));
      input{
        background: transparent;
        border: none;
        color: white;
        font-weight: 400;
        margin: -1.6% 0;
        font-size: 16px;
          @media #{$tabletAndLarger}{
            @include vw-pixels((
              'font-size': 13
            ));
          }
      }
    input:focus{
      background: transparent;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: white;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: white;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: white;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: white;
    }

    @media #{$mobileOnly}{
      > div{
        width: 100%;
      }
      input:focus{
        padding: 3.2vh 0;
      }
      button{
        padding: 1.6vh 0;
      }

    }
    @media #{$tabletAndLarger}{
      width: 25%;
    }
  }

  .cta-button{
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    font-weight: normal;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    font-weight: 900;
    font-size: 16px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3a74e0+0,5d34f5+100 */
    background: $colorEnergyBlue; /* Old browsers */
    background: -moz-linear-gradient(top, $colorEnergyBlue 0%, #5d34f5 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, $colorEnergyBlue 0%,#5d34f5 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, $colorEnergyBlue 0%,#5d34f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3a74e0', endColorstr='#5d34f5',GradientType=0 ); /* IE6-9 */
    @media #{$tabletAndLarger}{
        @include vw-pixels((
          'font-size': 13
        ));
      }
    }
	}
}


@keyframes boxBauble {
	0%, 100% {
		transform: translate(-7.2%, 0);
	}
	25% {
		transform: translate(0%, -3.2%);
	}
	50%{
		transform: translate(7.2%, 0);
	}
	75% {
		transform: translate(0%, 3.2%);
	}
}


@keyframes boxBaubleTwo {
	0%, 100% {
		transform: translate(-4%, 0);
	}
	25% {
		transform: translate(0%, -1.6%);
	}
	50%{
		transform: translate(4%, 0);
	}
	75% {
		transform: translate(0%, 1.6%);
	}
}
