@import  '../../utils/_variables';

.hidden-layer {
  height: 100%;
  left: 0px;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0px;
  visibility: hidden;
  width: 100%;
  z-index: 99;

  &.open {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
}

.user-info {
  align-items: center;
  cursor: pointer;
  display: flex;
  display: none;
  flex-direction: row;
  opacity: 0;
  outline: none;
  margin-left: 20px;
  margin-right: 0px;
  visibility: hidden;
  background-color: transparent;
  border: none;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-left: 10px;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    opacity: 1;
    visibility: visible;
  }

  @media screen and (min-width: 1024px) {
    margin-right: 0px;
  }

  .about {
    color: white;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    text-align: right;

    @media screen and (max-width: 767px) {
      margin-bottom: 50px;
      margin-top: 10px;
      text-align: left;
    }

    .name {
      @media screen and (max-width: 767px) {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.4px;
        margin-left: 2px;
      }

      font-family: Roboto;
      font-size: 13px;
      font-weight: 600;
    }

    .company {
      color: $colorGray;
      font-size: 14px;
    }
  }

  .profile {
    align-items: center;
    color: #FFF;
    display: flex;
    flex-direction: row;
    font-family: 'Lato', sans-serif;
    justify-content: center;
    margin-left: 10px;
    position:relative;

    @media screen and (max-width: 767px) {
      display: block;
      margin-left: 20px;
    }

    .container {
      background: linear-gradient(to bottom, #02bdad, #036ccf);
      border-radius: 100%;
      height: 50px;
      margin-right: 10px;
      position: relative;
      width: 50px;

      .avatar {
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 100%;
        height: calc(100% - 6px);
        left: 50%;
        margin: 0;
        object-fit: cover;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 6px);
      }
    }

    i {
      color: rgba(255, 255, 255, 0.2);
      margin-left: 10px;
    }
  }
}

.account-menu {
  background: #202227;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  box-shadow: 0px 0px 1px rgba(144, 144, 144, 0.1), inset 0px 0px 1px rgba(0, 0, 0, 0.59);
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  opacity: 1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0px;
  top: 85%;
  //transform: scale(0.7) translateY(-20%);
  transform: scale(1) translateY(0%);
  transform-origin: top right;
  transition: 200ms ease all;
  //visibility: hidden;
  visibility: visible;
  width: 190px;
  z-index: 100;

  li {
    border-bottom: 1px solid rgba(255,255,255,0.1);
    cursor: pointer;
    margin: 0;
    width: 100%;

    &.active {
      background: hsla(0,0%,100%,.05);
    }

    &:last-child {
      border-bottom: none;
    }

    a {
      align-items: center;
      background: none;
      border: none;
      color: $colorGray;
      display: inline-flex;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      letter-spacing: 0.4px;
      line-height: 16px;
      margin: 0;
      padding: 8px 10px;
      position: relative;
      text-decoration: none;
      transition: 300ms ease background;
      width: 100%;

      &.active,
      &:hover {
        background: rgba(255, 255, 255, 0.05);
        border-radius: 3px;
        outline: none;
      }
    }
  }
}