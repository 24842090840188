@import "../../utils/variables.scss";
@import "../../utils/util.scss";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,900;1,300&display=swap");

$theMaxWidth: 80vw;
$uniformPadding: 4vw;

@mixin trianglePokeyLeft {
  & :after {
    border: 24px solid transparent;
    border-left: 0;
    border-right-color: #202227;
    content: "";
    height: 0;
    left: 0;
    margin-left: -24px;
    margin-top: -24px;
    position: absolute;
    top: 50%;
    width: 0;
    z-index: -1;
  }

  @media #{$mobileOnly} {
    & :after {
      border: 10px solid transparent;
      border-left: 0;
      border-right-color: #202227;
      height: 0;
      left: 0;
      margin-left: -9.5px;
      margin-top: -9.5px;
      position: absolute;
      top: 50%;
      width: 0;
      z-index: -1;
    }
  }

  @media #{$tabletOnly} {}
}

@mixin trianglePokeyRight {
  & :after {
    border: 24px solid transparent;
    border-left-color: #202227;
    border-right: 0;
    content: "";
    height: 0;
    margin-right: -24px;
    margin-top: -24px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
  }
}

@mixin brandHeroText {
  color: white;
  font-family: Roboto;
  font-size: 40px;
  font-weight: 300;
}

@mixin centerFlex {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin vertFlex {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0vw $uniformPadding 0vw $uniformPadding;
}

@mixin spaceFlex {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

@mixin brandTwoGrid {
  align-items: center;
  display: flex;
  justify-content: center;

  @media #{$mobileOnly} {
    flex-direction: column;
  }

  @media #{$tabletAndLarger} {
    flex-direction: row;
  }
}

@mixin medLargeHeadline {
  color: #ffffff;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;

  @media #{$mobileOnly} {
    font-size: 30px;
  }

  @media #{$tabletOnly} {
    font-size: 35px;
  }

  @media #{$desktopAndLarger} {
    font-size: 40px;
  }
}

@mixin brandMedText {
  color: #ffffff;
  font-family: Roboto;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.6;
  text-align: center;

  @media #{$mobileOnly} {
    font-size: 14px;
  }

  @media #{$tabletOnly} {
    font-size: 16px;
  }

  @media #{$desktopAndLarger} {
    font-size: 20px;
  }
}

@mixin brandPurpleHeroText {
  color: #a142ff;
  font-family: Roboto;
  font-size: 45px;
  font-size: calc(min(3vw, 50px));
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;

  @media #{$mobileOnly} {
    font-size: 6vw;
  }

  @media #{$tabletOnly} {
    font-size: 4vw;
  }
}

@mixin brandSubtitleWhiteText {
  color: #ffffff;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;

  @media #{$mobileOnly} {
    font-size: 16px;
  }

  @media #{$tabletOnly} {
    font-size: 20px;
  }

  @media #{$desktopAndLarger} {
    font-size: 24px;
  }
}

@mixin greyBrandBodyText {
  color: #83858c;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.6;

  @media #{$mobileOnly} {
    font-size: 16px;
    line-height: 2;
  }

  @media #{$tabletOnly} {
    font-size: 18px;
  }

  @media #{$desktopAndLarger} {
    font-size: 20px;
  }
}

.brand-service-slide-a-text {
  @include centerFlex();
  @include brandHeroText();

  text-align: center;
  z-index: 20;

  @media #{$mobileOnly} {
    font-size: 30px;
  }
}

.brand-service-master {
  height: 100%;
  max-width: 1920px;
  min-height: 800px;
  position: absolute;
  width: 100%;
}

.brand-service-master-flextainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh !important;
  justify-items: center;
  width: 100vw !important;

  div {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.brand-service-clipmask {
  height: 110px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.brand-white-button {
  border: solid 2px #ffffff;
  border-radius: 24px;
  color: #ffffff;
  cursor: pointer;
  font-family: Roboto;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  height: 48px;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 180px;

  &:hover {
    background-color: #ffffff;
    color: #000000;
  }

  &:focus {
    outline: none;
  }
}

.brand-image-adorned {
  position: relative;

  img {
    z-index: 2;
  }
}

.brand-image-adorned:before {
  background-color: #3a74e0;
  content: "";
  height: 88px;
  left: -15px;
  position: absolute;
  top: -15px;
  width: 88px;
  z-index: -1;
}

.brand-source {
  color: #81858c;
  font-family: Roboto;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 17px;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  width: 375px;
  z-index: 1000;
}

.brand-source-text {
  font-size: 10px;
}