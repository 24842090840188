@import "../../../../utils/_variables";

.NothingHere {


@include col-center;
  width: 100%;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 900;
  .nothing-here-image {
    width: 10%;
    img {
      width: 100%;
    }
  }

  width: 100%;

}