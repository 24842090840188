@import  '../../../../utils/_variables';


.member-tier-edit {
    margin-left: 50px;
    padding-left: 20px;
}

.noTierSelected {
    color: $colorSalmon;
}


.memberTierSelected {
    color: $colorBlue;
}