.reload-button {
  background-color: white;
  border: solid 1px rgb(84, 91, 100);
  border-radius: 3px;
  color: rgb(84, 91, 100);
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  height: 28px;
  padding: 0 12px;
  text-align: center;

  svg {
    margin-bottom: -2px;
    margin-right: 6px;
    stroke: rgb(84, 91, 100);
    stroke-width: 2px;
    width: 12px;
  }
}