@import '../../../../../utils/_variables';

.ProductEdit__Wrapper {
  height: 100%;
  width: 100%;

  i {
    margin: 0 auto;
  }

  .ProductEdit {
    display: flex;
    flex-direction: column;

    width: 100%;

    padding: 25px;
    position: relative;

    background-color: $colorBackground;

    .ProductEdit__Header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 46px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.46px;
      text-align: left;

      .ProductEdit__Delete {
        @include button-base;

        height: 48px;
        min-width: 78px;

        background-color: $colorCandyRed2;
        color: $colorBlack;

        font-size: 16px;

        &:hover {
          background-color: $colorCandyRed;
        }
      }
    }

    .ProductEdit__Pages {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      width: 100%;

      position: relative;

      .ProductEdit__Pages_Bar {
        min-height: 1px;
        width: 95%;

        position: absolute;
        top: 33%;
        left: 25px;

        z-index: 90;

        background-color: $colorCGray;
      }

      .ProductEdit__Pages_Page {
        @include center-content;
        flex-direction: column;

        height: 100px;

        z-index: 99;

        cursor: pointer;

        .Product_Navigation {
          @include center-content;

          height: 20px;

          margin-bottom: 14px;

          .Product_Navigation_Bouy {
            background-color: $colorCGray;
            border-radius: 50%;
            padding: 6px;
            // border: 6px solid $colorCGray;
            // margin: -8px;

            &.Fill {
              background-color: $colorCyan;
              padding: 10px;
              // border: 10px solid $colorCyan;
              // margin: none;
            }

            &.ErrorPresent {
              background-color: $colorCandyRed;
              padding: 10px;
            }
          }

          // .Product_Navigation_Left {
          //   min-height: 1px;
          //   max-height: 1px;
          //   width: 100%;

          //   background-color: $colorCGray;
          // }

          // .Product_Navigation_Right {
          //   min-height: 1px;
          //   max-height: 1px;
          //   width: 100%;

          //   background-color: $colorCGray;
          // }
        }

        .ProductEdit__Pages_Page_Text {
          color: $colorCGray;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.24px;
          text-align: center;

          &.Fill {
            color: $colorCyan;
            font-weight: bold;
          }

          &.ErrorPresent {
            color: $colorCandyRed;
            font-weight: bold;
          }
        }
      }
    }

    .ProductEdit__Content {
      display: flex;
      flex-direction: column;

      width: 100%;

      & > * {
        margin: 9px 0;
      }
    }

    .ProductEdit__Error {
      @include center-content;

      width: 100%;

      margin-top: 15px;
      padding: 20px;

      border: 2px solid $colorCandyRed;
      border-radius: 12px;
      color: $colorCandyRed;
    }

    .ProductEdit__Buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      margin-top: 20px;

      .ProductEdit__Buttons_Button {
        height: 48px;

        font-size: 16px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
      }

      .Publish {
        @include purple-gradient-button;

        width: 100%;
      }

      .Save {
        @include purple-gradient-button;

        width: 48%;
      }

      .Continue {
        @include gray-border-button;

        width: 48%;
      }
    }
  }
}
