@import "../../../../utils/_variables";

.Masthead__Loading {
  @include center-content;
  height: 440px;
  width: 100%;
}

.Masthead__Blank {
  @include center-content;
  background-image: linear-gradient(to bottom, #3e71e1, #7b54f3);
  flex-direction: column;
  height: 440px;
  width: 100%;

  div {
    margin: 10px 0;
  }

  .Masthead__Blank_Title {
    font-size: 46px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0.46px;
    text-align: center;
  }

  .Masthead__Blank_Description {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.16px;
    text-align: center;
    width: 60%;
  }

  .Masthead__Blank_Create_Quest {
    @include hazy-button;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 900;
    height: 43px;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    width: 180px;
  }
}

.Masthead {
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 480px;
  min-height: 435px;
  padding: 28px;
  position: relative;
  width: 100%;

  @media #{$mobileAndTabletOnly} {
    height: auto;
    max-height: none;
    width: 100%;
  }

  .Masthead__Background {
    bottom: 0;
    filter: brightness(45%);
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .Masthead__Title {
    // justify-content: center;
    align-items: center;
    display: flex;
    height: 37px;
    z-index: 2;

    .Masthead__QuestActive {
      @include center-content;
      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);
      background-color: rgba(255, 255, 255, 0.09);
      border-radius: 8px;
      color: #fff;
      font-style: normal;
      height: 37px;
      letter-spacing: 0.84px;
      line-height: 1.29;
      margin-right: 18px;
      padding: 9px 0;
      text-align: center;
      width: 102px;

      // position: absolute;
      // top: 10px;
      // left: 10px;
    }

    .Masthead__CurrentlyStreaming {
      align-items: center;
      display: flex;

      .Masthead__StreamerIcons {
        align-items: center;
        display: flex;

        list-style: none;

        .Masthead__StreamerIcons_Icon {
          .Masthead__StreamerIcons_Icon_Img {

            border-radius: 50%;
            max-height: 34px;
            max-height: 34px;
            width: 100%;

            @include transform-scale;
          }
        }
      }
    }
  }

  .Masthead__Bottom {
    z-index: 2;

    .Masthead__QuestDetails {
      display: flex;
      flex-direction: column;
      // margin-top: 150px;

      .Masthead__QuestDetails_TitleBar {
        align-items: center;
        display: flex;

        @media #{$mobileAndTabletOnly} {
          flex-wrap: wrap;
        }

        .Masthead__QuestDetails_Title {
          font-size: 46px;
  
          font-weight: 900;
        }

        .Masthead__QuestIcon {
          @include purple-quest-icon;
          margin: 15px;
        }

        .Masthead__KeyIcon {
          @include green-key-quest-icon;
        }
      }

      .Masthead__QuestDetails_Description {
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 1.25;
      }
    }

    .Masthead__Links {
      display: flex;
      flex-wrap: wrap;
      margin-top: 25px;
      // justify-content: space-evenly;
      // align-items: center;

      @media #{$microOnly} {
        min-height: 150px;
      }

      a {
        margin-right: 20px;
      }

      .Masthead__Links_Link {
        @include center-content;
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        background-color: rgba(255, 255, 255, 0.09);
        border-radius: 12px;
        height: 67px;
        margin-right: 20px;
        min-width: 100px;
        padding: 0 13px;
        transition: background-color 0.2s ease-in-out;
        // width: 182px;

        &:hover {
          background-color: rgba(255, 255, 255, 0.25);
          cursor: pointer;
        }

        .Masthead__Links_Link_Icon {
          height: 20px;
          width: 20px;
        }

        .Masthead__Links_Link_Text {
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          font-weight: normal;
          letter-spacing: normal;
          line-height: 1.36;
          text-align: left;
        }
      }
    }
  }
}
