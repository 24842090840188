@import "../../../../utils/_variables.scss";

.DisputeDropdown_Col {
  @include col-start;
  width: 100%;

  .DisputeDropdown_Row {
    @include row-between;
    width: 100%;
    margin: 10px 0px;

    min-height: 103px;
    padding: 27px 9px 26px 23px;
    border-radius: 10px;
    background-color: #16181c;
  }

  .DisputeDropdown_Left {
    @include row-start;
    width: 40%;
    margin-left: 10px;
  }

  .DisputeDropdown_Right {
    @include row-end;
    width: 40%;
    margin-right: 10px;
  }

  .DisputeDropdown_ProductBox {
    max-width: 54px;
    max-height: 50px;
    img {
      max-width: 54px;
      max-height: 50px;
    }
    border-radius: 6px;
    background-size: cover;
    background-image: linear-gradient(to bottom, #e8cdfe, #b7bbfd);
  }

  .DisputeDropdown_QuestTitle {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: #fff;
    margin-left: 25px;
  }
  .DisputeDropdown_QuestDetails {
    margin-right: 25px;
    width: 170px;
    height: 34px;
    padding: 6.5px 37px;
    border-radius: 26px;
    a {
      text-decoration: none;
    }
    background-image: linear-gradient(
      to bottom,
      #3a74e0,
      #3f72e2 10%,
      #4471e4 20%,
      #496fe6 30%,
      #4f6ee7 40%,
      #546ce8 50%,
      #596ae9 60%,
      #6167ea 70%,
      #6c62eb 80%,
      #775dec 90%,
      #8358ed
    );
  }

  .DisputeDropdown_DropDownButton {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #873991;
      transition: all 0.3s ease-in-out;
    }
  }
  .DisputeList {
    width: 100%;
    @include col-start;
    &:last-child {
      border-bottom: none;
    }
    .DisputeItem {
      @include row-evenly;
      width: 100%;
      padding: 15px 10px;
      min-height: 90px;
      background-color: #242529;
      border-bottom: 1px solid #747474;
      // last child border-bottom: none
    }
  }
}
