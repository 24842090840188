@import "../../../../utils/_variables";

.AUserList {
  .Filters__section {
    @include col-center;

    // margin-left: 30px;
    input {
      width: 300px;
    }
    span.error {
      color: red;
      display: flex;
      margin: 10px 0px;
    }
  }
  .AUserList__wrapper {
    margin: 0 auto;
    padding: 30px;
    // padding-top: 120px;
    .button-style {
      width: 300px;
      height: 50px;
      border-radius: 30px;
      background: linear-gradient(to right, #45cacd, #47b8d4);
      color: #fff;
      border: none;
      outline: none;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 22px;
      letter-spacing: 1.2px;
      margin: 20px 0 20px 0;
      cursor: pointer;
    }
    .UserList__loading {
      padding-top: 50px;
      text-align: center;
    }
    .AUserList__title {
      font-weight: 500;
      letter-spacing: 3.5px;
      text-align: left;
      text-transform: uppercase;
      @include vw-pixels(
        (
          "font-size": 35,
        )
      );
    }
    .AUserList__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .CreateBrandUser {
        @extend .button-style;
      }
    }
    .ToggleTable {
      text-align: center;
      .ToggleTable__header {
        background: #18191d;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
        .ToggleTable__header__left {
          padding: 0;
          margin: 0;
          font-size: 22px;
          letter-spacing: 2.5px;
          button {
            margin-right: 4px;
            border-radius: 4px;
            padding: 5px 15px;
            background: #202227;
            color: #fff;
            border: none;
            outline: none;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 22px;
            letter-spacing: 1.2px;
            cursor: pointer;
            margin-left: auto;
            &:hover {
              background: rgba(255, 255, 255, 0.2);
            }
          }
          i {
            margin-left: auto;
          }
        }
        .ToggleTable__header__right {
          margin-left: auto;
          button {
            margin-right: 4px;
            border-radius: 4px;
            padding: 5px 15px;
            background: #202227;
            color: #fff;
            border: none;
            outline: none;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 22px;
            letter-spacing: 1.2px;
            cursor: pointer;
            margin-left: auto;
            &:hover {
              background: rgba(255, 255, 255, 0.2);
            }
          }
          i {
            margin-left: auto;
          }
        }
        .dot-status {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: #ef3937;
          box-shadow: 0 0 9px 0 #ff987f;
          margin-left: 10px;
        }
      }
      .ToggleTable__preTable {
        background: #202227;
        .ToggleTable__preTable__display {
          padding-top: 10px;
          span {
            &.attribute {
              border: 1px solid $colorLight;
              padding: 2px 3px;
              border-radius: 5px;
              font-weight: 300;
              margin-left: 5px;
              &.active {
                border: 1px solid $colorLifeGreen;
                color: $colorLifeGreen;
              }
            }
          }
        }
      }
      .ToggleTable__table-wrapper {
        background: #202227;
        padding: 30px;
        min-height: 686px;
        .no-results {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 44px;
          margin-top: 30px;
        }
        .row {
          border-bottom: 1px solid rgba(254, 254, 254, 0.1);
          padding: 0 10px;

          .quest-title {
            position: relative;
            .full-title {
              visibility: hidden;
              width: 300px;
              font-size: 12px;
              line-height: 13px;
              background-color: #282828;
              color: #fff;
              text-align: center;
              padding: 10px;
              border-radius: 3px;
              position: absolute;
              z-index: 100;
              bottom: 60px;
              left: 20px;
            }
          }

          .quest-title:hover .full-title {
            visibility: visible;
          }

          .quest-btn {
            display: inline-block;
            background: rgba(255, 255, 255, 0.05);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #fff;
            line-height: 1;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 11px;
            text-decoration: none;
            margin-right: 5px;
          }

          &.toggable {
            flex-wrap: wrap;
            .row-basic {
              width: 100%;
              display: flex;
              flex-flow: row nowrap;
            }
            .row-details {
              width: 100%;
              display: none;
            }
            &.open {
              .row-details {
                display: flex;
                .row-details-content {
                  width: 100%;
                  border: 1px solid rgba(254, 254, 254, 0.5);
                  margin: 20px 10px;
                  .table-cell {
                    &.headline {
                      font-size: 14px;
                      color: #9b9b9b;
                      font-weight: bold;
                      letter-spacing: 1.2px;
                    }
                    &:first-child {
                      justify-content: flex-start;
                      max-width: initial;
                      text-align: left;
                    }
                  }
                }
              }
            }
          }
          &:hover {
            background: rgba(255, 255, 255, 0.025);
            &:first-child {
              background: rgba(255, 255, 255, 0);
            }
          }
          &:last-child {
            border-bottom: none;
          }
          .table-cell {
            justify-content: center;
            &.headline {
              color: #9b9b9b;
              font-weight: 400;
              letter-spacing: 1.2px;
            }
            &.headline-clickable {
              cursor: pointer;
            }
            &:first-child {
              justify-content: flex-start;
              text-align: left;
            }
            .game-noiz {
              background-color: #fff;
              color: #202227;
              font-size: 12px;
              font-weight: 700;
              padding: 3px 4px;
              border-radius: 4px;
            }
            .table-cell-cta {
              background: rgba(255, 255, 255, 0.05);
              border: 1px solid rgba(255, 255, 255, 0);
              border: none;
              padding: 5px 8px;
              border-radius: 3px;
              color: #fff;
              letter-spacing: 1px;
              font-weight: 600;
              text-transform: uppercase;
              cursor: pointer;
              font-size: 11px;
              text-decoration: none;
              outline: none;
              margin: 0px 5px;
              &.active {
                border: 1px solid rgba(254, 254, 254, 0.5);
              }
              &:hover {
                background: rgba(255, 255, 255, 0.2);
              }
            }
            .removing-key {
              background: rgba(255, 255, 255, 0);
              border: none;
              padding: 5px 8px;
              border-radius: 3px;
              color: #e91e63;
              letter-spacing: 1px;
              font-weight: 600;
              text-transform: uppercase;
              font-size: 11px;
              cursor: pointer;
            }
          }
        }
      }
      .ToggleTable__footer {
        background: #202227;
        height: 100px;
        button {
          cursor: pointer;
          i {
            font-size: 60px;
            color: white;
          }
        }
        .ToggleTable__footer__left {
          float: left;
          text-align: left;
          padding: 30px;
          width: 50%;
        }
        .ToggleTable__footer__right {
          float: right;
          text-align: right;
          padding: 30px;
          width: 50%;
        }
      }
    }
  }
  .snowflake {
    min-width: 28px;
    max-height: 25px;
    width: 28px;
    height: auto;
    cursor: pointer;
  }
  .blueTip {
    background-color: #d6ecef;
    color: black;
    text-align: left;
  }
  .blueTip[class$="arrow"] {
    border-top: 5px solid #d6ecef !important;
  }
}

.AUserList {
  .ModalMaster {
    &.active {
      visibility: visible;
      opacity: 1;
      .Modal__wrapper {
        transform: scale(1);
      }
    }
    .Modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      background: rgba(0, 0, 0, 0.5);
      align-items: flex-start;
      @media screen and (min-width: 640px) {
        align-items: center;
      }
      &.active {
        visibility: visible;
        opacity: 1;
        .Modal__wrapper {
          // transform: scale(1);
          top: -50%;
          transform: scale(1) translateY(calc(50% + 10vh));
          margin: 10vh 0;
          padding: 30px;
          @media #{$mobileOnly} {
            transform: none !important;
            margin: 5vh 0 !important;
            top: 0 !important;
          }
        }
      }
      .Modal__layer {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      .Modal__wrapper {
        width: 90%;
        max-width: 825px;
        min-height: 300px;
        background: #202227;
        padding: 0;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
        // margin: 5vw 0 5vh 0;
        @media screen and (min-width: 640px) {
          margin: 0;
        }
      }
      .Modal__download {
        max-width: 700px;
        .Modal__download--spinner {
          padding-top: 140px;
        }
        .Modal__download--content {
          padding-bottom: 50px;
          .Modal__download--header {
            padding: 50px 0 30px 0;
            font-size: 26px;
          }
          .Modal__download--body {
            .Modal__download--row {
              padding: 15px 0;
            }
          }
          .Modal__download--footer {
            padding: 50px 0 15px 0;
            .Modal__download--cta {
              cursor: pointer;
              padding: 5px 10px;
              border-radius: 5px;
              font-size: 18px;
              color: white;
              border: 1px solid white;
              text-decoration: none;
            }
          }
          a {
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 18px;
            color: white;
            border: 1px solid white;
            text-decoration: none;
            margin: 0px 5px;
          }
        }
      }
      .AddToQuest {
        button {
          color: $colorLight;
          background: $colorBackground2;
          padding: 4px 7px;
          margin: 30px 10px 0px 0px;
          border: 1px solid $colorBackground2;
          border-radius: 5px;
          cursor: pointer;
          &.active {
            border: 1px solid $colorLight;
          }
          &:focus {
            outline: 0;
          }
        }
        .AddToQuest__title {
          font-size: 18px;
          margin-bottom: 20px;
          font-weight: 700;
        }

        .AddToQuest__close {
          // margin-top: 40px;
        }
      }
      .DisplayAttributes {
        padding: 30px 0;
        color: $colorLight;

        hr {
          width: 80%;
          opacity: 20%;
          margin-top: 20px;
        }

        button {
          color: $colorLight;
          background: $colorBackground2;
          padding: 4px 7px;
          margin-right: 5px;
          border: 1px solid $colorBackground2;
          border-radius: 5px;
          cursor: pointer;
          height: 30px;
          &.active {
            border: 1px solid $colorLight;
          }
          &:focus {
            outline: 0;
          }
        }
        .DisplayAttributes__title {
          font-size: 18px;
          margin-bottom: 15px;
          font-weight: 700;
        }
        .DisplayAttributes__row {
          display: flex;
          justify-content: center;
          padding-top: 15px;
        }
        .DisplayAttributes__fieldGroup {
          margin-bottom: 10px;
          flex: 1;
          .DisplayAttributes__field__label {
            margin-bottom: 2px;
          }
        }
        .DisplayAttributes__close {
          margin-top: 40px;
        }
      }
      .Filters {
        @include col-center;

        padding: 30px 0;
        color: $colorLight;
        button {
          color: $colorLight;
          background: $colorBackground2;
          padding: 4px 7px;
          margin-right: 5px;
          border: 1px solid $colorBackground2;
          border-radius: 5px;
          cursor: pointer;
          &.active {
            border: 1px solid $colorLight;
          }
          &:focus {
            outline: 0;
          }
        }
        .Filters__title {
          font-size: 18px;
          font-weight: 700;
          margin: 10px 0;

          &.left {
            text-align: left;
          }
        }
        .Filters__fieldGroup {
          margin: 10px 0;
          .Filters__field__label {
            margin-bottom: 2px;
          }
          input {
            background-color: #202227;
            border: none;
            border-bottom: 2px solid #83858c;
            border-radius: 0px;
            color: white;
            display: inline-block;
            padding-left: 0;
            text-align: left;
            margin: 0;
            width: 100%;

            &.minMax {
              width: 100px;
              font-size: 12px;
              text-align: center;
              padding-left: 8px;
            }
          }
          input:focus {
            box-shadow: none;
          }
        }
        .Filters__close {
          margin-top: 40px;
        }
      }
      .Sorting {
        padding: 30px 0;
        color: $colorLight;
        button {
          color: $colorLight;
          background: $colorBackground2;
          padding: 4px 7px;
          margin-right: 5px;
          border: 1px solid $colorBackground2;
          border-radius: 5px;
          cursor: pointer;
          &.active {
            border: 1px solid $colorLight;
          }
          &:focus {
            outline: 0;
          }
        }
        .Sorting__title {
          font-size: 18px;
          margin-bottom: 15px;
          font-weight: 700;
        }
        .Sorting__fieldGroup {
          margin-bottom: 10px;
          .Sorting__field__label {
            margin-bottom: 2px;
          }
        }
        .Sorting__close {
          margin-top: 40px;
        }
      }
    }
  }
  .quest-acceptance-address {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(254, 254, 254, 0.1);
    margin: 10px 10%;
    border-radius: 10px;
    max-width: 80%;
    padding: 10px;
    :nth-child(1) {
      margin-bottom: 4px;
      font-weight: 500;
      text-transform: uppercase;
    }
    :nth-child(2) {
      padding: 10px;
      border-radius: 10px;
      cursor: pointer;
      max-width: 85%;
      align-self: center;
      text-overflow: wrap;
      overflow-wrap: break-word;
      &:hover {
        background: rgba(254, 254, 254, 0.1);
      }
    }
  }
}
