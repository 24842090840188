@import "../../../../../utils/_variables";

$paddingSize: 40px;

.ReviewDeliverable {
  background-color: $colorBackground;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: $paddingSize;
  position: relative;
  width: 100%;

  .StreamerHead__SubmittedDisclaimer {
    background-color: rgb(57, 57, 57);
    border-radius: 10px;
    color: rgb(180, 186, 103);
    max-width: 50%;
    padding: 5px;
    text-align: center;
    width: 100%;
  }

  .StreamerHead {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    .StreamerHead__TimeRemaining {
      align-items: center;
      display: flex;
      position: absolute;
      right: 0px;
      top: 55px;

      .StreamerHead__TimeRemaining__Content {
        align-items: center;
        display: flex;
        position: relative;

        .StreamerHead__TimeRemaining__Backdrop {
          background-color: $colorCyan;
          height: 40px;
          position: absolute;
          right: 0;
          top: -10px;
          width: 70%;
          z-index: 1;
        }

        .StreamerHead__TimeRemaining__Time {
          align-items: center;
          background-color: $colorPurpleRay;
          color: $colorWhite;
          display: flex;
          font-size: 16px;
          font-stretch: normal;
          font-style: normal;
          font-weight: bold;
          height: 40px;
          letter-spacing: normal;
          line-height: 1.38;
          padding: 9px;
          text-align: right;
          z-index: 2;

          svg {
            height: 19px;
            width: 19px;
          }
        }
      }
    }

    .StreamerHead__Avatar {
      @include center-content;
      margin-top: 100px;
      position: relative;
      width: 100%;

      .StreamerHead__Avatar__ProgressRing {
        position: absolute;
        transform: translate(-50%, -50%);
        transform: rotate(-90deg);
      }

      .StreamerHead__Avatar__Img {

        border-radius: 50%;
        max-height: 174px;
        max-width: 174px;
        min-height: 174px;
        min-width: 174px;
      }
    }

    .StreamerHead__QuestProgress {
      align-items: center;
      display: flex;

      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: 1.58;
      margin-bottom: 10px;

      margin-top: 30px;
      text-align: left;

      svg {
        height: auto;
        width: 19px;
      }

      &.Completed {
        color: $colorCandyGreen;
      }

      &.Incomplete {
        color: $colorSoftDarkPurple;
      }
    }
  }

  .StreamerPayments {
    @include col-center;

    div {
      margin-bottom: 10px;
    }

    width: 100%;

    .StreamerPayments__Username {
      font-size: 30px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.3px;
      line-height: 1;
      margin: 10px 0px;
      text-align: center;
    }

    .StreamerPayments__Amount {
      font-size: 70px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.7px;
      line-height: 0.86;
      text-align: center;
    }

    .StreamerPayments__Buttons {
      @include row-between;
      margin: 10px 0;
      min-width: 300px;
      width: 100%;

      .StreamerPayments__Button {
        @include button-base;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 900;
        height: 53px;
        letter-spacing: normal;
        line-height: normal;
        min-width: 100px;
        text-align: center;
        width: 45%;

        &.Release {
          background-image: linear-gradient(to bottom, #62e841, #4be0ca);
          color: $colorBlack;
        }

        &.Reject {
          background-color: $colorCandyRed;
          color: $colorWhite;
        }

        &.disabled {
          background-color: $colorBGray;
          background-image: none;
          color: $colorGray;
          cursor: not-allowed;
        }
      }
    }
  }

  .StreamerDeliverables {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;

    .StreamerDeliverables__Title {
      font-size: 42px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.42px;
      line-height: 1.43;
      text-align: center;
    }

    .StreamerDeliverables__List {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      @media #{$mobileOnly} {
        flex-direction: column;
        flex-wrap: nowrap;
      }



      .StreamerDeliverablesCard {
        align-items: center;
        background-color: $colorCDarkGray;
        border-radius: 8px;
        display: flex;
        height: 95px;
        justify-content: space-between;
        margin: 9px;
        padding: 20px;
        width: 45%;

        @media #{$mobileOnly} {
          width: 100%;
        }

        @media screen and (min-width: 1025px) and (max-width: 1129px) {
          width: 100%;
        }

        &.Clickable {
          cursor: pointer;
        }

        svg {
          height: auto;
          width: 22px;
        }

        .StreamerDeliverablesCard__Icon {
          height: auto;
          margin-right: 8px;
          width: 22px;
        }

        .StreamerDeliverablesCard__Title {
          flex-basis: 60%;
          font-size: clamp(12px, 1.4vw, 16px);
          font-stretch: normal;
          font-style: normal;
          font-weight: 900;
          letter-spacing: normal;
          text-align: left;
        }

        .StreamerDeliverablesCard__Completion {
          @include center-content;
          border-radius: 50%;
          height: 40px;
          min-width: 40px;
          width: 40px;

          svg {
            height: 22px;
            width: 22px;
          }

          &.Completed {
            background-color: $colorCandyGreen;
          }

          &.Incomplete {
            border: 3px solid $colorSoftGray;
          }

          &.Rejected {
            border: 3px solid $colorCandyRed;
          }

          &.Pending {
            border: 3px solid $colorSoftDarkPurple;
          }
        }
      }
    }
  }

  .QuestInformation {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;

    .QuestInformation__Title {
      font-size: 30px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.3px;
      line-height: 1;
      margin-bottom: 16px;
      text-align: left;
    }

    .InfoHeader {
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      letter-spacing: normal;
      line-height: normal;
      margin: 10px 0;
      text-align: left;
    }

    .QuestInformation__Buttons {
      @include center-content;
      margin-top: 25px;
      width: 100%;

      .QuestInformation__Button {
        @include purple-gradient-button;
        height: 48px;
        width: 35%;
      }
    }
  }
}
