@import "../../../../../utils/_variables";

.Assets {
  display: flex;
  flex-direction: column;

  width: 100%;

  & > * {
    margin-bottom: 25px;
  }

  .Assets_Header {
    font-size: 26px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.26px;
    text-align: left;
  }

  .Assets_Card {
    display: flex;
    flex-direction: column;

    width: 100%;

    -webkit-transition: height 1s;
    -moz-transition: height 1s;
    -ms-transition: height 1s;
    -o-transition: height 1s;
    transition: height 1s;

    .Assets_Card_Header {
      margin-bottom: 25px;

      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.16px;
      text-align: left;
    }

    .Assets_Card_Content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      min-height: 117px;
      width: 100%;

      padding: 20px;

      background-color: $colorCDarkGray;
      border: 2px solid transparent;

      .Assets_Card_Img {
        min-height: 77px;
        max-height: 77px;
        min-width: 77px;
        max-width: 77px;

        object-fit: cover;
      }

      .Assets_Card_Text {
        color: $colorCyan;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
      }

      .Assets_Card_Delete {
        @include center-content;

        svg {
          @include transform-scale;

          height: auto;
          width: 18px;

          cursor: pointer;
        }
      }
    }

    .Error {
      border: 2px solid $colorCandyRed;
    }

    .Populated {
      .Assets_Card_Text {
        width: 70%;
      }
    }

    .Empty {
      display: flex;
      flex-direction: column;

      .EmptyCard {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;

        svg {
          height: 77px;
          width: auto;
        }

        .Assets_Card_Text {
          width: 28%;
        }

        .Assets_Card_Select {
          @include gray-border-button;

          height: 41px;
          width: 28%;
        }

        .Assets_Card_Upload {
          @include purple-gradient-button;

          height: 41px;
          width: 28%;
        }
      }

      .Assets_Card_Modal {
        display: flex;
        flex-wrap: wrap;

        max-height: 0;
        width: 100%;
        margin-top: 0px;

        -webkit-transition: height 1s;
        -moz-transition: height 1s;
        -ms-transition: height 1s;
        -o-transition: height 1s;
        transition: height 1s;

        background-color: $colorBackground;
        border-radius: 12px;

        overflow: auto;

        .GalleryCards {
          display: flex;
          flex-wrap: wrap;

          .GalleryCards__Img {
            min-height: 100px;
            max-height: 100px;
            min-width: 100px;
            max-width: 100px;

            margin-right: 10px;
            margin-bottom: 10px;

            border: 2px solid transparent;
            cursor: pointer;
            border-radius: 8px;
            object-fit: cover;

            &:hover {
              border: 2px solid $colorCyan;
            }
          }
        }
      }

      .Active {
        max-height: 130px;
        margin-top: 20px;
        padding: 15px;
      }
    }
  }
}
