@import "../../../../../utils/_variables";

.TableBuilder__MaxHeight {
  max-height: 600px;
  overflow: auto;

  scrollbar-width: thin !important;

  scrollbar-width: 1px; /* Firefox */
  &::-webkit-scrollbar {
    // color: $colorLightGray;
  }
}

.TableBuilder {
  height: 100%;
  width: 100%;

  .SetFixed {
    position: sticky;
    top: 0;
    width: 100%;
  }

  .Schedule_Cell {
    width: 200px;
    word-break: normal;
    overflow-wrap: normal;
    white-space: normal;

  }

  td,
  th {
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    white-space: nowrap;
    padding: 5px;
  }
  // header overrides
  th {
    min-height: 52px;
    position: sticky;
    top: 0;
  }

  .TableBuilder_Row_Base {
    color: white;
  }
  .TableBuilder_Row_Header {
    th {
      min-height: 60px;
      padding: 10px;
      background-image: linear-gradient(to bottom, #e8cdfe, #b7bbfd);
      border-collapse: collapse;
    }
    //first and last child add border radius of 4px
    th:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    th:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .TableBuilder_TotalsRow {
    td {
      min-height: 60px;
      border-radius: 4px;
      padding: 10px;
      background-image: linear-gradient(to bottom, #4be0ca, #62e841);
      color: black;
      border-radius: 0;
      border-collapse: collapse;
      font-weight: 600;
    }
  }

  .TableBuilder_Cell_Profile {
    border-right: 1px solid lightslategray;
    border-bottom: 1px solid lightslategray;
  }
  .TableBuilder_TableDivs {
    @include col-evenly;
    align-items: center;
    div {
      width: 100% !important;
    }

    // set first child margin to 5px
    &:first-child {
      margin-bottom: 10px;
    }
    
  }

  .TableBuilder_Row_ProfileImage {
    // style an image to be a circle
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 10px 0px 0px 10px;
  }

  .TableBuilder_NestedCell {
    @include col-start;
    width: 100%;
    border: none !important;
  }

  .TableBuilder_Cell_Base {
    width: 100%;
    height: 100%;
    border-right: 1px solid lightslategray;
    border-bottom: 1px solid lightslategray;
    word-wrap: break-word;
    max-width: 210px;
  }

  .TableBuilder_Row_Twitch {
    background-color: #a142ff;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }

  .TableBuilder_Row_Twitter {
    background-color: #64a9eb;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }
}

.TableBuilder_FilterBar {
  width: 100%;
  @include row-end;
  margin: 20px 0px;
  div:first-child {
    margin-right: 30px;
  }

  .TableBuilder_FilterBar_Search {
    input {
      width: 200px;
      height: 45px;
      margin: 28px 13px 38.5px;
      padding: 13px 14px 13px 15px;
      border-radius: 4px;
      background-color: #a142ff;
      color: white;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        &:focus {
          outline: none;
          background-color: transparent;
        }
      }
    }
  }

  .TableBuilder_FilterBar_Filter {
    width: 71px;

    select {
      width: 71px;
      height: 45px;
      margin: 28px 13px 38.5px 9px;
      padding: 13px 10px 13px 14px;
      border-radius: 4px;
      background-color: #a142ff;
      color: white;
    }
    cursor: pointer;
  }
}
