	.galleryItem {
		min-height: 30%;
		width: calc(50% - 16px);
		margin: 16px 0;
		position: relative;
		cursor: pointer;
		object-fit: cover;
		max-width: 500px;

		

		.galleryImage {
			// width: 100%;
			// height: 100%;
			max-height: 280px;
		}

		img {
			object-fit: cover;
			width: 100%;
		}
		span.cornerBtn {
			position: absolute;
			bottom: 0;
			right: 0;
			height: 0;
			width: 0;
			border-bottom: 48px solid rgba(0, 0, 0, 0.75);
			border-left: 48px solid transparent;
			opacity: 0;
		  transition: opacity 0.3s ease;
		}

		&:hover {
		  box-shadow: 0 0 24px 0 rgba(255, 255, 255, 0.25);
		  transition: 0.3s ease;

		  span.cornerBtn {
			  display: block;
			  opacity: 1;
		  }
		}
	}



