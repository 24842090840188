
@import "../../../../../utils/_variables";

.Portal_ReportMetric_Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // max-width: 610px;
  padding: 2% 0%;
  border-radius: 5px;
  min-width: 200px;
  min-height: 200px;

  .Portal_ReportMetric_Number {
    font-family: Roboto;
    font-weight: 900;
    @include vw-pixels(
      (
        "font-size": 50,
      )
    );
    @media #{$mobileAndTabletOnly} {
      @include vw-pixels(
        (
          "font-size": 80,
        )
      );
    }
  }

  .Portal_ReportMetric_Title {
    font-family: Roboto;
    font-weight: bold;
    text-align: left;
    @include vw-pixels(
      (
        "font-size": 14,
      )
    );
    @media #{$mobileAndTabletOnly} {
      @include vw-pixels(
        (
          "font-size": 25,
        )
      );
    }
  }
}
