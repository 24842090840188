@import '../../../../../utils/_variables';

$padding: 23px;

.QuestEdit {
  display: flex;
  flex-direction: column;

  // height: 100%;
  width: 100%;

  padding: $padding;

  background-color: $colorBackground;
  border-radius: 8px;

  .QuestEdit__Title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 46px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.46px;
    text-align: left;
  }

  .QuestEdit__Agreement {
    display: flex;
    align-items: center;
    // max-height: 400px;
    overflow: auto;

    margin-top: 10px;

    background-color: $colorBlack;
    border-radius: 8px;
    
    .QuestEdit__Agreement__Text {
      height: 400px;
      padding: 10px;
      white-space: pre-wrap;
    }
  }

  .QuestEdit__AverageViewers__Header {
    // margin-bottom: 10px;
    margin-top: 12px;

    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.16px;
    text-align: left;

    svg {
      height: 16px;
      width: auto;

      cursor: help;
    }
  }

  .QuestEdit__Tracking {
    display: flex;
    flex-direction: column;

    margin: 0 (-1 * $padding);
    padding: $padding (2 * $padding);
    // width: 100%;

    background-color: $colorSoftPurple;
    color: $colorBlack;

    .QuestEdit__Tracking__Header {
      font-size: 26px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 0.26px;
      text-align: left;
    }
  }

  .QuestEdit__Submit_Area {
    @include center-content;

    width: 100%;

    padding: $padding;

    .QuestEdit__Button {
      @include purple-gradient-button;

      height: 45px;
      width: 70%;
    }
  }
}