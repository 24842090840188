@import "../../utils/_variables";

.NotFound {
  display: flex;
  width: 100%;
  //background: rgba(0,0,0,0.8);
  background: url(//images/404-game-bg.jpg);
  min-height: 50vh;
  &__wrapper {
    //max-width: 1024px;
    padding: 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.8);

    width: 100%;
    min-height: calc(100vh);
  }
  &__content {
    max-width: 1024px;
    position: relative;
    min-height: 200px;
    width: 100%;
    //max-width: 480px;
    margin: 0 auto;

    @media only screen and (max-width: 1024px) {
      text-align: center;
      h1,
      p {
        text-align: center;
        width: 100% !important;
      }
    }
    figure {
      @media only screen and (max-width: 1024px) {
        position: relative;
        margin: auto;
        width: auto;
        max-width: 500px;
        float: none;
      }

      //position: absolute;
      //bottom: 0;
      float: right;
      right: 0;
      width: 500px;
      margin: 0;
      img {
        width: 100%;
      }
    }
    .back-home {
      margin-top: 20px;
      padding: 15px 50px !important;
      border-radius: 5px !important;
      background-image: linear-gradient(
        #3a74e0,
        #3f75dc,
        #4b73da,
        #5b6cdf,
        #6762e8
      );
    }
    .info-wrap {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
      /* vertical-align: sub; */
      float: none;
      position: absolute;
      @media only screen and (max-width: 1024px) {
        display: block;
        width: 100%;
        position: relative !important;
        .inner {
          br {
            display: none;
          }
          h1 {
            font-size: 40px !important;
            line-height: 45px !important;
          }
        }
      }
      .inner {
        text-shadow: -1px 1px 2px $colorBackground, 1px 1px 2px $colorBackground,
          1px -1px 0 $colorBackground, -1px -1px 0 $colorBackground;
      }
    }

    h1 {
      font-size: 72px;
      line-height: 80px;
      font-weight: 400;
      margin: 20px 0;
    }

    p {
      width: 80%;
      margin: 20px 0;
    }

    a {
      display: inline-flex;
      text-decoration: none;
      font-size: 16px;
    }
  }
}
