.SignUp {
	.ModalMaster {
		.Modal {
			.Modal__wrapper {
				.Modal__right {
					ul.Modal__tabs {
						margin-bottom: 20px;
					}
				}		
			}	
		}
	}
}
