.run-button {
  background-color: #29b5e8;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  height: 28px;
  padding: 0 12px;
  text-align: center;
  width: 85px;

  svg {
    margin-bottom: -2px;
    margin-right: 6px;
    width: 12px;
  }
}