.RangeFilter {
	background: #2b2e38;
	box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.38);
	min-width: 130%;
	position: absolute;
	top: 100%;
	z-index: 10001;

	.selectedBar {
		background: #3e424d;
		font-size: 18px;
		font-weight: 400;
		padding: 15px;
	}

	.selectedNumbers {
		display: flex;
		justify-content: space-between;
	}

	.selector {
		padding: 15px 15px 0px 15px;
	}
}