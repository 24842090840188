@import '../../../../utils/variables';
.CCQuestAffiliateActivity{
  h2{
    margin: 0 0 10px 0;
  }
  .CCQuestAffiliateActivity__tabs {
    display: flex;
    margin-bottom: 20px;
    background: #2B2E38;
    .CCQuestAffiliateActivity__tab {
      padding: 20px 30px;
      font-size: 16px;
      cursor: pointer;
      color: $colorLightGray;
      &.active {
        color: $colorLifeGreen;
        border-bottom: 2px solid $colorLifeGreen;
      }
      span {
        background: $colorLifeGreen;
        color: white;
        padding: 3px 4px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
  .CCQuestAffiliateActivity__loading{
    background: #2b2e38;
    text-align: center;
    padding: 50px 0;
    i {
      text-align: center;
    }
  }
    .buttonStyle{
      height: 50px;
      border-radius: 30px;
      background: linear-gradient(to right, #45cacd, #47b8d4);
      color: #FFF;
      border: none;
      outline: none;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 22px;
      letter-spacing: 1.2px;
      margin: 20px 0 20px 0;
      cursor: pointer;
    }
    .CCQuests__back--btn{
      @extend .buttonStyle;
      width: 300px;
    }
    .CCQuests__save--btn{
      @extend .buttonStyle;
      width: 100%;
    }
  
    .ToggleTable {
      .ToggleTable__header {
        background: #2b2e38;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 30px;
        border-bottom: 1px solid #454545;
        // border-top: 1px solid #1d1e23;
        border-top: 1px solid #454545;
        .ToggleTable__header-title {
          padding: 0;
          margin: 0;
          font-size: 22px;
          letter-spacing: 2.5px;
        }
        .dot-status{
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: #ef3937;
          box-shadow: 0 0 9px 0 #ff987f;
          margin-left: 10px;
        }
        i{
          font-size: 30px;
          line-height: 18px;
          margin-left: auto;
        }
      }
      .ToggleTable__table-wrapper {
        background: #2b2e38;
        padding: 30px;
        .no-results {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 44px;
          margin-top: 30px;
        }
        .row{
          border-bottom: 1px solid #1e1f25;
          padding: 0 10px;
          &.first-row { 
            justify-content: flex-end; 
            a { 
              color: white; 
            } 
          } 
          &.toggable{
            flex-wrap: wrap;
            .row-basic{
              width: 100%;
              display: flex;
              flex-flow: row nowrap;
            }
            .row-details{
              width: 100%;
              display: none;
            }
            &.open{
              .row-details{
                display: flex;
                .StreamActivityTable{
                  width: 100%;
                  border: 1px solid rgba(0,0,0,0.2);
                  margin: 20px 10px;
                  .table-cell{
                    &.green {
                      color: #49e1ca;
                      a {
                        color: #49e1ca;
                      }
                    }
                    i {
                      &.green {
                        color: #49e1ca;
                      }
                    }
                    &.capitalize {
                      text-transform: capitalize;
                    }
                    button {
                      color: white;
                      border-radius: 5px;
                      padding: 5px 10px;
                      margin-left: 5px;
                      background-color: #444546;
                      cursor: pointer;
                    }
                    &.headline{
                      font-size: 14px;
                      color: #9b9b9b;
                      font-weight: bold;
                      letter-spacing: 1.2px;
                    }
                    &:first-child{
                      justify-content: flex-start;
                      max-width: initial;
                    }
                    
                  }
                }
              }
            }
          }
          &:hover {
            background: rgba(255,255,255,0.025);
            &:first-child {
              background: rgba(255,255,255,0);
            }
          }
          &:last-child{
            border-bottom: none;
          }
          .table-cell{
            justify-content: center;
            a{
              color: white;
              font-weight: 300;
            }
            &.success {
              color: #49e1ca;
            }
            &.warning {
              color: #ffd300;
            }
            &.error {
              color: #e91e63;
              a {
                color: #e91e63;
              }
            }
            &.capitalize {
              text-transform: capitalize;
            }
            &.headline{
              color: #9b9b9b;
              font-weight: 400;
              letter-spacing: 1.2px;
            }
            &:first-child{
              justify-content: flex-start;
              max-width: 50px;
            }
            &:nth-child(2){
              justify-content: flex-start;
              // font-weight: bold;
            }
            &:nth-child(3){
              justify-content: flex-start;
            }
            &:nth-child(4){
              justify-content: flex-start;
            }
            &:last-child{
              justify-content: flex-end;
              align-items: center;
            }
            .small-btn {
              background: rgba(255,255,255,0.05);
              border: none;
              padding: 5px 8px;
              border-radius: 3px;
              color: #FFF;
              letter-spacing: 1px;
              font-weight: 600;
              text-transform: uppercase;
              cursor: pointer;
              font-size: 11px;
              text-decoration: none;
              margin-right: 5px;
              &.open{
                background: rgba(255,255,255,.15);
              }
              &:last-child{
                margin-right: 0;
              }
              &:hover{
                background: rgba(255,255,255,.2);
              }
  
            }
            .small-btn.waiting {
              background: rgba(255,255,255,0);
              border: none;
              padding: 5px 8px;
              border-radius: 3px;
              color: #e91e63;
              letter-spacing: 1px;
              font-weight: 600;
              text-transform: uppercase;
              font-size: 11px;
              text-decoration: none;
              cursor: pointer;
            }
          }
        }
      }
    }
    .NoQuestSale{
        padding-top: 10px;
    }
  }
  