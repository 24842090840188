input[type=password] {
  min-height: 35px;
  // margin: 1px;
}

input[type=text] {
  min-height: 35px;
  // margin: 1px;
}
select {
  min-height: 35px;
  // margin: 1px;
}
// input:focus {
//     background-color: transparent !important;
//   }
input {
  border: 0;
}

button {
  background-color: transparent;
  border: 0;

}
