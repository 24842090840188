@import  '../../../../utils/_variables';
.CCCampaignEdit{
  .CampaignLoading {
    text-align: center;
    margin-top: 100px;
  }
  .add_faq{
    color: #FFF;
    border-radius: 5px;
    outline: none;
    height: 50px;
    margin: 20px auto;
    border: 1px dashed rgba(255, 255, 255, 0.5);
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    cursor: pointer;
    -webkit-transition: 300ms ease background;
    -o-transition: 300ms ease background;
    transition: 300ms ease background;
  }
  .info-tab-wrap{
    width:100% !important;
    .QFLog {
      margin-top: 20px;
      .QFLog_top {

      }
      .QFLog_bot {
        .QFLog_bot_key {
          color: $colorLightGray;
          font-size: 14px;
          margin-top: 3px;
          &.QFLog_bot_key_button {
            cursor: pointer;
          }
          .old {
            border-radius: 4px;
            color: $colorLight;
            background-color: rgba(251, 159 ,177, 0.4);;
            font-weight: 300;
            padding: 1px;
            text-decoration: line-through;
          }
          .new {
            color: $colorLight;
            border-radius: 4px;
            background-color: rgba(161, 198 ,89, 0.4);
            font-weight: 300;
            padding: 1px;
          }
        }
      }
      .ModalCampaignLogs {
        font-family: Roboto;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%;
        padding-top: 120px;
        position: absolute;
        .ModalMaster {
          .Modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            opacity: 0;
            overflow-x: hidden;
            background: rgba(0,0,0,0.1);
            .Modal__layer {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              cursor: pointer;
            }
            align-items: flex-start;
            @media screen and (min-width: 640px) {
              align-items: flex-start;
            }
            .Modal__wrapper {
              width: 90%;
              max-width: 1300px;
              height: 600px;
              max-height: 700px;
              background: #202227;
              padding: 0 15px 0 15px;
              position: relative;
              text-align: center;
              align-items: center;
              justify-content: center;
              transform: scale(0.8);
              margin: 5vw 0 5vh;
              max-height:2000px !important;
              padding-bottom:50px !important;
              height:auto !important;
              @media screen and (max-width: 640px) {
                max-height:2000px !important;
                padding-bottom:50px !important;
                height:auto !important;
              }
              @media #{$mobileOnly}{
                padding: 0 10% 0 10%;
              }
              .Modal__title {
                font-size: 25px;
                letter-spacing: 2px;
                margin: 20px 0 0;
              }
              .Modal__close-btn {
                width: 35px;
                height: 35px;
                margin-left: auto;
                position: absolute;
                right: 30px;
                top: 30px;
                cursor: pointer;
                &:before {
                  content: "";
                  width: 3px;
                  height: 90%;
                  background: #37393D;
                  display: block;
                  position: absolute;
                  left: 50%;
                  transform-origin: center;
                  transform: rotate(45deg);
                }
                &:after {
                  content: "";
                  width: 3px;
                  height: 90%;
                  background: #37393D;
                  display: block;
                  position: absolute;
                  left: 50%;
                  transform-origin: center;
                  transform: rotate(-45deg);
                }
              }
            }
            &.active {
              visibility: visible;
              opacity: 1;
              .Modal__wrapper {
                transform: scale(1);
              }
            }
          }
          .Modal__ModalCampaignLogs {
            a, button {
              color: #3a74e0;
              cursor: pointer;
              font-weight: 700;
              text-decoration: none;
              @media screen and (max-width: 1023px) {
                &.desktop{
                  display:none !important;
                }
              }
              @media screen and (min-width: 1024px) {
                &.mobile{
                  display:none !important;
                }
              }
            }
            .Modal__wrapper {
              max-height: 650px;
              .ModalCampaignLogs__body {
                margin-top: 70px;
                margin-bottom: 30px;
                display: flex;
                @media #{$mobileOnly}{
                  display: block;	
                }
                .ModalCampaignLogs__olds{
                  flex: 1;
                  border-right: 1px solid #37393D;
                  // display: flex;
                  // flex-direction: column;
                  // align-items: center;
                  padding-right: 15px;
                  overflow-x: auto;
                  ::-webkit-scrollbar {
                    width: 10px;
                  }
                  ::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #83858c;
                    outline: 1px solid #83858c;
                  }
                  @media #{$mobileOnly}{
                    border: none;
                    margin-bottom: 40px;
                  }
                  .ModalCampaignLogs__olds__title{
                    font-weight: 700;
                    font-size: 30px;
                    margin-bottom: 10px;
                  }
                  .ModalCampaignLogs__olds__row{
                    font-weight: 300;
                    margin: 5px 0;
                    text-align: left;
                  }
                }
                .ModalCampaignLogs__news{
                  flex: 1;
                  // display: flex;
                  // flex-direction: column;
                  padding-left: 15px;
                  // align-items: center;
                  overflow-x: auto;
                  ::-webkit-scrollbar {
                    width: 10px;
                  }
                  ::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #83858c;
                    outline: 1px solid #83858c;
                  }
                  @media #{$mobileOnly}{
                    margin-bottom: 40px;
                  }
                  .ModalCampaignLogs__news__title{
                    font-weight: 700;
                    font-size: 30px;
                    margin-bottom: 10px;
                  }
                  .ModalCampaignLogs__news__row{
                    font-weight: 300;
                    margin: 5px 0;
                    text-align: left;
                  }
                }
              }
              .ModalCampaignLogs__footer {
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom:30px;
                .ModalCampaignLogs__footer__button {
                  .ModalCampaignLogs__footer__buttonA {
                    display: block;
                    background-color: #3A74E0;
                    color: white;
                    cursor: pointer;
                    text-decoration: none;
                    text-align: center;
                    font-weight: normal;
                    border-radius: 4px;
                    font-weight: 900;
                    font-size: 16px;
                    padding: 10px 30px;
                  }
                }
              }
            }
          }
        }
      }      
    }
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip .tooltiptext {
      visibility: hidden;
      width: 200px;
      font-size: 12px;
      line-height: 13px;
      background-color: $colorBackground;
      color: #fff;
      text-align: center;
      padding: 10px;
      border-radius: 6px;
      bottom: 25px;
      left: -50%;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      /* Position the tooltip text - see examples below! */
      position: absolute;
      z-index: 100;
  }
  .tooltip:hover .tooltiptext {
      visibility: visible;
  }
  .buttonStyle{
    height: 50px;
    border-radius: 30px;
    background: linear-gradient(to right, #45cacd, #47b8d4);
    color: #FFF;
    border: none;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: 1.2px;
    margin: 20px 0 20px 0;
    cursor: pointer;
  }
  .CCCampaigns__back--btn{
    @extend .buttonStyle;
    width: 300px;
  }
  .CCCampaigns__save--btn{
    @extend .buttonStyle;
    width: 100%;
  }

  form.campaigns-edit {
    background-color: #202227;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
    .Form__row {
      display: flex;
      justify-content: space-between;
      .Form__row--2 {
        width: 49%;
      }
      .Form__row--3 {
        width: 32%;
      }
    }
    .Label__URL{
      color: white;
      margin-bottom: 10px;
    }
    .CampaignForm__userInfo {
      display: none;
      &.active {
        display: block;
      }
    }

    .FeaturedTweetsWrapper {

      span.buttons {
        display: flex;
        // width: 150px;

        .button {
          width: 44px;
          height: 44px;
          margin: 0 0 0 8px;
          border-radius: 6px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
          background-image: linear-gradient(to bottom, #57616b, #31383f);
          color: white;

          &.disabled {
            background-image: none;
            background: #2c3239;

            i {
              color: $colorGray;
            }
          }
        }
      }

      .RepeaterWrapper__fields {
        width: 100%;
      }

      button.RepeaterWrapper__add-item {
        cursor: pointer;
        font-weight: bold;
        color: $colorLifeGreen;
        width: 100%;
        padding: 25px 0;
        text-align: center;
        border: solid 1px rgba(255, 255, 255, 0.11);
        border-radius: 12px;
      }

      ul {
        padding: 0;
      }

      li {
        border-radius: 12px;
        border: 12px solid #3f434d;
        list-style: none;
        padding: 12px 12px 0 12px;
        margin: 16px 0 0 0;
        display: flex;
        justify-content: space-between;

        input {
          padding: 0;
          border: 0;
        }
      }
    }

    .input-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      &.summary-container {
        margin-bottom: 10px;
      }
      label{
        color: white;
      }
      &.input-radio{
        .error-message{
          color: #dc4f4f;
          font-size: 12px;
        }
      }
        .frequency-wrap{
          display:flex;
          label{
            display:inline-block;
            margin-right:20px;
            margin-bottom:20px;
          }
        }
        &.stream-time-wrap{
          .stream-time-inner-wrap{
            label{
              display:inline-block;
              width:70px !important;
              &.timeSeparator{
                width:10px !important;
                height:46px;
                vertical-align:bottom;
              }
            }
            .stream-label{
              vertical-align: bottom;
              height: 45px;
              display: inline-block;
              margin: 0 20px;
            }
              .input-container{
                width:70px !important;
              }
          }

          .input-wrapper{
            width: 64px !important;
          }
        }
      .input-wrapper{
        display: flex;
        flex-flow: column-reverse;
        margin-bottom: 1em;
        position: relative;
        .error-message{
          color: #dc4f4f;
          font-size: 12px;
          position: absolute;
          right: 0;
          bottom: -17px;
        }
        .error-icon{
          color: #dc4f4f;
          display: none;
          position: absolute;
          right: 10px;
          top: 10px;
        }
        .ok-icon{
          position: absolute;
          right: 10px;
          top: 5px;
          color: #49E1CA;
        }
      }

      input, select, textarea{
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #83858C;
        border-radius: 0px;
        color: white;
        display: inline-block;
        padding-left: 0;
        text-align: left;
        margin: 0;
        overflow: hidden;
        box-shadow: none;
      }
      textarea{
        min-width: 100%;
        max-width: 100%;
        min-height: 120px;
      }
      input.error, select.error, textarea.error{
        border-bottom: 2px solid #dc4f4f;
      }
      select {
        background-image: none;
      }
      a {
        text-decoration: none;
      }
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgb(131, 133, 140);
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgb(131, 133, 140);
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: rgb(131, 133, 140);
      }
      .first-option{
        color: rgb(131, 133, 140);
      }
      input:focus, select:focus, textarea:focus{
        border-bottom: 2px solid #3A74E0;
        box-shadow: none;
      }



      input:placeholder-shown + label {
        cursor: text;
        max-width: 66.66%;
        color:#d1d1d1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 1.9rem) scale(1.5);
        &:after{
          content: attr(data-label);
        }
      }

      input, select{
        background-color: transparent;
      }
      select option{
        background-color: #202227;
      }
      label{
        z-index:0;
        margin-left: 0;
      }
      label, input, textarea {
        transition: all 0.2s;
        touch-action: manipulation;
      }
    }
  }
  .mce-branding{
    display:none !important;
  }
  .main_desc .mce-path,
  .main_desc .mce-container{
      background-color:#2D2D33 !important;
  }
  .main_desc .mce-panel{
    border:0 solid #2D2D33 !important;
  }
  .info-tabs{
    div.tab{
     display:inline-block;
     padding:10px 30px;
     background-color:#444;
     &:hover{
       cursor:pointer;
       background-color:#282930;
     }
     &.selected{
       background-color:#282930;
     }
    }
  }
  .faqs_wrapper{
    background-color:#33343B;
    padding:20px;
    margin-bottom:20px;
    .remove_faq{
      color: #FFF;
      padding: 5px 10px;
      background: red;
      font-weight: 500;
      font-size: 14px;
      float:right;
    }

  }
  .react-select__control {
    background-color: rgba(0,0,0,0);
    border: 0;
    border-bottom: 2px solid #83858c;
    border-radius: 0;
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &.react-select__control--is-focused {
        border: 0;
        box-shadow: none;
        border-bottom: 2px solid #2684FF;
    }
    .react-select__input {
      color: #FFF;
      transition: none;
    }
    .react-select__input * {
      transition: none !important;
    }
    .react-select__value-container {
      padding-left: 0;
      .react-select__multi-value {
        border: 1px solid #808080;
        border-radius: 2px;
        overflow: hidden;
        background: #3e424d;
        .react-select__multi-value__label {
          color: #fbfbfb;
          font-weight: bold;
        }
        .react-select__multi-value__remove:hover {
          background: rgb(214, 0, 73);
          border-radius: 0;
          color: #fff;
        }
      }
    }
  }
  .tooltip {
    position: relative;
    display: inline-block;
    color: #fff;
    .tooltip-icon{
      margin-left: 5px;
    }
    .tooltip-content{
      visibility: hidden;
      width: 200px;
      cursor: default;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      bottom: 30px;
      right: 0;
      font-size: 10px;
      background-color: #16181c;
      position: absolute;
      z-index: 100;
      left: calc(50% + 2.5px);
      transform: translateX(-50%);
      &:before{
        background-color: #16181c;
        position: absolute;
        z-index: 100;
        content: "";
        width: 11px;
        height: 11px;
        bottom: -4.5px;
        right: 70px;
        border-top: 1px solid #16181c;
        border-left: 1px solid #16181c;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
      &:hover{
        visibility: visible;
      }
    }
    &:hover .tooltip-content{
      visibility: visible;
    }
  }
  .CampaignEdit__upload {
    color: $colorEnergyBlue;
    display: inline-block;
    // margin-bottom: 10px;
    padding: 5px 0;
    cursor: pointer;
  }
  .ModalMaster {
    .inputFile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .cta__button{
      background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
      color: #fff;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
      font-weight: normal;
      border-radius: 2px;
      font-weight: 600;
      font-size: 14px;
      padding: 10px 20px;
    }
    .Modal__upload {
      .Modal__wrapper {
        max-height: 350px;
        max-width: 600px;
        display: flex;
        flex-flow: wrap;
        background: $colorBackground;
        .Modal__upload__content {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          // margin: 4%;
          padding: 5px;
          width: 92%;
          height: 92%;
          cursor: pointer;
          .Modal__upload__image {
            padding-top: 30px;
            background: url('../../../../images/profile/upload.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 50px;
            width: 55px;
            margin-right: auto;
            margin-left: auto;
            position: relative;
          }
          .Modal__upload__title {
            padding-top: 25px;
            font-weight: 700;
            font-size: 20px;
            width: 100%;
            color: white;
            position: relative;
          }
          .Modal__upload__or {
            padding-top: 10px;
            font-size: 14px;
            color: $colorLightGray;
            width: 100%;
            position: relative;
          }
          .Modal__upload__action {
            padding-top: 10px;
            position: relative;
            button, label {
              padding: 15px 30px;
              font-weight: 900;
              font-size: 16px;
            }
            label {
              width: 50%;
              line-height: 1;
              margin: 0 auto;
            }
          }
          .Modal__upload__loading {
            margin-top: 20px;
            height: 70px;
            margin-right: 40px;
            margin-left: 40px;
            position: relative;
            .Modal__upload__loading__progress {
              position: absolute;
              height: 100%;
              width: 42%;
              background-color: $colorEnergyBlue;
            }
            .Modal__upload__loading__percentage {
              position: absolute;
              font-weight: 900;
              right: 10px;
              font-size: 30px;
              line-height: 70px;
              color: $colorBackground2;
            }
            .Modal__upload__loading__fileinfo {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .Modal__upload__loading__top {
                position: relative;
                font-size: 18px;
                text-align: left;
                padding-left: 15px;
              }
              .Modal__upload__loading__bottom {
                position: relative;
                font-size: 12px;
                text-align: left;
                font-weight: 300;
                padding-left: 15px;
              }
            }
          }
          .Modal__upload__title2 {
            padding-top: 10px;
            font-weight: 700;
            font-size: 20px;
            width: 100%;
            color: white;
            position: relative;
          }
          .Modal__upload__button {
            cursor: pointer;
            padding-top: 10px;
            color: $colorEnergyBlue;
            font-size: 14px;
            font-weight: 700;
          }
          .Modal__upload__avatar {
            color: #FFF;
            display: flex;
            width: 140px;
            height: 140px;
            align-items: center;
            justify-content: center;
            justify-self: center;
            margin-right: auto;
            margin-left: auto;
            font-family: 'Lato', sans-serif;
            position:relative;
            .Modal__upload__avatar--container {
              position: relative;
              max-width: 130px;
              max-height: 130px;
              width: 100%;
              height: 100%;
              border-radius: 100%;
              background: linear-gradient(to bottom, #02bdad, #036ccf);
              .Modal__upload__avatar--container-avatar {
                width: calc(100% - 6px);
                height: calc(100% - 6px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 0;
                margin: 0;
                border-radius: 100%;
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }
        }
      }
    }
    .Modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      background: rgba(0,0,0,.5);

      .Modal__layer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      align-items: flex-start;
      @media screen and (min-width: 640px) {
        align-items: center;
      }
      .Modal__wrapper {
        width: 90%;
        height: 90%;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
        margin: 5vw 0 5vh;
        @media screen and (min-width: 640px) {
          margin: 0;
        }
        .Modal__upload__border{
          position: absolute;
          width: 92%;
          height: 92%;
          border: 2px dashed $colorBackground2;
          &.active {
            border: 2px solid $colorEnergyBlue;
          }
        }
        .icon {
          width: 50px;
          margin: 30px auto;
          img {
            width: 100%;
          }
        }
        .Modal__title {
          font-size: 25px;
          letter-spacing: 2px;
          margin: 20px 0 0;
        }
        .Modal__close-btn {
          width: 35px;
          height: 35px;
          margin-left: auto;
          position: absolute;
          right: 35px;
          top: 25px;
          cursor: pointer;
          z-index: 10;
          $crossColor: $colorBackground2;
          &:before {
            content: "";
            width: 3px;
            height: 90%;
            background: $crossColor;
            display: block;
            position: absolute;
            left: 50%;
            transform-origin: center;
            transform: rotate(45deg);
          }
          &:after {
            content: "";
            width: 3px;
            height: 90%;
            background: $crossColor;
            display: block;
            position: absolute;
            left: 50%;
            transform-origin: center;
            transform: rotate(-45deg);
          }
        }
      }
      &.active {
        visibility: visible;
        opacity: 1;
        .Modal__wrapper {
          transform: scale(1);
        }
      }
    }
  }
  .CampaignForm__notes {
    .CampaignForm__note {
      display: flex;
      .CampaignForm__note--fields {
        width: 90%;
        .CampaignForm__notes--title {

        }
        .CampaignForm__note--remove {

        }
      }
    }
    .CampaignForm__note--side {
      width: 10%;
      text-align: center;
      padding-top: 200px;
      .CampaignForm__note--remove {
        color: white;
        cursor: pointer;
      }
    }
    .CampaignForm__note--add {
      color: white;
      border: 1px solid white;
      padding: 5px 10px;
    }
  }
}
