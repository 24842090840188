@import '../../../utils/_variables';

.ContactLight{
  font-family: Roboto;
  background: $colorBackground_light;
  padding-top: 180px;
  padding-bottom: 100px;
  .ContactLight__wrapper{
    max-width: 1000px;
    margin-right: 25px;
    margin-left: 25px;
    @media #{$tabletAndLarger}{
      margin-right: auto;
      margin-left: auto;
    }
    .ContactLight__content{
      background-color: $colorBackground2_light;
      position: relative;
      z-index: 2;
      padding: 20px 0;
      @media #{$tabletAndLarger}{
        display: flex;
        padding: 60px 0;
      }
      .ContactLight__content__back{
        background: url('../../../images/brand/back-contact-light-form.jpg');
        position: absolute;
        top:0;
        left: -35px;
        height: 100%;
        width: 50%;
        z-index: 1;
        display: block;
        @media #{$tabletAndLarger}{
          height: calc(100% + 20px);
        }
      }
      .ContactLight__content__color{
        background: $colorBackground2_light;
        position: absolute;
        top:0;
        height: 100%;
        width: 50%;
        z-index: 2;
        display: block;
      }
      $squareSize: 50px;
      .ContactLight__content__square1{
        background: $colorBackground_light;
        height: $squareSize;
        width: $squareSize;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 3;
        display: none;
        @media #{$tabletAndLarger}{
          display: block;
        }
      }
      .ContactLight__content__square2{
        background: $colorLifeGreen;
        height: $squareSize;
        width: $squareSize;
        position: absolute;
        bottom: 0;
        right: $squareSize;
        z-index: 3;
        display: none;
        @media #{$tabletAndLarger}{
          display: block;
        }
      }
      .ContactLight__content__rectangle{
        background: $colorEnergyBlue;
        height: 80px;
        width: 50px;
        position: absolute;
        top: 50px;
        right: -25px;
        z-index: 3;
        display: none;
        @media #{$tabletAndLarger}{
          display: block;
        }
      }
      .ContactLight__left{
        background-color: $colorBackground2_light;
        position: relative;
        z-index: 2;
        @media #{$tabletAndLarger}{
          flex: 2;
        }
        .ContactLight__left__content{
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          z-index: 2;
          padding: 0 15px 0 15px;
          @media #{$tabletAndLarger}{
            padding: 0 10px 0 120px;
          }
          .ContactLight__title{
            font-weight: 900;
            font-size: 50px;
            line-height: 1.1;
            color: black;
            align-self: flex-start;
          }
          .ContactLight__left__rectangle{
            width: 100px;
            height: 25px;
            background-color: $colorFluidPink;
            margin: 25px 0 0 0;
            @media #{$tabletAndLarger}{
              margin: 0;
            }
          }
          .blurb{
            align-self: flex-end;
            color: #787A7B;
            width: 100%;
            text-align: left;
            margin: 25px 0 0 0;
            bottom: 20%;
            @media #{$tabletAndLarger}{
              margin: 0;
            }
          }
        }
      }
      .ContactLight__right{
        position: relative;
        height: 100%;
        z-index: 2;
        @media #{$tabletAndLarger}{
          flex: 3;
        }
        .ContactLight__right__content{
          padding: 10px 20px;
          @media #{$tabletAndLarger}{
            padding: 0 18%;
          }
        }
        .errors-container {
          color: #dc4f4f;
          font-size: 13px;
          text-align: left;
          padding: 5px 25px 5px 10px;
          border-radius: 1px;
          border: 1px solid #dc4f4f;
          position: relative;
          margin: 0 0 30px 0;
        }
        .input-container{
          label{
            color: $colorBackground;
            text-align: left;
            font-size: 12px;
            line-height: 12px;
            margin-top: 0px;
          }

          .input-wrapper{
            position: relative;
            .error-message{
              color: #dc4f4f;
              font-size: 12px;
              position: absolute;
              right: 0;
              bottom: -17px;
            }
            .error-icon{
              color: #dc4f4f;
              display: none;
              position: absolute;
              right: 10px;
              top: 10px;
            }
            .ok-icon{
              position: absolute;
              right: 5px;
              top: 22px;
              color: #49E1CA;
            }
          }
        }
        .errors-container {
          display: flex;
          color: #dc4f4f;
          font-size: 13px;
          text-align: left;
          padding: 0px 25px 0px 10px;
          border-radius: 1px;
          border: 1px solid #dc4f4f;
          position: relative;
          margin: 0;
          background: rgba(255, 255, 255, 0.01);
          align-items: center;
          visibility: hidden;
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          transition: ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms padding, ease 300ms margin;
          &.active{
            visibility: visible;
            opacity: 1;
            max-height: inherit;
            min-height: 35px;
            padding: 5px 25px 5px 10px;
            margin: 0 0 30px 0;
          }
          &:hover{
            background: rgba(255, 255, 255, 0.03)
          }
          .error-dismiss {
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            top: 8px;
            right: 10px;
            background: rgba(220, 79, 79, 0.22);
            cursor: pointer;
            outline: none;
            transition: ease 200ms all;
            &::before{
              content: '';
              height: 1px;
              width: 10px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
              background: rgba(255, 255, 255, 0.5);
              transition: ease 200ms all;
            }
            &::after{
              content: '';
              height: 1px;
              width: 10px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
              background: rgba(255, 255, 255, 0.5);
              transition: ease 200ms all;
            }
            &:hover{
              background: rgba(220, 79, 79, 0.5);
              &::before{
                background: rgba(255, 255, 255, 0.7);
              }
              &::after{
                background: rgba(255, 255, 255, 0.7);
              }
            }
          }
        }
        form.contact-light{
          width: 100%;
          input, select, textarea{
            background-color: #202227;
            border: none;
            border-bottom: 2px solid #83858C;
            border-radius: 0px;
            color: $colorBackground;
            display: inline-block;
            padding-left: 0;
            text-align: left;
            margin: 0;
            width: 100%;
            box-shadow: none;
          }
          input.error, select.error, textarea.error{
            border-bottom: 2px solid #dc4f4f;
          }
          select {
            background-image: none;
          }
          a {
            text-decoration: none;
          }
          ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $colorBackground;
            opacity: 1; /* Firefox */
          }

          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $colorBackground;
          }

          ::-ms-input-placeholder { /* Microsoft Edge */
            color: $colorBackground;
          }

          input:focus, select:focus, textarea:focus{
            border-bottom: 2px solid #3A74E0;
            box-shadow: none;
          }

          textarea:placeholder-shown + label, input:placeholder-shown + label {
            cursor: text;
            max-width: 66.66%;
            color: $colorBackground;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transform-origin: left bottom;
            transform: translate(0, 1.9rem) scale(1.5);
            &:after{
              content: attr(data-label);
            }
          }
          .input-wrapper {
            display: flex;
            flex-flow: column-reverse;
            margin-bottom: 1em;
          }
          input, select, textarea{
            z-index:1;
            background-color: transparent;
          }
          select option{
            background-color: #202227;
          }
          label{
            z-index:0;
          }
          label, input, textarea {
            transition: all 0.2s;
            touch-action: manipulation;
          }
          ::-webkit-input-placeholder {
            opacity: 0;
            transition: inherit;
          }

          textarea:focus::-webkit-input-placeholder, input:focus::-webkit-input-placeholder {
            opacity: 1;
          }

          textarea:not(:placeholder-shown) + label,
          textarea:focus + label,
          input:not(:placeholder-shown) + label,
          input:focus + label,
          select:not(.firstOptionSelected) + label,
          select:focus + label {
            color: $colorBackground !important;
            transform: translate(0, 0) scale(1);
            &:after{
              content: attr(data-label-small);
            }
            cursor: pointer;
          }
        }
      }
      .form__message{
        color: $colorBackground;
        font-size: 12px;
        margin-top: 15px;
        text-align: left;
      }
      .form__footer{
        padding-top: 10px;
        .form__footer__left{
          padding-left: 0;
          text-align: left;
          .cta-button{
            background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
            color: #fff;
            cursor: pointer;
            text-decoration: none;
            text-align: center;
            font-weight: normal;
            border-radius: 4px;
            font-weight: 900;
            font-size: 16px;
            padding: 20px 40px;
          }
        }
      }
    }
  }
}
