@import  '../../utils/_variables';
@import '../../utils/_util';


.flex-parent-popup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
  }
  
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
  
    background-color: rgba(0, 0, 0, 0.55);
    z-index: 400;
  }


  
  .popup_inner {
    margin: auto;
    background: $colorBDarkGray;
    letter-spacing: 2px;
    color: white;
    height: auto;
    overflow-y: scroll;
    max-width: 550px;
    min-width: 450px;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    z-index: 10000;
  
    /* border: .5px solid white; */
  }
  
  .closeSignup {
    /* height: 30px; */
    padding: 6px 10px 0px 0px;
    align-self: flex-end;
    cursor: pointer;
    left: 10px;
    right: 10px;
  }
  
  .popupHeader {
    text-transform: uppercase;
    font-size: 35px;
    text-align: left;
    font-weight: 700;
  }
  
  .signupBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1em 1em 1em;
    width: 80%;
    // margin: 25px 50px 50px 50px;
    margin: auto;
  }
  
  .signupBody input {
    display: block;
    width: 98.7%;
    background: $colorBGray;
    color: white;
    border: white 1px solid;
    padding: 10px 0px 10px 4px;
    border-collapse: collapse;
    /* height: 2em !important; */
    font-size: .7em;
    border-style:solid;
  }
  
  
  .signupBody input:focus {
    border-style:solid;
    border-color:white;
    outline-color: $colorBlue;
    -webkit-box-shadow: inset 0px 0px 8px 2px $colorBlue;
    -moz-box-shadow: inset 0px 0px 8px 2px $colorCyan;
    box-shadow: inset 0px 0px 8px 2px $colorBlue;
  }
  
  .signupBody button {
    /* display: block; */
    width: 100%;
    padding: 8px 0px 8px 4px;
    /* height: 2em; */
    border: 0px;
    font-size: 1em;
    cursor: pointer;
    background-color: white;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .signupBody button:hover {
    background-color: $colorBlue;
    color: white;
  }
  
  .subHeader {
    text-align: left;
    font-weight: 500;
    letter-spacing: 0em;
    line-height: 1.5em;
    font-size: 15px;
    margin: 5px 0px 10px 0px;
  }
  
//   @media only screen and (max-width: 500px) {
//     .popup_inner {
//       min-width: 250px;
//       height: 350px;
//       max-width: 95%;
//     }
  
//     .signupBody {
//       left: 45%;
//     }
  
//     .popupHeader {
//       font-size: 1.3em;
//     }
  
//     .closeSignup {
//       font-size: 1.5m;
//     }
//   }
  
  .blueButton {
    background-color: rgba(172, 0, 255, 1);
    color: white;
  }
  
  .signup-margin {
    margin-bottom: 10px;
  }
  
  .signup-bottom-margin {
    margin-bottom: 20px;
  }
  

  .form-container-quick {
    width: 50%;
    margin: 0 auto;
    max-width: 600px;
    @media screen and (max-width: 768px){
      width: 90%;
    }
    .input-container{
      max-width: 400px;
      margin: 0 auto;
      label{
        font-size: 18px;
        margin: 10px 0 6px 0;
        display: block;
        color: #fff;
        @media screen and (max-width: 768px){
          font-size: 12px;
          letter-spacing: 2px;
        }
      }
      .input-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          input, select.country-dropdown{
            color: #fff;
            width: 100%;
            height: 50px;
            outline: none;
            border: 2px solid #fff;
            border-radius: 2px;
            background: transparent;
            font-size: 18px;
            padding: 10px;
            font-weight: 300;
            text-align: left;
            @media screen and (max-width: 768px){
              font-size: 16px;
              letter-spacing: 1.2px;
            }
          }
          select.country-dropdown{
            padding: 10px 16px 10px 16px;
          }
          select{
            background: transparent;
            color: #fff;
            border: none;
            font-size: 18px;
            outline: none;
          }
          .react-datepicker-wrapper {
              width: 100%;
              .react-datepicker__input-container{
                width: 100%;
              }
          }
          .error-message{
              box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
              background-color: #fff6f6;
              color: #9f3a38;
              position: relative;
              min-height: 16px;
              margin: 0px 0px 0px 0px;
              padding: 5px 10px;
              -webkit-transition: opacity .1s ease,color .1s ease,background .1s ease,box-shadow .1s ease;
              transition: opacity .1s ease,color .1s ease,background .1s ease,box-shadow .1s ease;
              border-radius: 2px;
              font-size: 14px;
              display: inline-flex;
              margin-left: auto;
              &:before {
                  content: "";
                  position: absolute;
                  width: 11px;
                  height: 11px;
                  top: -4.5px;
                  right: 15px;
                  background: #fff6f6;
                  border-top: solid 1px #e0b4b4;
                  border-left: solid 1px #e0b4b4;
                  transform: rotate(45deg);
              }
          }
          .ok-icon,
          .error-icon {
              position: absolute;
              top: 0px;
              right: 0;
              color: #3fc70c;
              padding: 15px 15px;
          }
          .error-icon{
              color: #d81e18;
          }
      }
    }
    .btn.btn-submit{
      margin: 40px auto;
      display: block;
      max-width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  form.contact-light-quick{
    width: 100%;
    input, select, textarea{
      background-color: #202227;
      border: none;
      border-bottom: 2px solid #83858C;
      border-radius: 0px;
      color: $colorBackground;
      display: inline-block;
      padding-left: 0;
      text-align: left;
      margin: 0;
      width: 100%;
      box-shadow: none;
    }
    input.error, select.error, textarea.error{
      border-bottom: 2px solid #dc4f4f;
    }
    select {
      background-image: none;
    }
    a {
      text-decoration: none;
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $colorBackground;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $colorBackground;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: $colorBackground;
    }

    input:focus, select:focus, textarea:focus{
      border-bottom: 2px solid #3A74E0;
      box-shadow: none;
    }

    textarea:placeholder-shown + label, input:placeholder-shown + label {
      cursor: text;
      max-width: 66.66%;
      color: $colorBackground;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform-origin: left bottom;
      transform: translate(0, 1.9rem) scale(1.5);
      &:after{
        content: attr(data-label);
      }
    }
    .input-wrapper {
      display: flex;
      flex-flow: column-reverse;
      margin-bottom: 1em;
    }
    input, select, textarea{
      z-index:1;
      background-color: transparent;
    }
    select option{
      background-color: #202227;
    }
    label{
      z-index:0;
    }
    label, input, textarea {
      transition: all 0.2s;
      touch-action: manipulation;
    }
    ::-webkit-input-placeholder {
      opacity: 0;
      transition: inherit;
    }

    textarea:focus::-webkit-input-placeholder, input:focus::-webkit-input-placeholder {
      opacity: 1;
    }

    textarea:not(:placeholder-shown) + label,
    textarea:focus + label,
    input:not(:placeholder-shown) + label,
    input:focus + label,
    select:not(.firstOptionSelected) + label,
    select:focus + label {
      color: $colorBackground !important;
      transform: translate(0, 0) scale(1);
      &:after{
        content: attr(data-label-small);
      }
      cursor: pointer;
    }
  }
  

  .form__footer-quick{
    padding-top: 10px;
    .form__footer__left-quick{
      padding-left: 0;
      text-align: left;
      .cta-button-quick{
        background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        font-weight: normal;
        border-radius: 4px;
        font-weight: 900;
        font-size: 16px;
        padding: 20px 40px;
      }
    }
  }