@import  '../../utils/_variables';

.navigation {
  align-items: center;
  background-color: initial;
  display: flex;
  flex-direction: row;
  height: initial;
  justify-content: center;
  left: 0;
  opacity: 1;
  padding-top: 0;
  position: initial;
  top: 120px;
  transition: 200ms ease all;
  visibility: visible;
  width: 100%;

  ul {
    display: flex;
    flex-direction: row;
    padding: 0;
    list-style: none;
    margin: 0;
    width: initial;

    li {
      height: 100%;
      margin-left: 20px;
      position: relative;

      a,
      button {
        align-items: center;
        background: initial;
        border: none;
        cursor: pointer;
        display: inline-flex;
        font-family: Roboto;
        height: 100%;
        justify-content: center;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin: 0;
        padding: 15px 20px;
        position: relative;
        text-decoration: none;
        transition: 300ms ease background;
        width: 100%;
      }
    }

    &.navigation-left {
      height: 100%;
      margin-left: 50px;

      li {
        a,
        button {
          font-size: 18px;
          font-weight: 900;

          &:after {
            background-position-x: -45px;
            background-repeat: no-repeat;
            background-size: 35px;
            background: none;
            border-bottom: 0px solid #49e1ca;
            border-image-slice: 1;
            border-image-source: linear-gradient(to left, #3a74e0, #a142ff );
            border-left: 0px solid #49e1ca;
            border-right: 0px solid #49e1ca;
            border-top: 3px solid #49e1ca;
            bottom: 0;
            color: #FFF;
            content: "";
            display: block;
            height: inherit;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            transition: 200ms ease all;
            width: 100%;
            z-index: 10;
          }
  
          &:hover:after,
          &.active:after {
            background-repeat: no-repeat;
            background-position-x: 0px;
            opacity: 1;
          }
        }
      }
    }

    &.navigation-right {
      align-items: center;
      margin-left: auto;

      li {
        align-items: center;
        display: flex;

        button {
          background-color: rgba(255, 255, 255, 0.1);
          border: solid 2px rgba(255, 255, 255, 0.1);
          border-radius: 22px;
          height: 44px;
          padding: 0;
          width: 144px;

          i {
            color: $colorGray;
            font-size: 16px;
            margin-right: 8px;    
          }

          span {
            color: white;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      &.navigation-left {
        margin-left: 0;

        li {
          a {
            font-size: 15px;
            padding: 15px 10px;
          }
        }
      }

      &.navigation-right {
        li {
          margin-left: 0;
        }
      }
    }
  }
}