@import "../../../utils/variables";

.AddGameModal {
  // position: relative;
  padding: 8px;
}

.AddGameModal--button {
  margin-top: 10px;
}


#AddModal {
  .AddGameModal--textfield {
    width: 100%;
  }
  
  .AddGameModal--list {
    margin: 5px;
  }
  
  .AddGameModal--close {
    margin-top: 10px;
    // position: absolute;
    // margin-right: auto;
    // right: 0;
  }
  
  // * {
  //   padding: 3px;
  // }
}